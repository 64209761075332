import React, { FunctionComponent, useEffect, useState } from "react";
import ContentAnimation from "modules/App/OrdersLayout/Content/ContentAnimationWrapper";
import { IOrder, IOrderType } from "@medlog/shared/models/orders/IOrder";
import { prebookedFormRoutes, samedayFormRoutes, urgentFormRoutes } from "modules/Routing/routes";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import ContentAnimationWrapper from "modules/App/OrdersLayout/Content/ContentAnimationWrapper";
import useOrderContext from "modules/App/OrdersLayout/OrderContext/useOrderContext";
import routesPaths from "modules/Routing/routesPaths";
import { ContentContainer } from "modules/App/OrdersLayout/Content/Content.components";
import OrderType from "modules/OrderingForm/OrderType/OrderType";
import useSettingsContext from "modules/OrderingRoutingLayout/SettingsContext/useSettingsContext";
import { IOrderState } from "@medlog/shared/models/orders/IOrderState";
import {
  isPathAllowed,
  getPathsAllowState,
  isDeliveriesDisabledPathAllowed,
} from "common/navigation/navigationHandler";
import { isSamedayDisabled, isSamedayFlow, isUrgentDisabled, isUrgentFlow } from "common/helpers/deliveriesHelpers";
import getOrderInitialTime from "common/helpers/timer/getOrderInitialTime";
import useAnalyticsPathChange from "common/analytics/usePathChange";

const routes = (type: IOrderType) => {
  switch (type) {
    case IOrderType.URGENT:
      return urgentFormRoutes;
    case IOrderType.SAME_DAY:
      return samedayFormRoutes;
    case IOrderType.PRE_BOOK:
      return prebookedFormRoutes;
  }
};

const shouldRedirect = (order: IOrder) => {
  if (order.type === IOrderType.URGENT) {
    return order.samedayInstead
      ? [undefined, IOrderState.CREATED, IOrderState.SAMEDAY_INSTEAD, IOrderState.PAYMENT_PENDING].includes(
          order.currentState
        )
      : [undefined, IOrderState.CREATED].includes(order.currentState);
  } else if (order.type === IOrderType.SAME_DAY) {
    return order.currentState === undefined || order.currentState === IOrderState.CREATED;
  }
  return false;
};

const Content: FunctionComponent = () => {
  const { order, orderDetails, clearOrder } = useOrderContext();
  const { type } = order;
  const location = useLocation();
  const { sameDaysEnabled, urgentsEnabled, deliveriesEnabled } = useSettingsContext();
  const [prevPathname, setPrevPathname] = useState("");
  const history = useHistory();

  useAnalyticsPathChange();

  useEffect(() => {
    const allowedPaths = [routesPaths.HOME, routesPaths.REJECTION];
    if (shouldRedirect(order) && !allowedPaths.includes(location.pathname)) {
      if (deliveriesEnabled === false && order.type != undefined && order.type != IOrderType.PRE_BOOK) {
        history.push(routesPaths.REJECTION, true);
      } else if (urgentsEnabled === false && order.type === IOrderType.URGENT) {
        history.push(routesPaths.REJECTION, true);
      } else if (sameDaysEnabled === false && order.type === IOrderType.SAME_DAY) {
        history.push(routesPaths.REJECTION, true);
      }
    }
  }, [sameDaysEnabled, urgentsEnabled, deliveriesEnabled, order, location]);

  useEffect(() => {
    if (order.currentState === IOrderState.LOST && location.pathname !== routesPaths.REJECTION) {
      history.push(routesPaths.TIMESUP, true);
    }
  }, [order.currentState]);

  useEffect(() => {
    const pathname = location.pathname;
    if (order && pathname !== prevPathname) {
      setPrevPathname(pathname);
      const confirmationStates = [
        IOrderState.PAYMENT_SUCCEEDED,
        IOrderState.COPIED,
        IOrderState.COURIER_ON_THE_WAY,
        IOrderState.PARCEL_PICKED_UP,
        IOrderState.PARCEL_DELIVERED,
        IOrderState.ARCHIVED,
      ];

      const orderTime = getOrderInitialTime(order);
      if (pathname === routesPaths.TIMESUP && orderTime && orderTime <= 1000) {
        //Always allow user to go to the TIMESUP page.
        history.replace(pathname, true);
      } else if (pathname === routesPaths.MY_ORDERS || pathname === routesPaths.RATING) {
        //Always allow nawigation to my orders.
        history.replace(pathname);
      } else if (pathname === routesPaths.HOME) {
        //For home path check if there is no need of order clear.
        const allowedPaths = isUrgentFlow(order)
          ? [undefined, IOrderState.CREATED, IOrderState.PAYMENT_PENDING, IOrderState.SAMEDAY_INSTEAD]
          : [undefined, IOrderState.CREATED];
        if (!allowedPaths.includes(order.currentState) || order.paymentInitialized) {
          clearOrder();
          history.replace(routesPaths.HOME, true);
        }
      } else if (order.currentState === IOrderState.SAMEDAY_INSTEAD) {
        history.replace(routesPaths.RESCHEDULE, true);
      } else if (order.currentState === IOrderState.REJECTED) {
        history.replace(routesPaths.REJECTION, true);
      } else if (order.currentState === IOrderState.LOST && order.cancelled === true) {
        history.push(routesPaths.HOME, true);
      } else if (order.currentState === IOrderState.LOST || order.currentState === IOrderState.LOST_RESCHEDULED) {
        if (pathname === routesPaths.REJECTION) {
          history.push(routesPaths.REJECTION);
        } else {
          history.replace(routesPaths.TIMESUP);
        }
      } else if (order.currentState && confirmationStates.includes(order.currentState)) {
        //For paid order always navigate to confirmation screen.
        history.replace(routesPaths.CONFIRMATION, true);
      } else if (
        !order.paid &&
        order.paymentInitialized &&
        pathname !== routesPaths.CONFIRMATION &&
        orderTime &&
        orderTime > 0
      ) {
        //Navigate user to payment screens if order is not paid and payment was initialized.
        const paths = [routesPaths.CANCELATION, routesPaths.CONFIRMATION];
        history.replace(paths.includes(pathname) ? pathname : routesPaths.SUMMARY, true);
      } else if (
        //For not accepted yet urgent navigate user to availability screen.
        order.type === IOrderType.URGENT &&
        order.currentState === IOrderState.CREATED &&
        !isUrgentDisabled(deliveriesEnabled, urgentsEnabled)
      ) {
        history.replace(routesPaths.AVAILABILITY, true);
      } else if (isPathAllowed(pathname, order, orderDetails)) {
        //If path is allowed then just navigate user to it.
        history.replace(pathname, true);
      } else {
        //Handle next allowed path.
        const paths = getPathsAllowState(order, orderDetails); //Get allowed/disallowed paths.
        const firstDissalowedPath = paths.findIndex(p => !p.allowed); //Find first disallowed path and navigate user to previous one.

        if (firstDissalowedPath > 0) {
          const path = paths[firstDissalowedPath - 1].path;
          if (
            //Additional check for confirmation screen. Becaouse of Firebase Functions delay confirm screen is also availaable for not paid state.
            path === routesPaths.CONFIRMATION &&
            order.currentState === IOrderState.PAYMENT_PENDING &&
            order.type === IOrderType.URGENT
          ) {
            history.replace(routesPaths.SUMMARY, true);
          } else if (isDeliveriesDisabledPathAllowed(path, order, urgentsEnabled, sameDaysEnabled, deliveriesEnabled)) {
            history.replace(path, true);
          }
        } else {
          history.replace(routesPaths.HOME, true);
        }
      }
    } else if (order && pathname === routesPaths.HOME) {
      //Handle home actions on disabled deliveries.
      if (isUrgentFlow(order) && isUrgentDisabled(deliveriesEnabled, urgentsEnabled)) {
        clearOrder();
      } else if (isSamedayFlow(order) && isSamedayDisabled(deliveriesEnabled, sameDaysEnabled)) {
        clearOrder();
      }
    }
  }, [
    order,
    order.pickupAndDropoff,
    orderDetails,
    location.pathname,
    deliveriesEnabled,
    urgentsEnabled,
    sameDaysEnabled,
  ]);

  if (type === undefined || location.pathname === routesPaths.HOME)
    return (
      <ContentAnimation tag="orderType" inCondition="/">
        <OrderType />
      </ContentAnimation>
    );

  return (
    <ContentContainer>
      <Switch location={location}>
        {type !== undefined &&
          routes(type).map((route, index) => (
            <Route key={index} exact path={route.path}>
              {({ match }) => {
                return (
                  <ContentAnimationWrapper tag={index.toString()} inCondition={match?.path}>
                    <route.component />
                  </ContentAnimationWrapper>
                );
              }}
            </Route>
          ))}
      </Switch>
    </ContentContainer>
  );
};

export default Content;
