import React, { FunctionComponent } from "react";
import {
  AvailabilityInfo,
  AvailabilityWrapper,
  IntroductionContainer,
  IntroductionDescription,
  IntroductionHeader,
  FooterContainer,
  FooterWrapper,
} from "modules/shared/components/Sidebar/Introduction/Introduction.components";
import { ReactComponent as TimeIcon } from "modules/shared/components/Sidebar/Introduction/timeIcon.svg";
import ArrowRight from "modules/shared/components/Sidebar/Introduction/arrow_right.png";
import { Transition } from "react-transition-group";
import Config from "@medlog/shared/Config";

const Introduction: FunctionComponent = () => {
  return (
    <Transition timeout={20} in={true} appear>
      {status => (
        <IntroductionContainer status={status}>
          <IntroductionHeader>
            Welcome to <br />
            the ordering system
          </IntroductionHeader>
          <IntroductionDescription>The highest medical delivery standards on the market</IntroductionDescription>
          <AvailabilityWrapper>
            <IntroductionDescription>Ordering available:</IntroductionDescription>
            <AvailabilityInfo>
              <TimeIcon /> <span>7 days a week</span>
            </AvailabilityInfo>
            <AvailabilityInfo>
              <TimeIcon /> <span>From 8 am to 5 pm</span>
            </AvailabilityInfo>
          </AvailabilityWrapper>
          <FooterContainer>
            <FooterWrapper>
              <a href="/my-orders">
                My Orders <img src={ArrowRight} />
              </a>
              <a href={Config.common.couriers_url}>
                Medical Couriers <img src={ArrowRight} />
              </a>
            </FooterWrapper>
          </FooterContainer>
        </IntroductionContainer>
      )}
    </Transition>
  );
};

export default Introduction;
