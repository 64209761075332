import React, { useCallback, useEffect, useState } from "react";
import { Container, Wrapper, Outline, ContentWrapper, LogoWrapper, LogoImage } from "modules/Rating/Rating.components";
import { firestore } from "config/firebase/firebase";
import LogoDrop from "@medlog/shared/icons/icon_logo_drop.png";
import Button from "@medlog/shared/components/Button";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { NON_EXISTING_ID } from "modules/App/OrdersLayout/OrderContext/Storage/baseOrder";
import PageLoader from "modules/App/PageLoader";
import SendRate from "modules/Rating/SendRate";
import routesPaths from "modules/Routing/routesPaths";
import { useHistory } from "react-router";
import ShowRate from "modules/Rating/ShowRate";
import { IOrder } from "@medlog/shared/models/orders/IOrder";
import IRate from "@medlog/shared/models/rating/IRate";
import { sendRate } from "common/firebase/rating/sendRate";
import IRating from "@medlog/shared/models/rating/IRating";

const Rating = () => {
  const [uid, setUid] = useState<string>(sessionStorage.getItem("rateUid") || NON_EXISTING_ID);
  const [rate, setRate] = useState<IRate | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  const history = useHistory();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id = params.get("uid");
    const rating = Number(params.get("rate"));

    if (id) {
      sessionStorage.setItem("rateUid", id);
      setUid(id);
    }
    if (rating && rating > 0 && rating < 4) setRate(rating);

    history.replace(routesPaths.RATING);
  }, [location.search]);

  const rateRef = firestore.collection("rates").doc(uid);
  const [rateData, rateLoading] = useDocumentData<IRating>(rateRef);

  const orderRef = firestore.collection("orders").doc(uid);
  const [order, orderLoading] = useDocumentData<IOrder>(orderRef);

  useEffect(() => {
    if (rateData) setRate(rateData.rate);
  }, [rateData?.rate]);

  const rateOrder = useCallback(async () => {
    setLoading(true);
    try {
      await sendRate(order!, rate!, description);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [order?.uid, description, rate]);

  const showOrderRatedScreen = (!order || !!rateData) && !loading;

  return rateLoading || orderLoading ? (
    <PageLoader />
  ) : (
    <Container>
      <Wrapper>
        <Outline />
        <ContentWrapper>
          <LogoWrapper>
            <LogoImage src={LogoDrop} />
          </LogoWrapper>
          {showOrderRatedScreen ? (
            <ShowRate rate={rate} />
          ) : (
            <SendRate
              rate={rate!}
              description={description}
              rateChanged={setRate}
              descriptionChanged={setDescription}
            />
          )}
          <Button
            loading={loading}
            onClick={() => {
              if (showOrderRatedScreen) {
                history.push(routesPaths.HOME);
              } else {
                rateOrder();
              }
            }}
          >
            {showOrderRatedScreen ? "New order" : "Send"}
          </Button>
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
};

export default Rating;
