import { createGlobalStyle } from "styled-components";
import { isMobileOnly, isMobileSafari } from "react-device-detect";

const GlobalStyle = createGlobalStyle`
  html { 
    //bug: https://issueexplorer.com/issue/ionic-team/ionic-framework/23994
    height: ${isMobileOnly && isMobileSafari ? "100vh" : "100%"};
  }
  body {
    //bug: https://issueexplorer.com/issue/ionic-team/ionic-framework/23994
    height: ${isMobileOnly && isMobileSafari ? "100vh" : "100%"};
    background: #f8f8f9;
		margin: 0;
    color: #4A4A4F;
    font-family: 'Barlow', sans-serif;
    -webkit-tap-highlight-color: transparent;
  }

  button {
    font-family: 'Barlow', sans-serif; 
  }
  
  input:-webkit-autofill { 
    -webkit-background-clip: text;
    background-clip: text;
  }
`;

export default GlobalStyle;
