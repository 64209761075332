import { colors } from "@medlog/shared/styles/colors";
import { media } from "@medlog/shared/styles/media";
import { MobileDescription, MobileHeader } from "modules/shared/styles/mixins";
import styled from "styled-components/macro";

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  margin-top: 256px;

  > :last-child {
    bottom: 24px;
    position: sticky;
  }
`;

export const DetailsContainer = styled.div`
  width: calc(100% - 64px);
  //Set min height to prevent bottom button resizing. Values: buttonHeight with margin, margin top, margin bottom.
  min-height: calc(100% - 80px - 46px - 32px);
  display: flex;
  flex-direction: column;
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 46px;
  margin-bottom: 32px;
`;

export const Header = styled.h1`
  margin: 0px;
  color: ${colors.gray._800};
  ${MobileHeader}
`;

export const Description = styled.span`
  ${MobileDescription};
  color: ${colors.gray._600};
  margin-top: 8px;

  ${media.down("MOBILE")`
    margin-bottom: 48px;
  `}

  ${media.down("MOBILE_SMALL")`
    margin-bottom: 32px;
  `}

  ${media.down("MOBILE_TINY")`
    margin-bottom: 16px;
  `}
`;

export const DetailsSpan = styled.span`
  ${media.down("MOBILE")`
    font-size: 16px;
  `}
  ${media.down("MOBILE_SMALL")`
    font-size: 16px;
  `}
  ${media.down("MOBILE_TINY")`
    font-size: 14px;
  `}
`;

export const RedSpan = styled.span`
  color: ${colors.red._500};

  ${media.down("MOBILE")`
    font-size: 18px;
  `}
  ${media.down("MOBILE_SMALL")`
    font-size: 16px;
  `}
  ${media.down("MOBILE_TINY")`
    font-size: 14px;
  `}
`;
