import React, { FunctionComponent, useCallback, useRef, useState } from "react";
import Input from "@medlog/shared/components/Input/Input";
import useOnClickOutside from "common/helpers/useOnClickOutside";
import { IAutocomplete, ISuggestion } from "common/types/PostcodesAPI/IAutocomplete";
import {
  PostcodeInputContainer,
  PostcodeSuggestionsContainer,
} from "modules/shared/components/Form/Postcodes/PostcodeInput/PostcodeInput.components";
import { useEffect } from "react";
import { AddressSuggestion } from "modules/shared/components/Form/Postcodes/AddressInput/AddressInput.components";
import Config from "config/Config";

type AddressInputProps = {
  postcode: string;
  disabled: boolean;
  fieldName: string;
  value: ISuggestion | string;
  error?: string;
  isMobile?: boolean;
  isFullScreen?: boolean;
  onClick?: () => void;
  onDone?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
};

const filterSuggestions = (suggestions: ISuggestion[], filterPhase: string) => {
  return suggestions.filter(suggestion => suggestion.address.toLowerCase().includes(filterPhase.toLowerCase()));
};

const AddressInput: FunctionComponent<AddressInputProps> = ({
  postcode,
  disabled,
  fieldName,
  value,
  error,
  setFieldValue,
  isMobile,
  isFullScreen,
  onClick,
  onDone,
}) => {
  const [suggestions, setSuggestions] = useState<ISuggestion[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [filterPhase, setFilterPhase] = useState("");
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);

  const suggestionsWrapperRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const addressInputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  useOnClickOutside(suggestionsWrapperRef, () => {
    setSuggestions([]);
    setShowSuggestions(false);
  });

  const onSuggestionClick = async (suggestion: ISuggestion) => {
    setFieldValue(fieldName, suggestion);
    setShowSuggestions(false);

    onDone && onDone();
  };

  const getSuggestedAddressess = useCallback(
    async (postcode: string) => {
      try {
        const response = await fetch(
          `https://api.getAddress.io/autocomplete/${postcode}?api-key=${Config.get_address_io.public_key}&all=true`
        );
        const data: IAutocomplete = await response.json();
        const suggestions = data.suggestions ?? [];
        if (postcode.split(" ").join("") === "EC1N7UU") {
          suggestions.unshift({ address: "Medical Laboratories, 1-5 Portpool Lane, London", id: "medical-laboratory" });
        }
        setSuggestions(suggestions);
        setShowSuggestions(suggestions.length > 0);
      } catch (errpr) {
        setSuggestions([]);
        setShowSuggestions(false);
      }
    },
    [filterPhase]
  );

  const getValue = () => (typeof value === "string" ? value : value.address ?? "");

  useEffect(() => {
    if (isMobile) return;
    if (!disabled && getValue() === "" && postcode !== "") {
      getSuggestedAddressess(postcode);
      addressInputRef.current.focus();
    }
  }, [disabled]);

  useEffect(() => {
    setFilterPhase(getValue());
    setActiveSuggestionIndex(0);
  }, [value]);

  const onInputFocus = () => {
    if (getValue() === "") getSuggestedAddressess(postcode);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!suggestions || !showSuggestions || e.shiftKey) return;

    const filteredSuggestions = suggestions.filter(suggestion =>
      suggestion.address.toLowerCase().includes(filterPhase.toLowerCase())
    );
    switch (e.key) {
      case "Enter":
        e.preventDefault();
        if (filteredSuggestions[activeSuggestionIndex]) {
          onSuggestionClick(filteredSuggestions[activeSuggestionIndex]);
          setActiveSuggestionIndex(-1);
        }
        break;
      case "ArrowUp":
        e.preventDefault();
        if (activeSuggestionIndex > 0) setActiveSuggestionIndex(activeSuggestionIndex - 1);
        suggestionsWrapperRef.current.children[1].scroll({ top: activeSuggestionIndex * 48 - 48, behavior: "smooth" });

        break;
      case "ArrowDown":
        e.preventDefault();
        if (activeSuggestionIndex + 1 < filteredSuggestions.length) setActiveSuggestionIndex(activeSuggestionIndex + 1);
        suggestionsWrapperRef.current.children[1].scroll({ top: activeSuggestionIndex * 48, behavior: "smooth" });
        break;
      default:
        break;
    }
  };

  const filteredSuggestions = filterSuggestions(suggestions, filterPhase);

  return (
    <PostcodeInputContainer ref={suggestionsWrapperRef} onClick={onClick}>
      {/* To hide autosuggestions. https://stackoverflow.com/a/31613047 */}
      <input type="text" style={{ display: "none" }} />
      <Input
        title={getValue()}
        autoComplete="off"
        type={isMobile ? "text" : "search"}
        onDone={() => onDone && onDone()}
        disabled={disabled}
        onKeyDown={onKeyDown}
        label={"Street"}
        inputSize={isMobile ? "mobile" : "smedium"}
        onChange={e => {
          setFieldValue(fieldName, e.target.value);
          getSuggestedAddressess(postcode);
        }}
        onFocus={onInputFocus}
        autoFocus={(!disabled && getValue().length === 0) || isFullScreen}
        ref={addressInputRef}
        value={getValue()}
        error={error}
        hideErrorMessage
        hideErrorIcon
        hideEndAdornment
      />
      {showSuggestions && (
        <PostcodeSuggestionsContainer suggestionsVisible={filteredSuggestions.length > 0}>
          {suggestions.length && !disabled && filteredSuggestions.length > 0
            ? filteredSuggestions.map((suggestion, index) => (
                <AddressSuggestion
                  key={index}
                  active={activeSuggestionIndex === index}
                  onClick={() => onSuggestionClick(suggestion)}
                >
                  <span> {suggestion.address}</span>
                </AddressSuggestion>
              ))
            : null}
        </PostcodeSuggestionsContainer>
      )}
    </PostcodeInputContainer>
  );
};

export default AddressInput;
