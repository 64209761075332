import styled from "styled-components";

export const MobileInputContainer = styled.div`
  width: 100vw;
  height: 100%;
  position: fixed;
  background-color: white;
  top: 0;
  left: 0;
  z-index: 100000;
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
