import React, { FunctionComponent, useCallback } from "react";
import Heading from "modules/shared/components/Heading";
import DateButton from "modules/OrderingForm/DeliveryDate/components/DateButton/DateButton";
import {
  FormContainer,
  Container,
  Wrapper,
  HeroImage,
  WhiteFooter,
  PageContainer,
  MobileButtonContainer,
} from "modules/OrderingForm/DeliveryDate/DeliveryDate.components";
import { addDays, isSameDay } from "date-fns";
import { Form, Formik, FormikHelpers } from "formik";
import useOrderContext from "modules/App/OrdersLayout/OrderContext/useOrderContext";
import routesPaths from "modules/Routing/routesPaths";
import { useHistory } from "react-router";
import Button from "@medlog/shared/components/Button";
import SectionContainer from "modules/shared/components/SectionContainer/SectionContainer";
import { deliveryDateValidationSchema, OrderDateFormValues } from "modules/OrderingForm/DeliveryDate/DeliveryDateForm";
import Hero from "modules/OrderingForm/DeliveryDate/icons/hero.png";
import { NON_EXISTING_ID } from "modules/App/OrdersLayout/OrderContext/Storage/baseOrder";
import MobileSheet from "modules/shared/components/Mobile/MobileSheet";
import { useIsMobileDown } from "common/helpers/useMedia";
import MobileButton from "modules/shared/components/Mobile/MobileButton";
import { WhiteCover } from "modules/App/OrdersLayout/OrdersLayout.components";
import { IOrder } from "@medlog/shared/models/orders/IOrder";
import { updatePrebookDetails } from "common/firebase/orders/updatePrebookDetails";

const DeliveryDate: FunctionComponent = () => {
  const { order, setOrder } = useOrderContext();
  const history = useHistory();
  const isMobile = useIsMobileDown();

  const now = new Date();
  const dates = Array(7)
    .fill(0)
    .map((_value, index) => addDays(now, index + 1));

  const handleSubmit = useCallback(async (values: OrderDateFormValues, helpers: FormikHelpers<OrderDateFormValues>) => {
    helpers.setSubmitting(true);
    const newOrder: IOrder = {
      ...order,
      preBookDeliveryDate: values.preBookDeliveryDate,
    };

    if (order.uid !== NON_EXISTING_ID) {
      try {
        await updatePrebookDetails(order, values.preBookDeliveryDate, order.preBookDeliveryTime);
        setOrder(newOrder);
      } catch (error) {
        console.log(error);
      }
    } else {
      setOrder(newOrder);
    }
    helpers.setSubmitting(false);
    history.push(routesPaths.TIME);
  }, []);

  return (
    <>
      <SectionContainer>
        <PageContainer>
          <Formik
            initialValues={{ preBookDeliveryDate: order.preBookDeliveryDate }}
            validationSchema={deliveryDateValidationSchema}
            onSubmit={handleSubmit}
          >
            {formProps => (
              <Form>
                <Container>
                  <WhiteFooter />
                  <Wrapper>
                    <HeroImage src={Hero} />
                    <Heading big>
                      Choose a <b>date</b> for the&nbsp;pick&#8209;up
                    </Heading>
                    <MobileSheet>
                      <FormContainer>
                        {dates.map((date, index) => (
                          <DateButton
                            index={index}
                            key={index}
                            date={date}
                            selected={
                              formProps.values.preBookDeliveryDate != undefined &&
                              isSameDay(date, formProps.values.preBookDeliveryDate)
                            }
                            onClick={date => formProps.setFieldValue("preBookDeliveryDate", date)}
                          />
                        ))}
                      </FormContainer>
                    </MobileSheet>
                  </Wrapper>
                </Container>

                {!isMobile && (
                  <Button className="bottomButton" type="submit" loading={formProps.isSubmitting}>
                    Next step
                  </Button>
                )}

                <MobileButtonContainer>
                  <MobileButton text="Next step" isSubmit={true} loading={false} />
                </MobileButtonContainer>
              </Form>
            )}
          </Formik>
        </PageContainer>
        <WhiteCover />
      </SectionContainer>
    </>
  );
};

export default DeliveryDate;
