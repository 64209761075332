import React, { FunctionComponent } from "react";
import { Container, Wrapper, HeroImage, M25Badge } from "modules/OrderingForm/OrderType/OrderType.components";
import Heading from "modules/shared/components/Heading";
import OrderTypeForm from "modules/OrderingForm/OrderType/OrderTypeForm";
import useSettingsContext from "modules/OrderingRoutingLayout/SettingsContext/useSettingsContext";
import Hero from "modules/OrderingForm/OrderType/icons/hero.png";
import MobileSheet from "modules/shared/components/Mobile/MobileSheet";
import { WhiteCover } from "modules/App/OrdersLayout/OrdersLayout.components";

const OrderType: FunctionComponent = () => {
  const { deliveriesEnabled } = useSettingsContext();

  return (
    <Container>
      <Wrapper>
        <HeroImage src={Hero} />
        {deliveriesEnabled != false ? (
          <Heading big>
            Choose <b>the delivery service</b> that suits you best
            <M25Badge>Only M25</M25Badge>
          </Heading>
        ) : (
          <Heading big>
            Sorry, we have no couriers available today!
            <br /> Book one on the <b>upcoming days</b>
          </Heading>
        )}
        <MobileSheet>
          <OrderTypeForm />
        </MobileSheet>
      </Wrapper>
      <WhiteCover />
    </Container>
  );
};

export default OrderType;
