import React, { FunctionComponent } from "react";

const Storybook: FunctionComponent = () => {
  return (
    <div
      style={{
        flexDirection: "column",
        display: "flex",
        alignItems: "center",
        flex: 1,
        backgroundColor: "white",
        gap: "50px",
        justifyContent: "center",
      }}
    >
    </div>
  );
};

export default Storybook;
