import { colors } from "@medlog/shared/styles/colors";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background-color: black;
  justify-content: center;
  align-items: center;
`;

export const HeroImage = styled.img`
  margin-top: -64px;
  width: 105px;
  height: 114px;
`;

export const RotateScreenHeader = styled.h1`
  margin: 0px;
  color: ${colors.gray._300};
  font-weight: 600;
  font-style: normal;
  font-size: 24px;
  margin-left: 8px;
  margin-right: 8px;
`;

export const RotateScreenDescription = styled.span`
  margin-left: 8px;
  margin-right: 8px;
  font-size: 18px;
  color: white;
  margin-top: 8px;
  text-align: center;
`;
