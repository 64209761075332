import { ORDERING_BREAKPOINTS, DASHBOARD_BREAKPOINTS } from "./const";
import { css, CSSObject, SimpleInterpolation } from "styled-components";
import { isMobile } from "react-device-detect";

const appType = process.env.REACT_APP_TYPE;

const BREAKPOINTS = appType === "ORDERING" ? ORDERING_BREAKPOINTS : DASHBOARD_BREAKPOINTS;

export const orientationToAspects = (orientation: "landscape" | "portrait") => {
  const ratio = isMobile ? "13 / 9" : "1 / 1";
  return {
    minAspectRatio: orientation == "landscape" ? ratio : "1 / 1000",
    maxAspectRatio: orientation == "landscape" ? "1000 / 1" : ratio,
  };
};

export const media = {
  down:
    (breakpoint: keyof typeof BREAKPOINTS) =>
    (first: TemplateStringsArray | CSSObject, ...interpolations: SimpleInterpolation[]) => {
      if (!BREAKPOINTS[breakpoint]) return css``;

      const bp = BREAKPOINTS[breakpoint];
      const styleStrings = [];

      if (!isMobile && bp?.isMobile) return css``;

      if (bp) {
        if (bp.orientation) {
          const { minAspectRatio, maxAspectRatio } = orientationToAspects(bp.orientation);

          if (bp.max_width) {
            styleStrings.push(css`
              @media (max-width: ${bp.max_width}px) and (max-aspect-ratio: ${maxAspectRatio}) and (min-aspect-ratio: ${minAspectRatio}) {
                ${css(first, ...interpolations)}
              }
            `);
          }

          if (bp.max_height) {
            styleStrings.push(css`
              @media (max-height: ${bp.max_height}px) and (max-aspect-ratio: ${maxAspectRatio}) and (min-aspect-ratio: ${minAspectRatio}) {
                ${css(first, ...interpolations)}
              }
            `);
          }

          if (!bp.max_height && !bp.max_width) {
            styleStrings.push(css`
              @media (orientation: ${bp.orientation}) {
                ${css(first, ...interpolations)}
              }
            `);
          }

          return styleStrings;
        } else if (bp.max_width || bp.max_height) {
          if (bp.max_width) {
            styleStrings.push(css`
              @media (max-width: ${bp.max_width}px) {
                ${css(first, ...interpolations)}
              }
            `);
          }

          if (bp.max_height) {
            styleStrings.push(css`
              @media (max-height: ${bp.max_height}px) {
                ${css(first, ...interpolations)}
              }
            `);
          }

          return styleStrings;
        }
      }

      return css`
        ${css(first, ...interpolations)}
      `;
    },
};
