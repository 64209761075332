import { colors } from "@medlog/shared/styles/colors";
import { media } from "@medlog/shared/styles/media";
import styled, { css } from "styled-components/macro";

export const Container = styled.div<{ selected: boolean; highlighted: boolean }>`
  aspect-ratio: 8/10;
  background: white;
  box-sizing: border-box;
  box-shadow: 0px 4px 16px -12px rgba(33, 89, 105, 0.5);
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex: 1;
  transition: transform 0.2s ease;

  ${props =>
    props.highlighted &&
    css`
      transform: scale(1.1);
    `}

  :hover {
    transform: scale(1.1);
  }

  ${media.down("LAPTOP_BIG")`
    max-width: 128px;
  `}

  ${media.down("LAPTOP")`
    max-width: 112px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    max-width: 92px;
  `}

  ${media.down("LAPTOP_SMALL")`
    max-width: 92px;
  `}

  ${media.down("LAPTOP_TINY")`
    max-width: 92px;
  `}

  ${props => media.down("TABLET")`
    aspect-ratio: 6/1;
    max-width: 400px;
    flex: 1;
    width: 100%;
    margin-bottom: 12px;

    :hover {
      transform: scale(1.025);
    }

    ${
      props.selected &&
      css`
        & h2.full {
          font-weight: 600 !important;
        }
      `
    }
  `}
  
  ${media.down("MOBILE")`
    width: 84vw;
    height: 56px;
    margin-bottom: 12px;
    box-shadow: none;
    
    :hover {
      transform: scale(1.025);
    }
  `}

  ${media.down("MOBILE_SMALL")`
    margin-bottom: 8px;
  `}

  ${props =>
    props.selected
      ? css`
          background: linear-gradient(${colors.red._500}, ${colors.mint._400});
        `
      : css`
          border: 1px solid ${colors.gray._100};
        `}



  ${props =>
    props.highlighted &&
    media.down("MOBILE")`
    transform: scale(1.025);
  `}
`;

export const Button = styled.button`
  cursor: pointer;
  outline: none;
  margin: 2px;
  border: none;
  background: #ffffff;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  border-radius: 13px;

  > h2 {
    margin: 0px;
    padding: 0px;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 140%;
    color: ${colors.gray._800};
  }

  > h3 {
    margin: 0px;
    padding: 0px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.01em;
    color: ${colors.mint._600};
  }

  > h2.full {
    display: none;
    font-size: 20px;
    font-weight: 400;
  }

  ${media.down("LAPTOP_BIG")`
    > h2 {
      font-size: 24px;
    }
  `}

  ${media.down("LAPTOP")`
    > h2 {
      font-size: 22px;
    }
  `}

  ${media.down("LAPTOP_MEDIUM")`
    > h2 { font-size: 20px; }
  `}

  ${media.down("LAPTOP_SMALL")`
    > h2 { font-size: 18px; }
    > h3 { font-size: 14px;}
  `}

  ${media.down("LAPTOP_SMALL")`
    > h2 { font-size: 20px; }
    > h3 { font-size: 16px;}
  `}

  ${media.down("TABLET")`
    text-align: left;

    > h2.full {
      display: inline;
    }

    > h2.short {
      display: none;
    }

    > h3 {
      font-size: 20px;
      display: inline;
      float: right; 
      margin-right: 20px;
    }
  `}

  ${media.down("MOBILE")`
    padding: 0;
    padding-top: 2px;
    > h2.full { font-size: 16px; position: relative; bottom: 4px; }
    > h3 { font-size: 16px; position: relative; top: 3px;}
  `}
`;

export const RadioImage = styled.img`
  display: none;

  width: 24px;
  height: 24px;
  position: relative;
  top: 5px;
  margin-right: 12px;
  margin-left: 20px;

  ${media.down("TABLET")`
    display: inline-block;
  `};

  ${media.down("MOBILE")`
    top: 2px;
  `}
`;
