import useGA from "common/analytics/useGA";
import { useEffect, useState } from "react";

const useAnalyticsPathChange = () => {
  const [prevLocation, setPrevLocation] = useState<string | undefined>();
  const { trackPageView } = useGA();
  useEffect(() => {
    if (prevLocation !== location.pathname) {
      setPrevLocation(location.pathname);
      trackPageView(location.pathname);
    }
  }, [prevLocation, location.pathname]);
};

export default useAnalyticsPathChange;
