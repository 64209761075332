import {
  Container,
  Content,
  DetailsContainer,
  Header,
  Logo,
} from "@medlog/shared/components/Error404/Error404.components";
import React, { FunctionComponent } from "react";
import Image404 from "@medlog/shared/components/Error404/image_404.png";
import IconLogoDrop from "@medlog/shared/icons/icon_logo_drop.png";
import Button from "@medlog/shared/components/Button";
import { useHistory } from "react-router";

const Error404: FunctionComponent = () => {
  const history = useHistory();
  return (
    <Container>
      <Logo src={IconLogoDrop} alt="logo" onClick={() => history.push("/")} />
      <Header>
        <a href="https://bookcourier.medicallogistics.co.uk/">Medical Couriers</a>
        <a href="https://medicalservices.medicallogistics.co.uk/">Medical Services</a>
        <a href="https://medicallogistics.co.uk/medical-logistics-story">About Us</a>
      </Header>
      <Content>
        <img src={Image404} />
        <DetailsContainer>
          <h1>Ooops!</h1>
          <span>
            We can&apos;t find the page you are looking for. Here
            <br />
            is a handy link to our Ordering System!
          </span>
          <Button onClick={() => history.push("/")}>Back to home</Button>
        </DetailsContainer>
      </Content>
    </Container>
  );
};

export default Error404;
