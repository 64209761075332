import React, { FunctionComponent, useState } from "react";
import useOrderContext from "modules/App/OrdersLayout/OrderContext/useOrderContext";
import { TimesUpDescription, TimesUpContainer, TimesUpContent } from "modules/OrderingForm/TimesUp/TimesUp.components";
import WhiteBox from "modules/shared/components/WhiteBox/WhiteBox";
import { ReactComponent as ClockImage } from "./icons/clock.svg";
import { useHistory } from "react-router-dom";
import { handleCheckAvailabilityAgain } from "common/helpers/handleCheckAvailabilityAgain";
import useSettingsContext from "modules/OrderingRoutingLayout/SettingsContext/useSettingsContext";
import { IOrderType } from "@medlog/shared/models/orders/IOrder";
import routesPaths from "modules/Routing/routesPaths";

const TimesUp: FunctionComponent = () => {
  const [loading, setLoading] = useState(false);
  const { order, clearOrder, setupOrder } = useOrderContext();
  const { deliveriesEnabled, sameDaysEnabled, urgentsEnabled } = useSettingsContext();
  const history = useHistory();

  const mainButtonClick = async () => {
    if (order.type === IOrderType.URGENT && (deliveriesEnabled === false || urgentsEnabled === false)) {
      history.push(routesPaths.REJECTION, true);
    } else if (order.type === IOrderType.SAME_DAY && (deliveriesEnabled === false || sameDaysEnabled === false)) {
      history.push(routesPaths.REJECTION, true);
    } else {
      setLoading(true);
      try {
        await handleCheckAvailabilityAgain(order, history, setupOrder, clearOrder);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <WhiteBox
      rounded={false}
      mainButton={{
        loading: loading,
        text: "Check availability",
        action: mainButtonClick,
      }}
    >
      <TimesUpContainer>
        <ClockImage />
        <TimesUpContent>
          <h2>You’ve run out of time for the payment</h2>
          <TimesUpDescription>Please check again for the availability of our couriers!</TimesUpDescription>
        </TimesUpContent>
      </TimesUpContainer>
    </WhiteBox>
  );
};

export default TimesUp;
