import styled from "styled-components";
import { colors } from "@medlog/shared/styles/colors";

const getBorderColor = (focused: boolean, error?: boolean) => {
  if (error) {
    return colors.red._500;
  } else if (focused) {
    return colors.gray._600;
  } else {
    return colors.gray._300;
  }
};

export const InputContainer = styled.div<{ error?: boolean; focused: boolean }>`
  height: 56px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid;
  position: relative;
  display: flex;
  border-color: ${props => getBorderColor(props.focused, props.error)};

  :hover {
    border-color: ${props => getBorderColor(true, props.error)};
    > span {
      color: ${props => getBorderColor(true, props.error)};
    }
  }
`;

export const InputPlaceholder = styled.span<{ error?: boolean; focused: boolean }>`
  position: relative;
  font-family: Barlow;
  font-weight: 400;
  background-color: white;
  padding: 0px 8px;
  font-size: 12px;
  top: -12px;
  left: 8px;
  color: ${props => getBorderColor(true, props.error)};

  :hover {
    color: ${props => getBorderColor(true, props.error)};
  }
`;

export const InputsWrapper = styled.div`
  flex: 1;
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  align-items: center;

  > input {
    font-style: normal;
    font-size: 16px;
    letter-spacing: 0.005em;
    font-weight: 500;
    font-family: "Barlow", sans-serif;

    @-moz-document url-prefix() {
      font-weight: 400; //Mozilla font 500 is not equal to chrome 500.
    }
  }
`;

export const DirectionalInput = styled.input<{ error?: boolean }>`
  width: 64px;
  border: 0px;
  outline: 0px;
  color: ${props => (props.error ? colors.red._500 : colors.gray._800)};
  background-color: transparent;
  text-align: center;

  :focus {
    font-weight: 400;
  }

  ::placeholder {
    font-weight: 500;
    color: ${props => (props.error ? colors.red._500 : colors.gray._500)};
  }
`;

export const InputsDivider = styled.div<{ error?: boolean }>`
  width: 1px;
  height: 24px;
  background-color: ${props => (props.error ? colors.red._500 : colors.gray._400)};
`;

export const PhoneNumberInput = styled.input<{ error?: boolean }>`
  border: 0px;
  appearance: none;
  outline: 0px;
  vertical-align: middle;
  background-color: transparent;
  flex: 1;
  padding: 20px;
  color: ${props => (props.error ? colors.red._500 : colors.gray._800)};

  :focus {
    font-weight: 400;
  }

  ::placeholder {
    font-weight: 500;
    color: ${props => (props.error ? colors.red._500 : colors.gray._500)};
  }

  /* Hide up/bottom arrows.  */
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`;
