import React, { FunctionComponent } from "react";
import {
  AvailabilityWrapper,
  BoxContainer,
  BoxOuterContainer,
  CancelOrderButton,
  Container,
  InformationBox,
  InformationsContainer,
  TimerContainer,
  IconHeroImage,
  IconClockImage,
} from "modules/OrderingForm/Summary/Summary.components";
import Button from "@medlog/shared/components/Button";
// import IconClock from "modules/OrderingForm/Summary/icon_clock.svg";
import IconClock from "modules/OrderingForm/Summary/icon_clock.png";
import IconHero from "modules/OrderingForm/Summary/icon_hero.png";
import SectionContainer from "modules/shared/components/SectionContainer/SectionContainer";
import OrderTimer from "modules/shared/components/OrderTimer/OrderTimer";
import { IOrder, IOrderType } from "@medlog/shared/models/orders/IOrder";

type COURIER_AVAILABLE_BEHAVIOUR = "COURIER_AVAILABLE";
type ORDER_SUMMARY_BEHAVIOUR = "ORDER_SUMMARY";

type SummaryConfiguration = {
  title: string;
  subline: string;
  time: number | null;
  order: IOrder;
  orderPrice: JSX.Element;
  orderDeliveryTime: JSX.Element;
  nextStep: () => void;
  buttonLoading: boolean;
};

type CourierAvailableConfiguration = SummaryConfiguration & {
  behaviour: COURIER_AVAILABLE_BEHAVIOUR;
  cancelOrder: () => void;
};

type OrderSummaryConfiguration = SummaryConfiguration & {
  behaviour: ORDER_SUMMARY_BEHAVIOUR;
};

type SummaryProps = {
  configuration: CourierAvailableConfiguration | OrderSummaryConfiguration;
};

const Summary: FunctionComponent<SummaryProps> = ({ configuration }) => {
  const { behaviour, order, time, title, subline, orderDeliveryTime, orderPrice, nextStep, buttonLoading } =
    configuration;

  const getIcon = () => {
    return behaviour === "ORDER_SUMMARY" && order.type === IOrderType.URGENT && !order.rescheduled ? (
      <IconHeroImage src={IconHero} />
    ) : (
      <IconClockImage src={IconClock} />
    );
  };

  return (
    <SectionContainer>
      <Container>
        <BoxOuterContainer>
          {getIcon()}
          <BoxContainer>
            {(behaviour === "COURIER_AVAILABLE" || order.type !== IOrderType.URGENT || order.rescheduled) && (
              <TimerContainer>
                <OrderTimer time={time} showHint type={order.type} />
              </TimerContainer>
            )}
            <AvailabilityWrapper shakeButton={behaviour === "ORDER_SUMMARY"}>
              <h1>{title}</h1>
              <h3>{subline}</h3>
              <InformationsContainer>
                <InformationBox>
                  {orderDeliveryTime}
                  <h4>
                    {order.type === IOrderType.PRE_BOOK && order.preBookDeliveryTime?.timeSpecified
                      ? "Pick-up Time"
                      : "Delivery Time"}
                  </h4>
                </InformationBox>
                <InformationBox>
                  {orderPrice}
                  <h4>Order Price</h4>
                </InformationBox>
              </InformationsContainer>

              <Button loading={buttonLoading} onClick={nextStep}>
                {behaviour === "ORDER_SUMMARY" ? "Order and Pay" : "Next step"}
              </Button>
            </AvailabilityWrapper>
          </BoxContainer>
        </BoxOuterContainer>
        {behaviour === "COURIER_AVAILABLE" && (
          <CancelOrderButton onClick={(configuration as CourierAvailableConfiguration).cancelOrder}>
            Cancel my Order
          </CancelOrderButton>
        )}
      </Container>
    </SectionContainer>
  );
};

export default Summary;
