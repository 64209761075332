import React, { FunctionComponent } from "react";
import { ShowRatingContent, ShowRatingHeader } from "modules/Rating/Rating.components";
import RatedGreat from "modules/Rating/icons/rated_great.png";
import RatedOkay from "modules/Rating/icons/rated_okay.png";
import RatedBad from "modules/Rating/icons/rated_bad.png";
import IRate from "@medlog/shared/models/rating/IRate";

type ShowRateProps = {
  rate?: IRate;
};

const ShowRate: FunctionComponent<ShowRateProps> = ({ rate }) => {
  const getRateImage = () => {
    if (rate == IRate.BAD) return RatedBad;
    else if (rate == IRate.OKAY) return RatedOkay;
    return RatedGreat;
  };

  return (
    <ShowRatingContent>
      <img src={getRateImage()} />
      <ShowRatingHeader>Thank you for your feedback!</ShowRatingHeader>
    </ShowRatingContent>
  );
};

export default ShowRate;
