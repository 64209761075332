import { media } from "@medlog/shared/styles/media";
import {
  MessageDescriptionSpan,
  MessageHeaderH2,
  RWDWidthsAndHeights,
  MobileHeader,
  MobileDescription,
} from "modules/shared/styles/mixins";
import styled from "styled-components";

export const CancelationContainer = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  > svg {
    position: absolute;
    top: -15%;
    width: 52%;
    height: auto;
  }

  ${RWDWidthsAndHeights(480, 285)}

  ${media.down("TABLET")`
    width: 480px;
    height: 385px;

    > svg {
        position: absolute;
        width: 60%;
        top: -25%;
        margin-right: 0;
    }
  `}

  ${media.down("MOBILE")`
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 200px;
    margin-top: 32px;
  `}
`;

export const CancelationContent = styled.div`
  text-align: center;
  margin-top: 64px;

  > h2 {
    ${MessageHeaderH2}
    ${MobileHeader}
  }

  ${media.down("MOBILE")`
    margin-top: 64px;
    margin-bottom: 128px;
    margin-left: 32px;
    margin-right: 32px;
    max-width: unset;
    > svg {
      height: 149px;
      width: 155px;
    }
    > h2 {
      margin-top: 24px;
    }
  `}

  ${media.down("MOBILE_SMALL")`
    margin-top: 48px;
    margin-bottom: 96px;
  `}

  ${media.down("MOBILE_TINY")`
    margin-top: 48px;
    margin-bottom: 64px;
  `}
`;

export const CancelationDescription = styled.span`
  display: inline-block;
  max-width: 62%;

  ${MessageDescriptionSpan}
  ${MobileDescription}

  ${media.down("MOBILE")`
    max-width: unset;
  `}
`;
