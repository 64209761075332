import { IOrder, IOrderType } from "@medlog/shared/models/orders/IOrder";

export const isUrgentFlow = (order: IOrder) => order.type === IOrderType.URGENT && !order.rescheduled;
export const isSamedayFlow = (order: IOrder) =>
  order.type === IOrderType.SAME_DAY || (order.type === IOrderType.URGENT && order.rescheduled);

export const isUrgentDisabled = (deliveriesEnabled?: boolean, urgentEnabled?: boolean) =>
  deliveriesEnabled === false || urgentEnabled === false;
export const isSamedayDisabled = (deliveriesEnabled?: boolean, samedayEnabled?: boolean) =>
  deliveriesEnabled === false || samedayEnabled === false;
