import styled from "styled-components/macro";
import { colors } from "@medlog/shared/styles/colors";
import { TransitionStatus } from "react-transition-group";
import { media } from "@medlog/shared/styles/media";

export const AnimatedAdditionalInformations = styled.div<{ status: TransitionStatus }>`
  transition: all 900ms ease;
  opacity: ${props => (props.status === "entered" ? "1" : "0")};
  z-index: 2;
`;

export const AdditionalInformationsContainer = styled.div`
  position: absolute;
  display: flex;
  right: 0px;
  top: 0px;
`;

export const AdditionalInformationWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  margin-top: 16px;

  > .urgent-label {
    margin-right: 8px;
    margin-top: -3px;
    margin-left: 0px;
  }
`;

export const AdditionalPriceContainer = styled.div`
  display: flex;
  border-radius: 0px 0px 0px 64px;
  background-color: black;
  padding-left: 8px;
  padding-right: 8px;
  width: 132px;
  height: 64px;

  ${media.down("LAPTOP_TINY")`
    border-radius: 0px 0px 0px 46px;
    width: 92px;
    height: 46px;
  `}
`;

export const PriceOuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  width: 100%;
  height: 100%;

  > span {
    font-size: 11px;
    line-height: 140%;
    letter-spacing: 0.2px;
    margin-right: 16px;
    color: ${colors.gray._400};
    margin-top: 2px;
  }

  ${media.down("LAPTOP_TINY")`
    > span { font-size: 10px; margin-right: 4px; }
  `}
`;

export const PriceWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 16px;
  align-items: flex-end;
  > span {
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0.2px;
    color: ${colors.gray._400};
    margin-right: 2px;
  }

  > h3 {
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 24px;
    line-height: 90%;
    color: ${colors.white};
  }

  ${media.down("LAPTOP_TINY")`
    margin-right: 4px;
    margin-top: -6px;
    > h3 { 
      font-size: 20px;
    }
  `}
`;

export const AdditionalDetailWrapper = styled.div`
  display: flex;
  margin-right: 8px;
  > span {
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.1px;
    color: ${colors.gray._500};
    margin-right: 2px;
    white-space: nowrap;
  }

  > :last-child {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.1px;
    color: ${colors.gray._700};
    white-space: nowrap;
  }
`;

export const AdditionalLocationWrapper = styled.div<{ status: TransitionStatus }>`
  display: flex;
  transition: all 900ms ease;
  display: ${props => (props.status == "exited" ? "none" : "flex")};
  opacity: ${props => (props.status === "entered" ? "1" : "0")};
  transform: translateX(${props => (props.status === "entered" ? "0" : "30px")});
`;

export const TimerContainer = styled.div<{ visible: boolean }>`
  background-color: black;
  display: flex;
  align-items: center;
  width: ${props => (props.visible ? 91 : 0)}px;
  max-width: ${props => (props.visible ? 91 : 0)}px; //For Safari.
  transition: width 150ms linear;
  overflow: hidden;
`;

export const TimeDivider = styled.div`
  background-color: ${colors.gray._700};
  width: 1px;
  height: calc(100% - 16px);
  margin: 16px 0px;
`;

export const TimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90px;
  justify-content: center;
  align-items: center;

  > span {
    font-weight: normal;
    font-size: 11px;
    line-height: 140%;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
    color: ${colors.gray._400};
    margin-top: 2px;
  }
`;

export const TimeText = styled.h2<{ warning: boolean }>`
  margin: 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 90%;
  display: flex;
  align-items: center;
  transition: color 200ms linear;
  color: ${props => (props.warning ? colors.red._300 : colors.white)};
`;
