import React, { FunctionComponent, useState } from "react";
import { FormikProps, Form } from "formik";
import InputField from "@medlog/shared/components/Input/InputField";
import Button from "@medlog/shared/components/Button";
import {
  DeliveryDetailsInnerWrapper,
  DeliveryDetailsInputsWrapper,
  DeliveryDetailsWrapper,
  DetailsWrapper,
  FormHeroContainer,
  IndicatorImage,
} from "modules/OrderingForm/DeliveryDetails/DeliveryDetails.components";
import Heading from "modules/shared/components/Heading/Heading";
import { PickupContactFormValues } from "modules/OrderingForm/DeliveryDetails/PickupDetails/PickupContact/form";
import PhoneInput from "modules/shared/components/PhoneInput";
import PickupIndicator from "modules/OrderingForm/DeliveryDetails/icons/indicator.png";
import Hint from "modules/shared/components/Hint/Hint";
import HeroLeft from "modules/OrderingForm/DeliveryDetails/icons/hero_left.png";
import { useIsMobileDown } from "common/helpers/useMedia";
import MobileButton from "modules/shared/components/Mobile/MobileButton";

const PickupContactFormContent: FunctionComponent<FormikProps<PickupContactFormValues>> = ({
  submitForm,
  isSubmitting,
  errors,
}) => {
  const [submitCount, setSubmitCount] = useState(0);
  const isMobile = useIsMobileDown();
  return (
    <DetailsWrapper>
      <FormHeroContainer position="left_bottom">
        <img src={HeroLeft} />
        <DeliveryDetailsWrapper biggerPaddingBottom>
          <IndicatorImage direction="pickup" src={PickupIndicator} />
          <DeliveryDetailsInnerWrapper>
            <Heading>
              Who should we contact for <br />
              the <b>pick-up?</b>
            </Heading>
            <Form
              onKeyDown={async e => {
                if (e.key === "Enter" && e.shiftKey === false) {
                  e.preventDefault();
                  setSubmitCount(submitCount + 1);
                  await submitForm();
                }
              }}
            >
              <DeliveryDetailsInputsWrapper>
                <InputField label="Name" name="contactName" type="text" inputSize={isMobile ? "mobile" : "large"} />
                <Hint
                  position="left"
                  hint="It's for the courier - if you don't know the number, enter yours."
                  delay={500}
                >
                  <PhoneInput errors={errors.phone} submitCount={submitCount} />
                </Hint>
                <InputField
                  label="Email Address (optional)"
                  optional
                  name="email"
                  type="email"
                  inputSize={isMobile ? "mobile" : "large"}
                />
              </DeliveryDetailsInputsWrapper>
            </Form>
          </DeliveryDetailsInnerWrapper>
        </DeliveryDetailsWrapper>
      </FormHeroContainer>
      {!isMobile && (
        <Button
          className="bottomButton"
          type="submit"
          onClick={() => {
            setSubmitCount(submitCount + 1);
            submitForm();
          }}
          loading={isSubmitting}
        >
          Next step
        </Button>
      )}
      {isMobile && (
        <MobileButton
          isSubmit={true}
          onClick={() => {
            setSubmitCount(submitCount + 1);
            submitForm();
          }}
          text="Next step"
        />
      )}
    </DetailsWrapper>
  );
};

export default PickupContactFormContent;
