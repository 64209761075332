enum SidebarSections {
  DATE,
  DATE_AND_TIME,
  LOCATION,
  PACKAGE_SIZE,
  CONTACT_DETAILS,
  PICKUP_DETAILS,
  DROPOFF_DETAILS,
  PAYMENT,
}

export default SidebarSections;
