import { ISuggestion } from "common/types/PostcodesAPI/IAutocomplete";
import * as Yup from "yup";

export interface PostcodesFormValues {
  pickupPostcode: string;
  pickupPostcodeSelected: boolean;
  pickupAddress: ISuggestion;
  dropoffPostcode: string;
  dropoffPostcodeSelected: boolean;
  dropoffAddress: ISuggestion;
  isPromo: boolean;
}

export const postcodesInitialValues: PostcodesFormValues = {
  pickupPostcode: "",
  pickupPostcodeSelected: false,
  pickupAddress: {
    address: "",
    id: "",
  },
  dropoffPostcode: "",
  dropoffPostcodeSelected: false,
  isPromo: false,
  dropoffAddress: {
    address: "",
    id: "",
  },
};

export const postcodesValidationSchema = Yup.object().shape({});
