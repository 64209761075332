import { media } from "@medlog/shared/styles/media";
import {
  MessageDescriptionSpan,
  MessageHeaderH2,
  MobileDescription,
  MobileHeader,
  RWDWidthsAndHeights,
} from "modules/shared/styles/mixins";
import styled from "styled-components";

export const TimesUpContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;

  align-items: center;
  justify-content: center;

  box-sizing: border-box;

  > svg {
    width: 40%;
    height: auto;
    margin-right: -3%;
  }

  ${RWDWidthsAndHeights(624, 350)}

  ${media.down("TABLET")`
    padding-bottom: 6%;
    width: 444px;
    height: 350px;

    > svg {
      position: absolute;
      top: -25%;
      margin-right: 0;
    }
  `}

  ${media.down("MOBILE")`
    > svg {
      position: static;
    }
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: unset;
    margin-bottom: 0px;
    margin-top: 32px;
  `}
`;

export const TimesUpContent = styled.div`
  text-align: left;
  max-width: 40%;

  > h2 {
    ${MessageHeaderH2}
    ${MobileHeader}
  }

  ${media.down("TABLET")`
    text-align: center;
    max-width: 70%;
    margin-top: 15%;
  `}

  ${media.down("MOBILE")`
    margin-top: 0px;
    max-width: unset;
    margin-left: 32px;
    margin-right: 32px;
    > svg {
      height: 149px;
      width: 155px;
    }
    > h2 {
      margin-top: 24px;
    }
  `}
`;

export const TimesUpDescription = styled.span`
  display: inline-block;

  ${MessageDescriptionSpan}
  ${MobileDescription}

  ${media.down("TABLET")`
    margin-top: 6%;
    max-width: 75%;
  `}

  ${media.down("MOBILE")`
    max-width: unset;
  `}
`;
