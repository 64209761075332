import React, { FunctionComponent } from "react";
import { OrderPriceWrapper, TimeContainer, TimeText } from "modules/shared/components/OrderTimer/OrderTimer.components";
import { Transition } from "react-transition-group";
import { IOrder, IOrderType } from "@medlog/shared/models/orders/IOrder";
import { IOrderState } from "@medlog/shared/models/orders/IOrderState";
import routesPaths from "modules/Routing/routesPaths";

export const formatTimerTime = (time: number | null) => {
  if (time == null) return "10:00";
  const minutes = Math.floor(time / 60);
  const minutesPrefix = minutes < 10 ? "0" : "";
  const seconds = time - minutes * 60;
  const secondsPrefix = seconds < 10 ? "0" : "";
  return `${minutesPrefix}${minutes}:${secondsPrefix}${seconds}`;
};

type OrderTimeProps = {
  type?: IOrderType;
  time: number | null;
  showHint?: boolean;
  rescheduled?: boolean;
};

const WARNING_TIME_SECONDS = 2 * 60;

const OrderTimer: FunctionComponent<OrderTimeProps> = ({ type, time, rescheduled, showHint = false }) => {
  return (
    <OrderPriceWrapper warning={time ? time < WARNING_TIME_SECONDS : false} showHint={showHint}>
      <TimeContainer>
        <Transition timeout={0} in={time == null}>
          {status => (
            <TimeText duration={500} status={status}>
              {type === undefined ? "-- : --" : type === IOrderType.URGENT && rescheduled === false ? "30:00" : "10:00"}
            </TimeText>
          )}
        </Transition>
        <Transition timeout={0} in={time != null}>
          {status => (
            <TimeText duration={1000} status={status}>
              {formatTimerTime(time)}
            </TimeText>
          )}
        </Transition>
      </TimeContainer>
      <h4>Time Left</h4>
    </OrderPriceWrapper>
  );
};

export const shouldShowTimer = (
  order: IOrder,
  pathname: string,
  time: number | null,
  showOnCourierAvailable?: boolean
) => {
  if (order.type === IOrderType.URGENT && order.currentState === IOrderState.PAYMENT_PENDING && !order.rescheduled) {
    const paths = [routesPaths.AVAILABILITY, routesPaths.CONFIRMATION];
    if (!showOnCourierAvailable) paths.push(routesPaths.COURIER_AVAILABLE);
    return time && !paths.includes(pathname);
  } else if (order.type === IOrderType.URGENT && order.rescheduled) {
    if (pathname === routesPaths.SUMMARY) return false;
    else return order.currentState === IOrderState.PAYMENT_PENDING && order.paymentStartDate !== undefined;
  } else if (pathname === routesPaths.SUMMARY) {
    return true;
  }
  return false;
};

export default OrderTimer;
