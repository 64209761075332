import styled from "styled-components";
import { colors } from "@medlog/shared/styles/colors";
import { media } from "@medlog/shared/styles/media";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: absolute;
  bottom: 32px;
  right: 32px;

  ${media.down("MOBILE")`
    bottom: 32px;
    right: 16px;
  `}

  ${media.down("MOBILE_TINY")`
    bottom: 20px;
  `}
`;

export const ArrowContainer = styled.div<{ visible: boolean }>`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  margin-right: 32px;
  transition: opacity 300ms ease-in;
  opacity: ${props => (props.visible ? 1 : 0)};

  > svg {
    transform: scaleX(-1) rotate(-15deg);
  }

  ${media.down("MOBILE")`
    display: none;
  `}
`;

export const WhatsappContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const WhatsappDescription = styled.span`
  position: relative;
  width: max-content;
  flex: 1;
  transition: opacity 300ms ease-in;
  margin-right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: right;
  letter-spacing: 0.01em;
  color: ${colors.gray._700};

  ${media.down("MOBILE")`
    display: none;
  `}
`;

export const WhatsappIconContainer = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.4s ease;
  background-color: ${colors.white};

  :hover {
    transform: scale(0.9);
  }

  > svg {
    width: 75%;
    height: 75%;
  }

  ${media.down("LAPTOP")`
    width: 48px;
    height: 48px;
    bottom: 32px;
    right: 32px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 20px;
  `}


  ${media.down("LAPTOP_SMALL")`
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 20px;
  `}

  ${media.down("TABLET")`
    width: 48px;
    height: 48px;
    bottom: 16px;
    right: 24px;
  `}
`;
