import MobileSheet from "modules/shared/components/Mobile/MobileSheet";
import React, { FunctionComponent } from "react";
import {
  BoxesContainer,
  ButtonsWrapper,
  CancelButton,
  ClockImage,
  Container,
  DetailsBox,
  SheetWrapper,
} from "modules/OrderingForm/Summary/CoureirAvailableMobile.components";
import SectionContainer from "modules/shared/components/SectionContainer/SectionContainer";
import IconClock from "modules/OrderingForm/Summary/icon_clock.svg";
import Button from "@medlog/shared/components/Button";

type CoureirAvailableMobileProps = {
  title: string;
  subline: string;
  time: string;
  price: string;
  onNext: () => void;
  onCancel: () => void;
};

const CoureirAvailableMobile: FunctionComponent<CoureirAvailableMobileProps> = ({
  title,
  subline,
  time,
  price,
  onNext,
  onCancel,
}) => {
  return (
    <SectionContainer>
      <Container>
        <MobileSheet>
          <SheetWrapper>
            <h1>{title}</h1>
            <span>{subline}</span>
            <BoxesContainer>
              <DetailsBox>
                <h4>{time}</h4>
                <span>Delivery Time</span>
              </DetailsBox>
              <DetailsBox>
                <h4>{price}</h4>
                <span>Order Price</span>
              </DetailsBox>
            </BoxesContainer>
            <ButtonsWrapper>
              <Button onClick={onNext}>Next step</Button>
              <CancelButton onClick={onCancel}>Cancel my Order</CancelButton>
            </ButtonsWrapper>
          </SheetWrapper>
        </MobileSheet>
        <ClockImage src={IconClock} />
      </Container>
    </SectionContainer>
  );
};

export default CoureirAvailableMobile;
