import React, { FunctionComponent, useState } from "react";
import {
  AuthenticationContainer,
  AuthenticationDescription,
  AuthenticationHeading,
  AuthenticationInnerContainer,
  AuthenticationOutline,
  AuthenticationWrapper,
  LogoWrapper,
} from "modules/MyOrders/Authentication/Authentication.components";
import AuthenticationForm from "modules/MyOrders/Authentication/AuthenticationForm";
import { ReactComponent as LogoDrop } from "modules/MyOrders/Authentication/LogoDrop.svg";
import useMyOrdersContext from "modules/MyOrders/MyOrdersContext/useMyOrdersContext";

const Authentication: FunctionComponent = () => {
  const [tokenRequested, setTokenRequested] = useState(false);
  const { tokenExpired } = useMyOrdersContext();
  return (
    <AuthenticationContainer>
      <AuthenticationInnerContainer>
        <AuthenticationOutline />
        <AuthenticationWrapper>
          <LogoWrapper>
            <LogoDrop />
          </LogoWrapper>
          {!tokenRequested ? (
            <>
              <AuthenticationHeading>
                {!tokenExpired
                  ? "Please enter an email that you've place the order with."
                  : "For security reasons, our links have an expiration time."}
              </AuthenticationHeading>
              <AuthenticationDescription>
                {!tokenExpired
                  ? "We will send you an email with a link to your dashboard."
                  : "To access your orders please enter your email address used while placing an order again."}
              </AuthenticationDescription>
              <AuthenticationForm setTokenRequested={setTokenRequested} />
            </>
          ) : (
            <AuthenticationHeading style={{ marginBottom: "105px" }}>
              {
                "If you have ever used our courier's service, we will send you an email containing a link to access all of your orders."
              }
            </AuthenticationHeading>
          )}
        </AuthenticationWrapper>
      </AuthenticationInnerContainer>
    </AuthenticationContainer>
  );
};

export default Authentication;
