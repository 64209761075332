import { colors } from "@medlog/shared/styles/colors";
import { media } from "@medlog/shared/styles/media";
import { TransitionStatus } from "react-transition-group";
import styled from "styled-components";

export const OrderDetailsWrapper = styled.div<{ status: TransitionStatus }>`
  display: flex;
  flex-direction: column;
  background: ${colors.white};
  box-shadow: 0px 8px 24px -16px rgba(58, 68, 74, 0.2);
  border-radius: 10px;
  width: 100%;
  padding: 20px 32px 22px 32px;
  margin-top: 24px;
  box-sizing: border-box;

  transition: transform 500ms ease, opacity 500ms ease;
  opacity: ${props => (props.status === "entered" ? "1" : "0")};
  transform: translateX(${props => (props.status === "entered" ? "" : "-100px")});

  :hover {
    transform: scale(1.03);
    cursor: pointer;
  }

  ${media.down("TABLET_SMALL")`
    padding: 20px;
    margin-top: 16px;
  `}
`;

export const OrderDetailsHeaderWrapper = styled.div`
  display: flex;
  padding-bottom: 10px;
  border-bottom: 1px solid ${colors.gray._200};
`;

export const OrderDetailsHeader = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.1px;
  color: #313135;

  > span {
    margin-left: 8px;
  }

  ${media.down("TABLET_SMALL")`
    font-size:14px;
    > span {
      margin-left: 4px;
    }
  `}
`;

export const OrderDetailsDescriptionWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr;
  margin-top: 10px;

  ${media.down("TABLET_SMALL")`
    grid-auto-flow: column;
    grid-template-columns: 1fr;
    grid-template-rows: 2fr 2fr 2fr 2fr;
    width: 100%;
    *  {
      margin-top:8px;
    }
  `}
`;

export const OrderNumberWrapper = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: ${colors.gray._500};

  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: ${colors.gray._800};
  }

  ${media.down("TABLET_SMALL")`
    white-space: nowrap;
  `}
`;

export const PostcodesWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: ${colors.gray._800};

  > svg {
    margin: 0 32px;
  }

  ${media.down("TABLET_SMALL")`
    justify-content: flex-start;
    width:100%;
    white-space: nowrap;
    > svg {
      margin: 0 8px;
    }
  `}
`;

export const Price = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${colors.gray._800};

  ${media.down("TABLET_SMALL")`
   justify-content: flex-start;
  `}
`;

export const DetailsButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: ${colors.gray._800};
  > svg {
    display: flex;
    margin-left: 5px;
  }

  ${media.down("TABLET_SMALL")`
    justify-content: flex-start;
    > svg {
    display: flex;
    margin-top: 1px;
  }
  `}
`;

export const OrderTime = styled.span`
  display: flex;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.1px;
  color: #313135;
  margin-left: 8px;
  align-items: center;

  ${media.down("TABLET_SMALL")`
    padding: 0px;
    margin: 0px;
    font-size: 14px;
    margin-left:2px;
  `}
`;

export const UrgentBadge = styled.span`
  display: flex;
  margin-left: 8px;
  margin-right: 8px;
  background: ${colors.red._500};
  border-radius: 10px;
  font-weight: 600;
  font-size: 12px;
  line-height: 160%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  text-transform: capitalize;
  color: ${colors.white};
  padding: 2px 8px 4px 8px;

  ${media.down("TABLET_SMALL")`
    justify-content: flex-start;
    font-size: 11px;
  `}
`;

export const MobileTitle = styled.p`
  padding: 0;
  margin: 0;
  display: flex;
  height: 100%;
  align-items: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: ${colors.gray._500};
  margin-right: 2px;
`;

export const PriceDetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
