import styled, { css, keyframes } from "styled-components/macro";
import { colors } from "@medlog/shared/styles/colors";
import { media } from "@medlog/shared/styles/media";

const IconFly = keyframes`
     from { transform: translateY(0px) }
     to {  transform: translateY(-15px)  }
 `;

export const ButtonBorderContainer = styled.div<{ selected: boolean; highlighted: boolean }>`
  width: 224px;
  height: 256px;
  cursor: pointer;
  box-shadow: 0px -8px 24px -18px rgba(58, 68, 74, 0.2), 0px 8px 24px -18px rgba(58, 68, 74, 0.2);
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  transition: transform 0.2s ease;

  ${props =>
    props.highlighted &&
    css`
      transform: scale(1.05);
    `}

  ${props =>
    props.selected &&
    css`
      background: linear-gradient(${colors.red._500}, ${colors.mint._400});
    `}

  :nth-child(2) {
    margin: 0px 24px;
  }

  :hover {
    transform: scale(1.05);
  }

  :active {
    background: linear-gradient(${colors.red._500}, ${colors.mint._400});
  }

  ${media.down("LAPTOP_BIG")`
    width: 280px;
    height: 320px;

    :nth-child(2) {
      margin: 0px 32px;
    }
  `}

  ${media.down("LAPTOP")`
    width: 224px;
    height: 254px;

    :nth-child(2) {
      margin: 0px 24px;
    }
  `}

  ${media.down("LAPTOP_MEDIUM")`
    width: 192px;
    height: 222px;
  `}

  ${media.down("LAPTOP_SMALL")`
    width: 168px;
    height: 202px;
    :nth-child(2) {
      margin: 0px 16px;
    }
  `}

  ${media.down("TABLET")`
    width:400px;
    height:161px;

    :nth-child(2) {
      margin: 12px 0px;
    }
  `}

  ${media.down("MOBILE")`
    box-shadow: none;
    border: 1px solid #EAEEF1;
  `}

  ${media.down("MOBILE")`
    width: 80vw;
    height: 116px;
  `}

  ${media.down("MOBILE_SMALL")`
    height: 106px;
  `}

  ${media.down("MOBILE_TINY")`
    width: 90vw;
    height: 92px;
  `}
`;

export const ButtonContainer = styled.button`
  width: 220px;
  height: 252px;
  cursor: pointer;
  border: 0px;
  box-shadow: none;
  outline: none;
  border-radius: 19px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.down("LAPTOP_BIG")`
    width: 276px;
    height: 316px;
  `}

  ${media.down("LAPTOP")`
    width: 220px;
    height: 250px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    width: 188px;
    height: 218px;
  `}

  ${media.down("LAPTOP_SMALL")`
    width: 164px;
    height: 198px;
  `}

  ${media.down("TABLET")`
    width:396px;
    height:157px;
    flex-direction:row-reverse;
    align-items: center;
    justify-content: flex-start;
  `}

  ${media.down("TABLET")`
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: 17px;
  `}
`;

export const ButtonImageContainer = styled.div`
  height: 88px;
  width: 100%;
  position: relative;

  ${media.down("TABLET")`
    width: 130px;
    flex-direction:row-reverse;
  `}
`;

export const InformationsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  position: relative;
  bottom: 15px;

  ${media.down("TABLET")`
    align-items: flex-start;
    margin-left: 34px;
    bottom: 0;
  `}

  > h2 {
    margin: 0px;
    padding: 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 130%;
    color: ${colors.gray._900};
    margin-top: 8px;

    ${media.down("LAPTOP_BIG")`
      font-size: 28px;
      margin-top: 24px;
    `}

    ${media.down("LAPTOP")`
      font-size: 22px;
      margin-top: 16px;
    `}

    ${media.down("LAPTOP_MEDIUM")`
      font-size: 20px;
      margin-top: 8px;
    `}

    ${media.down("LAPTOP_SMALL")`
      font-size: 20px;
    `}

    ${media.down("TABLET")`
      margin-top: -8px;
      font-size: 22px;
    `}


    ${media.down("MOBILE")`
      font-size: 16px;
    `}
  }

  > h3 {
    margin: 0px;
    padding: 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: ${colors.mint._600};
    margin-top: 2px;

    ${media.down("LAPTOP_BIG")`
      font-size: 22px;
    `}

    ${media.down("LAPTOP")`
      font-size: 18px;
    `}

    ${media.down("LAPTOP_MEDIUM")`
      font-size: 16px;
    `}

    ${media.down("LAPTOP_SMALL")`
      font-size: 14px;
    `}

    ${media.down("TABLET")`
      font-size: 18px;
    `}


    ${media.down("MOBILE")`
      font-size: 14px;
    `}
  }

  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.01em;
    color: ${colors.gray._600};
    margin-top: 24px;

    ${media.down("LAPTOP_BIG")`
      font-size: 18px;
    `}

    ${media.down("LAPTOP")`
      font-size: 14px;
    `}

    ${media.down("LAPTOP_MEDIUM")`
      font-size: 12px;
    `}

    ${media.down("LAPTOP_SMALL")`
      font-size: 12px;
    `}

    ${media.down("TABLET")`
      margin-top: 14px;
    `}



    ${media.down("MOBILE")`
      margin-top: 12px;
      font-size: 12px;
    `}
  }
`;

export const ButtonImage = styled.img<{ delay: number }>`
  position: absolute;
  top: -8px;
  margin-left: auto;
  margin-right: 24px;
  left: 0px;
  right: 0px;
  animation: ${IconFly} 2s infinite alternate;
  animation-delay: ${props => props.delay}s;

  width: 75%;

  ${media.down("LAPTOP_BIG")`
    top: -24px;
  `}

  ${media.down("LAPTOP")`
    top: -16px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    top: -8px;
  `}

  ${media.down("TABLET")`
    width: 85%;
    top: 4px;
  `}

  ${media.down("MOBILE")`
    width: 85%;
    top: 8px;
  `}

  ${media.down("MOBILE_SMALL")`
    width: 80%;
    top: 8px;
  `}

  ${media.down("MOBILE_TINY")`
    width: 65%;
    top: 16px;
  `}
`;
