import React, { FunctionComponent } from "react";
import { ContactDetails, Container } from "modules/shared/components/Sidebar/DetailsItem/Items/Items.components";
import DetailsItem from "modules/shared/components/Sidebar/DetailsItem";
import SidebarSections from "modules/shared/components/Sidebar/SidebarSections";
import useOrderContext from "modules/App/OrdersLayout/OrderContext/useOrderContext";
import { useHistory } from "react-router-dom";
import routesPaths from "modules/Routing/routesPaths";
import { Link } from "react-scroll";
import { SCROLL_DURATION } from "modules/shared/components/Sidebar/Sidebar";
import { isSidebarPathAllowed } from "common/navigation/navigationHandler";
import { isSidebarItemChecked, isSidebarItemActive } from "modules/shared/components/Sidebar/SidebarHelpers";

const ContactItem: FunctionComponent = () => {
  const { order } = useOrderContext();
  const checked = isSidebarItemChecked(SidebarSections.CONTACT_DETAILS, order);
  const history = useHistory();
  const isAllowed = isSidebarPathAllowed(routesPaths.DETAILS, order, order.pickupAndDropoff);

  const handleClick = () => {
    isAllowed && history.push(routesPaths.DETAILS);
  };

  return (
    <Link
      name={routesPaths.DETAILS}
      to={routesPaths.DETAILS}
      smooth={true}
      duration={SCROLL_DURATION}
      containerId="containerId"
    >
      <DetailsItem
        active={isSidebarItemActive(SidebarSections.CONTACT_DETAILS, location.pathname)}
        checked={checked}
        type={SidebarSections.CONTACT_DETAILS}
        onClick={handleClick}
        animationDisabled={!isAllowed}
      >
        <Container>
          <ContactDetails>
            <span>{`${order.phone.directional} ${order.phone.phoneNumber}`}</span>
            <span>{order.email}</span>
          </ContactDetails>
        </Container>
      </DetailsItem>
    </Link>
  );
};

export default ContactItem;
