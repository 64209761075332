import * as Yup from "yup";
import { IOrderType } from "@medlog/shared/models/orders/IOrder";

export interface OrderTypeFormValues {
  type: IOrderType | undefined;
}

export const orderTypeInitialValues: OrderTypeFormValues = {
  type: undefined,
};

export const orderTypeValidationSchema = Yup.object().shape({
  type: Yup.string().required(),
});
