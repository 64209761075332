import React, { FunctionComponent } from "react";
import { RescheduledWrapper } from "@medlog/shared/components/RescheduledBadge/RescheduledBadge.components";
import IconRefresh from "./icon_refresh.png";

const RescheduledBadge: FunctionComponent<{ visible?: boolean }> = ({ visible }) => {
  return visible ? (
    <RescheduledWrapper>
      <img src={IconRefresh} />
      Rescheduled
    </RescheduledWrapper>
  ) : null;
};

export default RescheduledBadge;
