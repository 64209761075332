import Button from "@medlog/shared/components/Button";
import React, { FunctionComponent } from "react";
import { FlattenSimpleInterpolation } from "styled-components";
import SectionContainer from "../SectionContainer/SectionContainer";
import { ButtonsWrapper, ShadowContainer, UnderlinedButton, WhiteBoxContainer } from "./WhiteBox.components";

type CustomStyle = {
  container?: FlattenSimpleInterpolation;
};

type ButtonConfiguration = {
  text: string;
  action: () => void;
};

type MainButtonConfiguration = ButtonConfiguration & {
  loading?: boolean;
};

type WhiteBoxProps = {
  mainButton?: MainButtonConfiguration;
  underlinedButton?: ButtonConfiguration;
  customStyle?: CustomStyle; // just an example of how the custom styling can be introduced to this component
  rounded?: boolean;
};

const WhiteBox: FunctionComponent<WhiteBoxProps> = ({
  children,
  customStyle,
  mainButton,
  underlinedButton,
  rounded = true,
}) => {
  return (
    <SectionContainer>
      <WhiteBoxContainer hasUnderlinedButton={!!underlinedButton} customStyle={customStyle?.container}>
        <ShadowContainer show={!rounded}>
          {children}
          <ButtonsWrapper absolute={!rounded}>
            {mainButton && (
              <Button size="block" onClick={mainButton.action} loading={mainButton.loading}>
                {mainButton.text}
              </Button>
            )}
            {underlinedButton && (
              <UnderlinedButton onClick={underlinedButton.action}>{underlinedButton.text}</UnderlinedButton>
            )}
          </ButtonsWrapper>
        </ShadowContainer>
      </WhiteBoxContainer>
    </SectionContainer>
  );
};

export default WhiteBox;
