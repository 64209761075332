import React, { FunctionComponent } from "react";
import { useState } from "react";
import useOrderContext from "modules/App/OrdersLayout/OrderContext/useOrderContext";
import { useStripe } from "@stripe/react-stripe-js";
import {
  CancelationDescription,
  CancelationContainer,
  CancelationContent,
} from "modules/OrderingForm/Cancelation/Cancelation.components";
import WhiteBox from "modules/shared/components/WhiteBox/WhiteBox";
import { ReactComponent as CardsImage } from "./icons/cards.svg";
import { initCheckoutSession } from "common/firebase/payments/initCheckoutSession";

const Cancelation: FunctionComponent = () => {
  const [loading, setLoading] = useState(false);
  const { order } = useOrderContext();
  const stripe = useStripe();

  const mainButtonClick = async () => {
    try {
      setLoading(true);
      const sessionId = await initCheckoutSession(order.uid);
      await stripe?.redirectToCheckout({ sessionId: sessionId });
    } catch (error) {
      console.log("Cannot generate payment.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <WhiteBox
      rounded={false}
      mainButton={{
        loading,
        text: "Go to Stripe payment",
        action: mainButtonClick,
      }}
    >
      <CancelationContainer>
        <CardsImage />
        <CancelationContent>
          <h2>Please try again!</h2>
          <CancelationDescription>Looks like a problem occured with your payment</CancelationDescription>
        </CancelationContent>
      </CancelationContainer>
    </WhiteBox>
  );
};

export default Cancelation;
