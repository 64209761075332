import React, { FunctionComponent } from "react";
import { Container, Loader } from "modules/App/PageLoader.components";
import { colors } from "@medlog/shared/styles/colors";

const PageLoader: FunctionComponent<{ absolute?: boolean }> = ({ absolute = true }) => {
  return (
    <Container absolute={absolute}>
      <Loader color={colors.red._500} />
    </Container>
  );
};

export default PageLoader;
