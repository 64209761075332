import { IOrderType } from "../models/orders/IOrder";

const VAT_RATE = 0.2;
const MIN_DISTANCE = 4;
// Fees
const URGENT_FEE = 5;
const SAMEDAY_FEE = 2.5;
// Minimal prices
const MIN_URGENT_FEE = 20;
const MIN_SAMEDAY_FEE = 10;

export interface IOrderPrice {
  urgent: number;
  sameday: number;
}

const getRawPrice = (distanceInMiles: number, orderType: IOrderType) => {
  if (distanceInMiles <= MIN_DISTANCE) return orderType === IOrderType.URGENT ? MIN_URGENT_FEE : MIN_SAMEDAY_FEE;
  switch (orderType) {
    case IOrderType.URGENT:
      return distanceInMiles * URGENT_FEE;
    case IOrderType.SAME_DAY:
      return distanceInMiles * SAMEDAY_FEE;
    case IOrderType.PRE_BOOK:
      throw new Error("To calculate prebook price use urgent/sameday values depending on timeSpecified flag.");
    default:
      return MIN_URGENT_FEE;
  }
};

const getOrderPrice = (distanceInMiles: number): IOrderPrice => {
  const urgentRaw = getRawPrice(distanceInMiles, IOrderType.URGENT);
  const samedayRaw = getRawPrice(distanceInMiles, IOrderType.SAME_DAY);
  const urgentVat = urgentRaw * VAT_RATE;
  const samedayVat = samedayRaw * VAT_RATE;
  const urgentPrice = Math.round((urgentRaw + urgentVat + Number.EPSILON) * 100);
  const samedayPrice = Math.round((samedayRaw + samedayVat + Number.EPSILON) * 100);
  return {
    urgent: urgentPrice < getMinOrderPrice(IOrderType.URGENT) ? getMinOrderPrice(IOrderType.URGENT) : urgentPrice,
    sameday:
      samedayPrice < getMinOrderPrice(IOrderType.SAME_DAY) ? getMinOrderPrice(IOrderType.SAME_DAY) : samedayPrice,
  };
};

export const getOrderPriceDescription = (price?: number) => "*including VAT";

export const getMinOrderPrice = (orderType: IOrderType) => {
  switch (orderType) {
    case IOrderType.URGENT:
      return MIN_URGENT_FEE;
    case IOrderType.SAME_DAY:
      return MIN_SAMEDAY_FEE;
    case IOrderType.PRE_BOOK:
      throw new Error("To calculate prebook price use urgent/sameday values depending on timeSpecified flag.");
  }
};

export const getPromoPrice = (): IOrderPrice => {
  const urgentRaw = 19.99;
  const samedayRaw = 9.99;
  const urgentPrice = Math.round((urgentRaw + Number.EPSILON) * 100);
  const samedayPrice = Math.round((samedayRaw + Number.EPSILON) * 100);
  return {
    sameday: samedayPrice,
    urgent: urgentPrice,
  };
};

export const getMinPromoPrice = (orderType: IOrderType) => {
  switch (orderType) {
    case IOrderType.URGENT:
      return 19.99;
    case IOrderType.SAME_DAY:
      return 9.99;
    case IOrderType.PRE_BOOK:
      throw new Error("To calculate prebook price use urgent/sameday values depending on timeSpecified flag.");
  }
};

export default getOrderPrice;
