import * as Yup from "yup";
import { IParcelSize } from "@medlog/shared/models/orders/IOrder";

export interface PackageSizeFormValues {
  size: IParcelSize | undefined;
}

export const packageSizeInitialValues: PackageSizeFormValues = {
  size: undefined,
};

export const packageSizeValidationSchema = Yup.object().shape({
  size: Yup.string().required(),
});
