import React, { useState, FunctionComponent, useCallback, useEffect } from "react";
import { IOrder, IOrderType } from "@medlog/shared/models/orders/IOrder";
import OrderContext from "modules/App/OrdersLayout/OrderContext/OrderContext";
import { IOrderDetails } from "@medlog/shared/models/orders/IOrderDetails";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { firestore } from "config/firebase/firebase";
import { IOrderState } from "@medlog/shared/models/orders/IOrderState";
import {
  baseOrder,
  baseOrderDetails,
  setStorageOrder,
  removeStorageOrder,
  storedOrder,
  storedPrice,
  storedOrderDetails,
  getStorageOrderParsed,
} from "modules/App/OrdersLayout/OrderContext/Storage";
import { OrderConverter } from "@medlog/shared/helpers/Converters";
import { IOrderPrice } from "@medlog/shared/helpers/getOrderPrice";

export const OrderProvider: FunctionComponent = ({ children }) => {
  const [order, setOrder] = useState<IOrder>(storedOrder);
  const [orderType, setOrderType] = useState<IOrderType | undefined>(undefined);
  const [orderDetails, setOrderDetails] = useState<IOrderDetails>(storedOrderDetails);
  const [orderPrice, setOrderPrice] = useState<number | undefined>(undefined);
  const [localPrice, setLocalPrice] = useState<IOrderPrice | undefined>(storedPrice);
  const [currentOrderState, setCurrentOrderState] = useState<IOrderState | undefined>(undefined);
  const [value, loading, error] = useDocumentData<IOrder>(
    firestore
      .collection("orders")
      .doc(order.uid || "")
      .withConverter(OrderConverter)
  );

  const clearOrder = useCallback(() => {
    setOrderType(undefined);
    setCurrentOrderState(undefined);
    setOrder(baseOrder);
    setOrderType(undefined);
    setOrderDetails(baseOrderDetails);
    removeStorageOrder();
    setLocalPrice(undefined);
    setOrderPrice(undefined);
  }, [baseOrder, baseOrderDetails]);

  const setupOrder = useCallback((order: IOrder, details: IOrderDetails) => {
    clearOrder();
    setCurrentOrderState(undefined);
    setOrder(order);
    setOrderDetails(details);
  }, []);

  useEffect(() => {
    setOrderType(order.type);
    if (order.price) {
      setOrderPrice(order.price);
    }
    if (!orderType || getStorageOrderParsed()?.type === order.type) {
      setStorageOrder(order, orderDetails, localPrice);
    } else {
      clearOrder();
    }
  }, [order, orderDetails, orderType]);

  useEffect(() => {
    if (value && !loading) {
      setCurrentOrderState(value.currentState);
      setOrder(value);
    }
    if (error) console.log(error);
  }, [value]);

  return (
    <OrderContext.Provider
      value={{
        order,
        setOrder,
        orderDetails,
        setOrderDetails,
        currentOrderState,
        setCurrentOrderState,
        orderPrice,
        setOrderPrice,
        localPrice,
        setLocalPrice,
        clearOrder,
        setupOrder,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};
