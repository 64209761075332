import React, { FunctionComponent, useState } from "react";
import {
  Container,
  Button,
  RadioImage,
} from "modules/OrderingForm/DeliveryDate/components/DateButton/DateButton.components";
import { format } from "date-fns";

import RadioOn from "../../icons/radio-on.png";
import RadioOff from "../../icons/radio-off.png";

type DateButtonProps = {
  index: number;
  date: Date;
  selected: boolean;
  onClick: (date: Date) => void;
};

const DateButton: FunctionComponent<DateButtonProps> = ({ index, date, selected, onClick }) => {
  const [focused, setFocused] = useState(false);
  return (
    <Container selected={selected} onClick={() => onClick(date)} highlighted={focused}>
      <Button type="button" onFocus={() => setFocused(true)} onBlur={() => setFocused(false)}>
        <RadioImage src={selected ? RadioOn : RadioOff} />
        <h2 className="full">{index == 0 ? `Tomorrow (${format(date, "EEE")})` : format(date, "EEEE")}</h2>
        <h2 className="short">{format(date, "EEE")}</h2>
        <h3>{format(date, "dd.MM")}</h3>
      </Button>
    </Container>
  );
};

export default DateButton;
