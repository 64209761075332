import React, { FunctionComponent } from "react";
import {
  Container,
  CheckBoxContainer,
  CheckBoxContent,
  TextContainer,
} from "modules/shared/components/PrivacyCheck/PrivacyCheck.components";
import { ReactComponent as CheckIcon } from "modules/shared/components/PrivacyCheck/icon_check.svg";
import Config from "@medlog/shared/Config";

type PrivacyCheckProps = {
  buttonText: string;
  checked: boolean;
  onClick: () => void;
};

const PrivacyCheck: FunctionComponent<PrivacyCheckProps> = ({ buttonText, checked, onClick }) => {
  return (
    <Container>
      <CheckBoxContainer onClick={onClick} type="button">
        <CheckBoxContent checked={checked}>
          <CheckIcon />
        </CheckBoxContent>
      </CheckBoxContainer>
      <TextContainer>
        By clicking {buttonText} you agree to our{" "}
        <a href={Config.common.terms_url} target="_blank" rel="noreferrer">
          Terms and Conditions
        </a>{" "}
        and{" "}
        <a href={Config.common.privacy_cookies_url} target="_blank" rel="noreferrer">
          Privacy Policy.
        </a>
      </TextContainer>
    </Container>
  );
};

export default PrivacyCheck;
