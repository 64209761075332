import styled from "styled-components/macro";
import Background from "modules/App/OrdersLayout/background.svg";
import { media } from "@medlog/shared/styles/media";
import { colors } from "@medlog/shared/styles/colors";
import { FirefoxLightScrollbar, FirefoxScrollbar } from "modules/shared/styles/mixins";
import { isMobileOnly, isMobileSafari } from "react-device-detect";

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100vw;
  background: #f8f8f9;

  ${FirefoxLightScrollbar}

  ${media.down("MOBILE")`
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
  `};

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${colors.gray._700};
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${colors.gray._600};
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }
`;

export const TimerContainer = styled.div`
  z-index: 2;
  position: absolute;
  top: 16px;
  right: 16px;

  ${media.down("LAPTOP_TINY")`
    display: none;
  `}

  ${media.down("TABLET")`
    display: none;
  `}

  ${media.down("LAPTOP_TINY")`
    display: none;
  `}
`;

export const ContentWrapper = styled.div`
  min-height: 670px;
  width: 100%;
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 32px;
  margin: auto;

  ${media.down("TABLET")`
    min-height: 1000px;
  `}

  ${media.down("MOBILE")`
    min-width: 100%;
    padding-bottom: 0px;
    min-height: 500px;
    overflow-x: hidden;
    overflow-y: auto;
  `}

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${colors.gray._700};
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${colors.gray._600};
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  ${media.down("MOBILE_TINY")`
    /* padding-top: 64px; */
  `}

  .bottomButton {
    position: absolute;
    bottom: 32px;
  }
`;

export const FooterWrapper = styled.div`
  min-width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.down("TABLET")`
    min-width: 500px;
  `}

  ${media.down("MOBILE")`
    min-width: 100%;
  `}
`;

export const Wrapper = styled.div`
  //bug: https://issueexplorer.com/issue/ionic-team/ionic-framework/23994
  height: ${isMobileOnly && isMobileSafari ? "100vh" : "100%"};

  margin: auto;

  display: flex;
  flex-direction: column;
  position: relative;
  background: #f8f8f9;
  background-image: url("${Background}");
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: 100%;
  flex: 1;
  width: 100%;
  overflow-y: auto;

  ${FirefoxScrollbar}

  ::-webkit-scrollbar {
    width: 8px;
    height: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${colors.gray._700};
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${colors.gray._600};
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  ${media.down("LAPTOP_TINY")`
    background-image: none;
  `}

  ${media.down("MOBILE")`
    z-index: 1;
  `}
`;

export const WhiteCover = styled.div`
  width: 100vw;
  height: 50vh;
  position: absolute;
  bottom: 0px;
  background-color: white;
  z-index: -1;
  display: none;

  ${media.down("MOBILE")`
    display: block;
  `}
`;

export const UrgentInfoDetails = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 160%;
  color: ${colors.gray._700};
  flex: 1;
  position: absolute;
  left: 32px;
  bottom: 32px;
  margin-right: 232px;
  display: ${p => (p.hidden ? "none" : "flex")};

  > span:nth-child(2) {
    display: none;
  }

  ${media.down("LAPTOP_BIG")`
    font-size: 18px;
    margin-bottom: 12px;
  `}

  ${media.down("LAPTOP")`
    font-size: 14px;
    margin-bottom: 12px;
  `}
  
  ${media.down("LAPTOP_MEDIUM")`
    font-size: 12px;
    margin-bottom: 8px;
  `}
  
  ${media.down("LAPTOP_SMALL")`
    font-size: 12px;
    margin-bottom: 10px;
  `}

  ${media.down("LAPTOP_TINY")`
    margin-bottom: 10px; //Because transform scale in OrderLayout.components.tsx.
  `}
  
  ${media.down("TABLET")`
    font-size: 12px;
    margin-bottom: 16px; //Because transform scale in OrderLayout.components.tsx.
  `}

  ${media.down("MOBILE")`
    font-size: 12px;
    bottom: 16px;
    width: calc(100vw - 72px - 32px);
    
    > span {
      display: inline-block;
      max-width: 180px;
    }

    > span:nth-child(2) { 
      display: inline-block; 
      width: 75px;
      float: right;
      text-align: right;
    }
  `}


  /* To proper text wrapping on some desktop resolutions. */
  @media (max-width: 600px) {
    width: 256px;
    margin-bottom: 8px;
  }

  ${media.down("MOBILE_TINY")`
    > span:nth-child(2) { 
        display: none;
      }
      bottom: 8px;
  `}
`;
