import * as Yup from "yup";
import IPhone from "@medlog/shared/models/common/IPhone";

export interface ContactDetailsFormValues {
  email: string;
  phone: IPhone;
  termsAndPrivacyAccepted?: boolean
}

export const contactDetailsInitialValues: ContactDetailsFormValues = {
  email: "",
  phone: {
    directional: "+48",
    phoneNumber: "",
  },
  termsAndPrivacyAccepted: undefined
};

export const contactDetailsValidationSchema = Yup.object().shape({
  email: Yup.string().email("E-mail must be valid").required("E-mail is required"),
  phone: Yup.object().shape({
    directional: Yup.string()
      .required("Directional number is required")
      .matches(/^[+][0-9]{2,4}$/, "Directional number is wrong"),

    phoneNumber: Yup.string()
      .required("Phone number is required")
      .min(6, "Phone number is wrong")
      .max(11, "Phone number is wrong")
  }),
  termsAndPrivacyAccepted: Yup.boolean().optional()
});
