import styled from "styled-components";
import { colors } from "@medlog/shared/styles/colors";
import { media } from "@medlog/shared/styles/media";

type HeadingProps = {
  big?: boolean;
  maxWidth?: number;
};

export const HeadingContent = styled.h2<HeadingProps>`
  margin: 0;
  padding: 0;
  font-weight: 300;
  line-height: 130%;
  letter-spacing: -0.02em;
  color: ${colors.gray._800};
  text-align: center;
  ${p => p.maxWidth && `max-width: ${p.maxWidth}px;`}
  b {
    font-weight: 600;
  }

  ${p => media.down("LAPTOP_BIG")`
    font-size: ${p.big ? 40 : 32}px;
  `}

  ${p => media.down("LAPTOP")`
    font-size: ${p.big ? 30 : 26}px;
  `}

  ${p => media.down("LAPTOP_MEDIUM")`
    font-size: ${p.big ? 24 : 21}px;
  `}

  ${p => media.down("LAPTOP_SMALL")`
    font-size: ${p.big ? 22 : 20}px;
  `}

  ${p => media.down("TABLET")`
    font-size: ${p.big ? 30 : 26}px;
  `}

  ${p => media.down("MOBILE")`
    font-size: ${p.big ? 22 : 20}px;
    max-width: 70vw;
  `}

  ${media.down("MOBILE_TINY")`
    max-width: 80vw;
  `}


  // Code below is to debug what breakpoint you are at, might be removed someday

  ${media.down("LAPTOP_BIG")`
    content: "LAPTOP_BIG";
  `}
  ${media.down("LAPTOP")`
    content: "LAPTOP";
  `}
  ${media.down("LAPTOP_MEDIUM")`
    content: "LAPTOP_MEDIUM";
  `}
  ${media.down("LAPTOP_SMALL")`
    content: "LAPTOP_SMALL";
  `}
  ${media.down("LAPTOP_TINY")`
    content: "LAPTOP_TINY";
  `}
  ${media.down("TABLET")`
    content: "TABLET";
  `}
  ${media.down("TABLET_SMALL")`
    content: "TABLET_SMALL";
  `}
  ${media.down("MOBILE")`
    content: "MOBILE";
  `}
  ${media.down("MOBILE")`
    content: "MOBILE";
  `}
  ${media.down("MOBILE_SMALL")`
    content: "MOBILE_SMALL";
  `}
  ${media.down("MOBILE_TINY")`
    content: "MOBILE_TINY";
  `}
`;
