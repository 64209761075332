import React, { FunctionComponent } from "react";
import { HeadingContent } from "modules/shared/components/Heading/Heading.components";

type HeadingProps = {
  big?: boolean;
  maxWidth?: number;
};

const Heading: FunctionComponent<HeadingProps> = ({ children, big, maxWidth }) => {
  return (
    <HeadingContent big={big} maxWidth={maxWidth}>
      {children}
    </HeadingContent>
  );
};

export default Heading;
