import styled, { css, keyframes } from "styled-components/macro";
import { colors } from "@medlog/shared/styles/colors";
import { IOrderType } from "@medlog/shared/models/orders/IOrder";
import { media } from "@medlog/shared/styles/media";
import { DefaultBoxShadow } from "modules/shared/styles/mixins";

const IconFly = keyframes`
     from { transform: translateY(0px) }
     to {  transform: translateY(-15px)  }
 `;

const ScaleShadow = keyframes`
     from { transform: scale(0.9) }
     to {  transform: scale(1.1)  }
 `;

export const ButtonBorderContainer = styled.div<{ selected: boolean; enabled: boolean; highlighted: boolean }>`
  width: 236px;
  height: 300px;
  overflow: hidden;
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: transparent;
  transition: transform 0.2s ease;
  position: relative;

  ${props =>
    props.highlighted &&
    css`
      transform: scale(1.05);
    `}

  ${props =>
    props.enabled
      ? css`
          ${DefaultBoxShadow}

          cursor: pointer;

          :hover {
            transform: scale(1.05);
          }

          :active {
            background: linear-gradient(${colors.red._500}, ${colors.mint._400});
          }
        `
      : css`
          cursor: not-allowed;
          filter: grayscale(1);
        `}

  ${props =>
    props.selected &&
    props.enabled &&
    css`
      background: linear-gradient(${colors.red._500}, ${colors.mint._400});
    `}

  :nth-child(2) {
    margin: 0px 24px;
  }

  ${media.down("LAPTOP_BIG")`
    width: 309px;
    height: 395px;
    border-radius: 25px;

    :nth-child(2) {
      margin: 0px 32px;
    }
  `}

  ${media.down("LAPTOP")`
    width: 232px;
    height: 296px;
    border-radius: 20px;

    :nth-child(2) {
      margin: 0px 24px;
    }
  `}

  ${media.down("LAPTOP_MEDIUM")`
    width: 208px;
    height: 272px;
    border-radius: 15px;
  `}

  ${media.down("LAPTOP_SMALL")`
    width: 186px;
    height: 246px;

    :nth-child(2) {
      margin: 0px 16px;
    }
  `}

  ${media.down("TABLET")`
    width: 436px;
    height: 173px;
    :nth-child(2) {
      margin: 16px 0px;
    }
  `};

  ${props => media.down("MOBILE")`
    width: calc(80vw + 4px);
    height: 120px;
    box-shadow: none;
    border-radius: 10px;
    ${
      !props.selected &&
      css`
        border: 1px solid #edf0f3;
      `
    }
  `}

  ${media.down("MOBILE_SMALL")`
    height: 110px;
  `}

  ${media.down("MOBILE_TINY")`
    width: calc(90vw + 4px);
    height: 100px;
  `}
`;

export const ButtonContainer = styled.button<{ enabled: boolean }>`
  width: 100%;
  height: calc(100% - 4px);
  margin: 2px;

  cursor: pointer;
  border: 0px;
  box-shadow: none;
  padding: 0;
  outline: none;
  border-radius: 19px;
  background: white;
  display: flex;
  flex-direction: column;
  ${props =>
    props.enabled
      ? css`
          opacity: 1;
          cursor: pointer;
        `
      : css`
          opacity: 0.8;
          cursor: not-allowed;
        `}

  ${media.down("LAPTOP_BIG")`
    border-radius: 24px;
  `}

  ${media.down("LAPTOP")`
    border-radius: 19px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    border-radius: 14px;
  `}


  ${media.down("TABLET")`
    flex-direction: row;
    align-items: center;
  `}

  ${media.down("MOBILE")`
    border-radius: 9px;
  `}
`;

export const ButtonImageContainer = styled.div<{ delay?: number }>`
  height: 122px;
  position: relative;
  > .shadow {
    position: absolute;
    bottom: 0px;
    left: 16px;
    animation: ${ScaleShadow} 2s infinite alternate;
    animation-delay: ${props => props.delay};

    ${media.down("LAPTOP_BIG")`
      left: 24px;
      bottom: 16px;
    `}

    ${media.down("LAPTOP")`
      left: 16px;
      bottom: 8px;
    `}

    ${media.down("LAPTOP_MEDIUM")`
      left: 11px;
      bottom: 0px;
    `} 

    ${media.down("LAPTOP_SMALL")`
      left: 8px;
    `}

    ${media.down("TABLET")`
      left: 12px;
    `}

    ${media.down("MOBILE")`
      left: 8px;
      bottom: 8px;
    `}

    ${media.down("MOBILE_SMALL")`
      left: 4px;
    `}
  }

  ${media.down("LAPTOP_BIG")`
    height: 160px;
  `}

  ${media.down("LAPTOP")`
    height: 128px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    height: 112px;
  `}

  ${media.down("LAPTOP_SMALL")`
    height: 88px;
  `}

  ${media.down("TABLET")`
    height: 94px;
  `}

  ${media.down("MOBILE")`
    height: 88px;
  `}
`;

export const PriceBadge = styled.span<{ color: "red" | "green" }>`
  position: absolute;
  top: 2px;
  right: 2px;
  padding: 8px 16px;
  border-radius: 0px 23px 0px 15px;
  background-color: ${props => (props.color === "red" ? "#FFF0F1" : props.color === "green" ? "#E4F7F7" : "white")};
  font-size: 14px;
  line-height: 160%;
  font-weight: normal;
  color: ${colors.gray._800};
  display: none;

  ${media.down("TABLET")`
    display: block;

    > small {
      font-size: 13px;
    }
  `}

  ${media.down("MOBILE")`
    padding: 6px 10px;
    border-radius: 0px 8px 0px 15px;

    > small {
      font-size: 11px;
    }
  `}

  > strong {
    font-weight: 700;
  }
`;

export const DescriptionVatSpan = styled.span`
  font-size: 12px;
  line-height: 160%;

  ${media.down("LAPTOP_BIG")`
    font-size: 16px;
  `}

  ${media.down("LAPTOP")`
    font-size: 12px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    font-size: 12px;
  `}

  ${media.down("LAPTOP_SMALL")`
    font-size: 12px;
  `}

  ${media.down("TABLET")`
    font-size: 16px;
  `}

  ${media.down("MOBILE")`
    font-size: 12px;
  `}
`;

export const DescriptionSpan = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  color: ${colors.gray._700};
  text-align: left;

  strong {
    font-size: 16px;
  }

  ${media.down("LAPTOP_BIG")`
    font-size: 16px;
    line-height: 160%;
    
    strong {
      font-size: 20px;
    }
  `}

  ${media.down("LAPTOP")`
    font-size: 12px;
    line-height: 160%;

    strong {
      font-size: 16px;
    }
  `}

  ${media.down("LAPTOP_MEDIUM")`
    font-size: 12px;
    line-height: 150%;

    strong {
      font-size: 16px;
    }
  `}

  ${media.down("LAPTOP_SMALL")`
    font-size: 12px;
    line-height: 140%;

    strong {
      font-size: 16px;
    }
  `}

  ${media.down("TABLET")`
    font-size: 16px;
    line-height: 150%;
    margin-top: 8px;
  `}

  ${media.down("MOBILE")`
    font-size: 12px;
    line-height: 140%;
    max-width: 95%;

    strong {
      font-size: 16px;
    }
  `}
`;

export const DescriptionHeader = styled.h2`
  margin: 0px;
  position: relative;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
  color: ${colors.gray._900};
  text-align: left;
  max-width: 85%;

  ${media.down("LAPTOP_BIG")`
      font-size: 26px;
  `}

  ${media.down("LAPTOP")`
    font-size: 20px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    font-size: 18px;
  `}

  ${media.down("LAPTOP_SMALL")`
    font-size: 16px;
  `}

  ${media.down("TABLET")`
    font-size: 20px;
    /* width: 80%; */
    width: calc(100% - 100px);
  `}

  ${media.down("MOBILE")`
    font-size: 16px;
  `}
`;

export const DescriptionSpanContainer = styled.div`
  flex: 1;
  width: 100%;
  text-align: left;
  font-size: 12px;
  padding: 0px;
  margin: 0px;
  margin-top: 6px;
`;

export const PriceSpanContainer = styled.div`
  margin-bottom: 12px;
  font-size: 12px;
  text-align: left;

  ${media.down("LAPTOP_BIG")`
    margin-bottom: 24px;
  `}

  ${media.down("LAPTOP")`
    margin-bottom: 16px;
  `}

  ${media.down("LAPTOP")`
    margin-bottom: 12px;
  `}

  ${media.down("TABLET")`
    display: none;
  `}
`;

export const InformationsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  ${media.down("LAPTOP_BIG")`
    padding: 0 24px 0 40px;
    margin-top: 16px;
  `}

  ${media.down("LAPTOP")`
    padding: 0 16px 0 32px;
    margin-top: 4px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    padding: 0 24px 0 32px;
  `}

  ${media.down("LAPTOP_SMALL")`
    padding: 0 16px 0 24px;
    margin-top: 8px;
  `}

  ${media.down("TABLET")`
    padding: 0 55px 0 32px;
    margin-top: 0px;
    align-items: start;
  `}

  ${media.down("MOBILE")`
    padding: 0 10px 0 20px;
  `}
`;

export const UrgentBadge = styled.div`
  margin-left: 4px;
  height: 18px;
  bottom: 2px;
  position: absolute;
  background: ${colors.red._50};
  border-radius: 15px;
  text-align: center;
  font-weight: normal;
  font-size: 10px;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  padding: 0px 8px;
  color: #dd2736;
  white-space: nowrap;
  display: inline-block;
  justify-content: center;
  align-items: center;

  ${media.down("LAPTOP_BIG")`
    height: 24px;
    line-height: 24px;
  `}

  ${media.down("LAPTOP")`
    height: 18px;
    line-height: 18px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    height: 16px;
    line-height: 16px;
  `}

  ${media.down("MOBILE")`
    font-size: 11px;
    height: 16px;
    line-height: 16px;
    padding: 0 4px;
  `}
`;

export const ButtonImage = styled.img<{ type: IOrderType; enabled: boolean }>`
  position: absolute;
  left: 0;
  width: 64px;
  height: 64px;
  margin-top: 34px;
  margin-left: 30px;
  ${props =>
    props.enabled &&
    css`
      animation: ${IconFly} 2s infinite alternate;
    `}

  ${media.down("LAPTOP_BIG")`
    width: 84px;
    height: 84px;
  `}

  ${media.down("LAPTOP")`
    width: 64px;
    height: 64px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    width: 56px;
    height: 56px;
  `}

  ${media.down("LAPTOP_SMALL")`
    width: 44px;
    height: 44px;
    margin-top: 24px;
  `}

  ${media.down("TABLET")`
    width: 56px;
    height: 56px;
    margin-top: 8px;
    position: static;
  `}

  ${media.down("MOBILE")`
    width: 44px;
    height: 44px;
    margin-top: 24px;
  `}

  ${media.down("MOBILE_SMALL")`
    width: 40px;
    height: 40px;
    margin-top: 28px;
  `}
`;
