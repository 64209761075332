import React, { FunctionComponent, useEffect, useState } from "react";
import { IOrder, IOrderType } from "@medlog/shared/models/orders/IOrder";
import { useStripe } from "@stripe/react-stripe-js";
import { setOrderPaymentStartTime } from "common/firebase/orders/setOrderPaymentStartTime";
import { useOrderTimer } from "common/helpers/timer/useOrderTimer";
import Summary from "./Summary";
import useOrderContext from "modules/App/OrdersLayout/OrderContext/useOrderContext";
import PrebookTime from "modules/OrderingForm/Summary/PrebookTime";
import { initCheckoutSession } from "common/firebase/payments/initCheckoutSession";
import { useIsMobileDown } from "common/helpers/useMedia";
import OrderSummaryMobile from "modules/OrderingForm/Summary/OrderSummaryMobile";

const getOrderDeliveryTime = (order: IOrder) => {
  switch (order.type) {
    case IOrderType.URGENT:
      return order.samedayInstead ? <h2>up to 8:00 pm</h2> : <h1>90 min</h1>;
    case IOrderType.SAME_DAY:
      return <h2>up to 8:00 pm</h2>;
    case IOrderType.PRE_BOOK:
      return <PrebookTime date={order.preBookDeliveryDate!} time={order.preBookDeliveryTime!} />;
    default:
      throw "INCORRECT ORDER TYPE";
  }
};

const getOrderPrice = (order: IOrder, price: number | undefined) => {
  const orderPrice = price ? (price / 100)?.toFixed(2) : undefined;
  switch (order.type) {
    case IOrderType.URGENT:
      return order.samedayInstead ? <h2>£{orderPrice}</h2> : <h1>£{orderPrice}</h1>;
    case IOrderType.SAME_DAY:
      return <h2>£{orderPrice}</h2>;
    case IOrderType.PRE_BOOK:
      return <h2>£{orderPrice}</h2>;
    default:
      throw "INCORRECT ORDER TYPE";
  }
};

const OrderSummary: FunctionComponent = () => {
  const stripe = useStripe();
  const { order, orderPrice, setOrder } = useOrderContext();
  const [loading, setLoading] = useState(false);
  const time = useOrderTimer(order);

  useEffect(() => {
    if (!order.paymentStartDate && order.currentState !== undefined) {
      setOrderPaymentStartTime(order.uid);
    }
  }, [order]);

  useEffect(() => {
    const initSession = async () => {
      if (loading && order.price) {
        try {
          const sessionId = await initCheckoutSession(order.uid);
          setOrder({ ...order, paymentInitialized: true });
          await stripe?.redirectToCheckout({ sessionId: sessionId });
        } catch (error) {
          console.log(error, "Cannot generate payment.");
        } finally {
          setLoading(false);
        }
      }
    };
    initSession();
  }, [loading, order.price]);

  const nextStep = () => setLoading(true);

  const isMobile = useIsMobileDown();
  const title = "Almost done!";
  const subline = "Please proceed with the payment and a courier will shortly take care of your order.";

  return isMobile ? (
    <OrderSummaryMobile title={title} loading={loading} subline={subline} nextStep={nextStep} />
  ) : (
    <Summary
      configuration={{
        behaviour: "ORDER_SUMMARY",
        time,
        order,
        title: title,
        subline: subline,
        orderDeliveryTime: getOrderDeliveryTime(order),
        orderPrice: getOrderPrice(order, orderPrice),
        nextStep,
        buttonLoading: loading,
      }}
    />
  );
};

export default OrderSummary;
