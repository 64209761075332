import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import {
  Container,
  ButtonContainer,
  Wrapper,
  PriceContainer,
  DescriptionContainer,
  DetailsContainer,
  TimeInputContainer,
  TimeInput,
  AmPmButton,
  TimeContainer,
  HeroImage,
  ParentContainer,
} from "modules/OrderingForm/DeliveryTime/TimeButton.components";
import CrownIcon from "modules/OrderingForm/DeliveryTime/icons/icon_crown.png";
import DisabledCrownIcon from "modules/OrderingForm/DeliveryTime/icons/icon_crown_disabled.png";
import StandardIcon from "modules/OrderingForm/DeliveryTime/icons/icon_standard.png";
import DisabledStandardIcon from "modules/OrderingForm/DeliveryTime/icons/icon_standard_disabled.png";
import { setHours, setMinutes, getHours, getMinutes } from "date-fns";
import Hero from "modules/OrderingForm/DeliveryTime/icons/hero.png";

type TimeButtonProps = {
  selected?: boolean;
  type: "standard" | "fixed";
  onClick: () => void;
  date?: Date;
  onChange?: (date: Date) => void;
  amChanged?: (isAm: boolean) => void;
  isAm?: boolean;
  price: string;
};

const isNumber = (value: string) => {
  return /^[0-9\b]+$/.test(value);
};

const onPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
  const value = event.currentTarget.value;
  if (!isNumber(value)) {
    event.preventDefault();
  }
};

const getIcon = (type: "standard" | "fixed", selected: boolean) => {
  if (type === "fixed") {
    return selected ? <img src={CrownIcon} id="1" /> : <img src={DisabledCrownIcon} id="3" />;
  } else if (type === "standard") {
    return selected ? <img src={StandardIcon} id="2" /> : <img src={DisabledStandardIcon} id="4" />;
  }
  return null;
};

const getHoursWithZero = (value: number | undefined) => {
  if (value === undefined) return value;
  const hours = value > 12 ? value - 12 : value;

  return hours < 10 ? `0${hours}` : `${hours}`;
};

const getMinutesWithZero = (value: number | undefined) => {
  if (value === undefined) return undefined;
  return value < 10 ? `0${value}` : `${value}`;
};

const TimeButton: FunctionComponent<TimeButtonProps> = ({
  onChange,
  amChanged,
  isAm,
  date,
  type,
  selected = false,
  price,
  onClick,
}) => {
  const dateHours = date ? getHours(date) : undefined;
  const dateMinutes = date ? getMinutes(date) : undefined;
  const [focused, setFocused] = useState(false);

  const [hours, setInputHours] = useState<string | undefined>(getHoursWithZero(dateHours));
  const [minutes, setInputMinutes] = useState<string | undefined>(getMinutesWithZero(dateMinutes));

  const hoursRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const minutesRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    let hoursNumber = Number(hours ?? 0);
    const minutesNumber = Number(minutes ?? 0);
    if (hoursNumber === 12) {
      hoursNumber = isAm ? hoursNumber - 12 : hoursNumber;
    } else if (hoursNumber != 0) {
      hoursNumber = isAm ? hoursNumber : hoursNumber + 12;
    }
    const newDate = setHours(setMinutes(date ?? new Date(), minutesNumber), hoursNumber);
    if (onChange) onChange(newDate);
  }, [hours, minutes, isAm]);

  const onKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key.length === 1 && !/^[0-9\b]+$/.test(event.key)) {
      event.preventDefault();
    } else if (event.currentTarget === hoursRef.current) {
      if (event.key === "ArrowRight" && event.currentTarget.selectionEnd == 2) {
        event.preventDefault();
        minutesRef.current.focus();
        minutesRef.current.setSelectionRange(0, 0);
      }
    } else if (event.currentTarget === minutesRef.current) {
      if (event.key === "ArrowLeft" && event.currentTarget.selectionEnd == 0) {
        hoursRef.current.focus();
        event.preventDefault();
      }
    }
  }, []);

  const onInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (isNumber(value) || value.length === 0) {
      if (event.currentTarget === hoursRef.current) {
        const hoursValue = Number(value);
        if (hoursValue) {
          if (hoursValue > 12 && minutes == undefined) {
            setInputMinutes(value.slice(1));
            minutesRef.current.focus();
          } else if (hoursValue > 1 && hoursValue <= 12) {
            setInputHours(value);
            minutesRef.current.focus();
          } else if (hoursValue < 12) {
            setInputHours(value);
          }
        } else {
          setInputHours(undefined);
        }
      } else if (event.currentTarget === minutesRef.current) {
        const minutesValue = Number(value);
        if (minutesValue) {
          if (minutesValue <= 59) setInputMinutes(value);
        } else {
          setInputMinutes(undefined);
        }
      }
    }
  }, []);

  return (
    <ParentContainer>
      <Container
        selected={selected}
        onClick={onClick}
        highlighted={focused}
        className={type === "fixed" ? "fixed-time" : ""}
      >
        <ButtonContainer
          selected={selected}
          type="button"
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        >
          {getIcon(type, selected)}
          <Wrapper>
            <DescriptionContainer>
              <h3>{type === "standard" ? "Standard" : "Fixed Time"}</h3>
              <h5>{type === "standard" ? "Pick-up between:" : "I need a pick-up at:"}</h5>
            </DescriptionContainer>
            <DetailsContainer>
              {type === "standard" ? (
                <h4>
                  <b>8:00 am</b> and <b>8:00 pm</b>
                </h4>
              ) : (
                <TimeInputContainer>
                  <TimeContainer>
                    <TimeInput
                      ref={hoursRef}
                      maxLength={2}
                      placeholder="00"
                      value={hours}
                      onKeyDown={onKeyDown}
                      onPaste={onPaste}
                      onChange={onInputChange}
                      type="number"
                    />
                    <span>:</span>
                    <TimeInput
                      onPaste={onPaste}
                      ref={minutesRef}
                      onKeyDown={onKeyDown}
                      maxLength={2}
                      placeholder="00"
                      value={minutes}
                      onChange={onInputChange}
                      type="number"
                    />
                  </TimeContainer>
                  <AmPmButton type="button" selected={isAm!} onClick={() => amChanged && amChanged(true)}>
                    Am
                  </AmPmButton>
                  <AmPmButton type="button" selected={!isAm} onClick={() => amChanged && amChanged(false)}>
                    Pm
                  </AmPmButton>
                </TimeInputContainer>
              )}
            </DetailsContainer>
          </Wrapper>
          <PriceContainer>
            <h4>{price}</h4>
          </PriceContainer>
        </ButtonContainer>
      </Container>
      {type === "fixed" && <HeroImage src={Hero} />}
    </ParentContainer>
  );
};

export default TimeButton;
