import { media } from "@medlog/shared/styles/media";
import { PostcodeSuggestion } from "modules/shared/components/Form/Postcodes/PostcodeInput/PostcodeInput.components";
import styled, { css } from "styled-components";

export const AddressSuggestion = styled(PostcodeSuggestion)`
  justify-content: flex-start;
  padding-left: 16px;
  width: 228px;
  ${p =>
    p.active &&
    css`
      background: #f2f5f8;
      color: #1a1f23;
    `}

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  ${media.down("MOBILE")`
    .full-screen & {
      width: 88vw;
      justify-content: start;
      margin-left: 4px;
      background: transparent;
      padding-left: 0;

      :hover {
        background: transparent;
      }
    }
  `}
`;

export const NoResults = styled(PostcodeSuggestion)`
  height: 76px;
  text-align: center;
  padding: 20px 28px;
  box-sizing: border-box;
`;
