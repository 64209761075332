import React, { FunctionComponent } from "react";
import {
  Container,
  Pointer,
  TextContainer,
} from "modules/shared/components/Sidebar/DetailsItem/Items/Items.components";
import { IParcelSize } from "@medlog/shared/models/orders/IOrder";
import DetailsItem from "modules/shared/components/Sidebar/DetailsItem";
import SidebarSections from "modules/shared/components/Sidebar/SidebarSections";
import useOrderContext from "modules/App/OrdersLayout/OrderContext/useOrderContext";
import routesPaths from "modules/Routing/routesPaths";
import { useHistory } from "react-router-dom";
import { Link } from "react-scroll";
import { SCROLL_DURATION } from "modules/shared/components/Sidebar/Sidebar";
import { isSidebarPathAllowed } from "common/navigation/navigationHandler";
import { isSidebarItemActive, isSidebarItemChecked } from "modules/shared/components/Sidebar/SidebarHelpers";

const getParcelSize = (size: IParcelSize) => {
  switch (size) {
    case IParcelSize.SMALL:
      return "Small";
    case IParcelSize.MEDIUM:
      return "Medium";
    case IParcelSize.LARGE:
      return "Large";
  }
};

const getParcelDimensions = (size: IParcelSize) => {
  switch (size) {
    case IParcelSize.SMALL:
      return "25x18x2 cm, < 1 kg";
    case IParcelSize.MEDIUM:
      return "35x25x10 cm, < 2kg";
    case IParcelSize.LARGE:
      return "40x28x15 cm, < 5 kg";
  }
};

const PackageSizeItem: FunctionComponent = () => {
  const { order, orderDetails } = useOrderContext();
  const { parcelSize } = order;
  const history = useHistory();
  const checked = isSidebarItemChecked(SidebarSections.PACKAGE_SIZE, order);
  const isAllowed = isSidebarPathAllowed(routesPaths.SIZE, order, orderDetails);

  const handleClick = () => {
    isAllowed && history.push(routesPaths.SIZE);
  };

  return (
    <Link
      name={routesPaths.SIZE}
      to={routesPaths.SIZE}
      smooth={true}
      duration={SCROLL_DURATION}
      containerId="containerId"
    >
      <DetailsItem
        active={isSidebarItemActive(SidebarSections.PACKAGE_SIZE, location.pathname)}
        checked={checked}
        type={SidebarSections.PACKAGE_SIZE}
        onClick={handleClick}
        animationDisabled={!isAllowed}
      >
        <Container>
          <TextContainer>
            <span>
              {getParcelSize(parcelSize!)}
              <Pointer />
              {getParcelDimensions(parcelSize!)}
            </span>
          </TextContainer>
        </Container>
      </DetailsItem>
    </Link>
  );
};

export default PackageSizeItem;
