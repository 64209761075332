import styled, { css } from "styled-components";
import { colors } from "@medlog/shared/styles/colors";

import {
  RWDWidthsAndHeights,
  ModalHeaderH2,
  ModalDescriptionSpan,
  RWDFontSizes,
  MobileHeader,
} from "modules/shared/styles/mixins";
import { media } from "@medlog/shared/styles/media";

export const ModalOverlay = styled.div<{ show: boolean }>`
  ${p =>
    p.show &&
    css`
      & + * {
        filter: blur(5px);
      }
    `}

  position: absolute;
  display: flex;
  justify-items: center;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 20;
  overflow: hidden;

  ${props =>
    !props.show &&
    css`
      display: none;
    `}

  ${props =>
    props.show
      ? media.down("MOBILE")`
          display: flex;
          background-color: rgba(0, 0, 0, 0.5);
          max-height: 100vh;
          transition: max-height 0ms ease, background-color 200ms ease 0ms, filter 200ms ease;
        `
      : media.down("MOBILE")`
          display: flex;
          background-color: rgba(0, 0, 0, 0);
          max-height: 0vh;
          transition: max-height 0ms ease 200ms, background-color 200ms ease 0ms, filter 200ms ease;
        `}
`;

export const ConfirmModalContainer = styled.div<{ show: boolean }>`
  position: absolute;
  overflow: hidden;
  background: ${colors.white};
  border: 1px solid ${colors.gray._100};
  box-shadow: 0px -4px 24px -18px rgba(58, 68, 74, 0.2), 0px 8px 24px -18px rgba(58, 68, 74, 0.2);
  border-radius: 30px;
  text-align: center;

  box-sizing: border-box;

  top: 50%;

  ${media.down("LAPTOP_BIG")`
      left: calc((100% - 520px) / 2 + 520px);
  `}

  ${media.down("LAPTOP")`
    left: calc((100% - 380px) / 2 + 380px);
  `}

  ${media.down("LAPTOP_MEDIUM")`
    left: calc((100% - 304px) / 2 + 304px);
  `}
  
  ${media.down("LAPTOP_SMALL")`
    left: calc((100% - 296px) / 2 + 296px);
  `}

  ${media.down("LAPTOP_TINY")`
    left: calc((100% - 80px) / 2 + 80px);
  `}

  ${media.down("TABLET")`
    left: calc((100% - 80px) / 2 + 80px);
  `}

  transform: translate(-50%, -50%);
  transform-origin: left top;

  ${RWDWidthsAndHeights(440, 304)} ${media.down("TABLET")`
    width: 440px;
    height: 304px;
  `}
  
  > h2 {
    ${ModalHeaderH2}
    margin-top: 8%;
    margin-bottom: 1.5%;

    ${MobileHeader}
  }

  ${media.down("MOBILE")`
    transform: unset;
    width: 100%;
    height: unset;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0px;
    top: unset;
    transition: transform 200ms ease, max-height 0ms ease 200ms;
  `}

  ${props =>
    props.show
      ? css`
          ${media.down("MOBILE")`
            transform: translateY(0%);
            max-height: unset;
          `}
        `
      : css`
          ${media.down("MOBILE")`
          max-height: 0px;
            transform: translateY(100%);
              `}
        `}
`;

export const ConfirmModalDescription = styled.span`
  display: inline-block;
  max-width: 70%;
  overflow: hidden;
  ${ModalDescriptionSpan};

  ${media.down("MOBILE")`
    font-size: 14px;
    margin-left: 52px;
    margin-right: 52px;
  `}

  ${media.down("MOBILE_SMALL")`
    margin-left: 24px;
    margin-right: 24px;
  `}
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #f2f5f8;
  padding-top: 16px;
  margin: 8% auto 0 auto;
  width: 85%;
  ${media.down("MOBILE")`
    width: fit-content;
    margin-bottom: 32px;

    > :first-child {
      margin-right: 8px;
    }
    > :last-child {
      margin-left: 8px;
    }
  `}
`;

export const CancelButton = styled.button`
  ${RWDFontSizes("LAPTOP_14")}

  cursor: pointer;
  background: none;
  border: none;
  margin-left: 10%;
  color: ${colors.greyscale.black};

  ${media.down("MOBILE")`
    margin-left: 0px;
  `};
`;
