export const SHARE_ROUTE = "share";

const rootPaths = {
  HOME: "/",
  STORYBOOK: "/storybook",
  ERROR404: "/404",
  MY_ORDERS: "/my-orders",
  RATING: "/rating",
};

export const orderingPaths = {
  POSTCODES: "/postcodes",
  SIZE: "/size",
  DETAILS: "/details",
  AVAILABILITY: "/availability",
  PICKUP: "/pickup",
  PICKUP_CONTACT: "/pickup-contact",
  DROPOFF: "/dropoff",
  DATE: "/date",
  TIME: "/time",
  COURIER_AVAILABLE: "/courier-available",
  DROPOFF_CONTACT: "/dropoff-contact",
  SUMMARY: "/summary",
  CANCELATION: "/cancelation",
  CONFIRMATION: "/confirmation",
  RESCHEDULE: "/reschedule",
  REJECTION: "/rejection",
  TIMESUP: "/timesup",
};

// ALL IN ONE
const routesPaths = {
  ...orderingPaths,
  ...rootPaths,
};

export default routesPaths;
