import { IOrder } from "@medlog/shared/models/orders/IOrder";
import { IOrderState } from "@medlog/shared/models/orders/IOrderState";
import getOrderInitialTime from "common/helpers/timer/getOrderInitialTime";
import useInterval from "common/useInterval";
import { differenceInMilliseconds } from "date-fns";
import routesPaths from "modules/Routing/routesPaths";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

type TimerValue = number | null;

export const useOrderTimer = (order: IOrder) => {
  const history = useHistory();
  const [timeLeft, setTimeLeft] = useState<TimerValue>(null);
  const [start, setStart] = useState<Date | null>(null);
  const initialTimeRef = useRef<TimerValue>(null);

  useEffect(() => {
    const timerStates = [
      undefined,
      IOrderState.CREATED,
      IOrderState.PAYMENT_PENDING,
      IOrderState.SAMEDAY_INSTEAD,
      IOrderState.REJECTED,
    ];
    if (!timerStates.includes(order.currentState)) {
      initialTimeRef.current = null;
      setStart(null);
      setTimeLeft(null);
    } else {
      initialTimeRef.current = getOrderInitialTime(order);
      setStart(new Date());
      setTimeLeft(null);
    }
  }, [order.currentState, order.paymentStartDate]);

  useInterval(() => {
    const initialValue = initialTimeRef.current;
    if (initialValue !== null) {
      const now = new Date();
      const elapsed = differenceInMilliseconds(now, start!);
      const newTime = initialValue - elapsed;
      const newTimeSeconds = Math.max(0, Math.floor(newTime / 1000));
      if (newTimeSeconds != timeLeft) setTimeLeft(newTimeSeconds);
    }
  }, 10);

  useEffect(() => {
    if (timeLeft !== null && timeLeft <= 0) {
      initialTimeRef.current = null;
      setTimeLeft(null);
      history.push(routesPaths.TIMESUP, true);
    }
  }, [timeLeft]);

  return timeLeft;
};
