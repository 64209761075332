import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import Heading from "modules/shared/components/Heading";
import {
  FormContainer,
  Container,
  Wrapper,
  ErrorSpan,
  BreakRow,
  HeroImage,
  HeaderContainer,
} from "modules/OrderingForm/DeliveryTime/DeliveryTime.components";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import useOrderContext from "modules/App/OrdersLayout/OrderContext/useOrderContext";
import routesPaths from "modules/Routing/routesPaths";
import { useHistory } from "react-router";
import Button from "@medlog/shared/components/Button";
import SectionContainer from "modules/shared/components/SectionContainer/SectionContainer";
import { deliveryTimeValidationSchema, OrderTimeFormValues } from "modules/OrderingForm/DeliveryTime/DeliveryTimeForm";
import TimeButton from "modules/OrderingForm/DeliveryTime/TimeButton";
import { getHours, getMinutes } from "date-fns";
import { IOrder, IPreBookDeliveryTime } from "@medlog/shared/models/orders/IOrder";
import { subHours, addHours } from "date-fns";
import { ReactComponent as ErrorIcon } from "modules/OrderingForm/DeliveryTime/icons/icon_error.svg";
import { format } from "date-fns-tz";
import Hero from "modules/OrderingForm/DeliveryTime/icons/hero.png";
import { getAsLondonDate } from "@medlog/shared/helpers/formatAsLondonTime";
import { NON_EXISTING_ID } from "modules/App/OrdersLayout/OrderContext/Storage/baseOrder";
import MobileSheet from "modules/shared/components/Mobile/MobileSheet";
import { useIsMobileDown } from "common/helpers/useMedia";
import MobileButton from "modules/shared/components/Mobile/MobileButton";
import { WhiteCover } from "modules/App/OrdersLayout/OrdersLayout.components";
import { updatePrebookDetails } from "common/firebase/orders/updatePrebookDetails";

//Regex added to remove different time string error on Safari.
const buildLondonTime = (date: Date) => {
  return new Date(format(date, "yyyy-MM-dd HH:mm:ss zzz", { timeZone: "Europe/London" }).replace(/-/g, "/"));
};

const DeliveryTime: FunctionComponent = () => {
  const { order, setOrder, localPrice } = useOrderContext();
  const history = useHistory();
  const [selectedType, setSelectedType] = useState<"standard" | "fixed">("fixed");
  const [date, setDate] = useState<Date | undefined>(
    order.preBookDeliveryTime?.deliveryTime ? getAsLondonDate(order.preBookDeliveryTime!.deliveryTime!) : undefined
  );
  const [isAm, setIsAm] = useState(true);
  const [error, setError] = useState(false);

  const isMobile = useIsMobileDown();

  const checkForError = useCallback((date: Date) => {
    const hours = getHours(date);
    const minutes = getMinutes(date);
    if (hours < 8) {
      setError(true);
    } else if (hours > 20) {
      setError(true);
    } else if (hours === 20 && minutes > 0) {
      setError(true);
    } else {
      setError(false);
    }
  }, []);

  useEffect(() => {
    if (date) {
      checkForError(date);
      setIsAm(getHours(date) < 12);
    }
  }, []);

  useEffect(() => {
    if (order.preBookDeliveryTime) {
      const type = order.preBookDeliveryTime?.deliveryTime ? "fixed" : "standard";
      setSelectedType(type);
    } else {
      setSelectedType("fixed");
    }
  }, []);

  const handleSubmit = useCallback(
    async (values: OrderTimeFormValues, helpers: FormikHelpers<OrderTimeFormValues>) => {
      if (error && selectedType === "fixed") return;

      const preBookDeliveryTime: IPreBookDeliveryTime = {
        timeSpecified: values.preBookDeliveryTime?.timeSpecified ?? false,
        deliveryTime: values.preBookDeliveryTime?.deliveryTime
          ? buildLondonTime(values.preBookDeliveryTime?.deliveryTime)
          : undefined,
      };

      const price = selectedType === "standard" ? localPrice?.sameday : localPrice?.urgent;
      const newOrder: IOrder = {
        ...order,
        price: price,
        preBookDeliveryTime: preBookDeliveryTime,
      };
      if (order.uid !== NON_EXISTING_ID) {
        helpers.setSubmitting(true);
        try {
          await updatePrebookDetails(order, order.preBookDeliveryDate, preBookDeliveryTime);
          setOrder({ ...newOrder });
          history.push(routesPaths.PICKUP);
        } catch (error) {
          console.log(error);
        } finally {
          helpers.setSubmitting(true);
        }
      } else {
        setOrder({ ...newOrder });
        history.push(routesPaths.PICKUP);
      }
    },
    [error, selectedType]
  );

  const onChangeDate = useCallback(
    (
      newDate: Date,
      helpers: FormikProps<{
        preBookDeliveryTime: IPreBookDeliveryTime | undefined;
      }>
    ) => {
      setDate(newDate);
      checkForError(newDate);
      const time: IPreBookDeliveryTime = {
        timeSpecified: true,
        deliveryTime: newDate,
      };
      helpers.setFieldValue("preBookDeliveryTime", time);
    },
    []
  );

  const getOrderPrice = (type: "standard" | "fixed") => {
    // CHECK when rescheduled
    const price = type === "standard" ? localPrice?.sameday : localPrice?.urgent;
    return `£${((price ?? 0) / 100).toFixed(2)}`;
  };

  return (
    <SectionContainer>
      <Formik
        initialValues={{
          preBookDeliveryTime: order.preBookDeliveryTime,
          timeSpecified: order.preBookDeliveryTime?.timeSpecified,
        }}
        validationSchema={deliveryTimeValidationSchema}
        onSubmit={handleSubmit}
      >
        {formProps => (
          <Form>
            <Container>
              <Wrapper>
                <HeaderContainer>
                  <HeroImage src={Hero} />
                  <Heading big>
                    Choose a <b>time</b> for the&nbsp;pick&#8209;up
                  </Heading>
                </HeaderContainer>
                <MobileSheet>
                  <FormContainer>
                    <TimeButton
                      price={getOrderPrice("standard")}
                      type="standard"
                      selected={selectedType === "standard"}
                      onClick={() => {
                        const time: IPreBookDeliveryTime = {
                          timeSpecified: false,
                          deliveryTime: undefined,
                        };
                        formProps.setFieldValue("preBookDeliveryTime", time);
                        setSelectedType("standard");
                      }}
                    />
                    <TimeButton
                      price={getOrderPrice("fixed")}
                      onChange={date => {
                        onChangeDate(date, formProps);
                        setSelectedType("fixed");
                      }}
                      amChanged={am => {
                        if (isAm != am) {
                          if (am && date) {
                            onChangeDate(addHours(date, 12), formProps);
                          } else if (date) {
                            onChangeDate(subHours(date, 12), formProps);
                          }
                          setIsAm(am);
                        }
                      }}
                      isAm={isAm}
                      date={date}
                      type="fixed"
                      selected={selectedType === "fixed"}
                      onClick={() => {
                        const time: IPreBookDeliveryTime = {
                          timeSpecified: true,
                          deliveryTime: date,
                        };
                        setSelectedType("fixed");
                        formProps.setFieldValue("preBookDeliveryTime", time);
                      }}
                    />
                    {!isMobile && <BreakRow />}
                    <ErrorSpan visible={error && formProps.submitCount > 0 && selectedType === "fixed"}>
                      <ErrorIcon /> Enter the time between 8 am and 8 pm.
                    </ErrorSpan>
                  </FormContainer>
                </MobileSheet>
              </Wrapper>
            </Container>

            {isMobile && (
              <MobileButton
                text="Next Step"
                isSubmit={true}
                customPrice={selectedType === "standard" ? localPrice?.sameday : localPrice?.urgent}
              />
            )}

            {!isMobile && (
              <Button className="bottomButton" type="submit">
                Next step
              </Button>
            )}
          </Form>
        )}
      </Formik>
      <WhiteCover />
    </SectionContainer>
  );
};

export default DeliveryTime;
