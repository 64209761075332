import { colors } from "@medlog/shared/styles/colors";
import { media } from "@medlog/shared/styles/media";
import styled from "styled-components/macro";

export const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 30px;
  padding: 0px 56px 0px 56px;
  align-self: center;
  justify-self: center;
  margin-top: auto;

  ${media.down("TABLET")`
    margin-top: 40px;
  `}

  ${media.down("MOBILE")`
    margin-top: 32px;
    margin-bottom: 80px;
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: -50px;

  ${media.down("LAPTOP")`
    margin-top: 0;
  `}

  ${media.down("LAPTOP_SMALL")`
    margin-top: -30px;
  `}

  ${media.down("TABLET")`
    margin-top: 0;
  `}

  ${media.down("MOBILE")`
    align-items: center;
    > h2 {
      max-width: 60vw;
      align-self: center;
      position: relative;
    }
  `}
`;

export const BreakRow = styled.div`
  flex-basis: 100%;
`;

export const FormContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 100%;
  margin-top: 114px;
  justify-content: center;

  flex-wrap: wrap;

  > :nth-child(odd) {
    margin-right: 32px;
  }

  transform: scale(1.25);

  ${media.down("LAPTOP")`
    transform: scale(1);
    margin-top: 64px;
  `}

  ${media.down("LAPTOP_SMALL")`
    transform: scale(.9);
    margin-top: 40px;
  `}

  ${media.down("TABLET")`
    transform: scale(1);
    margin-top: 40px;

    > :nth-child(odd) {
      margin-right: 0px;
    }
  `};

  ${media.down("MOBILE")`
    margin-top: 30px;
    gap: 0px;

    > :first-child {
      margin-bottom: 20px;
    }

    > :last-child {
      margin-top: 10px;
    }
  `}

  ${media.down("MOBILE_TINY")`
    margin-top: 16px;

    > :first-child {
      margin-bottom: 8px;
    }

    > :last-child {
      margin-top: 8px;
    }
  `}
`;

export const ErrorSpan = styled.span<{ visible: boolean }>`
  margin-top: 48px;
  height: 40px;
  background: ${colors.red._50};
  border-radius: 10px;
  padding-left: 40px;
  padding-right: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 40px;
  text-align: center;
  color: ${colors.red._500};
  transition: opacity ease-in 100ms 0ms, visibility ease-in 10ms ${props => (props.visible ? 0 : 500)}ms;
  visibility: ${props => (props.visible ? "visible" : "hidden")};
  opacity: ${props => (props.visible ? 1 : 0)};
  display: flex;
  align-items: center;

  > svg {
    margin-right: 4px;
  }

  ${media.down("LAPTOP_MEDIUM")`
    margin-top: 24px;
  `}

  ${media.down("LAPTOP_SMALL")`
    margin-top: 24px;
  `}

  ${media.down("LAPTOP_TINY")`
    margin-top: 8px;
  `}

  ${media.down("TABLET")`
    margin-top: 48px;
  `};

  ${media.down("MOBILE")`
    margin-top: 0;
    margin-bottom: 0;
    width: 80vw;
    box-sizing: border-box;
    padding-left: 0;
    padding-right: 0;
    justify-content: center;
    visibility: visible;
  `}
`;

export const HeroImage = styled.img`
  display: none;
  margin: 0 auto;

  ${media.down("TABLET")`
    display: block;
    width: 96px;
  `};

  ${media.down("MOBILE")`
    position: absolute;
    right: -32px;
    top: -40px;
    z-index: 2;
  `}

  ${media.down("MOBILE_TINY")`
    display: none;
  `}
`;

export const HeaderContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
