import styled from "styled-components/macro";
import { media } from "@medlog/shared/styles/media";
import { MessageDescriptionSpan, MessageHeaderH2, MobileDescription, MobileHeader } from "modules/shared/styles/mixins";

export const WhiteSheetFix = styled.div`
  height: 50vh;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 0;
  background: white;
  display: none;

  ${media.down("MOBILE")`
    display: static;
  `}
`;

export const Container = styled.div`
  display: flex;
  margin-top: 92px;
  height: calc(100% - 184px);
  width: 100%;
  justify-content: center;

  ${media.down("MOBILE")`
    margin-top: 192px;
    height: calc(100% - 192px);
  `}

  ${media.down("MOBILE_SMALL")`
    margin-top: 128px;
    height: calc(100% - 128px);
  `}

  ${media.down("MOBILE_TINY")`
    margin-top: 112px;
    height: calc(100% - 112px);
  `}
`;

export const HeroMobileContainer = styled.div`
  display: none;
  position: absolute;

  ${media.down("MOBILE")`
    display: block;
      top: 80px;
    > img {
      height: 172px;
    }
  `}

  ${media.down("MOBILE_SMALL")`
      top: 64px;
    > img {
      height: 154px;
    }
  `}

  ${media.down("MOBILE_TINY")`
      top: 52px;
    > img {
      height: 128px;
    }
  `}
`;

export const HeroContainer = styled.div`
  position: absolute;
  right: 0px;
  bottom: 110px;
  width: 280px;
  height: 300px;

  > img {
    width: 100%;
    height: 100%;
  }

  ${media.down("LAPTOP")`
    width: 216px;
    height: 216px;
    right: 0px;
    bottom: 100px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    width: 188px;
    height: 188px;
    bottom: 90px;
  `}

  ${media.down("LAPTOP_SMALL")`
    width: 150px;
    height: 150px;
    bottom: 85px;
  `}

  ${media.down("TABLET")`
    width: 190px;
    height: 190px;
    top: -130px;
    left: 0;
    right: 0;
    margin: 0px auto;
  `}

  ${media.down("MOBILE")`
    display: none;
  `}
`;

export const FailureContainer = styled.div`
  box-sizing: border-box;
  /* padding-bottom: 30px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  width: 770px;
  height: 530px;
  padding-left: 80px;

  > h2 {
    ${MessageHeaderH2}
  }

  > img {
    position: absolute;
    right: 0px;
    bottom: 110px;
    width: 280px;
    height: 300px;
  }

  ${media.down("LAPTOP")`

    padding-left: 64px;
    > img { 
      width: 216px;
      height: 216px;
      right: 0px;
      bottom: 100px;
    }

    width: 616px;
    height: 424px;
  `}

  ${media.down("LAPTOP_MEDIUM")`

    padding-left: 56px;
    > img { 
      width: 188px;
      height: 188px;
      bottom: 90px;
    }

    width: 552px;
    height: 376px;
  `}

  ${media.down("LAPTOP_SMALL")`
    
    padding-left: 56px;
    > img { 
      width: 150px;
      height: 150px;
      bottom: 85px;
    }

    width: 488px;
    height: 328px;
  `}

  ${media.down("TABLET")`
    align-items: center;
    padding-left: 0px;

    > img { 
      width: 190px;
      height: 190px;
      top: -130px;
      left: 0;
      right: 0;
      margin: 0px auto;
    }

    width: 552px;
    height: 488px;
  `}

  > h2 {
    ${MobileHeader}
  }

  ${media.down("MOBILE")`  
    width: 100%;
    height: 100%;
    margin-top: 20px;
    padding-top: 0px;
  `}

  ${media.down("MOBILE_SMALL")`
    margin-top: 100px;
    padding-bottom: 30px;
  `}

  ${media.down("MOBILE_TINY")`
    margin-top: 112px;
  `}
`;

export const FailureDescription = styled.span<{ biggerMarginTop?: boolean }>`
  max-width: 57%;

  ${MessageDescriptionSpan}

  ${media.down("TABLET")`
    max-width: 72%;
    text-align: center;
  `}

  ${media.down("MOBILE")`
    max-width: unset;
    margin-left: 30px;
    margin-right: 30px;
  `}

  ${MobileDescription}
`;
