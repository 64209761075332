import React, { FunctionComponent, useCallback, useState } from "react";
import useOrderContext from "modules/App/OrdersLayout/OrderContext/useOrderContext";
import routesPaths from "modules/Routing/routesPaths";

import { useHistory } from "react-router-dom";
import Failure from "modules/OrderingForm/Failure/Failure";
import PlaceArrow from "modules/OrderingForm/Failure/PlaceArrow";
import { handleAnotherDay } from "common/helpers/handleAnotherDay";
import HeroReject from "modules/OrderingForm/Failure/icons/hero_reject.png";

const Rejection: FunctionComponent = () => {
  const { clearOrder, order, setupOrder, setLocalPrice } = useOrderContext();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const header = "We are too busy...";
  const mainButtonText = "Order for another day";
  const description = (
    <>
      Our couriers have their hands full and will not be able to deliver your order today. We don&apos;t want to promise
      something we can&apos;t do.
      <br />
      <br />
      If you wish you{" "}
      <strong>
        can pre-book your <PlaceArrow arrowType="top">order</PlaceArrow>{" "}
      </strong>{" "}
      for another day right away by clicking the button below.
    </>
  );

  const mainButttonClick = useCallback(async () => {
    setLoading(true);
    await handleAnotherDay(order, history, setupOrder, setLocalPrice);
    setLoading(false);
  }, [order]);

  const underlinedButtonClick = () => {
    clearOrder();
    history.push(routesPaths.HOME, true);
  };

  return (
    <Failure
      configuration={{
        header,
        mainButtonText,
        description,
        mainButttonClick,
        underlinedButtonClick,
        loading,
        hero: <img src={HeroReject} />,
      }}
    />
  );
};

export default Rejection;
