import { DeliveriesSettings, UrgentDeliveriesEnabled, SameDayDeliveriesEnabled } from "common/firebase/Queries"

export const areDeliveriesDisabled = async (type: "urgent" | "sameday" | "general") => {
    const deliveriesSettingsSnap = await DeliveriesSettings().get()
    if (type === "urgent") {
        const urgentDeliveriesEnabledSnap = await UrgentDeliveriesEnabled().get()
        return deliveriesSettingsSnap.exists || urgentDeliveriesEnabledSnap.exists
    } else if (type === "sameday") {
        const samedayDeliveriesEnabledSnap = await SameDayDeliveriesEnabled().get()
        return deliveriesSettingsSnap.exists || samedayDeliveriesEnabledSnap.exists
    }

    return deliveriesSettingsSnap.exists
}