import React, { FunctionComponent, useCallback, useState, useRef, useEffect } from "react";
import {
  PostcodeInputContainer,
  PostcodeSuggestion,
  PostcodeSuggestionsContainer,
} from "modules/shared/components/Form/Postcodes/PostcodeInput/PostcodeInput.components";
import useOnClickOutside from "common/helpers/useOnClickOutside";
import Input from "@medlog/shared/components/Input/Input";
import { ReactComponent as SmallPinIcon } from "modules/shared/components/Form/Postcodes/PostcodeInput/smallPin.svg";
import { IPostcodeQuery } from "common/types/PostcodesAPI/IPostcode";

const MIN_POSTCODE_LENGHT = 1;

type PostcodeInputProps = {
  fieldType: "pickup" | "dropoff";
  value: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  postcodeSelected: boolean;
  error?: string;
  isMobile?: boolean;
  isFullScreen?: boolean;
  onClick?: () => void;
  onDone?: () => void;
  disabled?: boolean;
  handleChange?: boolean;
};

const PostcodeInput: FunctionComponent<PostcodeInputProps> = ({
  fieldType,
  value,
  setFieldValue,
  handleChange = true,
  postcodeSelected,
  error,
  isMobile,
  isFullScreen,
  onClick,
  disabled,
  onDone,
}: PostcodeInputProps) => {
  const fieldName = `${fieldType}Postcode`;
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);

  const suggestionsWrapperRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  useOnClickOutside(suggestionsWrapperRef, () => {
    setShowSuggestions(false);
  });

  const onSuggestionClick = useCallback(async (suggestion: string) => {
    setFieldValue(fieldName, suggestion);

    setShowSuggestions(false);
    setFieldValue(`${fieldName}Selected`, true);

    onDone && onDone();
  }, []);

  const handlePostcodeChange = useCallback(
    async (postcode: string) => {
      setFieldValue(fieldName, postcode);
      if (postcode.length > MIN_POSTCODE_LENGHT && handleChange) {
        let apiError = false;
        let filteredSuggestions: string[] = [];
        try {
          const response = await fetch(`https://api.postcodes.io/postcodes?q=${postcode}`);
          const data: IPostcodeQuery = await response.json();

          filteredSuggestions = data.result ? data.result.filter(r => r.region === "London").map(r => r.postcode) : [];
        } catch (error) {
          apiError = true;
        }

        if (apiError) {
          filteredSuggestions = [postcode.toUpperCase()];
        }

        setSuggestions(filteredSuggestions);
        setShowSuggestions(true);

        setFieldValue(`${fieldName}Selected`, false);
        setFieldValue(`${fieldType}Address`, "");

        if (!apiError && filteredSuggestions.length === 1 && !isMobile) {
          onSuggestionClick(filteredSuggestions[0]);
        }
      }
    },
    [handleChange]
  );

  useEffect(() => {
    if (!value && handleChange) {
      setFieldValue(`${fieldName}Selected`, false);
      setFieldValue(`${fieldType}Address`, "");
      setSuggestions([]);
    }
  }, [value]);

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!suggestions || !showSuggestions || e.shiftKey) return;
    switch (e.key) {
      case "Enter":
        e.preventDefault();
        if (suggestions.length > 0 && suggestions[activeSuggestionIndex]) {
          onSuggestionClick(suggestions[activeSuggestionIndex]);
          setActiveSuggestionIndex(-1);
        }
        break;
      case "ArrowUp":
        e.preventDefault();
        if (activeSuggestionIndex > 0) setActiveSuggestionIndex(activeSuggestionIndex - 1);
        suggestionsWrapperRef.current.scroll({ top: activeSuggestionIndex * 48 - 48, behavior: "smooth" });
        break;
      case "ArrowDown":
        e.preventDefault();
        if (activeSuggestionIndex + 1 < suggestions.length) setActiveSuggestionIndex(activeSuggestionIndex + 1);
        suggestionsWrapperRef.current.scroll({ top: activeSuggestionIndex * 48, behavior: "smooth" });
        break;
      default:
        break;
    }
  };

  return (
    <PostcodeInputContainer onClick={onClick}>
      <Input
        disabled={disabled}
        autoComplete="off"
        type={isMobile ? "text" : "search"}
        onDone={() => onDone && onDone()}
        label="Postcode"
        autoFocus={isFullScreen}
        inputSize={isMobile ? "mobile" : "small"}
        onKeyDown={onKeyDown}
        onClick={() => {
          if (!postcodeSelected && suggestions.length > 0) setShowSuggestions(true);
        }}
        onChange={e => handlePostcodeChange(e.target.value)}
        value={value}
        error={error}
        hideErrorMessage
        hideErrorIcon
        hideEndAdornment
      />
      {showSuggestions && (
        <PostcodeSuggestionsContainer ref={suggestionsWrapperRef} small suggestionsVisible>
          {suggestions.length ? (
            suggestions.map((suggestion, index) => (
              <PostcodeSuggestion
                key={index}
                active={activeSuggestionIndex === index}
                onClick={() => onSuggestionClick(suggestion)}
              >
                <SmallPinIcon />
                {suggestion}
              </PostcodeSuggestion>
            ))
          ) : (
            <PostcodeSuggestion>No results.</PostcodeSuggestion>
          )}
        </PostcodeSuggestionsContainer>
      )}
    </PostcodeInputContainer>
  );
};

export default PostcodeInput;
