import * as Yup from "yup";

export interface AuthenticationFormValues {
  email: string;
}

export const authenticationInitialValues: AuthenticationFormValues = {
  email: "",
};

export const authenticationValidationSchema = Yup.object().shape({
  email: Yup.string().email("E-mail must be valid").required("E-mail is required"),
});
