import React, { FunctionComponent } from "react";
import {
  ArrowContainer,
  Container,
  WhatsappContainer,
  WhatsappDescription,
  WhatsappIconContainer,
} from "modules/shared/components/Whatsapp/Whatsapp.components";
import { ReactComponent as WhatsappIcon } from "modules/shared/components/Whatsapp/Whatsapp.svg";
import useSettingsContext from "modules/OrderingRoutingLayout/SettingsContext/useSettingsContext";
import { isMobile } from "react-device-detect";
import { ReactComponent as ArrowIcon } from "modules/shared/components/Whatsapp/arrow.svg";
import { useLocation } from "react-router";
import routesPaths from "modules/Routing/routesPaths";
import { Transition } from "react-transition-group";
import {
  HomeWhatsappDescription,
  SizeWhatsappDescription,
} from "modules/shared/components/Whatsapp/Components/Descriptions";

const WHATSAPP_URL = isMobile ? "https://wa.me/" : "https://web.whatsapp.com/send?phone=";

const Whatsapp: FunctionComponent = () => {
  const { phoneNumber } = useSettingsContext();
  const formattedPhoneNumber = `${phoneNumber?.directional}${phoneNumber?.phoneNumber}`;
  const location = useLocation();

  return (
    <Container>
      <ArrowContainer visible={location.pathname === routesPaths.SIZE}>
        <ArrowIcon />
      </ArrowContainer>
      <WhatsappContainer>
        <WhatsappDescription>
          <Transition timeout={300} in={location.pathname === routesPaths.HOME} unmountOnExit>
            {status => <HomeWhatsappDescription status={status} />}
          </Transition>
          <Transition timeout={300} in={location.pathname === routesPaths.SIZE} unmountOnExit>
            {status => <SizeWhatsappDescription status={status} />}
          </Transition>
        </WhatsappDescription>
        <WhatsappIconContainer href={`${WHATSAPP_URL}${formattedPhoneNumber}`} target="_blank">
          <WhatsappIcon />
        </WhatsappIconContainer>
      </WhatsappContainer>
    </Container>
  );
};

export default Whatsapp;
