import React, { FunctionComponent } from "react";
import { colors } from "@medlog/shared/styles/colors";
import { Loader } from "./ButtonLoader.components";

type ButtonLoaderProps = {
  variant: "dark" | "red" | "light";
};

const getColor = (color: string) => {
  switch (color) {
    case "light":
      return colors.mint._500;
    case "dark":
      return colors.gray._100;
    case "red":
      return colors.gray._100
    default:
      return "";
  }
};

const ButtonLoader: FunctionComponent<ButtonLoaderProps> = ({ variant }) => {
  return <Loader color={getColor(variant)}/>;
};
export default ButtonLoader;
