import styled from "styled-components";
import { colors } from "@medlog/shared/styles/colors";

export const RescheduledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${colors.red._600};

  img {
    margin-top: 2px;
    margin-right: 4.5px;
  }
`;
