import React from "react";
import { useIsMobileSidebar, useIsTabletSidebar } from "common/helpers/useMedia";
import TabletSidebar from "modules/shared/components/Sidebar/TabletSidebar/TabletSidebar";
import Sidebar from "modules/shared/components/Sidebar/Sidebar";
import MobileSidebar from "modules/shared/components/Sidebar/MobileSidebar/MobileSidebar";

const SidebarHandler = () => {
  const isTabletSidebar = useIsTabletSidebar();
  const isMobileSidebar = useIsMobileSidebar();

  if (isMobileSidebar) return <MobileSidebar />;

  return isTabletSidebar ? <TabletSidebar /> : <Sidebar />;
};

export default SidebarHandler;
