import React, { FunctionComponent, useCallback } from "react";
import { useHistory } from "react-router-dom";
import Summary from "./Summary";
import { makeOrderLost } from "common/firebase/orders/makeOrderLost";
import useOrderContext from "modules/App/OrdersLayout/OrderContext/useOrderContext";
import routesPaths from "modules/Routing/routesPaths";
import { useOrderTimer } from "common/helpers/timer/useOrderTimer";
import { useIsMobileDown } from "common/helpers/useMedia";
import CoureirAvailableMobile from "modules/OrderingForm/Summary/CoureirAvailableMobile";

const getOrderPrice = (price: number | undefined) => (price ? `£${(price / 100)?.toFixed(2)}` : "");

const CourierAvailable: FunctionComponent = () => {
  const history = useHistory();
  const { order, clearOrder, orderPrice } = useOrderContext();
  const time = useOrderTimer(order);

  const nextStep = useCallback(() => history.push(routesPaths.PICKUP), []);
  const cancelOrder = useCallback(() => {
    makeOrderLost(order.uid);
    clearOrder();
    history.push(routesPaths.HOME, true);
  }, [order]);

  const isMobile = useIsMobileDown();
  const title = "A courier is available!";
  const subline =
    "We have booked a courier for you, but please note that your reservation will expire after 30 minutes. Continue to complete the order.";

  return isMobile ? (
    <CoureirAvailableMobile
      title={title}
      subline={subline}
      time="90 min"
      price={getOrderPrice(orderPrice)}
      onNext={nextStep}
      onCancel={cancelOrder}
    />
  ) : (
    <Summary
      configuration={{
        behaviour: "COURIER_AVAILABLE",
        time,
        order,
        title: title,
        subline: subline,
        orderDeliveryTime: <h1>90 min</h1>,
        orderPrice: <h1>£{orderPrice ? orderPrice / 100 : ""}</h1>,
        nextStep,
        cancelOrder,
        buttonLoading: false,
      }}
    />
  );
};

export default CourierAvailable;
