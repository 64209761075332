import {
  AnimatedMenuContainer,
  AnimatedMenuIconWrapper,
  Logo,
  MobileSidebarContainer,
  ScreenDimmer,
} from "modules/shared/components/Sidebar/TabletSidebar/TabletSidebar.components";
import React, { FunctionComponent, useState, useEffect } from "react";
import LogoDrop from "@medlog/shared/icons/icon_logo_drop.png";
// import { ReactComponent as LogoDrop } from "modules/shared/components/Sidebar/TabletSidebar/mobile_logo_drop.svg";
import { ReactComponent as OpenIcon } from "modules/shared/components/Sidebar/TabletSidebar/open_icon.svg";
import { Transition } from "react-transition-group";
import Sidebar from "modules/shared/components/Sidebar";
import useOrderContext from "modules/App/OrdersLayout/OrderContext/useOrderContext";
import useEventHandler from "common/eventHandlers/useEventHandler";
import { appEventTypes } from "common/events/AppEventTarget";
import { useHistory } from "react-router-dom";
import routesPaths from "modules/Routing/routesPaths";

const TabletSidebar: FunctionComponent = () => {
  const [showMobileSidebar, setShowMobileSidebar] = useState(false);
  const { order } = useOrderContext();
  const history = useHistory();

  useEventHandler(appEventTypes.HIDE_MOBILE_SIDEBAR_EVENT, () => {
    setShowMobileSidebar(false);
  });

  useEffect(() => {
    setShowMobileSidebar(false);
  }, [location.pathname]);

  return (
    <>
      <Transition timeout={50} in={!showMobileSidebar}>
        {status => (
          <MobileSidebarContainer status={status}>
            <Logo src={LogoDrop} onClick={() => history.push(routesPaths.HOME)} />
            {order.type && <OpenIcon className="open-button" onClick={() => setShowMobileSidebar(true)} />}
          </MobileSidebarContainer>
        )}
      </Transition>
      <Transition timeout={50} in={showMobileSidebar}>
        {status => <ScreenDimmer onClick={() => setShowMobileSidebar(false)} status={status} />}
      </Transition>
      <Transition timeout={100} in={showMobileSidebar}>
        {status => (
          <AnimatedMenuContainer status={status}>
            <Sidebar />
            <AnimatedMenuIconWrapper>
              <OpenIcon onClick={() => setShowMobileSidebar(false)} />
            </AnimatedMenuIconWrapper>
          </AnimatedMenuContainer>
        )}
      </Transition>
    </>
  );
};

export default TabletSidebar;
