import { colors } from "@medlog/shared/styles/colors";
import { media } from "@medlog/shared/styles/media";
import styled, { css } from "styled-components/macro";

export const ParentContainer = styled.div`
  position: relative;
`;

export const HeroImage = styled.img`
  position: absolute;
  bottom: -32px;
  right: -64px;
  pointer-events: none;
  width: 128px;

  ${media.down("TABLET")`
    display: none;
  `}
`;

export const Container = styled.div<{ selected: boolean; highlighted: boolean }>`
  width: 248px;
  height: 319px;
  overflow: hidden;
  border: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background: ${colors.white};
  transition: transform 0.2s ease;
  position: relative;
  overflow: visible;

  ${props =>
    props.highlighted &&
    css`
      transform: scale(1.05);
    `}

  :hover {
    transform: scale(1.05);
  }

  ${props =>
    props.selected &&
    css`
      box-shadow: 0px -8px 24px -18px rgba(58, 68, 74, 0.2), 0px 8px 24px -18px rgba(58, 68, 74, 0.2);
      cursor: pointer;
      transform: scale(1.05);

      :active {
        background: linear-gradient(${colors.red._500}, ${colors.mint._400});
      }
    `}

  ${media.down("LAPTOP_MEDIUM")`
      width: 248px;
      height: 266px;
  `}

  ${media.down("TABLET")`
    width: 478px;
    height: 178px;

    transform: scale(1);

    :hover {
      transform: scale(1) !important;
    }

    :nth-child(2) { 
      height: 194px;
    }

    :nth-child(2) > button { 
      height: 190px;
    }
  `}

  ${props =>
    props.selected &&
    css`
      background: linear-gradient(${colors.red._500}, ${colors.mint._400});
    `}

  ${props => media.down("MOBILE")`
    width: 84vw;
    height: 112px;

    ${
      !props.selected &&
      css`
        border: 1px solid #edf0f3;
      `
    }
    

    &.fixed-time  {
      height: 131px;
    }
  `}
`;

export const ButtonContainer = styled.button<{ selected: boolean }>`
  width: 244px;
  height: 315px;
  cursor: pointer;
  border: 0px;
  box-shadow: none;
  padding: 0;
  outline: none;
  border-radius: 13px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  > img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -30%);
  }

  ${media.down("LAPTOP_MEDIUM")`
      width: 244px;
      height: 262px;
  `}

  ${media.down("TABLET")`
    width: 474px;
    height: 174px;

    > img {
    top: 32px;
    left: 32px;
    transform: none;
  }
  `}

  ${media.down("MOBILE")`
    width: calc(84vw - 4px);
    height: calc(100% - 4px);

    > img {
    width: 40px;
    height: 40px;
    top: 18px;
    left: 18px;
  }
  `}
`;

export const Wrapper = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  ${media.down("TABLET")`
    align-items: start;
  `}

  ${media.down("MOBILE")`
    justify-content: start;
  `}
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  margin-top: 16px;

  > h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    margin: 0px;
    text-align: center;
    color: ${colors.gray._800};
  }

  > h5 {
    margin: 0px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-top: 4px;
    line-height: 17px;
    text-align: center;
    color: ${colors.gray._600};
  }

  ${media.down("TABLET")`
    align-items: start; 
    margin-left: 120px;
    margin-top: 0px;
    > h3 { text-align: left; }
    > h5 { text-align: left; font-size: 18px; }
  `}

  ${media.down("MOBILE")`
    margin-left: 72px;
    > h3 { font-size: 18px; margin-top: 16px;  }
    > h5 { font-size: 14px; margin-top: -2px; }
  `}
`;

export const DetailsContainer = styled.div`
  margin-top: 36px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;

  > h4 {
    margin: 0px;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.03em;
    color: ${colors.gray._800};

    > b {
      font-weight: 600;
    }
  }

  ${media.down("TABLET")`
    margin-left: 120px;
    margin-top: 25px;
    > h4 { font-size: 24px;}
  `}

  ${media.down("MOBILE")`
  margin-top: 4px;
  margin-left: 72px;
    > h4 { font-size: 16px;}
  `}
`;

export const PriceContainer = styled.div`
  width: calc(100% - 32px);
  margin-bottom: 12px;
  margin-left: 16px;
  margin-right: 16px;
  height: 44px;
  border-radius: 12px;
  background: ${colors.greyscale._100_06};
  justify-content: center;
  align-items: center;
  display: flex;

  > h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    margin: 0px;
    color: ${colors.gray._800};
  }

  ${media.down("TABLET")`
    position: absolute;
    right: 2px;
    top: 2px;
    width: auto;
    border-radius: 0 12px 0 12px;
    margin-right: 0;
    padding: 6px 22px;

    
    
    > h4 {
      font-size: 24px;
    }
  `}

  ${media.down("MOBILE")`

    padding: 0 16px;

    > h4 {
      margin: 0;
      padding: 0;
      font-size: 16px;
    }
  `}
`;

export const TimeInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${media.down("TABLET")`
    transform: scale(1.25);
    transform-origin: top left;
  `}

  @media(max-width: 350px) {
    position: relative;
    left: -52px;
  }
`;

export const TimeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 88px;
  height: 44px;

  > span {
    pointer-events: none;
    cursor: text;
    height: 100%;
    line-height: 44px;
    box-shadow: none;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    color: ${colors.gray._800};
    margin-left: 4px;
    margin-right: 4px;
  }

  ${media.down("MOBILE")`
    height: 48px;
  `}
`;

export const TimeInput = styled.input`
  font-family: "Barlow", sans-serif;
  border: none;
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  width: 40px;
  height: 42px;
  appearance: none;
  outline-style: none;
  box-shadow: none;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
  color: ${colors.gray._800};
  text-align: center;
  border: 1px solid ${colors.gray._300};
  border-radius: 10px;
  text-align: center;

  /* Hide up/bottom arrows.  */
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;

  ::placeholder {
    text-align: center;
  }

  ${media.down("MOBILE")`
    height: 32px;
    font-size: 12.8px;
  `}
`;

export const AmPmButton = styled.button<{ selected: boolean }>`
  width: 32px;
  height: 24px;
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  :nth-of-type(1) {
    margin-left: 12px;
    margin-right: 4px;
  }

  ${props =>
    props.selected
      ? css`
          background: ${colors.red._500};
          color: ${colors.white};
        `
      : css`
          background: #ffffff;
          border: 1px solid ${colors.gray._200};
          color: ${colors.gray._500};
        `}
`;
