import React, { FunctionComponent } from "react";
import ContactDetailsForm from "modules/OrderingForm/ContactDetails/ContactDetailsForm";
import {
  ContactDetailsWrapper,
  PriceHeader,
  PriceWrapper,
  UrgentContactHero,
  ContactHero,
} from "modules/OrderingForm/ContactDetails/ContactDetails.components";
import Heading from "modules/shared/components/Heading";
import SectionContainer from "modules/shared/components/SectionContainer/SectionContainer";
import useOrderContext from "modules/App/OrdersLayout/OrderContext/useOrderContext";
import { IOrderType } from "@medlog/shared/models/orders/IOrder";
import UrgentHero from "modules/OrderingForm/ContactDetails/icons/urgent_hero.png";
import Hero from "modules/OrderingForm/ContactDetails/icons/hero.png";
import { WhiteCover } from "modules/App/OrdersLayout/OrdersLayout.components";
import MobileSheet from "modules/shared/components/Mobile/MobileSheet";

const ContactDetails: FunctionComponent = () => {
  const { order } = useOrderContext();
  const showUrgentInfo = order.type === IOrderType.URGENT && !order.rescheduled;
  return (
    <SectionContainer>
      <ContactDetailsWrapper noPrice={!showUrgentInfo}>
        {showUrgentInfo && (
          <PriceWrapper>
            <PriceHeader>£{(order.price! / 100).toFixed(2)}</PriceHeader>
            <span>Order Price</span>
          </PriceWrapper>
        )}

        <Heading>
          Please fill in <b>your contact&nbsp;details</b>
        </Heading>
        <MobileSheet>
          <ContactDetailsForm />
          {showUrgentInfo ? <UrgentContactHero src={UrgentHero} /> : <ContactHero src={Hero} />}
        </MobileSheet>
      </ContactDetailsWrapper>
      <WhiteCover />
    </SectionContainer>
  );
};

export default ContactDetails;
