import React, { FunctionComponent, SetStateAction, useCallback, Dispatch } from "react";
import { Formik } from "formik";
import AuthenticationFormContent from "modules/MyOrders/Authentication/AuthenticationFormContent";
import {
  AuthenticationFormValues,
  authenticationInitialValues,
  authenticationValidationSchema,
} from "modules/MyOrders/Authentication/form";
import { functions } from "config/firebase/firebase";

type AuthenticationFormProps = {
  setTokenRequested: Dispatch<SetStateAction<boolean>>;
};

const AuthenticationForm: FunctionComponent<AuthenticationFormProps> = ({ setTokenRequested }) => {
  const handleSubmit = useCallback(async (values: AuthenticationFormValues) => {
    try {
      const requestMyOrdersAccessFunction = functions.httpsCallable("requestMyOrdersAccess");
      await requestMyOrdersAccessFunction({ email: values?.email });
      setTokenRequested(true);
    } catch (e) {
      console.error(e);
    }
  }, []);

  return (
    <Formik
      initialValues={authenticationInitialValues}
      validationSchema={authenticationValidationSchema}
      onSubmit={handleSubmit}
    >
      {formProps => <AuthenticationFormContent {...formProps} />}
    </Formik>
  );
};

export default AuthenticationForm;
