import { firestore } from "config/firebase/firebase";
import { IOrder } from "@medlog/shared/models/orders/IOrder";
import Firebase from "firebase/app";
import { IOrderState } from "@medlog/shared/models/orders/IOrderState";

const createBaseOrder = async (order: IOrder) => {
  const docRef = firestore.collection("orders").doc();
  order.uid = docRef.id;
  order.paid = false;
  order.currentState = IOrderState.CREATED;

  await docRef.set({
    uid: order.uid,
    archived: false,
    type: order.type,
    email: order.email,
    phone: order.phone,
    date: Firebase.firestore.FieldValue.serverTimestamp(),
    parcelSize: order.parcelSize,
    currentState: IOrderState.CREATED,
    paid: false,
    copydSuccessed: false,
    copyCount: 0,
    paymentInitialized: false,
    termsAndPrivacyAccepted: order.termsAndPrivacyAccepted || null,
    preBookDeliveryDate: order.preBookDeliveryDate || null,
    preBookDeliveryTime: order.preBookDeliveryTime
      ? {
          timeSpecified: order.preBookDeliveryTime.timeSpecified ?? false,
          deliveryTime: order.preBookDeliveryTime.deliveryTime ?? null,
        }
      : null,
  });

  return order;
};

export default createBaseOrder;
