import React, { FunctionComponent } from "react";
import {
  ClientDashboardContainer,
  ClientDashboardNavbar,
  LogoutButton,
  ClientDashboardHeading,
  OrdersWrapper,
  OrdersInnerWrapper,
  CornerWrapper,
} from "modules/MyOrders/ClientDashboard/ClientDashboard.components";
import { ReactComponent as LogoWhite } from "modules/MyOrders/ClientDashboard/LogoWhite.svg";
import { ReactComponent as LogoutIcon } from "modules/MyOrders/ClientDashboard/LogoutIcon.svg";
import { ReactComponent as CornerLeft } from "modules/MyOrders/ClientDashboard/CornerLeft.svg";
import { ReactComponent as CornerRight } from "modules/MyOrders/ClientDashboard/CornerRight.svg";
import OrderDetails from "modules/MyOrders/ClientDashboard/OrderDetails/OrderDetails";
import useMyOrdersContext from "modules/MyOrders/MyOrdersContext/useMyOrdersContext";
import { useIsMobileDown } from "common/helpers/useMedia";

const ClientDashboard: FunctionComponent = () => {
  const { orders, logout } = useMyOrdersContext();
  const isMobile = useIsMobileDown();

  return (
    <ClientDashboardContainer>
      <ClientDashboardNavbar>
        <LogoWhite />
        <LogoutButton onClick={() => logout()}>
          Logout
          <LogoutIcon />
        </LogoutButton>

        {!isMobile && (
          <>
            <CornerWrapper left>
              <CornerLeft />
            </CornerWrapper>
            <CornerWrapper>
              <CornerRight />
            </CornerWrapper>
          </>
        )}
      </ClientDashboardNavbar>
      <OrdersWrapper>
        <OrdersInnerWrapper>
          <ClientDashboardHeading>My orders</ClientDashboardHeading>
          {orders.map((order, index) => (
            <OrderDetails key={index} order={order} />
          ))}
        </OrdersInnerWrapper>
      </OrdersWrapper>
    </ClientDashboardContainer>
  );
};

export default ClientDashboard;
