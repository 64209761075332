import styled, { css } from "styled-components";
import { colors } from "@medlog/shared/styles/colors";
import { TransitionStatus } from "react-transition-group";
import { media } from "@medlog/shared/styles/media";

const hintPosition = {
  right: {
    desktop: {
      top: "-10px",
      right: "-208px",
    },
    tablet: {
      top: "130px",
      right: "-140px",
    },
  },
  left: {
    desktop: {
      top: "10px",
      left: "-210px",
    },
    tablet: {
      top: "0px",
      left: "-175px",
    },
  },
};

export const HintContainer = styled.div`
  position: relative;
`;

type HintWrapperProps = {
  position: "left" | "right";
};

export const HintArrowWrapper = styled.div<HintWrapperProps & { status: TransitionStatus }>`
  transition: all 900ms ease;
  opacity: ${props => (props.status === "entered" ? "1" : "0")};
  transform: scaleX(-1) rotate(180deg);
  transform: translateX(${props => (props.status === "entered" ? "0" : props.position === "left" ? "-30px" : "30px")})
    scaleX(-1) rotate(180deg);

  ${props =>
    props.position === "left"
      ? css`
          transform: translateX(${props.status === "entered" ? "0" : "-30px"});

          ${media.down("TABLET")`
            margin-top: 10px;
          `}
        `
      : css`
          transform: translateX(${props.status === "entered" ? "0" : "30px"});
        `}

  ${media.down("TABLET_SMALL")`
          display: none;
        `}
`;

export const HintWrapper = styled.div<HintWrapperProps>`
  display: flex;
  position: absolute;
  ${props =>
    props.position === "left"
      ? css`
          top: ${hintPosition.left.desktop.top};
          left: ${hintPosition.left.desktop.left};

          ${media.down("TABLET")`
            top: ${hintPosition.left.tablet.top};
            left: ${hintPosition.left.tablet.left};

            img {
            transform: scaleX(0.7) translateX(-20px)
          }
          `}
        `
      : css`
          top: ${hintPosition.right.desktop.top};
          right: ${hintPosition.right.desktop.right};

          ${media.down("TABLET")`
            top: ${hintPosition.right.tablet.top};
            right: ${hintPosition.right.tablet.right};

            img { 
              transform: scaleY(-1) scaleX(0.9) rotate(-25deg) translate(30px, 45px);
            }
          `}
        `}
`;

export const HintContentWrapper = styled.div<HintWrapperProps & { status: TransitionStatus }>`
  width: 107px;
  ${props => (props.position === "left" ? "margin-right:8px" : "margin-left: 8px")};
  transition: all 500ms ease;
  opacity: ${props => (props.status === "entered" ? "1" : "0")};
  transform: scale(${props => (props.status === "entered" ? "1" : "0.1")});

  ${media.down("TABLET")`
    width: 100px;
  `}

  ${props =>
    props.position === "left"
      ? css`
          text-align: right;
        `
      : css`
          text-align: left;
        `}

  ${media.down("TABLET_SMALL")`
    display: none;
  `};
`;

export const HintContent = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: -0.01em;
  color: ${colors.gray._700};
`;
