import { IOrder, IOrderType } from "@medlog/shared/models/orders/IOrder";
import { IOrderDetails } from "@medlog/shared/models/orders/IOrderDetails";
import { IOrderState } from "@medlog/shared/models/orders/IOrderState";
import isNotEmpty from "common/helpers/isNotEmpty";
import getOrderInitialTime from "common/helpers/timer/getOrderInitialTime";
import canBeVisited from "modules/shared/components/Sidebar/DetailsItem/Items/shouldNavigate";

type CheckItemData = {
  order: IOrder;
  details?: IOrderDetails;
};

export const isDateItemAllowed = (data: CheckItemData) => {
  const { order, details } = data;
  return canBeVisited(order, !!order.date, !!order.date, isNotEmpty(details?.dropOffDetails.postcode || ""));
};

export const isLocationItemAllowed = (data: CheckItemData) => {
  const { order } = data;
  return canBeVisited(order, !!order.type, !!order.type, !!order.type);
};
export const isConfirmationItemAllowed = (data: CheckItemData) => {
  const { order } = data;
  const confirmationPaths = [
    order.type === IOrderType.URGENT ? IOrderState.PAYMENT_PENDING : IOrderState.CREATED,
    IOrderState.COPIED,
    IOrderState.PAYMENT_SUCCEEDED,
    IOrderState.COURIER_ON_THE_WAY,
    IOrderState.PARCEL_PICKED_UP,
    IOrderState.PARCEL_DELIVERED,
    IOrderState.ARCHIVED,
  ];
  return order.currentState ? confirmationPaths.includes(order.currentState) : false;
};

export const isPickupDetailsItemAllowed = (data: CheckItemData) => {
  const { order, details } = data;
  return canBeVisited(
    order,
    (isNotEmpty(order.email) &&
      isNotEmpty(order.phone?.phoneNumber) &&
      isNotEmpty(order.phone.directional) &&
      order.currentState === IOrderState.PAYMENT_PENDING) ||
      isConfirmationItemAllowed(data),
    isNotEmpty(details?.dropOffDetails.postcode || "") && isNotEmpty(details?.dropOffDetails.address || ""),
    !!order.preBookDeliveryTime && !!order.preBookDeliveryDate
  );
};

export const isAvailabilityItemAllowed = (data: CheckItemData) => {
  const { order } = data;
  return (
    order.type === IOrderType.URGENT &&
    !order.rescheduled &&
    !!order.currentState &&
    [
      IOrderState.PAYMENT_PENDING,
      IOrderState.SAMEDAY_INSTEAD,
      IOrderState.COPIED,
      IOrderState.CREATED,
      IOrderState.PAYMENT_SUCCEEDED,
      IOrderState.COURIER_ON_THE_WAY,
      IOrderState.PARCEL_PICKED_UP,
      IOrderState.PARCEL_DELIVERED,
      IOrderState.ARCHIVED,
    ].includes(order.currentState)
  );
};

export const isPickupContactItemAllowed = (data: CheckItemData) => {
  const { details, order } = data;
  return canBeVisited(
    order,
    isNotEmpty(details?.pickupDetails?.placeName ?? "") && isNotEmpty(details?.pickupDetails?.placeDetails ?? ""),
    isNotEmpty(details?.pickupDetails?.placeName ?? "") && isNotEmpty(details?.pickupDetails?.placeDetails ?? ""),
    isNotEmpty(details?.pickupDetails?.placeName ?? "") &&
      isNotEmpty(details?.pickupDetails?.placeDetails ?? "") &&
      isPickupDetailsItemAllowed(data)
  );
};

export const isDropOffDetailsItemAllowed = (data: CheckItemData) => {
  const { order, details } = data;
  return canBeVisited(
    order,
    isNotEmpty(details?.pickupDetails.phone?.phoneNumber || ""),
    isNotEmpty(details?.pickupDetails.phone?.phoneNumber || ""),
    isNotEmpty(details?.pickupDetails.phone?.phoneNumber || "") && isPickupContactItemAllowed(data)
  );
};

export const isDropOffContactItemAllowed = (data: CheckItemData) => {
  const { details, order } = data;
  return canBeVisited(
    order,
    isNotEmpty(details?.dropOffDetails?.placeName ?? "") && isNotEmpty(details?.dropOffDetails?.placeDetails ?? ""),
    isNotEmpty(details?.dropOffDetails?.placeName ?? "") && isNotEmpty(details?.dropOffDetails?.placeDetails ?? ""),
    isNotEmpty(details?.dropOffDetails?.placeName ?? "") &&
      isNotEmpty(details?.dropOffDetails?.placeDetails ?? "") &&
      isDropOffDetailsItemAllowed(data)
  );
};

export const isPackageSizeItemAllowed = (data: CheckItemData) => {
  const { order, details } = data;
  return canBeVisited(
    order,
    isNotEmpty(details?.dropOffDetails.postcode || "") && isNotEmpty(details?.pickupDetails.postcode || ""),

    isNotEmpty(details?.dropOffDetails.name || "") &&
      isNotEmpty(details?.dropOffDetails.phone?.directional || "") &&
      isNotEmpty(details?.dropOffDetails.phone?.phoneNumber || ""),

    isNotEmpty(details?.dropOffDetails.name || "") &&
      isNotEmpty(details?.dropOffDetails.phone?.directional || "") &&
      isNotEmpty(details?.dropOffDetails.phone?.phoneNumber || "") &&
      isDropOffContactItemAllowed(data)
  );
};

export const isTimeItemAllowed = (data: CheckItemData) => {
  const { order } = data;
  return !!order.preBookDeliveryDate;
};

export const isCourierAvailableItemAllowed = (data: CheckItemData) => {
  const { order } = data;
  return order.type === IOrderType.URGENT && order.currentState === IOrderState.PAYMENT_PENDING;
};

export const isRescheduleItemAllowed = (data: CheckItemData) => {
  const { order } = data;
  return order.currentState === IOrderState.SAMEDAY_INSTEAD;
};

export const isRejectionItemAllowed = (data: CheckItemData) => {
  const { order } = data;
  return order.currentState === IOrderState.REJECTED;
};

export const isTimesUpItemAllowed = (data: CheckItemData) => {
  const { order } = data;
  const time = getOrderInitialTime(order);
  return order.currentState === IOrderState.LOST || (time !== null && time <= 0);
};

export const isContactItemAllowed = (data: CheckItemData) => {
  const { order, details } = data;
  return canBeVisited(
    order,
    !!order.parcelSize,
    !!order.parcelSize,
    !!order.parcelSize && isNotEmpty(details?.dropOffDetails.phone?.phoneNumber || "") && isPackageSizeItemAllowed(data)
  );
};

export const isPaymentItemAllowed = (data: CheckItemData) => {
  const { order, details } = data;
  return canBeVisited(
    order,
    isNotEmpty(details?.dropOffDetails.name || "") &&
      isNotEmpty(details?.dropOffDetails.phone?.phoneNumber || "") &&
      order.currentState === IOrderState.PAYMENT_PENDING,
    isNotEmpty(order.email) && isNotEmpty(order.phone.phoneNumber) && order.currentState === IOrderState.CREATED,
    isNotEmpty(order.email) &&
      isNotEmpty(order.phone.phoneNumber) &&
      isNotEmpty(details?.dropOffDetails.phone?.phoneNumber || "") && //For prebook that is prefilled from urgent.
      order.currentState === IOrderState.CREATED
  );
};

export const isCancelationItemAllowed = (data: CheckItemData) => {
  const { order } = data;
  return order.type === IOrderType.URGENT
    ? isPaymentItemAllowed(data)
    : isPaymentItemAllowed(data) && order.termsAndPrivacyAccepted === true;
};
