import React, { FunctionComponent } from "react";
import { FormikProps, Form } from "formik";
import InputField from "@medlog/shared/components/Input/InputField";
import Button from "@medlog/shared/components/Button";
import { AuthenticationFormValues } from "modules/MyOrders/Authentication/form";
import {
  AuthenticationFormContainer,
  AuthenticationFormWrapper,
  AuthInputContainer,
} from "modules/MyOrders/Authentication/Authentication.components";
import { useIsMobileDown } from "common/helpers/useMedia";

const AuthenticationFormContent: FunctionComponent<FormikProps<AuthenticationFormValues>> = ({ isSubmitting }) => {
  const isMobile = useIsMobileDown();
  return (
    <AuthenticationFormContainer>
      <Form>
        <AuthenticationFormWrapper>
          <AuthInputContainer>
            <InputField label="Email" name="email" type="email" inputSize={isMobile ? "medium" : "large"} />
          </AuthInputContainer>
          <Button
            loading={isSubmitting}
            active
            size="large"
            type="submit"
            style={{ position: "absolute", bottom: "-30px" }}
          >
            Login
          </Button>
        </AuthenticationFormWrapper>
      </Form>
    </AuthenticationFormContainer>
  );
};

export default AuthenticationFormContent;
