import React, { useCallback, useState } from "react";
import { Formik, FormikHelpers } from "formik";
import { PostcodesFormValues, postcodesValidationSchema } from "modules/OrderingForm/Postcodes/form";
import PostcodesFormContent from "modules/OrderingForm/Postcodes/PostcodesFormContent";
import { FunctionComponent } from "react";
import { useHistory } from "react-router";
import getDistanceBetween from "common/helpers/getDistanceBetween";
import useOrderContext from "modules/App/OrdersLayout/OrderContext/useOrderContext";
import getOrderPrice, { IOrderPrice, getPromoPrice } from "@medlog/shared/helpers/getOrderPrice";
import routesPaths from "modules/Routing/routesPaths";
import { IOrderType } from "@medlog/shared/models/orders/IOrder";
import { IOrderDetails } from "@medlog/shared/models/orders/IOrderDetails";
import { updateDetailsIfOrderExists } from "common/firebase/orders/updateDetailsIfOrderExists";
import { checkPromo } from "common/helpers/checkPromo";

const PostcodesForm: FunctionComponent = () => {
  const { order, setOrder, orderDetails, setOrderDetails, setLocalPrice } = useOrderContext();
  const history = useHistory();
  const [errors, setErrors] = useState({
    pickupPostcode: false,
    pickupAddress: false,
    dropoffPostcode: false,
    dropoffAddress: false,
    message: "",
  });

  const resetErrors = () => {
    setErrors({
      pickupPostcode: false,
      pickupAddress: false,
      dropoffPostcode: false,
      dropoffAddress: false,
      message: "",
    });
  };

  const handleSubmit = useCallback(async (values: PostcodesFormValues, actions: FormikHelpers<PostcodesFormValues>) => {
    const initErrors = {
      pickupPostcode: values.pickupPostcode === "",
      pickupAddress: ["", undefined].includes(values.pickupAddress.address),
      dropoffPostcode: values.dropoffPostcode === "",
      dropoffAddress: ["", undefined].includes(values.dropoffAddress.address),
      message: "All fields are required",
    };
    setErrors(initErrors);

    if (
      values.pickupPostcode === "" ||
      values.pickupAddress.address === "" ||
      values.pickupAddress.address === undefined ||
      values.dropoffPostcode === "" ||
      values.dropoffAddress.address === "" ||
      values.dropoffAddress.address === undefined
    )
      return;

    const pickupAndDropoff: IOrderDetails = {
      pickupDetails: {
        ...orderDetails.pickupDetails,
        postcode: values.pickupPostcode,
        postcodeSelected: values.pickupPostcodeSelected,
        addressId: values.pickupAddress.id,
        address: values.pickupAddress.address,
      },
      dropOffDetails: {
        ...orderDetails.dropOffDetails,
        postcode: values.dropoffPostcode,
        postcodeSelected: values.dropoffPostcodeSelected,
        address: values.dropoffAddress.address,
        addressId: values.dropoffAddress.id,
        isPromo: values.isPromo,
      },
    };

    try {
      actions.setSubmitting(true);
      await updateDetailsIfOrderExists(order, pickupAndDropoff);

      let price: IOrderPrice;

      if (checkPromo(values.dropoffPostcode, values.dropoffAddress.id)) {
        price = getPromoPrice();
        setLocalPrice(price);
      } else {
        const calculatedDistance = await getDistanceBetween(values.pickupPostcode, values.dropoffPostcode);
        price = getOrderPrice(Math.round(calculatedDistance) / 1000);
        setLocalPrice(price);
      }

      //Prebook price is calculated after day and time selection.
      if (order.type == IOrderType.URGENT) {
        setOrder({ ...order, price: price.urgent, pickupAndDropoff: pickupAndDropoff });
      } else if (order.type === IOrderType.SAME_DAY) {
        setOrder({ ...order, price: price.sameday, pickupAndDropoff: pickupAndDropoff });
      }

      setOrderDetails(pickupAndDropoff);

      switch (order.type!) {
        case IOrderType.URGENT:
          history.push(routesPaths.SIZE);
          break;
        case IOrderType.SAME_DAY:
          history.push(routesPaths.PICKUP);
          break;
        case IOrderType.PRE_BOOK:
          history.push(routesPaths.DATE);
          break;
        default:
          break;
      }
    } catch (e) {
      setErrors({ ...initErrors, message: "Please enter a valid postcodes." });
      console.error(e);
    } finally {
      actions.setSubmitting(false);
    }
  }, []);

  return (
    <Formik
      initialValues={{
        pickupPostcode: orderDetails.pickupDetails.postcode,
        pickupPostcodeSelected: orderDetails.pickupDetails.postcodeSelected || false,
        pickupAddress: {
          address: orderDetails.pickupDetails.address,
          id: orderDetails.pickupDetails.addressId,
        },
        pickupAddressId: orderDetails.pickupDetails.addressId,
        dropoffPostcode: orderDetails.dropOffDetails.postcode,
        dropoffPostcodeSelected: orderDetails.dropOffDetails.postcodeSelected || false,
        dropoffAddress: {
          address: orderDetails.dropOffDetails.address,
          id: orderDetails.dropOffDetails.addressId,
        },
        isPromo: orderDetails.dropOffDetails.isPromo || false,
        dropoffAddressId: orderDetails.dropOffDetails.addressId,
      }}
      validationSchema={postcodesValidationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {formProps => (
        <PostcodesFormContent
          {...formProps}
          customErrors={errors}
          setCustomErrors={setErrors}
          resetErrors={resetErrors}
        />
      )}
    </Formik>
  );
};

export default PostcodesForm;
