import React, { FunctionComponent, useCallback } from "react";
import { Formik, FormikHelpers } from "formik";
import { useHistory } from "react-router-dom";
import { pickupLocationValidationSchema } from "modules/OrderingForm/DeliveryDetails/PickupDetails/PickupLocation/form";
import routesPaths from "modules/Routing/routesPaths";
import DropoffLocationFormContent from "modules/OrderingForm/DeliveryDetails/DropoffDetails/DropoffLocation/DropoffLocationFormContent";
import { DropoffLocationFormValues } from "modules/OrderingForm/DeliveryDetails/DropoffDetails/DropoffLocation/form";
import useOrderContext from "modules/App/OrdersLayout/OrderContext/useOrderContext";
import { updateDetailsIfOrderExists } from "common/firebase/orders/updateDetailsIfOrderExists";
import { checkPromo } from "common/helpers/checkPromo";

const DropoffLocationForm: FunctionComponent = () => {
  const { order, orderDetails, setOrderDetails } = useOrderContext();
  const history = useHistory();
  const handleSubmit = useCallback(
    async (values: DropoffLocationFormValues, actions: FormikHelpers<DropoffLocationFormValues>) => {
      actions.setSubmitting(true);
      const updatedOrderDetails = {
        pickupDetails: orderDetails.pickupDetails,
        dropOffDetails: {
          ...orderDetails.dropOffDetails,
          placeName: values.placeName,
          placeDetails: values.placeDetails,
          additionalDetails: values.additionalInformation,
        },
      };
      try {
        await updateDetailsIfOrderExists(order, updatedOrderDetails);
        setOrderDetails(updatedOrderDetails);
        history.push(routesPaths.DROPOFF_CONTACT);
      } catch (e) {
        console.error(e);
      } finally {
        actions.setSubmitting(false);
      }
    },
    []
  );

  const getInitialValues = () => {
    const { postcode, addressId } = orderDetails.dropOffDetails;

    if (checkPromo(postcode, addressId)) {
      return {
        placeName: "Medical Laboratories",
        placeDetails: "Sample reception",
        additionalInformation: "",
      };
    }
    return {
      placeName: orderDetails.dropOffDetails.placeName,
      placeDetails: orderDetails.dropOffDetails.placeDetails,
      additionalInformation: orderDetails.dropOffDetails.additionalDetails,
    };
  };

  return (
    <Formik
      initialValues={getInitialValues()}
      validationSchema={pickupLocationValidationSchema}
      onSubmit={handleSubmit}
    >
      {formProps => <DropoffLocationFormContent {...formProps} />}
    </Formik>
  );
};

export default DropoffLocationForm;
