import { colors } from "@medlog/shared/styles/colors";
import { media } from "@medlog/shared/styles/media";
import { TransitionStatus } from "react-transition-group";
import styled, { css } from "styled-components/macro";

export const OrderPriceWrapper = styled.div<{ warning: boolean; showHint: boolean }>`
  transition: background-color ease 500ms;
  background: ${props => (props.warning ? colors.red._500 : colors.mint._100)};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 70px;
  border-radius: 30px;
  flex-direction: column;
  color: ${props => (props.warning ? colors.white : colors.gray._800)};

  ${media.down("LAPTOP")`
    width: 128px;
    height: 56px;
  `}

  ${props =>
    props.showHint &&
    css`
      cursor: pointer;
      :hover {
        ::after {
          display: inline-block;
          background: ${colors.gray._800};
          text-align: center;
          padding: 5px;
          top: calc(-100% + 10px);
          color: white;
          font-size: 12px;
          border-radius: 10px;
          position: absolute;
          content: "The time you have to pay for the order.";
        }

        ::before {
          display: inline-block;
          text-align: center;
          top: -10px;
          position: absolute;
          content: "";
          width: 0px;
          height: 0px;
          border-style: solid;
          border-width: 10px 10px 0 10px;
          border-width: 7.5px 7.5px 0 7.5px;
          border-color: ${colors.gray._800} transparent transparent transparent;
        }
      }
    `}

  > h2 {
    margin: 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 120%;
    display: flex;
    align-items: flex-end;
    text-align: center;
    letter-spacing: -0.015em;
  }

  > h4 {
    margin: 0px;
    margin-top: -2px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.01em;
    color: ${props => (props.warning ? colors.white : colors.mint._700)};

    ${media.down("LAPTOP")`
      font-size: 12px;
    `}
  }
`;

export const TimeContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 50%;
`;

export const TimeText = styled.h2<{ status: TransitionStatus; duration: number }>`
  position: absolute;
  left: 50%;
  top: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  margin: 0px auto;
  padding: 0px;
  transition: opacity ${props => props.duration}ms ease;
  opacity: ${props => (props.status === "entered" ? "1" : "0")};

  font-weight: 600;
  font-size: 26px;

  ${media.down("LAPTOP")`
    font-size: 22px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    font-size: 18px;
  `}
`;
