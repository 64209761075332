import { colors } from "@medlog/shared/styles/colors";
import { media } from "@medlog/shared/styles/media";
import styled from "styled-components";

export const ClientDashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: scroll;
  padding-bottom: 40px;
`;

export const ClientDashboardNavbar = styled.div`
  display: flex;
  position: fixed;
  background: ${colors.greyscale.black};
  width: 100%;
  height: 80px;
  top: 0;
  left: 0;
  justify-content: space-between;
  align-items: center;
  padding: 0 80px;
  box-sizing: border-box;
  z-index: 2000;
  ${media.down("TABLET")`
    padding: 0 40px;
  `}

  ${media.down("TABLET_SMALL")`
    padding: 0 24px;
    height: 52px;

    > svg {
      transform: scale(0.8)
    }
  `}
`;

export const CornerWrapper = styled.div<{ left?: boolean }>`
  position: absolute;
  ${p => (p.left ? "left: 0px;" : "right: 0px;")}
  bottom: -16px;
`;

export const LogoutButton = styled.button`
  display: flex;
  background: none;
  border: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: ${colors.white};
  opacity: 0.8;
  justify-content: center;
  align-items: center;

  transition: transform 0.4s ease;

  > svg {
    margin-left: 10px;
  }

  :hover {
    transform: scale(1.05);
    cursor: pointer;
  }

  ${media.down("TABLET_SMALL")`
    transform: scale(0.8);
  `}
`;

export const ClientDashboardHeading = styled.h2`
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: ${colors.gray._800};
  margin-bottom: 8px;

  ${media.down("TABLET_SMALL")`
    font-size: 20px;
    margin-bottom: 0px;
  `}
`;

export const OrdersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin-top: 140px;
  padding: 0 40px;
  width: 100%;

  ${media.down("TABLET_SMALL")`
    margin-top: 74px;
    padding: 0 24px;
  `}
`;

export const OrdersInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 897px;
  width: 100%;

  ${media.down("TABLET")`
    min-width:400px;
    max-width: 897px;
   `}
  ${media.down("TABLET_SMALL")`
    min-width:100px;
   `}
`;
