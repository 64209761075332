import React, { FunctionComponent } from "react";
import { CSSTransition } from "react-transition-group";

import "modules/shared/styles/animations.css";
import { TransitionChildren } from "react-transition-group/Transition";

type ContentAnimationWrapperProps = {
  tag: string;
  children?: React.ReactNode | undefined;
  inCondition?: string;
};

const ContentAnimationWrapper: FunctionComponent<ContentAnimationWrapperProps> = ({
  tag,
  children,
}: ContentAnimationWrapperProps) => {
  return (
    <CSSTransition
      in
      appear
      unmountOnExit
      key={tag}
      addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
      classNames="content-fade"
    >
      {children as TransitionChildren}
    </CSSTransition>
  );
};

export default ContentAnimationWrapper;
