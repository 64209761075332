import React, { FunctionComponent, useCallback, useMemo } from "react";
import { Formik, FormikHelpers } from "formik";
import { useHistory } from "react-router-dom";
import routesPaths from "modules/Routing/routesPaths";
import { pickupContactValidationSchema } from "modules/OrderingForm/DeliveryDetails/PickupDetails/PickupContact/form";
import { DropoffContactFormValues } from "modules/OrderingForm/DeliveryDetails/DropoffDetails/DropoffContact/form";
import DropoffContactFormContent from "modules/OrderingForm/DeliveryDetails/DropoffDetails/DropoffContact/DropoffContactFormContent";
import useOrderContext from "modules/App/OrdersLayout/OrderContext/useOrderContext";
import { IOrderType } from "@medlog/shared/models/orders/IOrder";
import { updateDetailsIfOrderExists } from "common/firebase/orders/updateDetailsIfOrderExists";
import { trackOrderScreenAction } from "common/analytics/trackOrderingFormEvent";
import { NON_EXISTING_ID } from "modules/App/OrdersLayout/OrderContext/Storage/baseOrder";

const DropoffContactForm: FunctionComponent = () => {
  const { order, orderDetails, setOrderDetails, setOrder } = useOrderContext();
  const history = useHistory();

  const handleSubmit = useCallback(
    async (values: DropoffContactFormValues, actions: FormikHelpers<DropoffContactFormValues>) => {
      actions.setSubmitting(true);
      const updatedOrderDetails = {
        pickupDetails: orderDetails.pickupDetails,
        dropOffDetails: {
          ...orderDetails.dropOffDetails,
          name: values.contactName,
          email: values.email,
          phone: {
            directional: values.phone.directional,
            phoneNumber: values.phone.phoneNumber,
          },
        },
      };
      try {
        await updateDetailsIfOrderExists(order, updatedOrderDetails);
        setOrder({
          ...order,
          pickupAndDropoff: updatedOrderDetails,
        });
        if (order.uid === NON_EXISTING_ID) {
          trackOrderScreenAction("drop-off-details-entered", order);
        }
        setOrderDetails(updatedOrderDetails);

        if (order.type === IOrderType.URGENT) {
          history.push(routesPaths.SUMMARY);
        } else {
          history.push(routesPaths.SIZE);
        }
      } catch (e) {
        console.error(e);
      } finally {
        actions.setSubmitting(false);
      }
    },
    [order]
  );

  const initialValues = useMemo(() => {
    if (
      orderDetails.dropOffDetails.addressId === "medical-laboratory" &&
      orderDetails.dropOffDetails.email === undefined
    ) {
      return {
        contactName: orderDetails.dropOffDetails.name!,
        email: "info@medicallaboratories.co.uk",
        phone: {
          directional: orderDetails.dropOffDetails.phone!.directional,
          phoneNumber: orderDetails.dropOffDetails.phone!.phoneNumber,
        },
      };
    }
    return {
      contactName: orderDetails.dropOffDetails.name!,
      email: orderDetails.dropOffDetails.email ?? "",
      phone: {
        directional: orderDetails.dropOffDetails.phone!.directional,
        phoneNumber: orderDetails.dropOffDetails.phone!.phoneNumber,
      },
    };
  }, []);

  return (
    <Formik initialValues={initialValues} validationSchema={pickupContactValidationSchema} onSubmit={handleSubmit}>
      {formProps => <DropoffContactFormContent {...formProps} />}
    </Formik>
  );
};

export default DropoffContactForm;
