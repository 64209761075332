import { firestore } from "config/firebase/firebase";
import { IOrderDetails } from "@medlog/shared/models/orders/IOrderDetails";

const updateOrderDetails = async (orderId: string, orderDetails: IOrderDetails) => {
  const docRef = firestore.collection(`orders/${orderId}/details`).doc("pickupAndDropoff");
  const docSnap = await docRef.get();
  if (docSnap.exists) await docRef.update(orderDetails);
  else await docRef.set(orderDetails);
  return true;
};

export default updateOrderDetails;
