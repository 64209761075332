import styled, { css } from "styled-components/macro";
import { colors } from "@medlog/shared/styles/colors";
import BackgroundPrice from "modules/shared/components/Sidebar/background_price.svg";
import { TransitionStatus } from "react-transition-group";
import { media } from "@medlog/shared/styles/media";
import { FirefoxScrollbar } from "modules/shared/styles/mixins";

export const Container = styled.div`
  position: relative;
  background-color: ${colors.greyscale.black};
  width: 388px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > .corner {
    z-index: 2;
    position: absolute;
    right: -11px;
  }

  > .corner_top {
    top: 0px;
  }
  > .corner_bottom {
    bottom: 0px;
  }

  ${media.down("LAPTOP_BIG")`
      width: 512px;
  `}

  ${media.down("LAPTOP")`
      width: 372px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    width: 296px;
  `}
  
  ${media.down("LAPTOP_SMALL")`
      width: 288px;
  `}

  ${media.down("TABLET")`
      width: 388px;
  `}

  ${media.down("TABLET")`
      width: 388px;
  `}

  ${media.down("MOBILE")`
    width: 100%;
  `}
`;

type SidebarAnimatedDivProps = {
  status: TransitionStatus;
};

export const SidebarAnimatedDiv = styled.div<SidebarAnimatedDivProps>`
  transition: opacity 300ms ease;
  opacity: ${props => (props.status === "entered" ? "1" : "0")};
`;

export const SidebarHeader = styled.div`
  position: relative;
  height: 90px;
  width: 100%;

  ${media.down("TABLET")`
    display: none;
  `}

  ${media.down("MOBILE")`
    display: none;
  `}
`;

export const Logo = styled.img`
  width: 72px;
  position: absolute;
  top: 0px;
  left: 64px;
  cursor: pointer;
  transform-origin: top;
  transition: transform 0.3s ease;

  :hover {
    transform: scale(1.05);
  }

  ${media.down("LAPTOP")`
    width: 64px;
    left: 59px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    width: 48px;
    left: 40px;
  `}

  ${media.down("LAPTOP_SMALL")`
    width: 45px;
    left: 36px;
  `}

  ${media.down("MOBILE")`
    display: none;
  `}
`;

type PriceContainerProps = {
  status: TransitionStatus;
};

export const PriceContainer = styled.div<PriceContainerProps>`
  width: 200px;
  height: 112px;
  position: absolute;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 0px;
  right: -15px;
  background-image: url("${BackgroundPrice}");
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 2000;
  transition: all 500ms ease;
  opacity: ${props => (props.status === "entered" ? "1" : "0")};
  transform: translateY(${props => (props.status === "entered" ? "" : "-100px")});

  ${media.down("LAPTOP")`
    width: 160px;
    height: 90px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    width: 140px;
    height: 78px;
  `}


  ${media.down("LAPTOP_SMALL")`
    width: 120px;
    height: 67px;
  `}
`;

export const IncludingVat = styled.h5`
  margin: 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 140%;
  text-align: right;
  letter-spacing: 0.2px;
  color: ${colors.gray._400};
`;

export const PriceH2 = styled.h2<{ exact: boolean }>`
  margin: 0px;
  text-align: ${props => (props.exact ? "end" : "center")};
  color: white;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 90%;

  ${media.down("LAPTOP")`
    font-size: 28px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    font-size: 24px;
  `}


  ${media.down("LAPTOP_SMALL")`
    font-size: 20px;
  `}
    

  ${props =>
    !props.exact &&
    css`
      ::before {
        content: "from ";
        font-weight: 600;
        font-size: 14px;
        color: white;
      }
    `}
`;

export const DetailsContainer = styled.div`
  margin: 48px 0px 48px 90px;
  width: calc(100% - 80px);
  flex: 1;
  padding-right: 20px;
  overflow-y: auto;
  scrollbar-color: ${colors.gray._700} transparent;
  scrollbar-width: thin;

  > a:first-child {
    > div {
      margin-top: 0px;
    }
  }

  ${FirefoxScrollbar}

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${colors.gray._700};
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${colors.gray._600};
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  ${media.down("LAPTOP")`
    margin: 48px 8px 48px 72px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    margin: 24px 0px 24px 24px;
  `}

  ${media.down("LAPTOP_SMALL")`
    margin: 0px 0px 24px 12px;
    width: calc(100% - 64px);
  `}

  ${media.down("LAPTOP_TINY")`
    margin: 0px 0px 24px 12px;
  `}

  ${media.down("TABLET")`
    margin: 48px 8px 48px 72px;
  `}

  ${media.down("MOBILE")`
    //Move scrollbar to right edge.
    margin: 24px 0px;
    padding: 24px 0px 24px 56px;
    width: calc(100% - 56px);
  `}
`;
