import { firestore } from "config/firebase/firebase";
import { IOrder, IParcelSize, IPreBookDeliveryTime } from "@medlog/shared/models/orders/IOrder";
import IPhone from "@medlog/shared/models/common/IPhone";

export const updateOrderBaseDetails = async (
  order: IOrder,
  data: {
    size?: IParcelSize;
    email?: string;
    phone?: IPhone;
    preBookDeliveryDate?: Date;
    preBookDeliveryTime?: IPreBookDeliveryTime;
  }
) => {
  order.parcelSize = data.size ?? order.parcelSize;
  order.email = data.email ?? order.email;
  order.phone = data.phone ?? order.phone;

  const updateData = {
    parcelSize: order.parcelSize,
    email: order.email,
    phone: order.phone,
  };

  await firestore.collection("orders").doc(order.uid).set(updateData, { merge: true });

  return order;
};
