import React, { FunctionComponent } from "react";
import { Field, FieldProps, FieldAttributes } from "formik";
import Input, { InputProps } from "./Input";

export type InputFieldProps = FieldAttributes<{
  placeholder?: string;
  multiline?: boolean;
  hideEndAdornment?: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}> &
  Pick<InputProps, "label" | "error" | "optional" | "disabled" | "type" | "inputSize">;

const InputField: FunctionComponent<InputFieldProps> = ({
  placeholder,
  label,
  type,
  optional,
  disabled,
  error,
  multiline,
  hideEndAdornment,
  inputSize,
  onFocus,
  onKeyDown,
  ...fieldProps
}) => (
  <Field {...fieldProps}>
    {({ field, meta }: FieldProps) => (
      <>
        <Input
          {...field}
          multiline={multiline}
          hideEndAdornment={hideEndAdornment}
          label={label}
          onKeyDown={onKeyDown}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          error={meta.touched && meta.error ? meta.error : ""}
          inputSize={inputSize}
        />
      </>
    )}
  </Field>
);

export default InputField;
