import { colors } from "../../styles/colors";
import styled, { css } from "styled-components";
import { TextField, TextFieldProps } from "@material-ui/core";
import IconResize from "./icons/icon_resize.svg";

type InputLabelProps = {
  error?: boolean;
  focused: boolean;
};

export const FirefoxLightScrollbar = () => css`
  scrollbar-color: ${colors.gray._300} transparent;
  scrollbar-width: thin;
`;

type InputContainerProps = {
  // https://english.stackexchange.com/a/163611
  size: "small" | "smedium" | "medium" | "large" | "unset" | "mobile";
};

const getInputColor = (focused: boolean, error?: boolean): string => {
  if (!error) {
    return focused ? colors.gray._600 : colors.gray._500;
  } else {
    return colors.red._500;
  }
};

const getInputOptionalColor = (focused: boolean, error?: boolean): string => {
  if (!error) {
    return focused ? colors.gray._400 : colors.gray._400;
  } else {
    return colors.red._500;
  }
};

const getInputWidth = (size: "small" | "smedium" | "medium" | "large" | "unset" | "mobile"): string => {
  switch (size) {
    case "small":
      return "112px";
    case "smedium":
      return "248px";
    case "medium":
      return "273px";
    case "large":
      return "344px";
    case "unset":
      return "100%";
    case "mobile":
      return "90vw";
  }
};

export const InputContainer = styled.div<InputContainerProps>`
  flex-direction: column;
  display: flex;
  width: ${props => getInputWidth(props.size)};
`;

export const InputWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

export const PullHandler = styled.div`
  height: 8px;
  width: 8px;
  background-image: url("${IconResize}");
  background-position: center;
  position: absolute;
  bottom: 33px;
  right: 5px;
  pointer-events: none;
  z-index: 2;
`;

export const ErrorLabel = styled.span`
  display: flex;
  margin-top: 4px;
  height: 17px;
  margin-bottom: 8px;
  color: ${colors.red._600};
  justify-content: flex-end;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.2px;
`;

export const InputLabelMain = styled.span`
  color: ${(props: InputLabelProps) => getInputColor(props.focused, props.error)};
  font-weight: ${(props: InputLabelProps) => (props.focused ? 500 : 300)};
`;

export const InputLabelOptional = styled.span`
  color: ${(props: InputLabelProps) => getInputOptionalColor(props.focused, props.error)};
  font-weight: ${(props: InputLabelProps) => (props.focused ? 500 : 300)};
`;

//hideendadorment as string beacause of styled components error message. Destipe of passing boolean styled-components show wrong type error.
export const InputField = styled(TextField)<TextFieldProps & { hideendadornment?: "true" | "false" }>`
  .MuiInputLabel-root {
    font-family: 'Barlow', sans-serif; !important;
    > span {
      font-weight: 400 !important;
    }
  }
  .MuiOutlinedInput-root {
    height: 56px;
    flex: 1;
    border-radius: 8px;
    /* Rest */
    fieldset {
      border-color: ${props => (props.error ? colors.red._500 : colors.gray._300)};
    }
    input {
      letter-spacing: 0.005em;
      font-weight: 500;
      font-family: "Barlow", sans-serif;

      @-moz-document url-prefix() {
        font-weight: 400; //Mozilla font 500 is not equal to chrome 500.
      }

      /* clears the ‘X’ from Internet Explorer */
      ::-ms-clear {
        display: none;
        width: 0;
        height: 0;
      }
      ::-ms-reveal {
        display: none;
        width: 0;
        height: 0;
      }
      /* clears the ‘X’ from Chrome */
      ::-webkit-search-decoration,
      ::-webkit-search-cancel-button,
      ::-webkit-search-results-button,
      ::-webkit-search-results-decoration {
        display: none;
      }
    }

    > ::-webkit-resizer {
      background: transparent;
    }

    ${props =>
      props.multiline &&
      css`
        .MuiInputAdornment-root {
          width: 0px;
          margin: 0px;
          padding: 0px !important;
          height: 0px;
        }
      `}

    ${props =>
      props.hideendadornment === "true" &&
      css`
        .MuiInputAdornment-root {
          width: 16px;
          margin: 0px;
          padding: 0px !important;
          height: 0px;
        }
      `}


    textarea {
      font-weight: 500;
      font-family: "Barlow", sans-serif;

      :focus {
        font-weight: 400;
      }

      @-moz-document url-prefix() {
        font-weight: 400; //Mozilla font 500 is not equal to chrome 500.
      }

      /* One line: min-height: 18px; */
      /* Two heights of normal input: min-height: 74px; */
      /* Two lines height: */
      min-height: 37px;
      max-height: 57px;
      padding-right: 20px;
      margin-top: 18px;
      margin-bottom: 4px;
      padding-bottom: 16px;
      margin-left: 14px;
      margin-right: 4px;
      position: relative;
      z-index: 1;
      resize: vertical;

      ${FirefoxLightScrollbar}

      ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        border-radius: 5px;
      }
      ::-webkit-scrollbar-thumb {
        background: ${colors.gray._300};
        border-radius: 5px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: ${colors.gray._500};
        border-radius: 5px;
      }
      ::-webkit-scrollbar-track {
        background: ${colors.gray._100};
        border-radius: 5px;
      }
    }

    /* Higlighted */
    :hover fieldset {
      ${p =>
        !p.disabled &&
        css`
          border-color: ${p.error ? colors.red._500 : colors.gray._600};
        `}
    }

    /* Focused */
    &.Mui-focused {
      fieldset {
        border-width: 1px;
        border-color: ${props => (props.error ? colors.red._500 : colors.gray._600)};
      }

      input {
        font-weight: 400;
      }
    }
  }

  & svg {
    cursor: ${props => (props.error ? "default" : "pointer")};
  }

  .MuiOutlinedInput-adornedEnd {
    padding: 0;
  }

  .Mui-disabled {
    background-color: ${colors.gray._100};
    span {
      color: ${colors.gray._500};
    }
  }
`;
