import { IParcelSize } from "@medlog/shared/models/orders/IOrder";

export const getSizeTitle = (size: IParcelSize) => {
  switch (size) {
    case IParcelSize.SMALL:
      return "Small";
    case IParcelSize.MEDIUM:
      return "Medium";
    case IParcelSize.LARGE:
      return "Large";
  }
};

export const getSizeInformations = (size: IParcelSize) => {
  switch (size) {
    case IParcelSize.SMALL:
      return "like a tablet";
    case IParcelSize.MEDIUM:
      return "like a laptop";
    case IParcelSize.LARGE:
      return "like a shoe box";
  }
};

export const getSizeDimensions = (size: IParcelSize) => {
  switch (size) {
    case IParcelSize.SMALL:
      return "25x18x2 cm, < 1 kg";
    case IParcelSize.MEDIUM:
      return "35x25x10 cm, < 2kg";
    case IParcelSize.LARGE:
      return "40x28x15 cm, < 5 kg";
  }
};
