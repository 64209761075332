import React, { FunctionComponent } from "react";
import { Container, Header } from "modules/OrderingForm/Summary/components/MobileDetails/MobileDetails.components";

type MobileDetailsProps = {
  title: string;
  children?: React.ReactNode | undefined;
};

const MobileDetails: FunctionComponent<MobileDetailsProps> = ({ title, children }) => {
  return (
    <Container>
      <Header>{title}</Header>
      {children}
    </Container>
  );
};

export default MobileDetails;
