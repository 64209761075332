import React, { FunctionComponent, useEffect, useState } from "react";
import { firestore } from "config/firebase/firebase";
import { useCollectionData } from "react-firebase-hooks/firestore";
import MyOrdersContext from "modules/MyOrders/MyOrdersContext/MyOrdersContext";
import { IOrder, IOrderType } from "@medlog/shared/models/orders/IOrder";
import { IOrderState } from "@medlog/shared/models/orders/IOrderState";
import { OrderConverter } from "@medlog/shared/helpers/Converters";
import { useHistory, useLocation } from "react-router-dom";
import { getClientToken, removeClientToken, setClientToken } from "modules/MyOrders/helpers/authentication.helpers";
import routesPaths from "modules/Routing/routesPaths";

const allowedStates = [
  IOrderState.PAYMENT_SUCCEEDED,
  IOrderState.COPIED,
  IOrderState.COURIER_ON_THE_WAY,
  IOrderState.PARCEL_PICKED_UP,
  IOrderState.PARCEL_DELIVERED,
];

const MyOrdersProvider: FunctionComponent = ({ children }) => {
  const search = useLocation().search;
  const urlToken = new URLSearchParams(search).get("token");
  const history = useHistory();

  const [token, setToken] = useState("");
  const [tokenExpired, setTokenExpired] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [date] = useState(new Date());

  const [values, loading, error] = useCollectionData<IOrder>(
    firestore
      .collection("orders")
      .where("tokenExp", ">=", date)
      .where("token", "==", token)
      .withConverter(OrderConverter)
  );

  const logout = () => {
    setOrders([]);
    removeClientToken();
    setAuthenticated(false);
    setToken("");
    setFetching(false);
  };

  const logoutExpired = () => {
    setTokenExpired(true);
    logout();
  };

  const finishFetching = () => {
    history.replace(routesPaths.MY_ORDERS);
    setAuthenticated(true);
    setFetching(false);
  };

  useEffect(() => {
    if (urlToken) setClientToken(urlToken);
    const storedClientToken = getClientToken();

    if (storedClientToken) setToken(storedClientToken);
  }, [urlToken]);

  const sortByDate = (o1: IOrder, o2: IOrder) => {
    if (o1.type === IOrderType.PRE_BOOK && o2.type === IOrderType.PRE_BOOK) {
      return o1.preBookDeliveryDate!.getTime() - o2.preBookDeliveryDate!.getTime();
    } else {
      return o1.date!.getTime() - o2.date!.getTime();
    }
  };

  useEffect(() => {
    if (error && token === "") logout();
    if (error) logout();
    if (!loading && values) {
      if (token === "" && values.length === 0) logout();
      else if (token && values.length === 0) logoutExpired();
      else {
        const filteredOrders = values
          .filter(o => allowedStates.includes(o.currentState!))
          .map(o => {
            //Sorting order:
            //Urgent payment succeeded copied (accepted)
            //Sameday payment succeeded copied (accepted)
            //Prebook payment succeeded copied (scheduled)
            //Urgent courier on the way - parcel picked up - parcel delivered
            //Sameday courier on the way - parcel picked up - parcel delivered
            //Prebook courier on the way - parcel picked up - parcel delivered
            //In case of the same status - Urgent/Order by date, Prebooks by delivery date.

            if (o.currentState === IOrderState.PAYMENT_SUCCEEDED || o.currentState === IOrderState.COPIED) {
              if (o.type === IOrderType.URGENT) o.orderIndex = 1;
              else if (o.type === IOrderType.SAME_DAY) o.orderIndex = 2;
              else if (o.type === IOrderType.PRE_BOOK) o.orderIndex = 3;
            } else {
              if (o.currentState === IOrderState.COURIER_ON_THE_WAY) o.orderIndex = 4;
              else if (o.currentState === IOrderState.PARCEL_PICKED_UP) o.orderIndex = 5;
              else if (o.currentState === IOrderState.PARCEL_DELIVERED) o.orderIndex = 6;

              if (o.type === IOrderType.URGENT) o.orderIndex! *= 1;
              else if (o.type === IOrderType.SAME_DAY) o.orderIndex! *= 2;
              else if (o.type === IOrderType.PRE_BOOK) o.orderIndex! = (o.orderIndex! + 1) * 3;
            }
            return o;
          })
          .sort((o1, o2) => o1.orderIndex! - o2.orderIndex! || sortByDate(o1, o2));

        setOrders(filteredOrders);
        finishFetching();
      }
    }
  }, [values, loading, error]);

  return (
    <MyOrdersContext.Provider
      value={{
        fetching,
        authenticated,
        orders,
        setOrders,
        tokenExpired,
        logout,
      }}
    >
      {children}
    </MyOrdersContext.Provider>
  );
};

export default MyOrdersProvider;
