import React, { FunctionComponent } from "react";
import { FormikProps, Form } from "formik";
import { RadiosWrapper } from "modules/OrderingForm/PackageSize/PackageSize.components";
import { IParcelSize } from "@medlog/shared/models/orders/IOrder";
import { PackageSizeFormValues } from "modules/OrderingForm/PackageSize/form";
import PackageSizeButton from "modules/OrderingForm/PackageSize/PackageSizeButton";

const PackageSizeFormContent: FunctionComponent<FormikProps<PackageSizeFormValues>> = ({
  values,
  setFieldValue,
  submitForm,
}) => {
  const onOptionClick = (size: IParcelSize) => {
    setFieldValue("size", size);
    setTimeout(() => submitForm(), 50);
  };

  const selectedSize = values.size;

  return (
    <Form>
      <RadiosWrapper role="group">
        <PackageSizeButton
          type={IParcelSize.SMALL}
          delay={2}
          onClick={() => onOptionClick(IParcelSize.SMALL)}
          selected={selectedSize === IParcelSize.SMALL}
        />
        <PackageSizeButton
          type={IParcelSize.MEDIUM}
          delay={1}
          onClick={() => onOptionClick(IParcelSize.MEDIUM)}
          selected={selectedSize === IParcelSize.MEDIUM}
        />
        <PackageSizeButton
          type={IParcelSize.LARGE}
          delay={0}
          onClick={() => onOptionClick(IParcelSize.LARGE)}
          selected={selectedSize === IParcelSize.LARGE}
        />
      </RadiosWrapper>
    </Form>
  );
};

export default PackageSizeFormContent;
