import styled from "styled-components/macro";
import { media } from "@medlog/shared/styles/media";
import { setRWDWidth } from "modules/shared/styles/mixins";
import { colors } from "@medlog/shared/styles/colors";

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100%;
  min-width: 100%;
  margin: auto;

  ${media.down("TABLET_SMALL")`
    min-width: 500px;
  `}

  ${media.down("MOBILE")`
    min-width: 100%;
    justify-content: start;
  `}
`;

export const M25Badge = styled.span`
  color: ${colors.red._500};
  background-color: ${colors.red._50};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  border-radius: 9999px;
  display: inline-block;
  vertical-align: center;
  line-height: 24px;
  margin-left: 8px;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  line-height: 100%;
  transform: translateY(-2px);
  padding: 8px 12px;

  ${media.down("LAPTOP_BIG")`
    font-size: 16px;
  `}

  ${media.down("LAPTOP")`
    font-size: 13px;
    padding: 6px 12px;
  `}
  
  ${media.down("TABLET")`
    font-size: 14px;
  `}
  
  ${media.down("MOBILE")`
    font-size: 12px;
    padding: 4px 12px;
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: 64px;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${media.down("TABLET")`
    width: 400px;
    margin: 0 auto;
    top: 0;
  `}

  ${media.down("MOBILE")`
    width: 100%;
    margin-bottom: 64px;
    margin-top: 32px;
    position: relative;
  `}
`;

export const RadiosWrapper = styled.div`
  display: flex;
  flex-direction: row;

  ${media.down("LAPTOP_BIG")`
    margin-top: 72px;
  `}

  ${media.down("LAPTOP")`
    margin-top: 56px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    margin-top: 48px;
  `}

  ${media.down("LAPTOP_SMALL")`
    margin-top: 24px;
  `}

  ${media.down("TABLET")`
      margin-top: 48px;
      flex-direction: column;
  `};

  ${media.down("MOBILE")`
    margin-bottom: 8px; 
  `}

  ${media.down("MOBILE_SMALL")`
    margin-bottom: 32px;
  `}

  ${media.down("MOBILE_TINY")`
      margin-top: 24px;
      margin-bottom: 16px;
      flex-direction: column;
  `};
`;

export const HeroImage = styled.img`
  ${setRWDWidth(142, 120, 80, 80, 88)}

  ${media.down("MOBILE")`
    position: absolute;
    left: -40px; 
    top: 0;
    z-index: 1;
  `}

  ${media.down("MOBILE_TINY")`
    display: none;
  `}
`;
