import styled from "styled-components";
import { media } from "@medlog/shared/styles/media";
import { GetHeroSize } from "modules/shared/styles/mixins";

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100%;
  min-width: 100%;

  ${media.down("MOBILE")`
    position: static;
    justify-content: start;
  `}
`;

export const PackageSizeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 64px;
  position: relative;

  ${media.down("MOBILE")`margin-top: 40px; margin-bottom: 88px;`}
  ${media.down("MOBILE_SMALL")`margin-top: 20px;`}
  ${media.down("MOBILE_TINY")`margin-top: 64px;`}

  > h2 {
    max-width: 60vw;
  }
`;

export const RadiosWrapper = styled.div`
  flex-direction: row;
  display: flex;
  margin-top: 100px;

  ${media.down("LAPTOP")`
    margin-top: 70px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    margin-top: 48px;
  `}

  ${media.down("TABLET")`
    margin-top: 48px;
    flex-direction: column;
  `}

  ${media.down("MOBILE_SMALL")`margin-top: 24px;`}

  padding: 8px;
`;

export const HeroImage = styled.img`
  ${GetHeroSize(180)}

  ${media.down("MOBILE")`
    position: absolute;
    top: -48px;
    right: -30px;
    z-index: 999;
  `}

  ${media.down("MOBILE_TINY")`
    display: none;
  `}
`;

export const MobileSpecialPriceInfo = styled.div`
  position: absolute;
  right: 72px;
  bottom: 44px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;

  ${media.down("MOBILE_TINY")`
    bottom: 32px;
  `}
`;
