import { IOrder, IOrderType } from "@medlog/shared/models/orders/IOrder";
import useGA from "common/analytics/useGA";
import { getShortUid } from "common/helpers/getShortUid";

type TrackOrderScreenAction = "contact-details-entered" | "pick-up-details-entered" | "drop-off-details-entered";
type TrackOrderAction = TrackOrderScreenAction | "delivery-service-chosen";

const trackOrderingFormEvent = (action: TrackOrderAction, label?: string) => {
  const { trackEvent } = useGA();
  trackEvent("ordering-form", action, label);
};

const trackDeliveryServiceChosen = (orderType: IOrderType) => {
  trackOrderingFormEvent("delivery-service-chosen", orderType.toString());
};

const trackOrderScreenAction = (action: TrackOrderScreenAction, order: IOrder) => {
  const label = order.type === IOrderType.URGENT && order.rescheduled ? "URGENT_RESCHEDULED" : order.type!.toString();
  trackOrderingFormEvent(action, label);
};

const trackOrderEcommerce = (order: IOrder) => {
  const { trackEcommerce } = useGA();
  const lastTrackEcommerceId = localStorage.getItem("track_ecommerce_id");
  const shortUid = getShortUid(order.uid);
  if (lastTrackEcommerceId !== shortUid) {
    const product =
      order.type === IOrderType.URGENT && order.rescheduled ? "URGENT_RESCHEDULED" : order.type!.toString();
    const price = order.price! / 100;
    trackEcommerce(shortUid, product, product, "bookings", price, 1);
    localStorage.setItem("track_ecommerce_id", shortUid);
  }
};

export { trackDeliveryServiceChosen, trackOrderScreenAction, trackOrderEcommerce };
