import { IOrder, IOrderType } from "@medlog/shared/models/orders/IOrder";

const canBeVisited = (
  order: IOrder,
  urgentCondition: boolean,
  samedayCondition: boolean,
  prebookCondition: boolean
) => {
  const { type } = order;
  if (type === IOrderType.URGENT) return urgentCondition;
  else if (type === IOrderType.SAME_DAY) return samedayCondition;
  else if (type === IOrderType.PRE_BOOK) return prebookCondition;
  else return false;
};

export default canBeVisited;
