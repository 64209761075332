import React, { ComponentPropsWithoutRef, Dispatch, FunctionComponent, SetStateAction, useState } from "react";
import { Field, FieldProps } from "formik";
import { PhoneNumberInput } from "modules/shared/components/PhoneInput/PhoneInput.components";

interface PhoneInputProps extends ComponentPropsWithoutRef<"input"> {
  setError: Dispatch<SetStateAction<string>>;
  setFocused: Dispatch<SetStateAction<boolean>>;
}

const normalizeTimeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
  // eslint-disable-next-line prefer-const
  let cursorPosition = e.target.selectionStart;
  // eslint-disable-next-line prefer-const
  let cursorEndPosition = e.target.selectionEnd;

  const isAllowed = /^([0-9]{0,11})$/.test(e.target.value);
  if (!isAllowed) {
    e.target.value = e.target.value
      .split("")
      .filter((char, index) => index !== cursorPosition! - 1)
      .join("");
    e.target.selectionStart! = --cursorPosition!;
    e.target.selectionEnd! = --cursorEndPosition!;
  }
};

const PhoneNumberInputField: FunctionComponent<PhoneInputProps> = ({
  placeholder,
  type,
  disabled,
  setError,
  setFocused,
  ...fieldProps
}: PhoneInputProps) => {
  return (
    <Field {...fieldProps}>
      {({ field, meta }: FieldProps) => {
        const [touched, setTouched] = useState(false);
        touched && setError(meta.error ? meta.error : "");
        return (
          <>
            <PhoneNumberInput
              {...field}
              type={type}
              autoComplete="tel-national"
              placeholder={placeholder}
              disabled={disabled}
              onChange={e => {
                normalizeTimeInput(e);
                field.onChange(e);
              }}
              onFocus={() => {
                setFocused(true);
              }}
              onBlur={e => {
                field.onBlur(e);
                setFocused(false);
                setTouched(true);
              }}
            />
          </>
        );
      }}
    </Field>
  );
};

export default PhoneNumberInputField;
