import {
  Container,
  HeroImage,
  RotateScreenHeader,
  RotateScreenDescription,
} from "modules/RotateScreen/RotateScreen.components";
import React, { FunctionComponent } from "react";
import Hero from "modules/RotateScreen/hero.png";

const RotateScreen: FunctionComponent = () => {
  return (
    <Container>
      <HeroImage src={Hero} />
      <RotateScreenHeader>Please rotate your device</RotateScreenHeader>
      <RotateScreenDescription>Please use the site in portrait mode for the best experience</RotateScreenDescription>
    </Container>
  );
};

export default RotateScreen;
