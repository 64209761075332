import { media } from "@medlog/shared/styles/media";
import styled from "styled-components/macro";

const MobileSheet = styled.div`
  z-index: 100;

  ${media.down("MOBILE")`
    z-index: auto;
    background-color: white;
    width: 100vw;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    clip-path: polygon(0 20px, 100% 0, 100% 100%, 0 100%);
    margin-top: 24px;
    padding-top: 16px;
    overflow-x: hidden;
  `};

  ${media.down("MOBILE_SMALL")`
    margin-top: 16px;
    padding-top: 8px;
  `}

  ${media.down("MOBILE_TINY")`
    margin-top: 8px;
  `}
`;

export default MobileSheet;
