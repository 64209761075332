import { colors } from "@medlog/shared/styles/colors";
import { media } from "@medlog/shared/styles/media";
import { MobileDescription, MobileHeader, MobileSmallText } from "modules/shared/styles/mixins";
import styled from "styled-components/macro";

export const Container = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  position: relative;

  ${media.down("MOBILE")`
    margin-top: 96px;
    height: calc(100% - 96px);
  `}

  ${media.down("MOBILE_SMALL")`
    margin-top: 80px;
    height: calc(100% - 80px);
  `}

  
  ${media.down("MOBILE_TINY")`
    margin-top: 64px;
    height: calc(100% - 64px);
  `}
`;

export const SheetWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > h1 {
    margin: 0px;
    ${MobileHeader}
  }

  > span {
    margin: 0px;
    margin-top: 12px;
    margin-bottom: 16px;
    ${MobileDescription}
    text-align: center;
  }

  width: calc(100% - 64px);
  ${media.down("MOBILE")`
    margin: 102px 32px 16px 32px;
  `}

  ${media.down("MOBILE_SMALL")`
    margin: 88px 32px 16px 32px;
  `}
  
  ${media.down("MOBILE_TINY")`
    margin: 80px 32px 16px 32px;
  `}
`;

export const ClockImage = styled.img`
  position: absolute;
  top: -32px;
  left: 0;
  right: 0;
  margin: 0px auto;

  ${media.down("MOBILE")`
    height: 160px;
  `}

  ${media.down("MOBILE_SMALL")`
    height: 140px;
  `}
  
  ${media.down("MOBILE_TINY")`
    height: 120px;
  `}
`;

export const BoxesContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  align-items: center;

  > :first-child {
    margin-right: 16px;
  }
`;

export const DetailsBox = styled.div`
  display: flex;
  height: max-content;
  align-items: center;
  flex: 1;
  flex-direction: column;
  border: 1px solid ${colors.gray._300};
  box-sizing: border-box;
  border-radius: 15px;

  > h4 {
    margin: 16px 16px 0px 16px;
    ${MobileDescription}
  }

  > span {
    margin: 0px 16px 16px 16px;
    text-align: center;
    ${MobileSmallText}
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  > :first-child {
    flex: 1;
    margin: 24px 0px 16px 0px;
  }
`;

export const CancelButton = styled.button`
  padding: 5px 10px;
  border: none;
  background: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.01em;
  text-decoration-line: underline;
  color: ${colors.red._500};
`;
