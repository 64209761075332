import IPhone from "../common/IPhone";
import { IOrderState } from "../orders/IOrderState";
import { IOrderDetails } from "../orders/IOrderDetails";

export enum IOrderType {
  URGENT = "URGENT",
  SAME_DAY = "SAME_DAY",
  PRE_BOOK = "PRE_BOOK",
}

export enum IParcelSize {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
}

export interface IPreBookDeliveryTime {
  timeSpecified: boolean;
  deliveryTime?: Date;
}

export interface IOrder {
  uid: string;
  archived: boolean;
  type?: IOrderType;
  email: string;
  phone: IPhone;
  date?: Date;
  parcelSize?: IParcelSize;
  currentState?: IOrderState;
  price?: number;
  termsAndPrivacyAccepted?: boolean;

  //Prebook:
  preBookDeliveryDate?: Date;
  preBookDeliveryTime?: IPreBookDeliveryTime;

  //Details for simplified display in controller dashboard:
  pickupAndDropoff?: IOrderDetails;

  //Order pickup/dropoff state changed dates:
  pickupDate?: Date;
  dropOffDate?: Date;

  //Accept/rescheduled/rejected controller details fileds:
  handledBy?: string;
  handledAt?: Date;

  //Payment fields:
  paid: boolean; //default false
  lastPaymentId?: string;
  paymentTime?: Date;
  paymentStartDate?: Date;
  paymentSessionId?: string;
  paymentInitialized?: boolean; // When user press "Order and pay".

  //My orders fields:
  token?: string;
  tokenExp?: Date;
  orderIndex?: number; // For correct sorting in my-orders view

  //Simplified info about rescheduled accepted order:
  rescheduled?: boolean;

  //Simplified info about rescheduled order:
  samedayInstead?: boolean;

  //Indicates if user cancelled order manually:
  cancelled?: boolean;

  //To handle copy to medlog failure:
  copydSuccessed: boolean;
  copyDate?: Date;
  copyCount?: number;
}
