import React, { FunctionComponent, useState } from "react";
import PickupContactForm from "modules/OrderingForm/DeliveryDetails/PickupDetails/PickupContact/PickupContactForm";
import PickupLocationForm from "modules/OrderingForm/DeliveryDetails/PickupDetails/PickupLocation/PickupLocationForm";
import routesPaths from "modules/Routing/routesPaths";
import { useEffect } from "react";
import { useLocation } from "react-router";
import { CSSTransition } from "react-transition-group";
import DropoffContactForm from "modules/OrderingForm/DeliveryDetails/DropoffDetails/DropoffContact/DropoffContactForm";
import DropoffLocationForm from "modules/OrderingForm/DeliveryDetails/DropoffDetails/DropoffLocation/DropoffLocationForm";
import SectionContainer from "modules/shared/components/SectionContainer/SectionContainer";
import { DetailsContainer } from "modules/OrderingForm/DeliveryDetails/DeliveryDetails.components";
import { WhiteCover } from "modules/App/OrdersLayout/OrdersLayout.components";

const DeliveryDetails: FunctionComponent = () => {
  const [currentLocation, setCurrentLocation] = useState(routesPaths.PICKUP);
  const location = useLocation();

  useEffect(() => {
    setCurrentLocation(location.pathname);
  }, [location.pathname]);

  const getDetailsComponent = () => {
    switch (currentLocation) {
      case routesPaths.PICKUP:
        return <PickupLocationForm />;
      case routesPaths.PICKUP_CONTACT:
        return <PickupContactForm />;
      case routesPaths.DROPOFF:
        return <DropoffLocationForm />;
      case routesPaths.DROPOFF_CONTACT:
        return <DropoffContactForm />;
      default:
        return <PickupLocationForm />;
    }
  };

  return (
    <SectionContainer>
      <CSSTransition
        in
        appear
        unmountOnExit
        mountOnEnter
        classNames="content-fade"
        addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
      >
        <DetailsContainer className="content-fade">{getDetailsComponent()}</DetailsContainer>
      </CSSTransition>
      <WhiteCover />
    </SectionContainer>
  );
};

export default DeliveryDetails;
