import useMedia from "use-media";
import { Breakpoint, ORDERING_BREAKPOINTS } from "@medlog/shared/styles/const";
import { orientationToAspects } from "@medlog/shared/styles/media";
import { isMobileOnly } from "react-device-detect";
import { useEffect, useState } from "react";

const prepareMediaHookForBreakpoint = (breakpoint: Breakpoint) => {
  return () => {
    const gotEnoughWidth = breakpoint.max_width
      ? useMedia({
          maxWidth: breakpoint.max_width,
        })
      : false;

    const gotEnoughtHeight = breakpoint.max_height
      ? useMedia({
          maxHeight: breakpoint.max_height!,
        })
      : false;

    const gotRightOrientation = breakpoint.orientation
      ? useMedia({
          "max-aspect-ratio": orientationToAspects(breakpoint.orientation).maxAspectRatio,
          "min-aspect-ratio": orientationToAspects(breakpoint.orientation).minAspectRatio,
        })
      : true;

    if (!isMobileOnly && breakpoint.isMobile) return false;
    if (!breakpoint.max_width && !breakpoint.max_height && breakpoint.orientation) return gotRightOrientation;

    return (gotEnoughWidth && gotRightOrientation) || (gotEnoughtHeight && gotRightOrientation);
  };
};

export const useIsTinyLaptopDown = prepareMediaHookForBreakpoint(ORDERING_BREAKPOINTS.LAPTOP_TINY!);
export const useIsTabletDown = prepareMediaHookForBreakpoint(ORDERING_BREAKPOINTS.TABLET!);
export const useIsMobileDown = prepareMediaHookForBreakpoint(ORDERING_BREAKPOINTS.MOBILE!);
export const useIsSmallTabletDown = prepareMediaHookForBreakpoint(ORDERING_BREAKPOINTS.TABLET_SMALL!);

export const useIsTabletSidebar = () => {
  const isTinyLaptop = useIsTinyLaptopDown();
  const isTablet = useIsTabletDown();

  return isTinyLaptop || isTablet;
};

export const useIsMobileSidebar = () => {
  const isMobile = useIsMobileDown();
  const isSmallTablet = useIsSmallTabletDown();

  return isMobile || isSmallTablet;
};

const checkScreenRequirments = (width: number, height: number) => {
  return !(width > height && isMobileOnly && (width < 740 || height < 500));
};

export const useIsSupportedScreen = () => {
  const [isSupported, setIsSupported] = useState(true);

  useEffect(() => {
    setIsSupported(checkScreenRequirments(window.innerWidth, window.innerHeight));
    const handleChange = () => {
      setTimeout(() => {
        setIsSupported(checkScreenRequirments(window.innerWidth, window.innerHeight));
      }, 20);
    };
    window.addEventListener("resize", handleChange);
    return () => window.removeEventListener("resize", handleChange);
  }, [window.innerWidth, window.innerHeight]);

  return isSupported;
};
