import { IOrder, IOrderType } from "@medlog/shared/models/orders/IOrder";
import { IOrderState } from "@medlog/shared/models/orders/IOrderState";
import { functions } from "config/firebase/firebase";
import { History } from "history";
import routesPaths from "modules/Routing/routesPaths";
import { areDeliveriesDisabled } from "common/firebase/deliveriesConfig/areDeliveriesDisabled";

export const handleJustToday = async (order: IOrder, clearOrder: () => void, history: History<unknown>) => {
  const deliveriesDisabled = await areDeliveriesDisabled("sameday");
  if (deliveriesDisabled) {
    history.push(routesPaths.REJECTION);
  }
  if (order.type === IOrderType.URGENT) {
    if (order.currentState === IOrderState.SAMEDAY_INSTEAD) {
      const rescheduleOrder = functions.httpsCallable("rescheduleOrder");
      await rescheduleOrder({ orderId: order.uid });
      history.push(routesPaths.PICKUP, true);
    } else {
      history.push(routesPaths.PICKUP, true);
    }
  } else {
    clearOrder();
    history.push(routesPaths.HOME, true);
  }
};
