import IPhone from "@medlog/shared/models/common/IPhone";
import React, { createContext } from "react";

export interface SettingsContextType {
  deliveriesEnabled?: boolean;
  urgentsEnabled?: boolean;
  sameDaysEnabled?: boolean;
  phoneNumber?: IPhone
}

const SettingsContext: React.Context<SettingsContextType> = createContext<SettingsContextType>(
  {} as SettingsContextType
);

export default SettingsContext;
