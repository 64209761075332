import {
  HomeWhatsappContainer,
  SizeWhatsappContainer,
} from "modules/shared/components/Whatsapp/Components/Descriptions.components";
import React, { FunctionComponent } from "react";
import { TransitionStatus } from "react-transition-group";

type WhatsappDescriptionProps = {
  status: TransitionStatus;
};

export const HomeWhatsappDescription: FunctionComponent<WhatsappDescriptionProps> = ({ status }) => {
  return (
    <HomeWhatsappContainer status={status}>
      Any <strong>special order</strong> time?
    </HomeWhatsappContainer>
  );
};

export const SizeWhatsappDescription: FunctionComponent<WhatsappDescriptionProps> = ({ status }) => {
  return (
    <SizeWhatsappContainer status={status}>
      <strong>Special order size?</strong>
      <br />
      Undersized, requires <br />
      special care? Contact us!
    </SizeWhatsappContainer>
  );
};
