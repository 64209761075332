export enum IOrderState {
  PAYMENT_PENDING = "PAYMENT_PENDING", // When controller accepts
  SAMEDAY_INSTEAD = "SAMEDAY_INSTEAD", // When controller selects 'Just today' option in dashboard
  REJECTED = "REJECTED", // When controller selects 'Reject' option in dashboard
  COPIED = "COPIED", // When controller copies data to medlog
  CREATED = "CREATED", // When user sends initial request to firebase
  LOST = "LOST", // After 10 minutes
  LOST_RESCHEDULED = "LOST_RESCHEDULED", //After 10 minutes if user don't answer to SAMEDAY_INSTEAD request.
  PAYMENT_SUCCEEDED = "PAYMENT_SUCCEEDED", // When payment succeeeds
  COURIER_ON_THE_WAY = "COURIER_ON_THE_WAY",
  PARCEL_PICKED_UP = "PARCEL_PICKED_UP",
  PARCEL_DELIVERED = "PARCEL_DELIVERED",
  ARCHIVED = "ARCHIVED",
}