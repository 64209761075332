import React, { FunctionComponent } from "react";
import {
  Container,
  DetailsContainer,
  Header,
  Description,
  DetailsSpan,
  RedSpan,
} from "modules/OrderingForm/Summary/OrderSummaryMobile.components";
import MobileButton from "modules/shared/components/Mobile/MobileButton";
import MobileDetails from "modules/OrderingForm/Summary/components/MobileDetails/MobileDetails";
import useOrderContext from "modules/App/OrdersLayout/OrderContext/useOrderContext";
import { getSizeDimensions, getSizeInformations, getSizeTitle } from "common/helpers/sizeHelpers";
import { IOrder, IOrderType } from "@medlog/shared/models/orders/IOrder";
import { formatDate } from "@medlog/shared/helpers/dateFormat";
import { formatAsLondonTime } from "@medlog/shared/helpers/formatAsLondonTime";

type OrderSummaryMobile = {
  title: string;
  subline: string;
  nextStep: () => void;
  loading: boolean;
};

const getDate = (order: IOrder) =>
  order.type === IOrderType.PRE_BOOK ? formatDate(order.preBookDeliveryDate!) : `Today, ${formatDate(order.date!)}`;

const getTime = (order: IOrder) => {
  if (order.type === IOrderType.PRE_BOOK && order.preBookDeliveryTime?.timeSpecified) {
    return `pick-up at: ${formatAsLondonTime(order.preBookDeliveryTime.deliveryTime!)}`;
  } else if (order.type === IOrderType.URGENT && !order.rescheduled) {
    return "up to 90 minutes";
  }
  return "between 8 am - 8 pm";
};

const OrderSummaryMobile: FunctionComponent<OrderSummaryMobile> = ({ title, subline, nextStep, loading }) => {
  const { order } = useOrderContext();

  return (
    <Container>
      <DetailsContainer>
        <Header>{title}</Header>
        <Description>{subline}</Description>
        <MobileDetails title="Pick-Up Details:">
          <RedSpan>{order.pickupAndDropoff?.pickupDetails.postcode}</RedSpan>
          <DetailsSpan>{order.pickupAndDropoff?.pickupDetails.placeName}</DetailsSpan>
          <DetailsSpan>{order.pickupAndDropoff?.pickupDetails.placeDetails}</DetailsSpan>
          <DetailsSpan>
            {order.pickupAndDropoff?.pickupDetails.name} {order.pickupAndDropoff?.pickupDetails.phone?.directional}{" "}
            {order.pickupAndDropoff?.pickupDetails.phone?.phoneNumber}
          </DetailsSpan>
        </MobileDetails>

        <MobileDetails title="Drop-off Details:">
          <RedSpan>{order.pickupAndDropoff?.dropOffDetails.postcode}</RedSpan>
          <DetailsSpan>{order.pickupAndDropoff?.dropOffDetails.placeName}</DetailsSpan>
          <DetailsSpan>{order.pickupAndDropoff?.dropOffDetails.placeDetails}</DetailsSpan>
          <DetailsSpan>
            {order.pickupAndDropoff?.dropOffDetails.name} {order.pickupAndDropoff?.dropOffDetails.phone?.directional}{" "}
            {order.pickupAndDropoff?.dropOffDetails.phone?.phoneNumber}
          </DetailsSpan>
        </MobileDetails>

        <MobileDetails title="Package Size:">
          <DetailsSpan>
            {getSizeTitle(order.parcelSize!)}, {getSizeInformations(order.parcelSize!)},{" "}
            {getSizeDimensions(order.parcelSize!)}
          </DetailsSpan>
        </MobileDetails>

        <MobileDetails title="Contact details:">
          <DetailsSpan>
            {order.phone.directional} {order.phone.phoneNumber}
          </DetailsSpan>
          <DetailsSpan>{order.email}</DetailsSpan>
        </MobileDetails>

        <MobileDetails title="Date &amp; Time:">
          <DetailsSpan>
            {getDate(order)} <RedSpan>{getTime(order)}</RedSpan>
          </DetailsSpan>
        </MobileDetails>
      </DetailsContainer>
      <MobileButton text="Go to Stripe Payment" onClick={nextStep} loading={loading} />
    </Container>
  );
};

export default OrderSummaryMobile;
