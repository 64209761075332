import React, { FunctionComponent, useCallback, useState } from "react";
import { FormikProps, Form } from "formik";
import { PostcodesFormValues } from "modules/OrderingForm/Postcodes/form";
import {
  CustomErrorMessage,
  PostcodeSectionWrapper,
  PostcodesInnerWrapper,
  PostcodesInputsWrapper,
  PostcodesSectionHeading,
  PostcodesWrapper,
  PostcodesHeading,
  PostcodeFormFieldsWrapper,
  RadioContainer,
  RadioButton,
  RadioTitle,
  RadioPromoContainer,
  RadioHeaderContainer,
  RadioDescription,
  PromoSpan,
  PostcodeInputsContainer,
} from "modules/OrderingForm/Postcodes/Postcodes.components";
import PostcodeInput from "modules/shared/components/Form/Postcodes/PostcodeInput/PostcodeInput";
import Button from "@medlog/shared/components/Button";
import AddressInput from "modules/shared/components/Form/Postcodes/AddressInput/AddressInput";
import { useEffect } from "react";
import { ReactComponent as ErrorIcon } from "modules/OrderingForm/Postcodes/errorIcon.svg";
import HeroImage from "modules/OrderingForm/Postcodes/hero.png";
import HeroErrirImage from "modules/OrderingForm/Postcodes/hero_error.png";
import MobileSheet from "modules/shared/components/Mobile/MobileSheet";
import { useIsMobileDown } from "common/helpers/useMedia";
import MobileButton from "modules/shared/components/Mobile/MobileButton";
import MobileInput from "./MobileInput/MobileInput";
import { useHistory } from "react-router";
import routesPaths from "modules/Routing/routesPaths";
import { ISuggestion } from "common/types/PostcodesAPI/IAutocomplete";

type PostcodesFormCustomErrors = {
  pickupPostcode: boolean;
  pickupAddress: boolean;
  dropoffPostcode: boolean;
  dropoffAddress: boolean;
  message: string;
};

type PostcodesFormContent = {
  customErrors: PostcodesFormCustomErrors;
  setCustomErrors: (errors: PostcodesFormCustomErrors) => void;
  resetErrors: () => void;
};

const PostcodesFormContent: FunctionComponent<FormikProps<PostcodesFormValues> & PostcodesFormContent> = ({
  setFieldValue,
  setCustomErrors,
  setValues,
  values,
  resetErrors,
  customErrors,
  isSubmitting,
}) => {
  useEffect(() => {
    resetErrors();
  }, [values]);

  const isMobile = useIsMobileDown();
  const history = useHistory();

  const [prevPostcode, setPrevPostcode] = useState("");
  const [prevAddress, setPrevAddress] = useState<ISuggestion>({ address: "", id: "" });
  const [prevPostcodeSelected, setPrevPostcodeSelected] = useState(false);

  const displayError = !isSubmitting && customErrors.message;

  const [page, setPage] = useState<"pickup" | "dropoff">("pickup");
  const [mobileInput, setMobileInput] = useState<"postcode" | "address" | "none">("none");
  const [animationDisabled, setAnimationDisabled] = useState(true);

  const showPickup = () => !isMobile || page == "pickup";
  const showDropoff = () => !isMobile || page == "dropoff";

  const navigateToDropoff = useCallback(() => {
    if (values.pickupAddress && values.pickupAddress.address.length > 0)
      setTimeout(() => {
        setPage("dropoff");
        history.replace(`${routesPaths.POSTCODES}?dropoff`);
      });
    else {
      setAnimationDisabled(false);
      setCustomErrors({
        pickupPostcode: values.pickupPostcode === "",
        pickupAddress: values.pickupAddress.address === "",
        dropoffAddress: false,
        dropoffPostcode: false,
        message: "All fields are required",
      });
    }
  }, [values.pickupAddress, values.pickupPostcode]);

  const applyMobileInputValue = useCallback(() => {
    setMobileInput("none");
  }, []);

  useEffect(() => {
    if (window.location.search == "?dropoff") {
      setTimeout(() => {
        setPage("dropoff");
        history.replace(`${routesPaths.POSTCODES}?dropoff`);
      });
    }
  }, []);

  useEffect(() => {
    if (page !== "dropoff") return;
    const unregister = history.listen(ev => {
      if (ev.pathname === routesPaths.HOME) history.push(routesPaths.POSTCODES);
    });
    return () => {
      setTimeout(unregister);
    };
  }, [page]);

  return (
    <>
      {isMobile && page === "pickup" && mobileInput === "postcode" && (
        <MobileInput
          mode="postcode"
          fieldType="pickup"
          value={values.pickupPostcode}
          setFieldValue={setFieldValue}
          postcodeSelected={values.pickupPostcodeSelected}
          error={customErrors.pickupPostcode ? "error" : undefined}
          onDone={applyMobileInputValue}
        />
      )}
      {isMobile && page === "pickup" && mobileInput === "address" && (
        <MobileInput
          mode="address"
          postcode={values.pickupPostcode}
          fieldName="pickupAddress"
          value={values.pickupAddress}
          setFieldValue={setFieldValue}
          error={customErrors.pickupAddress ? "error" : undefined}
          onDone={applyMobileInputValue}
        />
      )}
      {isMobile && page === "dropoff" && mobileInput === "postcode" && (
        <MobileInput
          mode="postcode"
          fieldType="dropoff"
          value={values.dropoffPostcode}
          setFieldValue={setFieldValue}
          postcodeSelected={values.dropoffPostcodeSelected}
          error={customErrors.dropoffPostcode ? "error" : undefined}
          onDone={applyMobileInputValue}
        />
      )}
      {isMobile && page === "dropoff" && mobileInput === "address" && (
        <MobileInput
          mode="address"
          postcode={values.dropoffPostcode}
          fieldName="dropoffAddress"
          value={values.dropoffAddress}
          setFieldValue={setFieldValue}
          error={customErrors.dropoffAddress ? "error" : undefined}
          onDone={applyMobileInputValue}
        />
      )}
      <Form autoComplete="off">
        {/* To hide autosuggestions. https://stackoverflow.com/a/31613047 */}
        <input type="text" style={{ display: "none" }} />
        <PostcodesWrapper isDropoff={true}>
          <PostcodesInnerWrapper>
            <img src={displayError ? HeroErrirImage : HeroImage} />
            <PostcodesHeading>
              Enter the <b>postcodes</b> for your parcel&apos;s journey
            </PostcodesHeading>
            <MobileSheet>
              <PostcodeFormFieldsWrapper>
                <PostcodeSectionWrapper show={showPickup()} animationDisabled={animationDisabled}>
                  <PostcodesSectionHeading>PICK-UP</PostcodesSectionHeading>
                  <PostcodeInputsContainer>
                    <PostcodesInputsWrapper>
                      <PostcodeInput
                        onClick={() => setMobileInput("postcode")}
                        fieldType="pickup"
                        value={values.pickupPostcode}
                        setFieldValue={setFieldValue}
                        postcodeSelected={values.pickupPostcodeSelected}
                        error={customErrors.pickupPostcode ? "error" : undefined}
                        isMobile={isMobile}
                      />
                      <AddressInput
                        onClick={() => values.pickupPostcodeSelected && setMobileInput("address")}
                        postcode={values.pickupPostcode}
                        disabled={!values.pickupPostcodeSelected}
                        fieldName="pickupAddress"
                        value={values.pickupAddress}
                        setFieldValue={setFieldValue}
                        error={customErrors.pickupAddress ? "error" : undefined}
                        isMobile={isMobile}
                      />
                    </PostcodesInputsWrapper>
                  </PostcodeInputsContainer>
                </PostcodeSectionWrapper>

                <PostcodeSectionWrapper
                  smallerMargin={!isMobile}
                  show={showDropoff()}
                  animationDisabled={animationDisabled}
                >
                  <PostcodesSectionHeading>DROP-OFF</PostcodesSectionHeading>

                  <PostcodeInputsContainer>
                    <RadioContainer
                      type="button"
                      selected={!values.isPromo}
                      onClick={() => {
                        if (values.isPromo) {
                          setValues({
                            ...values,
                            isPromo: false,
                            dropoffPostcode: prevPostcode,
                            dropoffAddress: prevAddress!,
                            dropoffPostcodeSelected: prevPostcodeSelected,
                          });
                          setPrevPostcodeSelected(false);
                          setPrevPostcode("");
                          setPrevAddress({ address: "", id: "" });
                        }
                      }}
                    >
                      <RadioButton selected={!values.isPromo} />
                      <RadioTitle>Custom location</RadioTitle>
                    </RadioContainer>
                    <PostcodesInputsWrapper>
                      <PostcodeInput
                        disabled={values.isPromo}
                        onClick={() => setMobileInput("postcode")}
                        fieldType="dropoff"
                        value={values.isPromo ? "" : values.dropoffPostcode}
                        setFieldValue={setFieldValue}
                        handleChange={!values.isPromo}
                        postcodeSelected={values.dropoffPostcodeSelected}
                        error={customErrors.dropoffPostcode ? "error" : undefined}
                        isMobile={isMobile}
                      />
                      <AddressInput
                        onClick={() => values.pickupPostcodeSelected && setMobileInput("address")}
                        postcode={values.dropoffPostcode}
                        disabled={!values.dropoffPostcodeSelected || values.isPromo}
                        fieldName="dropoffAddress"
                        value={
                          values.isPromo
                            ? {
                                address: "",
                                id: "",
                              }
                            : values.dropoffAddress
                        }
                        setFieldValue={setFieldValue}
                        error={customErrors.dropoffAddress ? "error" : undefined}
                        isMobile={isMobile}
                      />
                    </PostcodesInputsWrapper>
                    <div style={{}}>
                      <RadioContainer
                        selected={values.isPromo}
                        onClick={() => {
                          if (!values.isPromo) {
                            setPrevPostcode(values.dropoffPostcode);
                            setPrevAddress(values.dropoffAddress);
                            setPrevPostcodeSelected(values.dropoffPostcodeSelected);
                            setFieldValue("dropoffPostcode", "EC1N 7UU");
                            setFieldValue("isPromo", true);
                            setFieldValue("dropoffPostcodeSelected", false);
                            setFieldValue("dropoffAddress", {
                              address: "Medical Laboratories, 1-5 Portpool Lane, London",
                              id: "medical-laboratory",
                            });
                          }
                        }}
                        type="button"
                      >
                        <RadioButton selected={values.isPromo} />
                        <RadioPromoContainer>
                          <RadioHeaderContainer>
                            <RadioTitle>Medical Laboratories</RadioTitle>
                            <PromoSpan>only £9.99</PromoSpan>
                          </RadioHeaderContainer>
                          <RadioDescription>EC1N 7UU, 1-5 Portpool Lane. Choose to pay less!</RadioDescription>
                        </RadioPromoContainer>
                      </RadioContainer>
                    </div>
                  </PostcodeInputsContainer>
                </PostcodeSectionWrapper>
              </PostcodeFormFieldsWrapper>
              {displayError && (
                <CustomErrorMessage hide={page === "dropoff"}>
                  <ErrorIcon />
                  <span>{customErrors.message}</span>
                </CustomErrorMessage>
              )}
            </MobileSheet>
          </PostcodesInnerWrapper>
        </PostcodesWrapper>

        {isMobile && (
          <MobileButton
            text="Next step"
            isSubmit={page == "dropoff"}
            onClick={() => {
              if (page == "pickup") navigateToDropoff();
            }}
          />
        )}
        {!isMobile && (
          <Button className="bottomButton" type="submit">
            Next step
          </Button>
        )}
      </Form>
    </>
  );
};

export default PostcodesFormContent;
