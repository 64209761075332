import { colors } from "@medlog/shared/styles/colors";
import { media } from "@medlog/shared/styles/media";
import styled from "styled-components";

export const AuthenticationContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.down("MOBILE_TINY")`
    margin-left: 16px;
    margin-right: 16px;
    width: calc(100% - 32px);
  `}
`;

export const AuthenticationInnerContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AuthenticationOutline = styled.span`
  position: absolute;
  width: 488px;
  border-radius: 30px;
  height: 80px;
  background: ${colors.red._500};
  top: -4px;
  ${media.down("TABLET_SMALL")`
    width:100%;
  `}

  ${media.down("MOBILE")`
    margin-left: 16px;
    margin-right: 16px;
    width: calc(100% - 32px)
  `}
`;

export const AuthenticationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 488px;
  background: ${colors.white};
  box-shadow: 0px -4px 24px -18px rgba(58, 68, 74, 0.2), 0px 8px 24px -18px rgba(58, 68, 74, 0.2);
  border-radius: 30px;
  justify-content: center;
  align-items: center;

  ${media.down("TABLET_SMALL")`
    width: 100%;
  `}

  ${media.down("MOBILE")`
    margin-left: 16px;
    margin-right: 16px;
    width: calc(100% - 32px)
  `}
`;

export const LogoWrapper = styled.div`
  position: absolute;
  top: -4px;
  left: 50%;
  transform: translateX(-50%);
`;

export const AuthenticationHeading = styled.h2`
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.1px;
  color: #4a4a4f;
  margin-top: 116px;
  width: 342px;

  ${media.down("MOBILE")`
    width: unset;
    max-width: 200px;
    margin-left: 32px;
    margin-right: 32px;
  `}

  ${media.down("MOBILE_TINY")`
    margin-top: 88px;
  `}
`;

export const AuthenticationDescription = styled.p`
  margin: 0;
  padding: 0;
  font-weight: 300;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: ${colors.gray._600};
  width: 240px;
  margin-top: 8px;

  ${media.down("MOBILE")`
    width: unset;
    max-width: 200px
  `}
`;

export const AuthenticationFormContainer = styled.div`
  width: 100%;
  margin-left: 32px;
  margin-right: 32px;
`;

export const AuthenticationFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  align-items: center;
  height: 162px;

  ${media.down("MOBILE_TINY")`
    margin-top: 16px;
    height: 128px;
  `}
`;

export const AuthInputContainer = styled.div`
  flex: 1;
  display: flex;
`;
