import { colors } from "@medlog/shared/styles/colors";
import { media } from "@medlog/shared/styles/media";
import { MobileDescription } from "modules/shared/styles/mixins";
import styled, { css, FlattenSimpleInterpolation } from "styled-components/macro";

export const WhiteBoxContainer = styled.div<{
  customStyle?: FlattenSimpleInterpolation;
  hasUnderlinedButton: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  ${p =>
    p.hasUnderlinedButton &&
    css`
      margin-top: -40px;
    `}

  ${p => p.customStyle}

  ${media.down("MOBILE")`
    box-shadow: none;
    border: none;
    border-radius: none;
    height: 100%;
  `}
`;

export const ShadowContainer = styled.div<{ show: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  justify-content: center;
  border-radius: 40px;
  background: ${colors.white};
  box-shadow: 0px -4px 24px -18px rgba(58, 68, 74, 0.2), 0px 8px 24px -18px rgba(58, 68, 74, 0.2);
  box-sizing: border-box;
  border: 1px solid ${colors.gray._100};

  margin-left: 32px;
  margin-right: 32px;

  ${props =>
    !props.show &&
    media.down("MOBILE")`
      height: 100%;
      margin-left: 0px;
      margin-right: 0px;
      box-shadow: none;
      border: none;
      border-radius: none;
    
  `}
`;

export const ButtonsWrapper = styled.div<{ absolute: boolean }>`
  position: relative;
  flex: 1;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  > button {
    color: ${colors.red._500};
    background: none;
    border: none;
  }

  ${media.down("MOBILE")`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  `}

  ${props =>
    !props.absolute
      ? media.down("MOBILE")`
      position: static;
      transform: none;
    `
      : css`
          bottom: -20px;
        `}
`;

export const UnderlinedButton = styled.button`
  position: absolute;
  bottom: -72px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 600;
  font-size: 20px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.01em;
  text-decoration-line: underline;
  color: ${colors.red._500};
  cursor: pointer;
  width: 100%;

  ${media.down("LAPTOP")`
    font-size: 16px;
    bottom: -55px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    font-size: 14px;
    bottom: -46px;
  `}

  ${media.down("LAPTOP_SMALL")`
    font-size: 12px;
  `}

  ${media.down("TABLET")`
    font-size: 16px;
    bottom: -108px;
  `}

  ${media.down("MOBILE")`
    position: static;
    transform: none;
    margin-top: 32px;

    max-width: unset;
    margin-left: 30px;
    margin-right: 30px;
  `}

  ${media.down("MOBILE_SMALL")`
    margin-top: 24px;
  `}

  ${media.down("MOBILE_TINY")`
    margin-bottom: 16px;
  `}
  
  ${MobileDescription}
`;
