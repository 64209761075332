import { ReactComponent as IconPasswordShow } from './icons/icon_password_show.svg';
import { ReactComponent as IconPasswordHide } from './icons/icon_password_hide.svg';

type PasswordIconProps = {
  visible: boolean;
  setIsVisible: (visible: boolean) => void;
};

const PasswordIcon = ({ visible, setIsVisible }: PasswordIconProps) => {
  return (
    <div onClick={() => setIsVisible(!visible)} style={{ marginTop: '3px' }}>
      {visible ? <IconPasswordHide /> : <IconPasswordShow />}
    </div>
  );
};

export default PasswordIcon;
