import React, { FunctionComponent, useState, useEffect } from "react";
import {
  HintArrowWrapper,
  HintContainer,
  HintContent,
  HintContentWrapper,
  HintWrapper,
} from "modules/shared/components/Hint/Hint.component";
// import { ReactComponent as ArrowLeft } from "modules/shared/components/Hint/arrow_left.svg";
// import { ReactComponent as ArrowRight } from "modules/shared/components/Hint/arrow_right.svg";
import ArrowLeft from "modules/shared/components/Hint/arrow_left.png";
import ArrowRight from "modules/shared/components/Hint/arrow_right.png";
import { Transition } from "react-transition-group";

type HintProps = {
  position: "left" | "right";
  hint: string;
  delay?: number;
};

const Hint: FunctionComponent<HintProps> = ({ position, hint, delay, children }) => {
  const [showHint, setShowHint] = useState(false);
  const positionLeft = position === "left";

  useEffect(() => {
    const timer = setTimeout(() => setShowHint(true), delay);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <HintContainer>
      <HintWrapper position={position}>
        {!positionLeft && (
          <Transition timeout={20} in={showHint} appear>
            {status => (
              <HintArrowWrapper position={position} status={status}>
                <img src={ArrowLeft} />
              </HintArrowWrapper>
            )}
          </Transition>
        )}
        <Transition timeout={500} in={showHint} appear>
          {status => (
            <HintContentWrapper position={position} status={status}>
              <HintContent>{hint}</HintContent>
            </HintContentWrapper>
          )}
        </Transition>
        {positionLeft && (
          <Transition timeout={20} in={showHint} appear>
            {status => (
              <HintArrowWrapper position={position} status={status}>
                <img src={ArrowRight} />
              </HintArrowWrapper>
            )}
          </Transition>
        )}
      </HintWrapper>
      {children}
    </HintContainer>
  );
};

export default Hint;
