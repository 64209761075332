import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import {
  Container,
  ContentWrapper,
  FooterWrapper,
  TimerContainer,
  UrgentInfoDetails,
  Wrapper,
} from "modules/App/OrdersLayout/OrdersLayout.components";
import Content from "modules/App/OrdersLayout/Content";
import { OrderProvider } from "modules/App/OrdersLayout/OrderContext/OrderProvider";
import ConfirmModal from "modules/shared/components/ConfirmModal/ConfirmModal";
import SidebarHandler from "modules/shared/components/Sidebar/SidebarHandler";
import getOrder from "common/firebase/orders/getOrder";
import { useHistory, useLocation } from "react-router";
import useOrderContext from "modules/App/OrdersLayout/OrderContext/useOrderContext";
import PageLoader from "modules/App/PageLoader";
import routesPaths, { orderingPaths } from "modules/Routing/routesPaths";
import MyOrdersLayout from "modules/MyOrders/MyOrdersLayout/MyOrdersLayout";
import MyOrdersProvider from "modules/MyOrders/MyOrdersContext/MyOrdersProvider";
import OrderTimer, { shouldShowTimer } from "modules/shared/components/OrderTimer/OrderTimer";
import { handleJustToday } from "common/helpers/handleJustToday";
import { handleAnotherDay } from "common/helpers/handleAnotherDay";
import { handleCheckAvailabilityAgain } from "common/helpers/handleCheckAvailabilityAgain";
import OrderUrlActions from "common/types/Params/OrderUrlActions";
import { useOrderTimer } from "common/helpers/timer/useOrderTimer";
import useSettingsContext from "modules/OrderingRoutingLayout/SettingsContext/useSettingsContext";
import Error404 from "@medlog/shared/components/Error404/Error404";
import Whatsapp from "modules/shared/components/Whatsapp/Whatsapp";
import { useIsMobileDown, useIsMobileSidebar, useIsTabletSidebar } from "common/helpers/useMedia";
import Rating from "modules/Rating/Rating";
import { IOrderType } from "@medlog/shared/models/orders/IOrder";
import AdditionalInformations from "modules/shared/components/Sidebar/TabletSidebar/AdditionalInformations/AdditionalInformations";

const UrlParser: FunctionComponent = () => {
  const [orderLoading, setOrderLoading] = useState(false);
  const [uid, setUid] = useState<string | null>(null);
  const location = useLocation();
  const history = useHistory();
  const isTabletSidebar = useIsTabletSidebar();
  const isMobiletSidebar = useIsMobileSidebar();

  const { setOrder, clearOrder, setOrderDetails, setCurrentOrderState, order, setLocalPrice, setupOrder } =
    useOrderContext();

  const { deliveriesEnabled, urgentsEnabled, sameDaysEnabled } = useSettingsContext();
  const deliveriesDisabledLoading =
    deliveriesEnabled === undefined || urgentsEnabled === undefined || sameDaysEnabled === undefined;

  const time = useOrderTimer(order);

  const id = new URLSearchParams(location.search).get("uid");
  if (id != uid) {
    setOrderLoading(true);
    setUid(id);
  }

  const getFirebaseOrder = useCallback(async (uid: string) => {
    try {
      const order = await getOrder(uid);
      clearOrder();
      if (order) {
        setOrder(order);

        if (order.currentState) setCurrentOrderState(order.currentState);
        if (order.pickupAndDropoff) setOrderDetails(order.pickupAndDropoff);

        const action: OrderUrlActions | null = new URLSearchParams(location.search).get("action") as OrderUrlActions;
        switch (action) {
          case OrderUrlActions.JUST_TODAY:
            await handleJustToday(order, clearOrder, history);
            break;
          case OrderUrlActions.ANOTHER_DAY:
            await handleAnotherDay(order, history, setupOrder, setLocalPrice);
            break;
          case OrderUrlActions.CHECK_AVAILABILITY:
            await handleCheckAvailabilityAgain(order, history, setupOrder, clearOrder);
            break;
          default:
            history.replace(location.pathname);
            break;
        }
      } else history.replace(routesPaths.HOME);
    } catch (error) {
      clearOrder();
      history.replace(routesPaths.HOME);
    } finally {
      setUid(null);
    }
  }, []);

  useEffect(() => {
    if (uid) {
      setOrderLoading(true);
      getFirebaseOrder(uid);
    } else {
      setOrderLoading(false);
    }
  }, [uid]);

  const showTimer = useCallback(
    () => order.type === IOrderType.URGENT && shouldShowTimer(order, location.pathname, time),
    [time, location, order]
  );

  const isMobile = useIsMobileDown();
  const whatsappPaths = [routesPaths.HOME, routesPaths.CONFIRMATION, routesPaths.SIZE];
  const showWhatsapp = !isMobile || whatsappPaths.indexOf(location.pathname) > -1;

  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    scrollRef.current?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location.pathname]);

  return orderLoading || deliveriesDisabledLoading ? (
    <PageLoader />
  ) : (
    <>
      <ConfirmModal />
      <Container>
        <SidebarHandler />
        {showTimer() && (
          <TimerContainer>
            <OrderTimer time={time} type={order.type} rescheduled={order.rescheduled} />
          </TimerContainer>
        )}
        <Wrapper ref={scrollRef}>
          <ContentWrapper>
            {isTabletSidebar && !isMobiletSidebar && <AdditionalInformations />}
            <Content />
          </ContentWrapper>

          <FooterWrapper>
            <UrgentInfoDetails hidden={location.pathname !== routesPaths.HOME}>
              <span style={{ flex: 1 }}>
                * all prices including VAT
                <br />* time may vary depending on a current traffic/road conditions
              </span>
              <span style={{ height: "fit-content", margin: "auto" }}>
                Any <strong>special order</strong> or time?
              </span>
            </UrgentInfoDetails>
            {showWhatsapp && <Whatsapp />}
          </FooterWrapper>
        </Wrapper>
      </Container>
    </>
  );
};

const OrdersLayout: FunctionComponent = () => {
  const path = location.pathname;
  if (path === routesPaths.RATING) {
    return <Rating />;
  } else if (path === routesPaths.MY_ORDERS)
    return (
      <MyOrdersProvider>
        <MyOrdersLayout />
      </MyOrdersProvider>
    );
  else if (Object.values(orderingPaths).find(p => p === path) || path === "/")
    return (
      <OrderProvider>
        <UrlParser />
      </OrderProvider>
    );
  else return <Error404 />;
};

export default OrdersLayout;
