import React, { FunctionComponent, useCallback, useEffect } from "react";
import { Formik } from "formik";
import { OrderTypeFormValues, orderTypeValidationSchema } from "modules/OrderingForm/OrderType/form";
import OrderTypeFormContent from "modules/OrderingForm/OrderType/OrderTypeFormContent";
import useOrderContext from "modules/App/OrdersLayout/OrderContext/useOrderContext";
import { useHistory } from "react-router";
import routesPaths from "modules/Routing/routesPaths";
import { baseOrder } from "modules/App/OrdersLayout/OrderContext/Storage";
import { IOrderType } from "@medlog/shared/models/orders/IOrder";
import { IOrderState } from "@medlog/shared/models/orders/IOrderState";
import { trackDeliveryServiceChosen } from "common/analytics/trackOrderingFormEvent";

const OrderTypeForm: FunctionComponent = () => {
  const { order, setOrder, clearOrder } = useOrderContext();
  const history = useHistory();

  const handleSubmit = useCallback(
    (values: OrderTypeFormValues) => {
      try {
        if (order.type != values.type) {
          clearOrder();
          setOrder({
            ...baseOrder,
            type: values.type,
            date: new Date(),
          });
        } else {
          setOrder({
            ...order,
            type: values.type,
            date: new Date(),
          });
        }
        trackDeliveryServiceChosen(values.type!);
        history.push(routesPaths.POSTCODES);
      } catch (e) {
        console.error(e);
      }
    },
    [order]
  );

  useEffect(() => {
    if (order.type === IOrderType.URGENT) {
      if (order.currentState !== undefined) {
        clearOrder();
      }
    } else {
      const allowedStates = [undefined, IOrderState.CREATED];
      if (!allowedStates.includes(order.currentState)) {
        clearOrder();
      }
    }
  }, [order]);

  return (
    <Formik
      initialValues={{ type: order.type }}
      validationSchema={orderTypeValidationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {formProps => <OrderTypeFormContent {...formProps} />}
    </Formik>
  );
};

export default OrderTypeForm;
