import { media } from "@medlog/shared/styles/media";
import styled from "styled-components/macro";
import { TransitionStatus } from "react-transition-group";
import { ARROW_TYPE } from "modules/OrderingForm/Failure/PlaceArrow";

type ArrowWrapperProps = {
  status: TransitionStatus;
  arrowType: ARROW_TYPE;
};

export const PlaceArrowWrapper = styled.span`
  position: relative;
`;

const getTransform = (props: ArrowWrapperProps, scale: number) => `
    translateX(${props.status === "entered" ? "0" : "30px"})
    scaleY(${props.arrowType === "bottom" ? -scale : scale})
    scaleX(${scale})
`;

export const ArrowWrapper = styled.div<ArrowWrapperProps>`
  width: 70px;
  transition: all 900ms ease;
  opacity: ${props => (props.status === "entered" ? "1" : "0")};

  content: ${p => p.arrowType};

  position: absolute;
  transform: ${p => getTransform(p, 1.25)};
  transform-origin: center left;

  top: ${p => (p.arrowType == "bottom" ? 0 : -10)}px;
  right: -80px;

  ${p => media.down("LAPTOP")`
    transform: ${getTransform(p, 1)};
  `};

  ${p => media.down("LAPTOP_MEDIUM")`
    transform: ${getTransform(p, 0.8)};
  `};

  ${p => media.down("LAPTOP_SMALL")`
    transform: ${getTransform(p, 0.75)};
  `};

  ${media.down("TABLET")`
    display: none;
  `}
`;
