import { colors } from "@medlog/shared/styles/colors";
import { media } from "@medlog/shared/styles/media";
import { TransitionStatus } from "react-transition-group";
import styled, { css } from "styled-components";

export const HomeWhatsappContainer = styled.span<{ status: TransitionStatus }>`
  ${props =>
    ["exiting", "exited"].includes(props.status) &&
    css`
      opacity: 0;
    `}

  white-space: nowrap;
  right: 0px;
  position: absolute;
  transition: opacity 200ms ease-in;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  text-align: right;
  letter-spacing: 0.01em;
  color: ${colors.gray._900};

  ${media.down("LAPTOP_BIG")`
    font-size: 18px;
  `}

  ${media.down("LAPTOP")`
    font-size: 14px;
  `}
  
  ${media.down("LAPTOP_MEDIUM")`
    font-size: 12px;
  `}
  
  ${media.down("TABLET")`
    font-size: 12px;
  `}
`;

export const SizeWhatsappContainer = styled.span<{ status: TransitionStatus }>`
  white-space: nowrap;
  right: 0px;
  position: absolute;
  transition: opacity 200ms ease-in;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  align-items: center;
  text-align: right;
  letter-spacing: 0.01em;
  color: ${colors.gray._700};

  ${props =>
    ["exiting", "exited"].includes(props.status) &&
    css`
      opacity: 0;
    `}

  ${media.down("LAPTOP_BIG")`
    font-size: 16px;
  `}

  ${media.down("LAPTOP")`
    font-size: 11px;
  `}
  
  ${media.down("TABLET")`
    font-size: 12px;
  `}

  > strong {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 160%;
    text-align: right;
    letter-spacing: 0.1px;
    color: ${colors.gray._800};

    ${media.down("LAPTOP_BIG")`
    font-size: 16px;
  `}

    ${media.down("LAPTOP")`
  font-size: 12px;
`}

${media.down("LAPTOP_MEDIUM")`
  font-size: 11px;
`}
  
  ${media.down("TABLET")`
    font-size: 14px;
  `}
  }
`;
