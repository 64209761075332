import { colors } from "@medlog/shared/styles/colors";
import { media } from "@medlog/shared/styles/media";
import { MobileDescription } from "modules/shared/styles/mixins";
import styled, { css } from "styled-components";
import { isMobileOnly } from "react-device-detect";

export const ANIMATION_DURATION_MS = 400;

export const Container = styled.div<{ open: boolean; showScrollbar: boolean; visible: boolean }>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  background: ${colors.greyscale.black};
  position: absolute;
  left: 0;
  top: 0;
  z-index: 101; //To overlap inputs.
  height: ${props => (isMobileOnly ? (!props.visible ? "0px" : props.open ? "100%" : "48px") : "100%")};
  max-height: ${props => (!props.visible ? "0px" : props.open ? "100%" : "48px")};
  transition: max-height ${ANIMATION_DURATION_MS}ms ease;

  * ::-webkit-scrollbar {
    ${props =>
      !props.showScrollbar &&
      css`
        display: none;
      `}
  }
`;

export const HeaderArrowsContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  left: 0;
  top: 0;
  bottom: 0;
  min-width: 64px;
  height: 48px;
`;

export const OrderDetailsHeader = styled.div<{ visible: boolean }>`
  height: 100%;
  display: flex;
  margin-left: ${props => (props.visible ? -16 : 0)}px;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  transition: width ${ANIMATION_DURATION_MS}ms ease, margin-left ${ANIMATION_DURATION_MS}ms ease;
  width: ${props => (props.visible ? "72px" : "0px")};

  > span {
    transition: opacity ${ANIMATION_DURATION_MS}ms ease;
    opacity: ${props => (props.visible ? 1 : 0)};
    white-space: nowrap;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: ${colors.gray._200};
  }
`;

export const ElementsContainer = styled.div`
  z-index: 3;
  position: absolute;
  right: 18px;
  display: flex;
  > a {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    text-decoration: none;
    color: ${colors.gray._200};
  }
`;

export const AllOrdersLink = styled.a<{ visible: boolean }>``;

export const NewOrderLink = styled.a<{ visible: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 16px;
  display: ${props => (props.visible ? "static" : "none")};

  > img {
    margin-right: 4px;
    height: 16px;
    width: 16px;
  }
`;

export const HeaderRoundedBgContainer = styled.div`
  border-radius: 0px 0px 48px 0px;
  background-color: #1a1f23;
  display: flex;
`;

export const ArrowsImage = styled.img<{ open: boolean }>`
  transition: transform ${ANIMATION_DURATION_MS}ms ease;
  transform: rotate(${props => (props.open ? -180 : 0)}deg);
  margin-right: 8px;
  margin-bottom: 4px;
`;

export const HeaderContainer = styled.div`
  position: relative;
  height: 48px;
  display: flex;
  flex-direction: row;
`;

export const HeaderTitle = styled.h1<{ visible: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  vertical-align: middle;
  margin: auto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: ${colors.gray._200};
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity ${ANIMATION_DURATION_MS}ms ease ${props => (props.visible ? 500 : 0)}ms;
`;

export const HeaderContentContainter = styled.div<{ visible: boolean }>`
  flex: 1;
  transition: transform ${ANIMATION_DURATION_MS}ms ease ${props => (props.visible ? 500 : 0)}ms;
  transform: translateY(${props => (props.visible ? 0 : -100)}%);
`;

export const StepsContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const StepCompleted = styled.img`
  width: 16px;
  height: 16px;
`;

export const StepCurrent = styled.div`
  width: 28px;
  height: 28px;

  > svg {
    width: 28px;
    height: 28px;
  }
`;

export const FormStep = styled.div<{ hidden: boolean }>`
  opacity: ${props => (props.hidden ? 0 : 1)};
  display: flex;
  width: 28px;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
  margin-right: 6px;

  :first-child {
    margin-left: 0px;
  }

  :last-child {
    margin-right: 0px;
  }
`;

export const StepIndicator = styled.div`
  background: ${colors.gray._500};
  border: 1px solid ${colors.gray._500};
  box-sizing: border-box;
  border-radius: 100px;
`;

export const ContentContainer = styled.div<{ open: boolean }>`
  overflow: hidden;
  height: 100%;
  background-color: ${colors.greyscale.black};
`;

export const WhatsappContainer = styled.div<{ visible: boolean }>`
  position: absolute;
  bottom: 0;
  right: 0;
  transition: all ${ANIMATION_DURATION_MS / 2}ms ease ${props => (props.visible ? ANIMATION_DURATION_MS : 0)}ms;
  visibility: ${props => (props.visible ? "visible" : "hidden")};
  opacity: ${props => (props.visible ? 1 : 0)};
`;

export const TimeContainer = styled.div<{ visible: boolean }>`
  position: absolute;
  overflow: visible;
  top: 48px;
  right: 0px;
  z-index: 2;
  background-color: ${colors.red._500};
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(${props => (props.visible ? "0%" : "100%")});
  transition: transform 200ms ease;
  border-radius: 0px 0px 0px 32px;
  width: 128px;
  height: 64px;

  ${media.down("MOBILE")`
    width: 104px;
    height: 56px;
    border-radius: 0px 0px 0px 32px;
  `}

  ${media.down("MOBILE_SMALL")`
    width: 96px;
    height: 48px;
    border-radius: 0px 0px 0px 28px;
  `}

  
  ${media.down("MOBILE_TINY")`
    width: 88px;
    height: 40px;
    border-radius: 0px 0px 0px 24px;
  `}

  > h1 {
    margin: 0px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    margin-bottom: 3px;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 26px;
    color: ${colors.white};

    ${MobileDescription}
  }
`;
