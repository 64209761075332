import styled from "styled-components/macro";
import { colors } from "@medlog/shared/styles/colors";
import { media } from "@medlog/shared/styles/media";
import { GetHeroSize } from "modules/shared/styles/mixins";

type ContactDetailsProps = {
  noPrice: boolean;
};

export const ContactDetailsWrapper = styled.div<ContactDetailsProps>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid ${colors.gray._100};
  border-radius: 30px;
  box-shadow: 0px -4px 24px -18px rgba(58, 68, 74, 0.2), 0px 8px 24px -18px rgba(58, 68, 74, 0.2);
  justify-content: middle;
  position: relative;
  align-items: center;

  > h2 {
    max-width: 50%;
  }

  width: 610px;
  height: ${p => (p.noPrice ? 562 : 682)}px;
  padding-top: 40px;

  ${p => media.down("LAPTOP")`
    width: 488px;
    height: ${p.noPrice ? 462 : 552}px;
    padding-top: 32px;
  `}

  ${p => media.down("LAPTOP_MEDIUM")`
    width: 448px;
    height: ${p.noPrice ? 412 : 502}px;
  `}

  ${p => media.down("LAPTOP_SMALL")`
    width: 416px;
    height: ${p.noPrice ? 372 : 444}px;
    padding-top: 24px;
  `}

  ${p => media.down("TABLET")`
    width: 488px;
    height: ${p.noPrice ? 482 : 600}px;
    padding-top: 32px;
    margin-left: 30px;
  `}

  ${media.down("MOBILE")`
    width: 100%;
    height: 100%;
    box-shadow: none;
    border-radius: 0;
    margin-left: 0;
    background-color: transparent;
    align-items: center;
  `}
`;

export const UrgentContactHero = styled.img`
  position: absolute;
  bottom: -25px;
  left: -160px;

  ${GetHeroSize(250)}

  ${media.down("LAPTOP")`
    bottom: -30px;
    left: -170px; 
  `}

  ${media.down("LAPTOP_MEDIUM")`
    bottom: -30px;
    left: -150px; 
  `}

  ${media.down("LAPTOP_SMALL")`
    bottom: -25px;
    left: -120px; 
    width: 160px;
  `}

  ${media.down("TABLET")`
    bottom: -20px;
    left: -90px; 
  `}

  ${media.down("MOBILE")`
    display: none;
  `}
`;

export const ContactHero = styled.img`
  position: absolute;
  bottom: -40px;
  left: -140px;

  ${GetHeroSize(200)}

  ${media.down("LAPTOP")`
    bottom: -40px;
    left: -120px; 
  `}

  ${media.down("LAPTOP_MEDIUM")`
    bottom: -30px;
    left: -100px; 
  `}

  ${media.down("LAPTOP_SMALL")`
    bottom: -30px;
    left: -100px; 
    width: 140px;
  `}

  ${media.down("TABLET")`
    bottom: -20px;
    left: -90px; 
  `}

  ${media.down("MOBILE")`
    display: none;
  `}
`;

export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;

  > span {
    font-weight: normal;
    font-size: 18px;
    line-height: 140%;
    color: ${colors.gray._600};

    ${media.down("LAPTOP")`
      font-size: 14px;
    `}
  }

  ${media.down("LAPTOP_SMALL")`
  margin-bottom: 12px;
  `}

  ${media.down("TABLET")`
    margin-bottom: 24px;
  `}

  ${media.down("MOBILE")`
      display: none;
  `}
`;

export const PriceHeader = styled.h3`
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 50px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: ${colors.gray._800};

  ${media.down("LAPTOP")`
    font-size: 40px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    font-size: 32px;
  `}

  ${media.down("TABLET")`
    font-size: 40px;
  `}
`;

export const ContactDetailsInputsWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  margin-top: 35px;

  > button {
    margin-top: 17px;
    align-self: center;
  }

  ${media.down("MOBILE")`
    margin-bottom: 80px;
  `}
`;

export const ContactDetailsInputsInnerWrapper = styled.div`
  transform: scale(1.25);
  transform-origin: top center;
  margin-bottom: 50px;

  ${media.down("LAPTOP")`
    transform: scale(1);
    margin-bottom: 0px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    transform: scale(0.92);
    margin-bottom: -10px;
  `}

  ${media.down("LAPTOP_SMALL")`
    transform: scale(0.81);
    margin-top: -10px;
    margin-bottom: -20px;
  `}

  ${media.down("TABLET")`
    transform: scale(1);
    margin: 0 auto;
    margin-bottom: 10px;
  `}

  ${media.down("MOBILE")`
    margin-bottom: 0;
  `}

  ${media.down("MOBILE_TINY")`
    margin-bottom: -16px;
  `}
`;

export const PriceConfirmation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 16px;

  > h3 {
    font-weight: 600;
    font-size: 32px;
    line-height: 120%;
    text-align: center;
    letter-spacing: -0.02em;
    margin: 0;
  }

  > span {
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    text-align: center;
  }
`;
