import React, { FunctionComponent, useCallback, useState } from "react";
import {
  AnimationWrapper,
  CancelButton,
  CheckingAvailabilityContainer,
  ButtonWrapper,
  Container,
  DescriptionSpan,
  FewMinutesSpan,
  HeroImage,
  MobileAnimationWrapper,
  MobileButtonWrapper,
  Header,
  HeroMobileImage,
} from "modules/OrderingForm/CheckingAvailability/CheckingAvailability.components";
import useOrderContext from "modules/App/OrdersLayout/OrderContext/useOrderContext";
import { useHistory } from "react-router-dom";
import { IOrderState } from "@medlog/shared/models/orders/IOrderState";
import routesPaths from "modules/Routing/routesPaths";
import { IOrderType } from "@medlog/shared/models/orders/IOrder";
import SectionContainer from "modules/shared/components/SectionContainer/SectionContainer";
import availability from "!!file-loader!modules/OrderingForm/CheckingAvailability/icons/animation.svg";
import { makeOrderLost } from "common/firebase/orders/makeOrderLost";
import Hero from "modules/OrderingForm/CheckingAvailability/icons/hero.png";
import HeroMobile from "modules/OrderingForm/CheckingAvailability/icons/hero_mobile.png";
import MobileSheet from "modules/shared/components/Mobile/MobileSheet";
import { DisplayModalState } from "common/navigation/states/DisplayModalState";

const CheckingAvailability: FunctionComponent = () => {
  const { order, clearOrder } = useOrderContext();
  const history = useHistory();
  const [initialOrderState] = useState(order.currentState);

  const navigateToNextScreen = useCallback(() => {
    if (order.type === IOrderType.URGENT) {
      const locationState: DisplayModalState = { displayModal: initialOrderState === order.currentState };
      if (order.currentState === IOrderState.PAYMENT_PENDING)
        history.push(routesPaths.COURIER_AVAILABLE, locationState);
      else if (order.currentState === IOrderState.SAMEDAY_INSTEAD) history.push(routesPaths.RESCHEDULE);
      else if (order.currentState === IOrderState.REJECTED) history.push(routesPaths.REJECTION);
    }
  }, [order.currentState]);

  navigateToNextScreen();

  const cancelOrder = useCallback(() => {
    makeOrderLost(order.uid);
    clearOrder();
    history.push(routesPaths.HOME, true);
  }, [order]);

  return (
    <SectionContainer>
      <Container>
        <MobileAnimationWrapper>
        <object data={availability} />
        </MobileAnimationWrapper>
        <MobileSheet>
          <CheckingAvailabilityContainer>
            <AnimationWrapper>
             <object data={availability} />
            </AnimationWrapper>
            <Header>We are currently checking courier availability...</Header>
            <DescriptionSpan>
              We will send you <strong>an email</strong> of confirmation as soon as we find a courier for you. You can
              remain on this page or return at a later time.
            </DescriptionSpan>

            <MobileButtonWrapper>
              <HeroMobileImage src={HeroMobile} />
              <CancelButton onClick={cancelOrder}>Cancel my Order</CancelButton>
            </MobileButtonWrapper>

            <FewMinutesSpan>
              Shouldn’t take us more than
              <br />
              <span>a few minutes.</span>
            </FewMinutesSpan>

            <HeroImage src={Hero} />
          </CheckingAvailabilityContainer>
          <ButtonWrapper>
            <CancelButton onClick={cancelOrder}>Cancel my Order</CancelButton>
          </ButtonWrapper>
        </MobileSheet>
      </Container>
    </SectionContainer>
  );
};

export default CheckingAvailability;
