import { media } from "@medlog/shared/styles/media";
import { GetHeroSize } from "modules/shared/styles/mixins";
import { isMobileOnly } from "react-device-detect";
import styled from "styled-components/macro";

export const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  align-self: center;
  justify-self: center;
  margin-top: auto;
  align-items: center;
  justify-content: center;
  width: 100%;

  ${media.down("MOBILE")`
    position: relative;
    border-radius: 0;
    padding: 0;
    margin-top: 32px;
    min-height: 620px;
    margin-bottom: 32px;
  `}

  ${media.down("MOBILE_SMALL")`
    min-height: 640px;
    margin-top: 88px;
  `}

  ${media.down("MOBILE_TINY")`
    overflow: hidden;
    margin-top: 128px;
  `}
`;

export const WhiteFooter = styled.div`
  border-radius: 0px;
  position: absolute;
  bottom: 0;
  height: 50px;
  width: 100%;
  z-index: 0;
  background: white;
  display: ${isMobileOnly ? "static" : "none"};
`;

export const PageContainer = styled.div`
  width: 100%;

  ${media.down("MOBILE")`
    margin-top: 32px;
  `}
`;

export const MobileButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  margin: auto;
  justify-content: center;
  display: none;
  z-index: 30;

  ${media.down("MOBILE")`
    display: flex;
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;

  ${media.down("TABLET")`
    position: relative;
  `}

  ${media.down("MOBILE")`
    align-items: center;
    margin-top: 0px;
    > h2 { max-width: 60vw;}
  `}
`;

export const FormContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  margin-top: 64px;
  margin-left: 32px;
  margin-right: 32px;

  > :nth-child(odd) {
    margin-left: 16px;
    margin-right: 16px;
  }

  ${media.down("TABLET")`
    margin-top: 30px;
    flex-direction: column;
    align-items: center;

    > :nth-child(odd) {
      margin-left: 0;
      margin-right: 0;
  `}

  ${media.down("MOBILE")`
      margin-top: 20px; 
  `}
`;

export const HeroImage = styled.img`
  margin: 0 auto;
  ${GetHeroSize(150)}

  ${media.down("MOBILE")`
      position: absolute;
      z-index: 999;
      right: -20px;
      top: -8px;
  `}
`;
