import React, { FunctionComponent } from "react";
import { useDocument, useDocumentData } from "react-firebase-hooks/firestore";
import SettingsContext from "modules/OrderingRoutingLayout/SettingsContext/SettingsContext";
import {
  DeliveriesSettings,
  UrgentDeliveriesEnabled,
  SameDayDeliveriesEnabled,
  DefaultContactPhone,
} from "common/firebase/Queries";
import IPhone from "@medlog/shared/models/common/IPhone";

const SettingsProvider: FunctionComponent = ({ children }) => {
  const [urgentSnap, urgentSnapLoading] = useDocument(UrgentDeliveriesEnabled());
  const urgentsEnabled = urgentSnapLoading ? undefined : !urgentSnap?.exists;

  const [sameDaySnap, sameDaySnapLoading] = useDocument(SameDayDeliveriesEnabled());
  const sameDaysEnabled = sameDaySnapLoading ? undefined : !sameDaySnap?.exists;

  const [deliveriesSnap, deliveriesLoading] = useDocument(DeliveriesSettings());
  const deliveriesEnabled = deliveriesLoading ? undefined : !deliveriesSnap?.exists;

  const [phoneNumber] = useDocumentData<IPhone>(DefaultContactPhone());

  return (
    <SettingsContext.Provider value={{ phoneNumber, deliveriesEnabled, urgentsEnabled, sameDaysEnabled }}>
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;
