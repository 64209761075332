import React, { FunctionComponent, useEffect } from "react";
import {
  Container,
  SidebarHeader,
  IncludingVat,
  PriceContainer,
  PriceH2,
  DetailsContainer,
  Logo,
  SidebarAnimatedDiv,
} from "modules/shared/components/Sidebar/Sidebar.components";
import IconLogoDrop from "@medlog/shared/icons/icon_logo_drop.png";
import DateItem from "modules/shared/components/Sidebar/DetailsItem/Items/DateItem";
import LocationItem from "modules/shared/components/Sidebar/DetailsItem/Items/LocationItem";
import PackageSizeItem from "modules/shared/components/Sidebar/DetailsItem/Items/PackageSizeItem";
import ContactItem from "modules/shared/components/Sidebar/DetailsItem/Items/ContactItem";
import PickupDetailsItem from "modules/shared/components/Sidebar/DetailsItem/Items/PickupDetailsItem";
import useOrderContext from "modules/App/OrdersLayout/OrderContext/useOrderContext";
import DropoffDetailsItem from "modules/shared/components/Sidebar/DetailsItem/Items/DropoffDetailsItem";
import PaymentItem from "modules/shared/components/Sidebar/DetailsItem/Items/PaymentItem";
import routesPaths from "modules/Routing/routesPaths";
import Transition from "react-transition-group/Transition";
import Introduction from "modules/shared/components/Sidebar/Introduction/Introduction";
import { useHistory } from "react-router-dom";
import { scroller } from "react-scroll";
import { IOrder, IOrderType } from "@medlog/shared/models/orders/IOrder";
import { ReactComponent as CornerTop } from "modules/shared/components/Sidebar/corner_top.svg";
import { ReactComponent as CornerBottom } from "modules/shared/components/Sidebar/corner_bottom.svg";
import { useIsTabletSidebar } from "common/helpers/useMedia";
import { getMinPromoPrice, getOrderPriceDescription } from "@medlog/shared/helpers/getOrderPrice";
import { IOrderState } from "@medlog/shared/models/orders/IOrderState";

export const SCROLL_DURATION = 1000;

const handleScroll = (path: string) => {
  const paymentPaths = [routesPaths.REJECTION, routesPaths.AVAILABILITY, routesPaths.SUMMARY, routesPaths.RESCHEDULE];
  let scrollId = path;
  if (paymentPaths.includes(path)) {
    scrollId = routesPaths.SUMMARY;
  }
  scroller.scrollTo(scrollId, {
    containerId: "containerId",
    smooth: true,
    duration: SCROLL_DURATION,
    isDynamic: true,
  });
};

const displayAdditionalSections = (order: IOrder) => {
  if (order.currentState === IOrderState.SAMEDAY_INSTEAD) {
    return order.rescheduled;
  }
  return ![undefined, IOrderState.CREATED, IOrderState.REJECTED].includes(order.currentState);
};

export const getPages = (order: IOrder) => {
  if (!order.type) return <></>;
  switch (order.type) {
    case IOrderType.URGENT:
      return (
        <>
          <DateItem />
          <LocationItem />
          <PackageSizeItem />
          <ContactItem />
          <Transition in={displayAdditionalSections(order)} timeout={50} unmountOnExit>
            {status => (
              <SidebarAnimatedDiv status={status}>
                <PickupDetailsItem />
                <DropoffDetailsItem />
                <PaymentItem />
              </SidebarAnimatedDiv>
            )}
          </Transition>
        </>
      );
    case IOrderType.SAME_DAY:
      return (
        <>
          <DateItem />
          <LocationItem />
          <PickupDetailsItem />
          <DropoffDetailsItem />
          <PackageSizeItem />
          <ContactItem />
          <PaymentItem />
        </>
      );
    case IOrderType.PRE_BOOK:
      return (
        <>
          <LocationItem />
          <DateItem />
          <PickupDetailsItem />
          <DropoffDetailsItem />
          <PackageSizeItem />
          <ContactItem />
          <PaymentItem />
        </>
      );
  }
};

const Sidebar: FunctionComponent = () => {
  const history = useHistory();
  const isTabletSidebar = useIsTabletSidebar();
  useEffect(() => {
    return history.listen(location => handleScroll(location.pathname));
  }, [history]);

  const { order, orderPrice } = useOrderContext();

  const getOrderPrice = () => {
    if (orderPrice) {
      return (orderPrice / 100).toFixed(2);
    } else if (order.type != IOrderType.PRE_BOOK) {
      return getMinPromoPrice(order.type!);
    } else {
      return getMinPromoPrice(IOrderType.SAME_DAY);
    }
  };

  return (
    <Container>
      <SidebarHeader>
        <Logo src={IconLogoDrop} id="06.05.23/1" onClick={() => history.push(routesPaths.HOME)} />
        {!isTabletSidebar && (
          <Transition in={location.pathname !== routesPaths.HOME} timeout={50}>
            {status => (
              <PriceContainer status={status}>
                <div>
                  <PriceH2 exact={orderPrice !== undefined}>£{getOrderPrice()}</PriceH2>
                  <IncludingVat>{getOrderPriceDescription(orderPrice)}</IncludingVat>
                </div>
              </PriceContainer>
            )}
          </Transition>
        )}
      </SidebarHeader>
      {order.type !== undefined ? (
        <DetailsContainer id="containerId">{getPages(order)}</DetailsContainer>
      ) : (
        <Introduction />
      )}
      {!isTabletSidebar && (
        <>
          <CornerTop className="corner corner_top" />
          <CornerBottom className="corner corner_bottom" />
        </>
      )}
    </Container>
  );
};

export default Sidebar;
