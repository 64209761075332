import React, { FunctionComponent, useCallback } from "react";
import { Formik, FormikHelpers } from "formik";
import { useHistory } from "react-router-dom";
import routesPaths from "modules/Routing/routesPaths";
import {
  pickupContactValidationSchema,
  PickupContactFormValues,
} from "modules/OrderingForm/DeliveryDetails/PickupDetails/PickupContact/form";
import PickupContactFormContent from "modules/OrderingForm/DeliveryDetails/PickupDetails/PickupContact/PickupContactFormContent";
import useOrderContext from "modules/App/OrdersLayout/OrderContext/useOrderContext";
import { updateDetailsIfOrderExists } from "common/firebase/orders/updateDetailsIfOrderExists";
import { trackOrderScreenAction } from "common/analytics/trackOrderingFormEvent";

const PickupContactForm: FunctionComponent = () => {
  const { order, orderDetails, setOrderDetails } = useOrderContext();
  const history = useHistory();

  const handleSubmit = useCallback(
    async (values: PickupContactFormValues, actions: FormikHelpers<PickupContactFormValues>) => {
      actions.setSubmitting(true);
      const updatedOrderDetails = {
        pickupDetails: {
          ...orderDetails.pickupDetails,
          name: values.contactName,
          email: values.email,
          phone: {
            directional: values.phone.directional,
            phoneNumber: values.phone.phoneNumber,
          },
        },
        dropOffDetails: orderDetails.dropOffDetails,
      };
      try {
        updateDetailsIfOrderExists(order, updatedOrderDetails);
        trackOrderScreenAction("pick-up-details-entered", order);
        setOrderDetails(updatedOrderDetails);
        history.push(routesPaths.DROPOFF);
      } catch (e) {
        console.error(e);
      } finally {
        actions.setSubmitting(false);
      }
    },
    []
  );

  return (
    <Formik
      initialValues={{
        contactName: orderDetails.pickupDetails.name!,
        email: orderDetails.pickupDetails.email ?? "",
        phone: {
          directional: orderDetails.pickupDetails.phone!.directional,
          phoneNumber: orderDetails.pickupDetails.phone!.phoneNumber,
        },
      }}
      validationSchema={pickupContactValidationSchema}
      onSubmit={handleSubmit}
    >
      {formProps => <PickupContactFormContent {...formProps} />}
    </Formik>
  );
};

export default PickupContactForm;
