import { IOrder, IOrderType } from "@medlog/shared/models/orders/IOrder";
import { differenceInSeconds } from "date-fns";
import Firebase from "firebase/app";

const URGENT_PAYMENT_DURATION_SECONDS = 30 * 60;
const PAYMENT_DURATION_SECONDS = 10 * 60;

export const getOrderInitialTime = (order: IOrder) => {
  let date: Date | undefined = undefined;

  if (order.paymentStartDate instanceof Firebase.firestore.Timestamp) {
    date = (order.paymentStartDate as Firebase.firestore.Timestamp).toDate();
  } else {
    date = order.paymentStartDate;
  }

  if (date instanceof Date) {
    const difference = differenceInSeconds(new Date(), date);
    const duration =
      order.type === IOrderType.URGENT && !order.rescheduled
        ? URGENT_PAYMENT_DURATION_SECONDS
        : PAYMENT_DURATION_SECONDS;
    return Math.max(0, duration - difference) * 1000;
  }
  return null;
};

export default getOrderInitialTime;
