import React, { FunctionComponent } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { routes } from "modules/Routing/routes";
import SettingsProvider from "modules/OrderingRoutingLayout/SettingsContext/SettingsProvider";
import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from "config/stripe";
import RotateScreen from "modules/RotateScreen/RotateScreen";
import { useIsSupportedScreen } from "common/helpers/useMedia";
import { Container } from "modules/OrderingRoutingLayout/OrderingRoutingLayout.components";

const OrderingRoutingLayout: FunctionComponent = () => {
  const isSupportedScreen = useIsSupportedScreen();

  return isSupportedScreen ? (
    <SettingsProvider>
      <Elements stripe={stripePromise}>
        <Router>
          <Container>
            <Switch>
              {routes.map((route, index) => (
                <Route key={index} exact={route.exact} path={route.path}>
                  {route.component}
                </Route>
              ))}
            </Switch>
          </Container>
        </Router>
      </Elements>
    </SettingsProvider>
  ) : (
    <RotateScreen />
  );
};

export default OrderingRoutingLayout;
