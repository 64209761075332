import React, { FunctionComponent } from "react";
import useOrderContext from "modules/App/OrdersLayout/OrderContext/useOrderContext";
import {
  Container,
  StatusContaienr,
  InformationsContainer,
  OrderStatusWrapper,
  OrderStatus,
  StatusDescription,
  DetailsContainer,
  DetailsBox,
  InformationsWrapper,
  OrderStatusInnerWrapper,
  MobileContainer,
  MobileDetailsContainer,
  MobileDetailWrapper,
  MobileStatusContainer,
  MobileOrderStateDescription,
  MobileStateImage,
  StatusDetails,
} from "modules/OrderingForm/Confirmation/Confirmation.components";

import LineProgress from "modules/OrderingForm/Confirmation/line_progress.svg";
import LinePast from "modules/OrderingForm/Confirmation/line_past.svg";
import LineGray from "modules/OrderingForm/Confirmation/line_gray.svg";
import LineCurrent from "modules/OrderingForm/Confirmation/line_current.svg";

import MobileLineProgress from "modules/OrderingForm/Confirmation/mobile_line_progress.svg";
import MobileLinePast from "modules/OrderingForm/Confirmation/mobile_line_past.svg";
import MobileLineGray from "modules/OrderingForm/Confirmation/mobile_line_gray.svg";
import MobileLineCurrent from "modules/OrderingForm/Confirmation/mobile_line_current.svg";

import IconOrderId from "modules/OrderingForm/Confirmation/icon_order_id.png";
import IconOrderTime from "modules/OrderingForm/Confirmation/icon_order_time.png";

import OrderAccepted from "modules/OrderingForm/Confirmation/order_accepted.png";
import CourierOnTheWay from "modules/OrderingForm/Confirmation/courier_on_the_way.png";
import ParcelPickedUp from "modules/OrderingForm/Confirmation/parcel_picked_up.png";
import ParcelDelivered from "modules/OrderingForm/Confirmation/parcel_delivered.png";

import { IOrderState } from "@medlog/shared/models/orders/IOrderState";
import { IOrder, IOrderType } from "@medlog/shared/models/orders/IOrder";
import { addMinutes, format } from "date-fns";
import SectionContainer from "modules/shared/components/SectionContainer/SectionContainer";
import PrebookTime from "modules/OrderingForm/Summary/PrebookTime";
import { formatAsLondonTime } from "@medlog/shared/helpers/formatAsLondonTime";
import PageLoader from "modules/App/PageLoader";
import { getShortUid } from "common/helpers/getShortUid";
import Button from "@medlog/shared/components/Button";
import { useHistory } from "react-router";
import routesPaths from "modules/Routing/routesPaths";
import { useIsMobileDown } from "common/helpers/useMedia";
import { trackOrderEcommerce } from "common/analytics/trackOrderingFormEvent";

const INITIAL_STATES = [IOrderState.PAYMENT_PENDING, IOrderState.PAYMENT_SUCCEEDED, IOrderState.COPIED];

const getOrderStatusState = (current: IOrderState, state: IOrderState): "past" | "present" | "future" => {
  if (current === state || (state === IOrderState.PAYMENT_SUCCEEDED && current === IOrderState.PAYMENT_PENDING)) {
    return "present";
  } else if (current === IOrderState.COPIED && state === IOrderState.PAYMENT_SUCCEEDED) {
    return "present";
  } else if (current === IOrderState.PARCEL_DELIVERED) {
    return "past";
  } else if (current === IOrderState.PARCEL_PICKED_UP) {
    return state === IOrderState.PARCEL_DELIVERED ? "future" : "past";
  } else if (current === IOrderState.COURIER_ON_THE_WAY) {
    return state === IOrderState.PARCEL_DELIVERED || state === IOrderState.PARCEL_PICKED_UP ? "future" : "past";
  } else if (INITIAL_STATES.includes(current)) {
    return "future";
  }
  return "past";
};
const getStateImage = (current: IOrderState) => {
  switch (current) {
    case IOrderState.COURIER_ON_THE_WAY:
      return <img src={CourierOnTheWay} />;
    case IOrderState.PARCEL_PICKED_UP:
      return <img src={ParcelPickedUp} />;
    case IOrderState.PARCEL_DELIVERED:
      return <img src={ParcelDelivered} />;
    default:
      return <img src={OrderAccepted} />;
  }
};

const getDescriptionHeader = (order: IOrder) => {
  switch (order.currentState!) {
    case IOrderState.COURIER_ON_THE_WAY:
      return <>Your courier is on the way!</>;
    case IOrderState.PARCEL_PICKED_UP:
      return (
        <>
          Your parcel was
          <br />
          picked up!
        </>
      );
    case IOrderState.PARCEL_DELIVERED:
      return (
        <>
          Hurray - your parcel
          <br />
          has been delivered!
        </>
      );
    default:
      return <>Thank you for placing your order!</>;
  }
};

const getDescriptionText = (order: IOrder) => {
  switch (order.currentState!) {
    case IOrderState.COURIER_ON_THE_WAY:
      return (
        <>
          Please make sure the parcel is ready to be picked up. Our couriers have a tight schedule and will not be able
          to wait more than <b>10 minutes.</b>
        </>
      );
    case IOrderState.PARCEL_PICKED_UP:
      return <>The courier is now on the way to deliver it to its drop-off location.</>;
    case IOrderState.PARCEL_DELIVERED:
      return <>Your package has been safely transported to its drop-off location.</>;
    default:
      return <>We will send you an email as soon as the courier is on the way.</>;
  }
};

const getLine = (next: IOrderState, current: IOrderState, isMobile: boolean) => {
  const Progress = isMobile ? MobileLineProgress : LineProgress;
  const Current = isMobile ? MobileLineCurrent : LineCurrent;
  const Past = isMobile ? MobileLinePast : LinePast;
  const Gray = isMobile ? MobileLineGray : LineGray;

  if (next === IOrderState.COURIER_ON_THE_WAY) {
    if (INITIAL_STATES.includes(current)) {
      return <img src={Progress} />;
    } else if (current === IOrderState.COURIER_ON_THE_WAY) {
      return <img src={Current} />;
    } else {
      return <img src={Past} />;
    }
  }

  if (next === IOrderState.PARCEL_PICKED_UP) {
    if (current === IOrderState.COURIER_ON_THE_WAY) {
      return <img src={Progress} />;
    } else if (current === IOrderState.PARCEL_PICKED_UP) {
      return <img src={Current} />;
    } else if (!INITIAL_STATES.includes(current)) {
      return <img src={Past} />;
    }
  }

  if (next === IOrderState.PARCEL_DELIVERED) {
    if (current === IOrderState.PARCEL_PICKED_UP) {
      return <img src={Progress} />;
    } else if (current === IOrderState.PARCEL_DELIVERED) {
      return <img src={Current} />;
    }
  }

  return <img src={Gray} />;
};

const getDeliveryTime = (order: IOrder) => {
  if (order.currentState === IOrderState.PARCEL_PICKED_UP && order.pickupDate) {
    return formatAsLondonTime(order.pickupDate);
  } else if (order.currentState === IOrderState.PARCEL_DELIVERED && order.dropOffDate) {
    return formatAsLondonTime(order.dropOffDate);
  } else if (order.type === IOrderType.URGENT && !order.rescheduled && order.paymentTime instanceof Date) {
    return formatAsLondonTime(addMinutes(order.paymentTime, 90));
  } else if (order.type === IOrderType.SAME_DAY || (order.type === IOrderType.URGENT && order.rescheduled)) {
    return "up to 8:00 pm";
  } else if (order.type === IOrderType.PRE_BOOK) {
    return <PrebookTime date={order.preBookDeliveryDate!} time={order.preBookDeliveryTime!} />;
  }

  return formatAsLondonTime(order.date!);
};

const getOrderStateName = (state: IOrderState) => {
  switch (state) {
    case IOrderState.PAYMENT_PENDING:
    case IOrderState.SAMEDAY_INSTEAD:
    case IOrderState.COPIED:
    case IOrderState.CREATED:
    case IOrderState.PAYMENT_SUCCEEDED:
      return "Order accepted";
    case IOrderState.COURIER_ON_THE_WAY:
      return "Courier on the way";
    case IOrderState.PARCEL_PICKED_UP:
      return "Parcel picked up";
    case IOrderState.PARCEL_DELIVERED:
      return "Parcel delivered";
  }

  return "";
};

const getDetailsDescription = (order: IOrder) => {
  let pickupDescription: string | undefined = "";
  let dropOffDescription: string | undefined = "";
  if (order.currentState === IOrderState.PARCEL_PICKED_UP || order.currentState === IOrderState.PARCEL_DELIVERED) {
    pickupDescription =
      order.pickupDate instanceof Date
        ? `${order.pickupAndDropoff?.pickupDetails.name} ${formatAsLondonTime(order.pickupDate)}`
        : "";

    dropOffDescription =
      order.dropOffDate instanceof Date
        ? `${order.pickupAndDropoff?.dropOffDetails.name} ${formatAsLondonTime(order.dropOffDate)}`
        : "";
  }
  return { pickupDescription, dropOffDescription };
};

const getTimeDescription = (order: IOrder) => {
  if (order.type === IOrderType.PRE_BOOK && order.preBookDeliveryTime?.timeSpecified) {
    const initialStates = [
      IOrderState.CREATED,
      IOrderState.PAYMENT_PENDING,
      IOrderState.COPIED,
      IOrderState.PAYMENT_SUCCEEDED,
    ];
    if (initialStates.includes(order.currentState!)) return "Pick-up Time";
  }

  return order.currentState === IOrderState.PARCEL_PICKED_UP && order.pickupDate ? "Pick-up Time" : "Delivery Time";
};

const getOrderDate = (order: IOrder) => {
  const dateFormat = "LLL do";
  if (order.type === IOrderType.URGENT && !order.rescheduled) {
    const time = formatAsLondonTime(addMinutes(order.paymentTime!, 90));
    return `${format(order.date!, dateFormat)}, ${time}`;
  } else if (order.type === IOrderType.PRE_BOOK) {
    if (order.preBookDeliveryTime?.timeSpecified) {
      const time = formatAsLondonTime(addMinutes(order.preBookDeliveryTime.deliveryTime!, 90));
      return `${format(order.preBookDeliveryDate!, dateFormat)}, ${time}`;
    } else {
      return `${format(order.preBookDeliveryDate!, dateFormat)}, 8 AM - 8 PM`;
    }
  } else {
    return `${format(order.date!, dateFormat)}, 8 AM - 8 PM`;
  }
};

const Confirmation: FunctionComponent = () => {
  const { order } = useOrderContext();
  const history = useHistory();
  const isMobileDown = useIsMobileDown();
  trackOrderEcommerce(order);

  //uncomment to fake order status
  // order.currentState = window.name ? order.currentState : IOrderState.PARCEL_PICKED_UP;

  const current = order.currentState!;
  const description = getDetailsDescription(order);

  const showLoading = !(order.paymentTime instanceof Date);

  return showLoading ? (
    <PageLoader absolute={false} />
  ) : (
    <SectionContainer>
      <MobileContainer>
        <InformationsWrapper>
          <h1>{getDescriptionHeader(order)}</h1>
          <span>{getDescriptionText(order)}</span>
        </InformationsWrapper>
        <MobileDetailsContainer>
          <MobileDetailWrapper>
            <img src={IconOrderId} />
            <span>Order: #{getShortUid(order.uid)}</span>
          </MobileDetailWrapper>
          <MobileDetailWrapper>
            <img src={IconOrderTime} />
            <span>{getOrderDate(order)}</span>
          </MobileDetailWrapper>
        </MobileDetailsContainer>
        <MobileStatusContainer>
          <OrderStatusInnerWrapper>
            <OrderStatus state={getOrderStatusState(current, IOrderState.PAYMENT_SUCCEEDED)}>1</OrderStatus>
          </OrderStatusInnerWrapper>
          {getLine(IOrderState.COURIER_ON_THE_WAY, current, isMobileDown)}
          <OrderStatusInnerWrapper>
            <OrderStatus state={getOrderStatusState(current, IOrderState.COURIER_ON_THE_WAY)}>2</OrderStatus>
          </OrderStatusInnerWrapper>
          {getLine(IOrderState.PARCEL_PICKED_UP, current, isMobileDown)}

          <OrderStatusInnerWrapper>
            <OrderStatus state={getOrderStatusState(current, IOrderState.PARCEL_PICKED_UP)}>3</OrderStatus>
          </OrderStatusInnerWrapper>
          {getLine(IOrderState.PARCEL_DELIVERED, current, isMobileDown)}

          <OrderStatusInnerWrapper>
            <OrderStatus state={getOrderStatusState(current, IOrderState.PARCEL_DELIVERED)}>4</OrderStatus>
          </OrderStatusInnerWrapper>
        </MobileStatusContainer>

        <MobileStateImage>{getStateImage(current)}</MobileStateImage>

        <MobileOrderStateDescription>
          <h3>{getOrderStateName(order.currentState!)}</h3>
          <span>
            {order.currentState === IOrderState.PARCEL_PICKED_UP
              ? description.pickupDescription
              : order.currentState === IOrderState.PARCEL_DELIVERED
              ? description.dropOffDescription
              : ""}
          </span>
        </MobileOrderStateDescription>
      </MobileContainer>

      <Container>
        <StatusContaienr>
          <OrderStatusWrapper>
            <OrderStatusInnerWrapper>
              <OrderStatus state={getOrderStatusState(current, IOrderState.PAYMENT_SUCCEEDED)}>1</OrderStatus>
              <StatusDescription selected={INITIAL_STATES.includes(current)}>
                <span>Order accepted</span>
              </StatusDescription>
            </OrderStatusInnerWrapper>
            {getLine(IOrderState.COURIER_ON_THE_WAY, current, isMobileDown)}
            <OrderStatusInnerWrapper>
              <OrderStatus state={getOrderStatusState(current, IOrderState.COURIER_ON_THE_WAY)}>2</OrderStatus>
              <StatusDescription selected={current === IOrderState.COURIER_ON_THE_WAY}>
                <span>Courier on the way</span>
              </StatusDescription>
            </OrderStatusInnerWrapper>
            {getLine(IOrderState.PARCEL_PICKED_UP, current, isMobileDown)}
            <OrderStatusInnerWrapper>
              <OrderStatus state={getOrderStatusState(current, IOrderState.PARCEL_PICKED_UP)}>3</OrderStatus>
              <StatusDescription selected={current === IOrderState.PARCEL_PICKED_UP}>
                <span>Parcel picked up</span>
              </StatusDescription>
            </OrderStatusInnerWrapper>
            {getLine(IOrderState.PARCEL_DELIVERED, current, isMobileDown)}
            <OrderStatusInnerWrapper>
              <OrderStatus state={getOrderStatusState(current, IOrderState.PARCEL_DELIVERED)}>4</OrderStatus>
              <StatusDescription selected={current === IOrderState.PARCEL_DELIVERED}>
                <span>Parcel delivered</span>
              </StatusDescription>
            </OrderStatusInnerWrapper>
          </OrderStatusWrapper>
          <OrderStatusWrapper>
            <StatusDetails />
            <StatusDetails />
            <StatusDetails>{description.pickupDescription}</StatusDetails>
            <StatusDetails>{description.dropOffDescription}</StatusDetails>
          </OrderStatusWrapper>
        </StatusContaienr>
        <InformationsContainer>
          <InformationsWrapper>
            <h1>{getDescriptionHeader(order)}</h1>
            <span>{getDescriptionText(order)}</span>
            <span>
              Go to <a href={order.token ? `/my-orders?token=${order.token}` : "/my-orders"}>all orders</a>
            </span>
          </InformationsWrapper>
          <DetailsContainer>
            <DetailsBox>
              <h3>{getShortUid(order.uid)}</h3>
              <span>Your order number</span>
            </DetailsBox>
            <DetailsBox>
              <h3>{getDeliveryTime(order)}</h3>
              <span>{getTimeDescription(order)}</span>
            </DetailsBox>
          </DetailsContainer>
          {getStateImage(current)}
        </InformationsContainer>
      </Container>
      {!isMobileDown && (
        <Button className="bottomButton" onClick={() => history.replace(routesPaths.HOME, true)}>
          Place new order
        </Button>
      )}
    </SectionContainer>
  );
};

export default Confirmation;
