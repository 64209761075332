import styled from "styled-components/macro";
import { colors } from "@medlog/shared/styles/colors";
import { TransitionStatus } from "react-transition-group/Transition";
import { media } from "@medlog/shared/styles/media";

type IntroductionContainerProps = {
  status: TransitionStatus;
};

export const IntroductionContainer = styled.div<IntroductionContainerProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  transition: opacity 700ms ease, transform 700ms ease;
  opacity: ${props => (props.status === "entered" ? "1" : "0")};
  transform: translateY(${props => (props.status === "entered" ? "0px" : "-100px")});

  ${media.down("LAPTOP_BIG")`
    margin-left: 96px;
    margin-right: 72px;
  `}
  ${media.down("LAPTOP")`
    margin-left: 72px;
    margin-right: 54px;
  `}
  ${media.down("LAPTOP_MEDIUM")`
    margin-left: 56px;
    margin-right: 57px;
  `}
  ${media.down("LAPTOP_SMALL")`
    margin-left: 56px;
    margin-right: 49px;
  `}
`;

export const IntroductionHeader = styled.h3`
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: 28px;
  line-height: 130%;
  letter-spacing: -0.015em;
  color: ${colors.gray._100};
  margin-top: 62px;
  margin-bottom: 10px;

  ${media.down("LAPTOP_BIG")`
    font-size: 36px;
    margin-top: 112px;
  `}
  ${media.down("LAPTOP")`
    font-size: 28px;
    margin-top: 66px;
  `}
  ${media.down("LAPTOP_MEDIUM")`
    font-size: 22px;
    margin-top: 44px;
  `}
`;

export const IntroductionDescription = styled.p`
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: ${colors.gray._400};

  ${media.down("LAPTOP_BIG")`
    font-size: 20px;
  `}
  ${media.down("LAPTOP")`
    font-size: 16px;
  `}
  ${media.down("LAPTOP_MEDIUM")`
    font-size: 14px;
  `}
`;

export const AvailabilityWrapper = styled.div`
  ${media.down("LAPTOP_BIG")`
    margin-top: 48px;
  `}

  ${media.down("LAPTOP")`
    margin-top: 32px;
  `}
`;

export const AvailabilityInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-left: 16px;
  svg {
    margin-right: 10px;
  }
  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 155%;
    color: ${colors.gray._300};

    ${media.down("LAPTOP_BIG")`
      font-size: 20px;
    `}
    ${media.down("LAPTOP")`
      font-size: 16px;
    `}
    ${media.down("LAPTOP_MEDIUM")`
      font-size: 14px;
    `}
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  align-items: flex-end;
  margin-top: 32px;
`;

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 44px;
  border-top: 1px solid ${colors.gray._700};

  > a {
    padding: 4px 0px;
    display: flex;
    align-items: center;
    margin-top: 20px;
    font-style: normal;
    font-weight: 400;
    text-decoration: none;
    color: ${colors.gray._300};

    ${media.down("LAPTOP_BIG")`
      font-size: 20px;
    `}
    ${media.down("LAPTOP")`
      font-size: 16px;
    `}
    ${media.down("LAPTOP_MEDIUM")`
      font-size: 14px;
    `}

    :first-child {
      margin-top: 32px;
    }

    > img {
      width: 0px;
      margin-left: 4px;
      transition: width 200ms ease;

      ${media.down("LAPTOP_BIG")`
        height: 20px;
        `}
      ${media.down("LAPTOP")`
        height: 18px;
      `}
      ${media.down("LAPTOP_MEDIUM")`
        height: 16px;
      `}
    }

    :hover {
      font-weight: 500;
      color: ${colors.gray._200};

      > img {
        ${media.down("LAPTOP_BIG")`
          width: 26px;
        `}
        ${media.down("LAPTOP")`
          width: 24px;
        `}
        ${media.down("LAPTOP_MEDIUM")`
          width: 22px;
        `}
      }
    }
  }
`;
