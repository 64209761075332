import styled from "styled-components";
import { colors } from "@medlog/shared/styles/colors";
import { media } from "@medlog/shared/styles/media";
import { GetHeroSizeWithRatio, MobileDescription } from "modules/shared/styles/mixins";

export const CheckingAvailabilityContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  background: ${colors.white};
  border: 1px solid ${colors.gray._100};
  box-sizing: border-box;
  box-shadow: 0px -4px 24px -18px rgba(58, 68, 74, 0.2), 0px 8px 24px -18px rgba(58, 68, 74, 0.2);
  border-radius: 30px;
  text-align: center;
  position: relative;

  width: 650px;
  height: 650px;

  > object {
    width: 290px;
    backface-visibility: hidden; //few lines for the sake of performance optimization
    perspective: 1000;
    transform: translate3d(0, 0, 0);
    transform: translateZ(0);
  }

  ${media.down("LAPTOP")`
    width: 498px;
    height: 520px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    width: 421px;
    height: 451px;
  `}

  ${media.down("LAPTOP_SMALL")`
    margin-top: -8px;
    margin-left: 16px;
    width: 382px;
    height: 403px;
  `}

  ${media.down("TABLET")`
    width: 488px;
    height: 568px;
  `}

  ${media.down("MOBILE")`
    border: none;
    box-shadow: none;
    border-radius: none;
    width: calc(100% - 64px);
    margin-left: 32px;
    margin-right: 32px;
    height: 100%;
  `}

  ${media.down("MOBILE_TINY")`
    justify-content: flex-start;
  `}
`;

export const HeroMobileImage = styled.img`
  display: none;
  position: absolute;

  ${media.down("MOBILE")`
  display: block;
  width: 128px;
  left: -88px;
`}

  ${media.down("MOBILE_SMALL")`
    display: block;
    width: 128px;
    left: -88px;
  `}

  ${media.down("MOBILE_TINY")`
    display: block;
    width: 112px;
    left: -72px;
  `}
`;

export const HeroImage = styled.img`
  ${GetHeroSizeWithRatio(314, 0.96)}
  position: absolute;
  left: -260px;
  bottom: -50px;

  ${media.down("LAPTOP")`
    ${GetHeroSizeWithRatio(263, 0.96)}
    left: -200px;
    bottom: -35px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    ${GetHeroSizeWithRatio(214, 0.96)}
    left: -180px;
    bottom: -30px;
  `}

  ${media.down("LAPTOP_SMALL")`
    ${GetHeroSizeWithRatio(201, 0.96)}
    left: -130px;
    bottom: -25px;
  `}

  ${media.down("TABLET")`
    ${GetHeroSizeWithRatio(228, 0.96)}
    left: -80px;
    bottom: -80px;
  `}

  ${media.down("MOBILE")`
    display: none;
  `}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 16px;

  ${media.down("MOBILE")`
    position: relative;
    height: calc(100% - 32px);
    margin-top: 32px;
    margin-left: 0px;
  `}

  ${media.down("MOBILE_TINY")`
    height: calc(100% - 48px);
    margin-top: 48px;
  `}
`;

export const MobileAnimationWrapper = styled.div`
  position: absolute;
  top: 0px;
  width: 250px;
  z-index: 2;
  display: none;

  > object {
    backface-visibility: hidden; //few lines for the sake of performance optimization
    perspective: 1000;
    transform: translate3d(0, 0, 0);
    transform: translateZ(0);
  }

  ${media.down("MOBILE")`
    display: block;
    top: -52px;
  `}

  ${media.down("MOBILE_SMALL")`
    width: 224px;
    top: -52px;
  `}

  ${media.down("MOBILE_TINY")`
    width: 192px;
    top: -16px;
  `}
`;

export const AnimationWrapper = styled.div`
  width: 290px;

  > object {
    backface-visibility: hidden; //few lines for the sake of performance optimization
    perspective: 1000;
    transform: translate3d(0, 0, 0);
    transform: translateZ(0);
  }

  ${media.down("LAPTOP")`
    width: 232px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    width: 211px;
  `}

  ${media.down("LAPTOP_SMALL")`
    width: 211px; 
  `}

  ${media.down("TABLET")`
    width: 232px;
  `}

  ${media.down("MOBILE")`
    display: none;
  `}
`;

export const CancelButton = styled.button`
  margin-top: 52px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.01em;
  text-decoration-line: underline;
  border: none;
  background-color: transparent;
  color: ${colors.red._500};
  cursor: pointer;

  ${media.down("LAPTOP")`
    font-size: 16px;
    margin-top: 42px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    font-size: 14px;
    margin-top: 26px;
  `}

  ${media.down("LAPTOP_SMALL")`
    font-size: 12px;
    margin-top: 18px;
  `}

  ${media.down("TABLET")`
    font-size: 16px;
    margin-top: 42px;
  `}

  ${media.down("MOBILE")`
    margin-top: 0px;
  `}

  ${MobileDescription}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  ${media.down("MOBILE")`
    display: none;
  `}
`;

export const MobileButtonWrapper = styled.div`
  display: none;

  ${media.down("MOBILE")`
    display: block;
    margin-top: 40px;
    margin-bottom: 48px;
  `}

  ${media.down("MOBILE_SMALL")`
    margin-top: 32px;
    margin-bottom: 40px;
  `}

  ${media.down("MOBILE_TINY")`
    margin-top: 24px;
    margin-bottom: 32px;
  `}
`;

export const FewMinutesSpan = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: ${colors.gray._600};
  display: none;
  margin-bottom: 32px;

  > span {
    color: ${colors.mint._600};
  }

  ${media.down("TABLET")`
    margin-bottom: 84px;
  `}

  ${media.down("MOBILE")`
    display: block;
  `}

  ${media.down("MOBILE_TINY")`
    margin-bottom: 24px;
  `}

  ${MobileDescription}
`;

export const DescriptionSpan = styled.span`
  margin-top: 16px;
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 140%;
  text-align: center;
  color: ${colors.gray._600};
  max-width: 450px;

  ${media.down("LAPTOP")`
    font-size: 18px;
    max-width: 338px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    font-size: 16px;
    max-width: 307px;
  `}

  ${media.down("LAPTOP_SMALL")`
    font-size: 14px;
    max-width: 265px;
  `}

  ${media.down("TABLET")`
    font-size: 18px;
    max-width: 352px; 
  `}

  ${media.down("MOBILE")`
    font-size: 18px;
    margin-top: 8px;
  `}
  
  ${media.down("MOBILE_SMALL")`
    font-size: 16px;
    margin-top: 8px;
  `}

  ${media.down("MOBILE_TINY")`
    font-size: 16px;
    margin-top: 8px;
  `}
`;

export const Header = styled.h1`
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 48px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: ${colors.gray._800};
  margin-top: 40px;
  width: 360px;

  ${media.down("LAPTOP")`
    font-size: 38px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    font-size: 30px;
  `}

  ${media.down("LAPTOP_SMALL")`
    font-size: 24px;
  `}

  ${media.down("TABLET")`
    font-size: 38px;
  `}

  ${media.down("MOBILE")`
    font-size: 24px;
    width: 328px;
  `}

  ${media.down("MOBILE_SMALL")`
    font-size: 22px;
    width: 264px;
  `}

  ${media.down("MOBILE_TINY")`
    width: 264px;
    margin-top: 88px;
    font-size: 20px;
  `}
`;
