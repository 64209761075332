import styled from "styled-components";
import Background from "modules/App/OrdersLayout/background.svg";

export const MyOrdersLayoutContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100vw;
  height: 100%;
  background: #f8f8f9;
`;

export const MyOrdersLayoutWrapper = styled.div`
  display: flex;
  background: #f8f8f9;
  background-image: url("${Background}");
  background-position: bottom right;
  background-repeat: no-repeat;
  flex: 1;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;
