import styled, { css } from "styled-components/macro";
import { colors } from "@medlog/shared/styles/colors";
import { TransitionStatus } from "react-transition-group";
import { media } from "@medlog/shared/styles/media";

export const Container = styled.div<{
  disabled: boolean;
  checked: boolean;
  animationDisabled?: boolean;
  enableScaling?: boolean;
}>`
  display: flex;
  flex-direction: column;

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
    `}

  margin-top: ${p => (p.checked ? 14 : 20)}px;
  transition: transform 300ms ease;

  :hover {
    ${props =>
      (!props.animationDisabled || props.enableScaling) &&
      css`
        cursor: pointer;
        transform: scale(0.98);
      `}
  }
`;

export const HeaderContainer = styled.div<{ active: boolean }>`
  flex-direction: row;
  display: flex;
  width: fit-content;
  align-items: center;
  padding-right: 24px;
  background: ${props => (props.active ? colors.gray._900 : "transparent")};
  border-radius: 20px;
  margin-bottom: 4px;
`;

export const IconContainer = styled.div<{ checked: boolean }>`
  width: 40px;
  height: 40px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 50%;

  > svg {
    width: 100%;
    height: 100%;

    ${p =>
      p.checked &&
      css`
        width: auto;
        height: auto;
      `}
  }

  ${media.down("LAPTOP")`
    width: 32px;
    height: 32px;
  `}

  ${p => media.down("LAPTOP_SMALL")`
    width: 28px;
    height: 28px;

    ${
      p.checked &&
      css`
        > svg {
          width: 20px;
          height: 20px;
          margin: auto;
        }
      `
    }
  `}

  ${media.down("MOBILE")`
    width: 32px;
    height: 32px;
  `}
`;

export const HeaderTitle = styled.h3<{ active: boolean; checked: boolean }>`
  margin: 0px;
  font-weight: ${props => (props.active ? "bold" : 600)};
  font-size: 18px;
  line-height: 17px;
  margin-left: 12px;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: ${props => (props.active ? colors.gray._100 : props.checked ? colors.gray._300 : colors.gray._500)};

  ${media.down("LAPTOP")`
    font-size: 14px;
    margin-left: 20px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    font-size: 12px;
  `}

  ${media.down("LAPTOP_SMALL")`
    margin-left: 24px;
  `}

  ${media.down("TABLET")`
    font-size: 14px;
  `}

  ${media.down("MOBILE")`
    font-size: 14px;
  `}
`;

export const UrgentLabel = styled.span`
  margin-left: 8px;
  background: ${colors.red._500};
  color: white;
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;
  height: 22px;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  line-height: 0;
  padding-bottom: 2px;

  box-sizing: border-box;

  ${media.down("LAPTOP")`
    font-size: 12px;
    height: 20px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    font-size: 11px;
    padding-bottom: 1px;
  `}
`;

export const PickupDetail = styled.span<{ disabled: boolean }>`
  display: flex;
  margin-top: 8px;
  position: relative;
  margin-left: 13px;
  cursor: pointer;
  transition: transform 300ms ease;
  flex-direction: column;
  color: ${colors.gray._400};
  line-height: 120%;

  :first-child {
    margin-top: 0px;
  }

  > span {
    line-height: 120%;
    margin-bottom: 6px;

    > b {
      font-weight: 500;
      color: ${colors.gray._300};
    }
  }

  ::before {
    content: "";
    position: absolute;
    left: -13px;
    top: 6px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: ${colors.gray._400};
  }

  ${props =>
    props.disabled
      ? css`
          pointer-events: none;
        `
      : css`
          :hover {
            transform: scale(0.98);
          }
        `}
`;

export const PointerIcon = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${colors.gray._500};

  ${media.down("LAPTOP")`
    width: 8px;
    height: 8px;
  `}

  ${media.down("LAPTOP_SMALL")`
    width: 4px;
    height: 4px;
  `}

  ${media.down("MOBILE")`
    width: 8px;
    height: 8px;
  `}
`;

type ContentTransitionProps = {
  status: TransitionStatus;
};

export const Content = styled.div<ContentTransitionProps>`
  margin-left: 52px;
  max-height: 0;
  overflow: hidden;
  transition: opacity 950ms ease, transform 950ms ease, max-height 2s ease;
  opacity: ${props => (props.status === "entered" ? "1" : "0")};
  transform: translateX(${props => (props.status === "entered" ? "0px" : "-100px")});
  max-height: ${props => (props.status === "entered" ? "400px" : "0")};

  ${media.down("MOBILE")`
    margin-left: 44px;
  `}
`;
