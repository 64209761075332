export interface Breakpoint {
  max_width?: number;
  max_height?: number;
  orientation?: "landscape" | "portrait";
  isMobile?: boolean;
}

export type BreakpointNames =
  | "MOBILE_TINY"
  | "MOBILE_SMALL"
  | "MOBILE"
  | "TABLET_SMALL"
  | "TABLET"
  | "TABLET_LARGE"
  | "LAPTOP_TINY"
  | "LAPTOP_SMALL"
  | "LAPTOP_MEDIUM"
  | "LAPTOP"
  | "LAPTOP_BIG";

type Breakpoints = {
  [key in BreakpointNames]?: Breakpoint;
};

const MOBILE_TINY: Breakpoint = {
  max_width: 359.98,
  max_height: 610.98,
  orientation: "portrait",
  isMobile: true,
};
const MOBILE_SMALL: Breakpoint = {
  max_height: 719.98,
  orientation: "portrait",
  isMobile: true,
};
const MOBILE: Breakpoint = {
  max_width: 600.98,
  max_height: 959.98,
  orientation: "portrait",
  isMobile: true,
};
const TABLET_SMALL: Breakpoint = {
  max_width: 767.98,
  // orientation: "portrait",
};
const TABLET: Breakpoint = {
  orientation: "portrait",
};
const TABLET_LARGE: Breakpoint = {
  max_width: 1199.98,
};
const LAPTOP_TINY: Breakpoint = {
  max_width: 999.98,
  max_height: 519.98,
  orientation: "landscape",
};
const LAPTOP_SMALL: Breakpoint = {
  max_width: 1151.98,
  max_height: 599.98,
  orientation: "landscape",
};
const LAPTOP_MEDIUM: Breakpoint = {
  max_width: 1439.98,
  max_height: 799.98,
  orientation: "landscape",
};
const LAPTOP: Breakpoint = {
  max_width: 1919.98,
  max_height: 999.98,
  orientation: "landscape",
};
const LAPTOP_BIG: Breakpoint = {};

export const ORDERING_BREAKPOINTS: Breakpoints = {
  MOBILE_TINY,
  MOBILE_SMALL,
  MOBILE,
  TABLET_SMALL,
  TABLET,
  LAPTOP_TINY,
  LAPTOP_SMALL,
  LAPTOP_MEDIUM,
  LAPTOP,
  LAPTOP_BIG,
};

export const DASHBOARD_BREAKPOINTS: Breakpoints = {
  TABLET,
  TABLET_LARGE,
};
