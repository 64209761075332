import { getStorageOrderDetails } from "modules/App/OrdersLayout/OrderContext/Storage/sessionStorage.helpers";

const storageOrderDetails = getStorageOrderDetails();

export const baseOrderDetails = {
  pickupDetails: {
    postcode: "",
    address: "",
    addressId: "",
    placeName: "",
    placeDetails: "",
    additionalDetails: "",
    name: "",
    phone: {
      directional: "+44",
      phoneNumber: "",
    },
  },
  dropOffDetails: {
    postcode: "",
    address: "",
    addressId: "",
    placeName: "",
    placeDetails: "",
    additionalDetails: "",
    name: "",
    isPromo: false,
    phone: {
      directional: "+44",
      phoneNumber: "",
    },
  },
};

export const storedOrderDetails = storageOrderDetails || baseOrderDetails;
