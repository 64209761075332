import React, { FunctionComponent } from "react";
import { ReactComponent as Arrow } from "modules/OrderingForm/Failure/icons/arrow.svg";
import { Transition } from "react-transition-group";
import { ArrowWrapper, PlaceArrowWrapper } from "modules/OrderingForm/Failure/PlaceArrow.components";

export type ARROW_TYPE = "top" | "bottom";

type PlaceArrowProps = {
  arrowType: ARROW_TYPE;
};

const PlaceArrow: FunctionComponent<PlaceArrowProps> = ({ children, arrowType }) => {
  return (
    <PlaceArrowWrapper>
      {children}
      <Transition timeout={400} in={true} appear>
        {status => (
          <ArrowWrapper status={status} arrowType={arrowType}>
            <Arrow />
          </ArrowWrapper>
        )}
      </Transition>
    </PlaceArrowWrapper>
  );
};

export default PlaceArrow;
