import React, { FunctionComponent } from "react";
import PackageSizeForm from "modules/OrderingForm/PackageSize/PackageSizeForm";
import Heading from "modules/shared/components/Heading";

import Hero from "modules/OrderingForm/PackageSize/icons/hero.png";
import { PackageSizeWrapper, HeroImage, MobileSpecialPriceInfo, Container } from "./PackageSize.components";
import MobileSheet from "modules/shared/components/Mobile/MobileSheet";
import { WhiteCover } from "modules/App/OrdersLayout/OrdersLayout.components";
import MobileButton from "modules/shared/components/Mobile/MobileButton";
import { useIsMobileDown } from "common/helpers/useMedia";

const PackageSize: FunctionComponent = () => {
  const isMobile = useIsMobileDown();

  return (
    <Container>
      <PackageSizeWrapper>
        <HeroImage src={Hero} />
        <Heading big>
          <b>What size</b> is your package?
        </Heading>
        <MobileSheet>
          <PackageSizeForm />
        </MobileSheet>
      </PackageSizeWrapper>
      {isMobile && <MobileButton mode="price" />}
      {isMobile && <MobileSpecialPriceInfo>Special order size?</MobileSpecialPriceInfo>}
      <WhiteCover />
    </Container>
  );
};

export default PackageSize;
