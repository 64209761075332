import React, { createContext, Dispatch, SetStateAction } from "react";
import { IOrder } from "@medlog/shared/models/orders/IOrder";
import { IOrderDetails } from "@medlog/shared/models/orders/IOrderDetails";
import { IOrderState } from "@medlog/shared/models/orders/IOrderState";
import { IOrderPrice } from "@medlog/shared/helpers/getOrderPrice";

export interface OrderContextType {
  order: IOrder;
  setOrder: Dispatch<SetStateAction<IOrder>>;

  currentOrderState: IOrderState | undefined;
  setCurrentOrderState: Dispatch<SetStateAction<IOrderState>>;

  orderDetails: IOrderDetails;
  setOrderDetails: Dispatch<SetStateAction<IOrderDetails>>;

  orderPrice: number | undefined;
  setOrderPrice: Dispatch<SetStateAction<number | undefined>>;

  localPrice: IOrderPrice | undefined;
  setLocalPrice: Dispatch<SetStateAction<IOrderPrice | undefined>>;

  setupOrder: (order: IOrder, details: IOrderDetails) => void
  clearOrder: () => void;
}

const OrderContext: React.Context<OrderContextType> = createContext<OrderContextType>({} as OrderContextType);

export default OrderContext;
