import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import {
  AllOrdersLink,
  ANIMATION_DURATION_MS,
  ArrowsImage,
  Container,
  ContentContainer,
  ElementsContainer,
  FormStep,
  HeaderArrowsContainer,
  HeaderContainer,
  HeaderContentContainter,
  HeaderRoundedBgContainer,
  HeaderTitle,
  NewOrderLink,
  OrderDetailsHeader,
  StepCompleted,
  StepCurrent,
  StepIndicator,
  StepsContainer,
  TimeContainer,
  WhatsappContainer,
} from "modules/shared/components/Sidebar/MobileSidebar/MobileSidebar.componentsy";
import Arrows from "modules/shared/components/Sidebar/MobileSidebar/arrows.png";
import IconPlus from "modules/shared/components/Sidebar/MobileSidebar/icon_plus.svg";
import CheckIcon from "modules/shared/components/Sidebar/DetailsItem/icon_check.svg";
import Sidebar from "modules/shared/components/Sidebar";
import useEventHandler from "common/eventHandlers/useEventHandler";
import { appEventTypes } from "common/events/AppEventTarget";
import { useHistory, useLocation } from "react-router";
import routesPaths from "modules/Routing/routesPaths";
import Whatsapp from "modules/shared/components/Whatsapp/Whatsapp";
import { IOrder, IOrderType } from "@medlog/shared/models/orders/IOrder";
import useOrderContext from "modules/App/OrdersLayout/OrderContext/useOrderContext";
import { useOrderTimer } from "common/helpers/timer/useOrderTimer";
import { formatTimerTime, shouldShowTimer } from "modules/shared/components/OrderTimer/OrderTimer";
import { getItemActiveIcon } from "modules/shared/components/Sidebar/DetailsItem/DetailsItem";
import SidebarSections from "modules/shared/components/Sidebar/SidebarSections";
import {
  getSidebarTitle,
  isSidebarItemActive,
  isSidebarItemChecked,
} from "modules/shared/components/Sidebar/SidebarHelpers";
import { IOrderDetails } from "@medlog/shared/models/orders/IOrderDetails";
import { IOrderState } from "@medlog/shared/models/orders/IOrderState";
import { isMobileOnly } from "react-device-detect";

const getSidebarItems = (order: IOrder) => {
  if (!order.type) return [];
  switch (order.type) {
    case IOrderType.URGENT:
      return [
        SidebarSections.DATE,
        SidebarSections.LOCATION,
        SidebarSections.PACKAGE_SIZE,
        SidebarSections.CONTACT_DETAILS,
        SidebarSections.PICKUP_DETAILS,
        SidebarSections.DROPOFF_DETAILS,
        SidebarSections.PAYMENT,
      ];
    case IOrderType.SAME_DAY:
      return [
        SidebarSections.DATE,
        SidebarSections.LOCATION,
        SidebarSections.PICKUP_DETAILS,
        SidebarSections.DROPOFF_DETAILS,
        SidebarSections.PACKAGE_SIZE,
        SidebarSections.CONTACT_DETAILS,
        SidebarSections.PAYMENT,
      ];
    case IOrderType.PRE_BOOK:
      return [
        SidebarSections.LOCATION,
        SidebarSections.DATE_AND_TIME,
        SidebarSections.PICKUP_DETAILS,
        SidebarSections.DROPOFF_DETAILS,
        SidebarSections.PACKAGE_SIZE,
        SidebarSections.CONTACT_DETAILS,
        SidebarSections.PAYMENT,
      ];
  }
};

const getSidebarItem = (item: SidebarSections, order: IOrder, details: IOrderDetails) => {
  const checked = isSidebarItemChecked(item, order, details);
  const active = isSidebarItemActive(item, location.pathname);

  if (active) {
    return (
      <FormStep key={item} hidden={false}>
        <StepCurrent>{getItemActiveIcon(item)}</StepCurrent>
      </FormStep>
    );
  } else if (checked) {
    return (
      <FormStep key={item} hidden={false}>
        <StepCompleted src={CheckIcon} />
      </FormStep>
    );
  }

  const urgentHiddenSections = [
    SidebarSections.PICKUP_DETAILS,
    SidebarSections.DROPOFF_DETAILS,
    SidebarSections.PAYMENT,
  ];
  const urgentHiddenStates = [undefined, IOrderState.CREATED, IOrderState.REJECTED, IOrderState.SAMEDAY_INSTEAD];
  const stepHidden =
    order.type === IOrderType.URGENT &&
    urgentHiddenSections.includes(item) &&
    urgentHiddenStates.includes(order.currentState);
  return (
    <FormStep key={item} hidden={stepHidden}>
      <StepIndicator />
    </FormStep>
  );
};

const shouldDisplaySteps = (pathname: string) =>
  ![routesPaths.CONFIRMATION, routesPaths.TIMESUP, routesPaths.CANCELATION].includes(pathname);

const MobileSidebar: FunctionComponent = () => {
  const { order, orderDetails } = useOrderContext();
  const location = useLocation();
  const history = useHistory();
  const [displaySteps, setDisplaySteps] = useState(shouldDisplaySteps(location.pathname));
  const [isVisible, setIsVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showScrollbar, setShowScrollbar] = useState(false);
  const [title, setTitle] = useState("");
  const time = useOrderTimer(order);
  const [prevTime, setPrevTime] = useState<number | null>(null);

  //To avoid blinking of time after pressing order and pay.
  useEffect(() => {
    if (time !== null) {
      if (prevTime && prevTime > time) setPrevTime(time);
      else setPrevTime(time);
    }
  }, [time]);

  const showTimer = useCallback(
    () => isMobileOnly && shouldShowTimer(order, location.pathname, time, true),
    [time, location, order]
  );

  useEventHandler(appEventTypes.HIDE_MOBILE_SIDEBAR_EVENT, () => {
    setIsOpen(false);
  });

  useEffect(() => {
    setShowScrollbar(false);
    setTimeout(() => {
      setShowScrollbar(true);
    }, ANIMATION_DURATION_MS);
  }, [isOpen]);

  useEffect(() => {
    setTimeout(() => {
      const title = getSidebarTitle(location.pathname);
      setTitle(title);
      setDisplaySteps(shouldDisplaySteps(location.pathname));
    }, ANIMATION_DURATION_MS);

    setIsOpen(false);
    setIsVisible(location.pathname !== routesPaths.HOME);
  }, [location.pathname, order]);

  return (
    <>
      <Container open={isOpen} showScrollbar={showScrollbar} visible={isVisible}>
        <HeaderContainer>
          <HeaderRoundedBgContainer>
            <HeaderArrowsContainer onClick={() => setIsOpen(!isOpen)}>
              <ArrowsImage open={isOpen} src={Arrows} />
            </HeaderArrowsContainer>
            {!displaySteps && (
              <OrderDetailsHeader visible={!isOpen}>
                <span>Details</span>

                <ElementsContainer>
                  <NewOrderLink
                    visible={location.pathname === routesPaths.CONFIRMATION}
                    onClick={() => history.replace(routesPaths.HOME, true)}
                  >
                    <img src={IconPlus} />
                    New order
                  </NewOrderLink>
                  <AllOrdersLink href={"/my-orders"} visible={!isOpen} target="_blank">
                    All orders
                  </AllOrdersLink>
                </ElementsContainer>
              </OrderDetailsHeader>
            )}
          </HeaderRoundedBgContainer>
          <HeaderContentContainter visible={!isOpen}>
            {displaySteps && (
              <StepsContainer>
                {getSidebarItems(order).map(item => getSidebarItem(item, order, orderDetails))}
              </StepsContainer>
            )}
          </HeaderContentContainter>

          <HeaderTitle visible={isOpen}>{title}</HeaderTitle>
        </HeaderContainer>
        <ContentContainer open={isOpen}>
          <Sidebar />
          <WhatsappContainer visible={isOpen}>
            <Whatsapp />
          </WhatsappContainer>
        </ContentContainer>
      </Container>
      <TimeContainer visible={!!showTimer()}>
        <h1>{formatTimerTime(prevTime)}</h1>
      </TimeContainer>
    </>
  );
};

export default MobileSidebar;
