import React, { FunctionComponent, useEffect } from "react";
import GlobalStyles from "modules/shared/styles/global";
import OrderingRoutingLayout from "modules/OrderingRoutingLayout/OrderingRoutingLayout";
import { Loader, LoaderOptions } from "google-maps";
import useGA from "common/analytics/useGA";

const App: FunctionComponent = () => {
  const { initialize } = useGA();

  useEffect(() => {
    const initGoogleMaps = async () => {
      const options: LoaderOptions = { libraries: ["places", "geometry"] };
      const loader = new Loader(process.env.REACT_APP_GOOGLE_MAPS_KEY, options);
      await loader.load();
    };

    initialize();
    initGoogleMaps();
  }, []);

  return (
    <>
      <GlobalStyles />
      <OrderingRoutingLayout />
    </>
  );
};

export default App;
