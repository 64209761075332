import { colors } from "@medlog/shared/styles/colors";
import { media } from "@medlog/shared/styles/media";
import styled, { css } from "styled-components/macro";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const CheckBoxContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border: none;
  cursor: pointer;
  border-radius: 7px;
  outline: none;
  background-color: white;

  :hover,
  :focus {
    background-color: ${colors.mint._150};
  }
`;

export const CheckBoxContent = styled.div<{ checked?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border-radius: 3px;
  box-sizing: border-box;
  border: 2px solid ${colors.mint._600};
  background-color: white;

  ${props =>
    props.checked &&
    css`
      background-color: ${colors.mint._600};
    `}
`;

export const TextContainer = styled.span`
  width: 312px;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: ${colors.gray._600};
  margin-left: 4px;
  margin-bottom: 16px;

  > a {
    color: ${colors.mint._600};
    font-weight: 500;
    text-decoration: none;
  }

  ${media.down("LAPTOP")`
    font-size: 12px;
    width: 296px;
    margin-bottom: 8px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    font-size: 12px;
    width: 288px;
    margin-bottom: 4px;
  `}

  ${media.down("LAPTOP_SMALL")`
    margin-bottom: 0px;
  `}

  ${media.down("TABLET")`
    margin-bottom: 16px;
  `}

  ${media.down("MOBILE")`
    width: auto;
    max-width: 76vw;
  `}
`;
