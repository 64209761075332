import { firestore } from "config/firebase/firebase";
import { IOrder, IPreBookDeliveryTime } from "@medlog/shared/models/orders/IOrder";

export const updatePrebookDetails = async (order: IOrder, date?: Date, time?: IPreBookDeliveryTime) => {
  const prebokDeliveryTime = {
    deliveryTime: time?.deliveryTime ?? null,
    timeSpecified: time?.timeSpecified,
  };
  await firestore
    .collection("orders")
    .doc(order.uid)
    .collection("details")
    .doc("preBookDelivery")
    .set(
      {
        preBookDeliveryDate: date ?? null,
        preBookDeliveryTime: prebokDeliveryTime,
      },
      { merge: true }
    );
};
