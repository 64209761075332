import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100vw;
  position: fixed;
  height: 100%;
  background: #1c1c1e;
`;
