import React, {
  ComponentPropsWithoutRef,
  FunctionComponent,
  forwardRef,
  Ref,
  FocusEvent,
  useRef,
  useEffect,
} from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import LabelInput from "./LabelInput";
import { useState } from "react";
import { InputContainer, InputField, ErrorLabel, InputWrapper, PullHandler } from "./Input.components";
import { ReactComponent as IconError } from "./icons/icon_error.svg";
import PasswordIcon from "./PasswordIcon";

export interface InputProps extends ComponentPropsWithoutRef<"input"> {
  label: string;
  multiline?: boolean;
  error?: string;
  optional?: boolean;
  disabled?: boolean;
  type: "password" | "text" | "email" | "search";
  inputSize: "small" | "smedium" | "medium" | "large" | "unset" | "mobile";
  hideErrorIcon?: boolean;
  hideErrorMessage?: boolean;
  hideEndAdornment?: boolean;
  onDone?: (value: string) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const Input: FunctionComponent<
  Omit<InputProps, "size" | "color"> & {
    forwardedRef?: Ref<HTMLInputElement>;
  }
> = ({
  autoComplete,
  forwardedRef,
  label,
  error,
  optional,
  disabled,
  type,
  inputSize,
  name,
  onBlur,
  onFocus,
  hideErrorIcon,
  hideErrorMessage,
  hideEndAdornment,
  onKeyDown,
  onDone,
  multiline,
  ...inputProps
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  return (
    <InputWrapper>
      {multiline && <PullHandler />}
      <InputContainer size={inputSize}>
        <InputField
          autoComplete={autoComplete}
          maxRows={2}
          multiline={multiline}
          hideendadornment={hideEndAdornment ? "true" : "false"}
          {...inputProps}
          inputRef={forwardedRef}
          disabled={disabled}
          type={isPasswordVisible ? "text" : type}
          onFocus={(e: FocusEvent<HTMLInputElement>) => {
            if (onFocus) onFocus(e);
            setIsFocused(true);
          }}
          onBlur={(e: FocusEvent<HTMLInputElement>) => {
            if (onBlur) onBlur!(e);
            setIsFocused(false);
          }}
          id={name}
          onKeyDown={onKeyDown}
          label={<LabelInput text={label} optional={optional} error={!!error} focused={isFocused} />}
          variant="outlined"
          error={!!error}
          InputProps={{
            autoComplete: autoComplete,
            endAdornment: (
              <InputAdornment position="end" style={{ paddingRight: 14 }}>
                {error && !hideErrorIcon && !disabled ? (
                  <IconError />
                ) : type == "password" ? (
                  <PasswordIcon visible={isPasswordVisible} setIsVisible={visible => setIsPasswordVisible(visible)} />
                ) : (
                  <div style={{ display: "none", padding: 0 }}></div>
                )}
              </InputAdornment>
            ),
          }}
        />
        <ErrorLabel>{!hideErrorMessage && error}</ErrorLabel>
      </InputContainer>
    </InputWrapper>
  );
};

export default forwardRef<HTMLInputElement, InputProps>((props, ref) => (
  <Input
    {...props}
    onKeyUp={ev => ev.key == "Enter" && props.onDone && props.onDone((ev.target as HTMLInputElement).value)}
    forwardedRef={ref}
  />
));
