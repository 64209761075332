import getOrderPrice, { IOrderPrice, getPromoPrice } from "@medlog/shared/helpers/getOrderPrice";
import { IOrder, IOrderType } from "@medlog/shared/models/orders/IOrder";
import { IOrderDetails } from "@medlog/shared/models/orders/IOrderDetails";
import getDistanceBetween from "common/helpers/getDistanceBetween";
import { History } from "history";
import { baseOrder, baseOrderDetails } from "modules/App/OrdersLayout/OrderContext/Storage";
import routesPaths from "modules/Routing/routesPaths";
import { checkPromo } from "./checkPromo";

const handleNoPickupAndDropoff = (
  history: History<unknown>,
  setupOrder: (order: IOrder, details?: IOrderDetails) => void
) => {
  const prebookOrder: IOrder = {
    ...baseOrder,
    type: IOrderType.PRE_BOOK,
    pickupAndDropoff: baseOrderDetails,
  };
  setupOrder(prebookOrder, baseOrderDetails);

  history.push(routesPaths.POSTCODES);
};

export const handleAnotherDay = async (
  order: IOrder,
  history: History<unknown>,
  setupOrder: (order: IOrder, details?: IOrderDetails) => void,
  setLocalPrice: (price: IOrderPrice) => void
) => {
  if (order.pickupAndDropoff) {
    try {
      const pickupPostcode = order.pickupAndDropoff!.pickupDetails.postcode;
      const dropoffPostcode = order.pickupAndDropoff!.dropOffDetails.postcode;
      const droppAddressId = order.pickupAndDropoff.dropOffDetails.addressId;

      let price: IOrderPrice;
      if (checkPromo(dropoffPostcode, droppAddressId)) {
        price = getPromoPrice();
      } else {
        const calculatedDistance = await getDistanceBetween(pickupPostcode, dropoffPostcode);
        price = getOrderPrice(Math.round(calculatedDistance) / 1000);
      }
      const prebookOrder: IOrder = {
        ...baseOrder,
        type: IOrderType.PRE_BOOK,
        phone: order.phone,
        email: order.email,
        parcelSize: order.parcelSize,
        pickupAndDropoff: order.pickupAndDropoff,
      };
      setupOrder(prebookOrder, order.pickupAndDropoff!);

      setLocalPrice(price);

      history.push(routesPaths.DATE);
    } catch {
      handleNoPickupAndDropoff(history, setupOrder);
    }
  } else {
    handleNoPickupAndDropoff(history, setupOrder);
  }
};
