import { IOrderPrice } from "@medlog/shared/helpers/getOrderPrice";
import { IOrder } from "@medlog/shared/models/orders/IOrder";
import { IOrderDetails } from "@medlog/shared/models/orders/IOrderDetails";

const STORAGE_ORDER_KEY = "order";
const STORAGE_ORDER_PRICE_KEY = "price";

const getStorageOrderRaw = () => {
  return sessionStorage.getItem(STORAGE_ORDER_KEY);
};

const parseDate = (field: string | undefined) => {
  if (field) {
    return new Date(field);
  } else {
    return undefined;
  }
};

const getStorageOrderParsed = () => {
  const storageOrder = getStorageOrderRaw();

  if (storageOrder) {
    const parsedOrder = JSON.parse(storageOrder);

    const preBookDeliveryTime = parsedOrder.preBookDeliveryTime
      ? {
          timeSpecified: parsedOrder.preBookDeliveryTime.timeSpecified,
          deliveryTime: parseDate(parsedOrder.preBookDeliveryTime.deliveryTime),
        }
      : undefined;

    return {
      ...parsedOrder,
      date: parseDate(parsedOrder.date),
      preBookDeliveryDate: parseDate(parsedOrder.preBookDeliveryDate),
      preBookDeliveryTime: preBookDeliveryTime,
    } as IOrder;
  }
  return null;
};

const getStorageOrderDetails = () => {
  return getStorageOrderParsed()?.pickupAndDropoff;
};

const setStorageOrder = (order: IOrder, orderDetails: IOrderDetails, orderPrice: IOrderPrice | undefined) => {
  const stringifiedOrder = JSON.stringify({ ...order, pickupAndDropoff: { ...orderDetails } });

  // Replace only when there are any changes
  if (getStorageOrderRaw() !== stringifiedOrder) {
    sessionStorage.setItem(STORAGE_ORDER_KEY, stringifiedOrder);
    if (orderPrice) sessionStorage.setItem(STORAGE_ORDER_PRICE_KEY, JSON.stringify(orderPrice));
  }
};

const removeStorageOrder = () => {
  sessionStorage.removeItem(STORAGE_ORDER_KEY);
  sessionStorage.removeItem(STORAGE_ORDER_PRICE_KEY);
};

const getStorageOrderPrice = () => {
  const storagePrice = sessionStorage.getItem(STORAGE_ORDER_PRICE_KEY);
  return storagePrice ? (JSON.parse(storagePrice) as IOrderPrice) : undefined;
};

export {
  getStorageOrderRaw,
  getStorageOrderParsed,
  getStorageOrderDetails,
  setStorageOrder,
  removeStorageOrder,
  getStorageOrderPrice,
};
