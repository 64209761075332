import styled from "styled-components";
import { colors } from "@medlog/shared/styles/colors";
import { TransitionStatus } from "react-transition-group";

export const MobileSidebarContainer = styled.div<{ status: TransitionStatus }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 80px;
  background-color: ${colors.greyscale.black};
  align-items: center;

  > .open-button {
    cursor: pointer;
    margin-top: auto;
    margin-bottom: 32px;
    transition: transform 0.3s ease;
    :hover {
      transform: scale(1.1);
    }
  }
  transition: transform 500ms ease;
  transform: translateX(${props => (props.status === "entered" ? "0" : "-100px")});
`;

export const Logo = styled.img`
  width: 48px;

  :hover {
    cursor: pointer;
    transition: transform 200ms ease;
    transform: scale(1.05);
    transform-origin: top;
  }
`;

export const AnimatedMenuContainer = styled.div<{ status: TransitionStatus }>`
  position: relative;
  width: 388px;
  height: 100%;
  z-index: 50000;
  position: absolute;
  transition: transform 500ms ease;
  transform: translateX(${props => (props.status === "entered" ? "0" : "-450px")});
`;

export const AnimatedMenuIconWrapper = styled.div`
  position: absolute;
  bottom: 32px;
  right: 21px;

  > :last-child {
    cursor: pointer;
    transition: transform 0.3s ease;
    transform: rotate(-180deg);
    :hover {
      transform: scale(1.1) rotate(-180deg);
    }
  }
`;

export const ScreenDimmer = styled.div<{ status: TransitionStatus }>`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 40000;
  transition: opacity 500ms ease;
  opacity: ${props => (props.status === "entered" ? 1 : 0)};
  pointer-events: ${props => (props.status === "entered" ? "all" : "none")};
  background: #00000044;
`;
