import { IOrder, IOrderType } from "@medlog/shared/models/orders/IOrder";
import { IOrderDetails } from "@medlog/shared/models/orders/IOrderDetails";
import { IOrderState } from "@medlog/shared/models/orders/IOrderState";
import { isSamedayDisabled, isUrgentDisabled } from "common/helpers/deliveriesHelpers";
import getOrderInitialTime from "common/helpers/timer/getOrderInitialTime";
import {
  isAvailabilityItemAllowed,
  isCancelationItemAllowed,
  isConfirmationItemAllowed,
  isContactItemAllowed,
  isCourierAvailableItemAllowed,
  isDateItemAllowed,
  isDropOffContactItemAllowed,
  isDropOffDetailsItemAllowed,
  isLocationItemAllowed,
  isPackageSizeItemAllowed,
  isPaymentItemAllowed,
  isPickupContactItemAllowed,
  isPickupDetailsItemAllowed,
  isRejectionItemAllowed,
  isRescheduleItemAllowed,
  isTimeItemAllowed,
  isTimesUpItemAllowed,
} from "common/navigation/pathsValidators";
import { urgentFormRoutes, samedayFormRoutes, prebookedFormRoutes } from "modules/Routing/routes";
import routesPaths from "modules/Routing/routesPaths";

interface RouteState {
  path: string;
  allowed: boolean;
}

type CheckItemData = {
  order: IOrder;
  details?: IOrderDetails;
};

interface AllowedPath {
  [key: string]: ((data: CheckItemData) => boolean) | undefined;
}

const validationPaths: AllowedPath = {};
validationPaths[routesPaths.HOME] = isDateItemAllowed;
validationPaths[routesPaths.POSTCODES] = isLocationItemAllowed;
validationPaths[routesPaths.SIZE] = isPackageSizeItemAllowed;
validationPaths[routesPaths.DETAILS] = isContactItemAllowed;
validationPaths[routesPaths.PICKUP] = isPickupDetailsItemAllowed;
validationPaths[routesPaths.DROPOFF] = isDropOffDetailsItemAllowed;
validationPaths[routesPaths.SUMMARY] = isPaymentItemAllowed;
validationPaths[routesPaths.AVAILABILITY] = isAvailabilityItemAllowed;
validationPaths[routesPaths.PICKUP_CONTACT] = isPickupContactItemAllowed;
validationPaths[routesPaths.DROPOFF_CONTACT] = isDropOffContactItemAllowed;
validationPaths[routesPaths.DATE] = isDateItemAllowed;
validationPaths[routesPaths.TIME] = isTimeItemAllowed;
validationPaths[routesPaths.COURIER_AVAILABLE] = isCourierAvailableItemAllowed;
validationPaths[routesPaths.CONFIRMATION] = isConfirmationItemAllowed;
validationPaths[routesPaths.CANCELATION] = isCancelationItemAllowed;
validationPaths[routesPaths.REJECTION] = isRejectionItemAllowed;
validationPaths[routesPaths.RESCHEDULE] = isRescheduleItemAllowed;
validationPaths[routesPaths.TIMESUP] = isTimesUpItemAllowed;

const urgentCreatePaths = [routesPaths.HOME, routesPaths.POSTCODES, routesPaths.SIZE, routesPaths.DETAILS];
const urgentAvailabilityPaths = [
  routesPaths.AVAILABILITY,
  routesPaths.COURIER_AVAILABLE,
  routesPaths.REJECTION,
  routesPaths.RESCHEDULE,
];
const urgentFormPaths = [
  routesPaths.PICKUP,
  routesPaths.PICKUP_CONTACT,
  routesPaths.DROPOFF,
  routesPaths.DROPOFF_CONTACT,
  routesPaths.SUMMARY,
  routesPaths.TIMESUP,
  routesPaths.CANCELATION,
];
const urgentPadiPaths = [routesPaths.CONFIRMATION];

const mapPathToRoute = (path: string, order: IOrder, details?: IOrderDetails): RouteState => {
  const allowedPathFunction = validationPaths[path];
  return {
    path: path,
    allowed: allowedPathFunction ? allowedPathFunction({ order, details }) : false,
  };
};

export const getPathsAllowState = (order: IOrder, details?: IOrderDetails) => {
  const orderRoutes =
    order.type === IOrderType.URGENT
      ? urgentFormRoutes
      : order.type === IOrderType.SAME_DAY
      ? samedayFormRoutes
      : order.type === IOrderType.PRE_BOOK
      ? prebookedFormRoutes
      : [];

  const newRoutes: RouteState[] = [{ path: routesPaths.HOME, allowed: isDateItemAllowed({ order, details }) }];

  for (const route of orderRoutes) {
    if (Array.isArray(route.path)) {
      for (const path of route.path as string[]) {
        newRoutes.push(mapPathToRoute(path, order, details));
      }
    } else if (route.path as string) {
      newRoutes.push(mapPathToRoute(route.path as string, order, details));
    }
  }

  return newRoutes;
};

const validateUrgent = (path: string, order: IOrder, details?: IOrderDetails) => {
  const availabilityStates = [
    IOrderState.CREATED,
    IOrderState.REJECTED,
    IOrderState.SAMEDAY_INSTEAD,
    IOrderState.PAYMENT_PENDING,
  ];
  const paidStates = [
    IOrderState.PAYMENT_PENDING,
    IOrderState.COPIED,
    IOrderState.PAYMENT_SUCCEEDED,
    IOrderState.COURIER_ON_THE_WAY,
    IOrderState.PARCEL_PICKED_UP,
    IOrderState.PARCEL_DELIVERED,
    IOrderState.ARCHIVED,
  ];
  const { currentState } = order;
  const data = { order, details };

  if (urgentCreatePaths.includes(path) && currentState === undefined) return validationPaths[path]!(data);
  if (urgentAvailabilityPaths.includes(path) && currentState && availabilityStates.includes(currentState))
    return validationPaths[path]!(data);
  if (urgentFormPaths.includes(path) && currentState === IOrderState.PAYMENT_PENDING)
    return validationPaths[path]!(data);
  if (urgentPadiPaths.includes(path) && currentState && paidStates.includes(currentState) && isPaymentItemAllowed(data))
    return validationPaths[path]!(data);

  return false;
};

export const isSidebarPathAllowed = (path: string, order: IOrder, details?: IOrderDetails) => {
  if (order.paymentInitialized) return false;
  const orderTime = getOrderInitialTime(order);
  if (orderTime && orderTime <= 1000) return false; //For lost orders when time expired but state is not set yet.
  const paths = getPathsAllowState(order, details);
  const findedPath = paths.find(p => p?.path === path);
  return findedPath?.allowed === true;
};

export const isPathAllowed = (path: string, order: IOrder, details?: IOrderDetails) => {
  const paths = getPathsAllowState(order, details);
  const findedPath = paths.find(p => p?.path === path);

  return findedPath?.allowed === true && order.type === IOrderType.URGENT
    ? validateUrgent(path, order, details)
    : findedPath?.allowed === true;
};

export const isDeliveriesDisabledPathAllowed = (
  path: string,
  order: IOrder,
  urgentEnabled?: boolean,
  samedayEnabled?: boolean,
  deliveriesEnabled?: boolean
) => {
  const { currentState } = order;
  const disabledAllowedPaths = [routesPaths.HOME, routesPaths.REJECTION, routesPaths.CONFIRMATION];

  if (
    order.type === IOrderType.SAME_DAY &&
    (currentState === undefined || currentState === IOrderState.CREATED) &&
    isSamedayDisabled(deliveriesEnabled, samedayEnabled) &&
    !disabledAllowedPaths.includes(path)
  )
    return false;

  if (
    order.type === IOrderType.URGENT &&
    order.rescheduled &&
    currentState === IOrderState.PAYMENT_PENDING &&
    isSamedayDisabled(deliveriesEnabled, samedayEnabled)
  ) {
    return false;
  }
  if (
    order.type === IOrderType.URGENT &&
    !order.rescheduled &&
    [undefined, IOrderState.CREATED, IOrderState.SAMEDAY_INSTEAD].includes(currentState) &&
    isUrgentDisabled(deliveriesEnabled, urgentEnabled) &&
    !disabledAllowedPaths.includes(path)
  )
    return false;
  return true;
};
