import React, { FunctionComponent } from "react";
import {
  Container,
  AddressDetails,
  Divider,
  TextContainer,
  Pointer,
  LightSpan,
} from "modules/shared/components/Sidebar/DetailsItem/Items/Items.components";
import DetailsItem from "modules/shared/components/Sidebar/DetailsItem";
import SidebarSections from "modules/shared/components/Sidebar/SidebarSections";
import routesPaths from "modules/Routing/routesPaths";
import useOrderContext from "modules/App/OrdersLayout/OrderContext/useOrderContext";
import { useHistory } from "react-router-dom";
import { Link } from "react-scroll";
import { SCROLL_DURATION } from "modules/shared/components/Sidebar/Sidebar";
import { isSidebarPathAllowed } from "common/navigation/navigationHandler";
import { isSidebarItemActive, isSidebarItemChecked } from "modules/shared/components/Sidebar/SidebarHelpers";

const LocationItem: FunctionComponent = () => {
  const { order, orderDetails } = useOrderContext();

  const history = useHistory();
  const checked = isSidebarItemChecked(SidebarSections.LOCATION, order, orderDetails);
  const active = isSidebarItemActive(SidebarSections.LOCATION, location.pathname);
  const isAllowed = isSidebarPathAllowed(routesPaths.POSTCODES, order, orderDetails);

  const handleClick = () => {
    isAllowed && history.push(routesPaths.POSTCODES);
  };

  return (
    <Link
      name={routesPaths.POSTCODES}
      to={routesPaths.POSTCODES}
      smooth={true}
      duration={SCROLL_DURATION}
      containerId="containerId"
    >
      <DetailsItem
        active={active}
        checked={checked}
        type={SidebarSections.LOCATION}
        onClick={handleClick}
        animationDisabled={!isAllowed}
      >
        <Container>
          <AddressDetails>
            <TextContainer>
              <span>From</span>
              <Pointer />
              <LightSpan>
                <b>{orderDetails.pickupDetails.postcode}</b>
              </LightSpan>
            </TextContainer>
            <span>{orderDetails.pickupDetails.address}</span>
          </AddressDetails>
          <Divider />
          <AddressDetails>
            <TextContainer>
              <span>To</span>
              <Pointer />
              <LightSpan>
                <b>{orderDetails.dropOffDetails.postcode}</b>
              </LightSpan>
            </TextContainer>
            <span>{orderDetails.dropOffDetails.address}</span>
          </AddressDetails>
        </Container>
      </DetailsItem>
    </Link>
  );
};

export default LocationItem;
