import React, { FunctionComponent, useCallback, useState } from "react";
import { makeOrderLost } from "common/firebase/orders/makeOrderLost";
import useOrderContext from "modules/App/OrdersLayout/OrderContext/useOrderContext";
import routesPaths from "modules/Routing/routesPaths";
import { useHistory } from "react-router";
import Failure from "modules/OrderingForm/Failure/Failure";
import PlaceArrow from "modules/OrderingForm/Failure/PlaceArrow";
import { handleJustToday } from "common/helpers/handleJustToday";
import HeroReschedule from "modules/OrderingForm/Failure/icons/hero_reschedule.png";

const Reschedule: FunctionComponent = () => {
  const { clearOrder, order } = useOrderContext();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const header = "We are sorry...";
  const mainButtonText = "Order courier for today";
  const description = (
    <>
      We will not be able to deliver your package within the next 90 minutes as none of our couriers are currently
      available.
      <br />
      <br />
      What’s the next-best option? Book one of our{" "}
      <strong>
        same-day deliveries <PlaceArrow arrowType="bottom">instead!</PlaceArrow>
      </strong>
    </>
  );

  const mainButttonClick = useCallback(async () => {
    setLoading(true);
    await handleJustToday(order, clearOrder, history);
    setLoading(false);
  }, [order]);

  const underlinedButtonClick = () => {
    makeOrderLost(order.uid);
    clearOrder();
    history.push(routesPaths.HOME, true);
  };

  return (
    <Failure
      configuration={{
        header,
        mainButtonText,
        description,
        mainButttonClick,
        underlinedButtonClick,
        loading,
        hero: <img src={HeroReschedule} />,
      }}
    />
  );
};

export default Reschedule;
