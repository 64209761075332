import styled, { css } from "styled-components/macro";
import { colors } from "@medlog/shared/styles/colors";
import { media } from "@medlog/shared/styles/media";
import { MobileDescription, MobileHeader } from "modules/shared/styles/mixins";

const getMobileOrderStatusSize = (big: boolean) => {
  if (big) {
    return css`
      ${media.down("MOBILE")`
        width: 26px;
        height: 26px;
        font-size: 18px;
        line-height: 18px;
      `}
      ${media.down("MOBILE_SMALL")`
        width: 24px;
        height: 24px;
        font-size: 16px;
        line-height: 16px;
      `}
      ${media.down("MOBILE_TINY")`
        width: 20px;
        height: 20px;
        font-size: 14px;
        line-height: 14px;
      `}
    `;
  } else {
    return css`
      ${media.down("MOBILE")`
          text-indent: 1000%;
          white-space: nowrap;
          overflow: hidden;
          width: 14px;
          height: 14px;
      `}
      ${media.down("MOBILE_SMALL")`
          width: 12px;
          height: 12px;
      `}
      ${media.down("MOBILE_TINY")`
          width: 10px;
          height: 10px;
      `}
    `;
  }
};

export const MobileContainer = styled.div`
  display: none;
  flex-direction: column;
  height: calc(100% - 64px);
  width: calc(100% - 64px);

  margin-top: 32px;
  margin-left: 32px;
  margin-right: 32px;
  margin-bottom: 32px;

  ${media.down("MOBILE")`
    display: flex;
  `}
`;

export const MobileDetailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;

  :first-child {
    margin-top: 0px;
  }

  > img {
    ${media.down("MOBILE")`
      width: 20px;
      height: 20px;
    `}
    ${media.down("MOBILE_SMALL")`
      width: 18px;
      height: 18px;
    `}
    ${media.down("MOBILE_TINY")`
      width: 16px;
      height: 16px;
    `}
  }

  > span {
    margin-left: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: ${colors.gray._800};

    ${MobileDescription}
  }
`;

export const MobileStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  > img {
    margin-left: 8px;
    margin-right: 8px;
    width: 67px;
  }

  ${media.down("MOBILE")`
    margin-top: 64px;
  `}

  ${media.down("MOBILE_SMALL")`
    margin-top: 52px;
  `}

  ${media.down("MOBILE_TINY")`
    margin-top: 40px;
  `}
`;

export const MobileDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const MobileOrderStateDescription = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  > h3 {
    margin: 0px;
    margin-top: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    text-align: center;
    color: ${colors.gray._800};

    ${MobileDescription}
  }

  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 160%;
    text-align: center;
    letter-spacing: 0.01em;
    color: ${colors.gray._600};
  }
`;

export const MobileStateImage = styled.div`
  align-self: center;
  > svg,
  > img {
    ${media.down("MOBILE")`
      height: 172px;    
    `}

    ${media.down("MOBILE_SMALL")`
      height: 156px;    
    `}
    
    ${media.down("MOBILE_TINY")`
      height: 128px;    
    `}
  }

  ${media.down("MOBILE")`
    margin-top: 40px;
  `}

  ${media.down("MOBILE_SMALL")`
     margin-top: 32px;
  `}

  ${media.down("MOBILE_TINY")`
  margin-top: 24px;
  `}
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  height: 100%;

  ${media.down("MOBILE")`
    display: none;
  `}
`;

export const StatusContaienr = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 922px;

  ${media.down("LAPTOP")`
    margin-top: -20px;
    width: 776px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    margin-top: -36px;
    width: 720px;
  `}

  ${media.down("LAPTOP_SMALL")`
    margin-top: -40px;
    width: 596px;
  `}

  ${media.down("TABLET")`
    margin-top: 0px;
    width: 589px;
  `}
`;

export const StatusDetails = styled.span`
  flex: 1;
  align-self: flex-start;
  text-align: center;

  font-style: normal;
  font-weight: normal;
  line-height: 160%;
  margin-top: 2px;
  text-align: center;
  letter-spacing: 0.01em;
  color: ${colors.gray._600};
  font-size: 16px;

  :nth-child(3) {
    margin-left: 20px;
  }

  :last-child {
    margin-left: 14px;
  }

  ${media.down("LAPTOP")`
    font-size: 12px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    font-size: 12px;
  `}

  ${media.down("LAPTOP_SMALL")`
    font-size: 12px;
  `}
    
  ${media.down("TABLET")`
    font-size: 12px;
  `}
`;

export const OrderStatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;

  > img {
    margin-top: -30px;

    width: 180px;

    ${media.down("LAPTOP")`
      width: 146px;
    `}

    ${media.down("LAPTOP_MEDIUM")`
      width: 132px;
    `}

    ${media.down("LAPTOP_SMALL")`
      width: 100px;
    `}

    ${media.down("TABLET")`
      width: 100px;
      margin-top: -25px;
    `}
  }
`;

export const OrderStatusInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50px;
`;

export const OrderStatus = styled.span<{ state: "present" | "past" | "future" }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 32px;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  color: white;
  transition: background-color 500ms linear;
  background-color: ${props =>
    props.state === "present" ? colors.red._500 : props.state === "past" ? colors.mint._300 : colors.gray._400};

  ${media.down("LAPTOP")`
    width: 32px;
    height: 32px;
    font-size: 18px; 
  `}

  ${media.down("LAPTOP_MEDIUM")`
    width: 26px;
    height: 26px;
    font-size: 14px; 
  `}

  ${media.down("LAPTOP_SMALL")`
    width: 24px;
    height: 24px;
    font-size: 14px;
  `}
  
  ${media.down("TABLET")`
    width: 28px;
    height: 28px;
    font-size: 16px;
    line-height: 19px;
  `}

  ${props => getMobileOrderStatusSize(props.state === "present")}
`;

export const StatusDescription = styled.div<{ selected?: boolean }>`
  font-style: normal;
  font-size: 20px;
  line-height: 19px;
  letter-spacing: -0.02em;
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  white-space: nowrap;
  margin-top: 12.5px;

  ${media.down("LAPTOP")`
    font-size: 16px;
    margin-top: 10px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    font-size: 14px;
    margin-top: 8px;
  `}


  ${media.down("LAPTOP_SMALL")`
    font-size: 12px;
    margin-top: 6px;
  `}


  ${media.down("TABLET")`
    font-size: 14px;
    line-height: 17px;
    margin-top: 8px;
  `}

  > span:nth-child(2) {
    min-height: 19px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 160%;
    margin-top: 2px;
    text-align: center;
    letter-spacing: 0.01em;
    color: ${colors.gray._600};
  }

  ${props =>
    props.selected
      ? css`
          color: ${colors.gray._800};
          font-weight: 600;
        `
      : css`
          color: ${colors.gray._600}
          font-weight: normal;
  `}
`;

export const StatusExtraInfo = styled.span``;

export const OrderStatusDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  margin-top: 10px;
`;

export const InformationsContainer = styled.div`
  display: flex;
  margin-top: 64px;
  position: relative;
  flex-direction: column;
  background: white;
  border: 1px solid #f2f5f8;
  box-sizing: border-box;
  box-shadow: 0px -4px 24px -18px rgba(58, 68, 74, 0.2), 0px 8px 24px -18px rgba(58, 68, 74, 0.2);
  border-radius: 30px;
  padding: 80px 82px 70px 82px;
  box-sizing: border-box;
  width: 902px;

  > svg,
  > img {
    position: absolute;
    top: 56px;
    right: 44px;
    width: 350px;
    height: 275px;
  }

  ${media.down("LAPTOP")`
    margin-top: 50px;
    width: 736px;
    padding: 64px 66px 50px 66px;
    > svg, > img { 
      top: 56px;
      right: 72px;
      width: 280px;
      height: 220px;
     }
  `}

  ${media.down("LAPTOP_MEDIUM")`
    margin-top: 36px;
    width: 670px;
    padding: 56px 56px 40px 56px;
    > svg, > img { 
      top: 40px;
      right: 64px;
      width: 234px;
      height: 184px;
     }
  `}


  ${media.down("LAPTOP_SMALL")`
    margin-top: 24px;
    width: 556px;
    padding: 48px 40px 30px 40px;
    > svg, > img { 
      top: 36px;
      right: 48px;
      width: 202px;
      height: 158px;
     }
  `}


  ${media.down("TABLET")`
    width: 499px;
    
    padding: 176px 64px 36px 64px;
    margin-top: 72px;

    > svg, > img {
      top: -56px;
      left: 24px;
      width: 280px;
      height: 220px;
    }
  `}
`;

export const InformationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;

  > h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: ${colors.gray._800};
    margin: 0px;
    padding: 0px;
  }

  > span {
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 140%;
    letter-spacing: -0.01em;
    color: ${colors.gray._600};

    > b {
      color: ${colors.greyscale.black};
      font-weight: 600;
    }

    > a {
      color: ${colors.mint._600};
      font-weight: 500;
    }

    :nth-child(2) {
      margin: 16px 0px;

      ${media.down("MOBILE")`
        margin-top: 16px;
        margin-bottom: 32px;
      `}

      ${media.down("MOBILE_SMALL")`
        margin-top: 12px;
        margin-bottom: 28px;
      `}

      ${media.down("MOBILE_TINY")`
        margin-top: 8px;
        margin-bottom: 24px;
      `}
    }
  }

  ${media.down("LAPTOP")`
    width: 330px;
    > h1 { font-size: 38px; }
    > span { font-size: 18px; }
  `}

  ${media.down("LAPTOP_MEDIUM")`
    width: 280px;
    > h1 { font-size: 30px; }
    > span { font-size: 16px; }
  `}


  ${media.down("LAPTOP_SMALL")`
    width: 230px;
    > h1 { font-size: 22px; }
    > span { font-size: 14px; }
  `}

  ${media.down("TABLET")`
    width: 361px;
    > h1 { font-size: 38px; max-width: 338px; }
    > span { font-size: 18px; max-width: 357px; }
  `}

  > h1 {
    ${MobileHeader}

    ${media.down("MOBILE")`
      width: 260px;
    `}
    ${media.down("MOBILE_SMALL")`
      width: 224px;
    `}
    ${media.down("MOBILE_TINY")`
      width: 204px;
    `}
  }

  ${media.down("MOBILE")`
    > span {
      width: 256px;
    }
  `}
  ${media.down("MOBILE_SMALL")`
    > span {
      width: 276px;
    }
  `}
  ${media.down("MOBILE_TINY")`
    > span {
      width: 292px;
    }
  `}

  > span {
    ${MobileDescription}
  }
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 40px;

  ${media.down("LAPTOP_MEDIUM")`
    margin-top: 24px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    margin-top: 16px;
  `}

  ${media.down("TABLET")`
    flex-direction: column;
    width: 297px;
  `}
`;

export const DetailsBox = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.white};
  border: 1px solid ${colors.gray._300};
  box-sizing: border-box;
  border-radius: 15px;
  padding: 20px 40px;
  align-items: center;
  justify-content: center;
  text-align: center;

  :nth-child(even) {
    margin-left: 16px;

    ${media.down("TABLET")`
      flex-direction: column;
      margin-top: 16px;
      margin-left: 0;
    `}
  }

  h2 {
    font-size: 24px;
  }

  > h3 {
    margin: 0px;
    padding: 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 120%;
    text-align: center;
    letter-spacing: -0.02em;
    color: ${colors.gray._800};
  }

  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    text-align: center;
    color: ${colors.gray._600};
    margin-top: 1px;
    white-space: nowrap;
  }

  ${media.down("LAPTOP")`
    padding: 16px 32px;
    h2 { font-size: 20px; }
    > h3 { font-size: 20px; }
    > span { font-size: 12px; }
  `}

  ${media.down("LAPTOP_MEDIUM")`
    padding: 16px 32px;
    h2 { font-size: 18px; }
    > h3 { font-size: 18px; }
    > span { font-size: 12px; }
  `}


  ${media.down("LAPTOP_SMALL")`
   padding: 12px 32px;
   h2 { font-size: 14px; }
    > h3 { font-size: 14px; }
    > span { font-size: 12px; }
  `}

  ${media.down("TABLET")`
    padding: 12px 24px;
    h2 { font-size: 16px; }
    > h3 { font-size: 16px; }
    > span { font-size: 12px; }
  `}
`;
