import { colors } from "@medlog/shared/styles/colors";
import { media } from "@medlog/shared/styles/media";
import { MobileDescription } from "modules/shared/styles/mixins";
import styled from "styled-components/macro";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${colors.gray._200};

  ${media.down("MOBILE")`
    padding-bottom: 20px;
    margin-top: 20px;
  `}

  ${media.down("MOBILE_SMALL")`
    padding-bottom: 15px;
    margin-top: 15px;
  `}

  ${media.down("MOBILE_TINY")`
    padding-bottom: 10px;
    margin-top: 10px;
  `}

  :first-child {
    margin-top: 0px;
  }

  :last-child {
    border-bottom: none;
  }
`;

export const Header = styled.h1`
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: ${colors.gray._800};
  margin: 0px;
  margin-bottom: 8px;
  ${MobileDescription}
`;
