import React, { FunctionComponent, useCallback } from "react";
import {
  AdditionalDetailWrapper,
  AdditionalInformationsContainer,
  AdditionalPriceContainer,
  PriceOuterWrapper,
  PriceWrapper,
  AdditionalInformationWrapper,
  AdditionalLocationWrapper,
  AnimatedAdditionalInformations,
  TimerContainer,
  TimeDivider,
  TimeWrapper,
  TimeText,
} from "modules/shared/components/Sidebar/TabletSidebar/AdditionalInformations/AdditionalInformations.components";
import useOrderContext from "modules/App/OrdersLayout/OrderContext/useOrderContext";
import { IOrder, IOrderType, IPreBookDeliveryTime } from "@medlog/shared/models/orders/IOrder";
import { UrgentLabel } from "modules/shared/components/Sidebar/DetailsItem/DetailsItem.components";
import { format } from "date-fns";
import { Transition } from "react-transition-group";
import { getMinPromoPrice, getOrderPriceDescription } from "@medlog/shared/helpers/getOrderPrice";
import { useOrderTimer } from "common/helpers/timer/useOrderTimer";
import { formatTimerTime, shouldShowTimer } from "modules/shared/components/OrderTimer/OrderTimer";
import { formatAsLondonTime } from "@medlog/shared/helpers/formatAsLondonTime";

export const getPrebookTime = (time?: IPreBookDeliveryTime) => {
  if (!time) return "";
  return time.timeSpecified && time.deliveryTime ? formatAsLondonTime(time.deliveryTime) : "8 AM - 8 PM";
};

const getTimeDetail = (order: IOrder) => {
  switch (order.type) {
    case IOrderType.URGENT:
      return <UrgentLabel className="urgent-label">Urgent</UrgentLabel>;
    case IOrderType.SAME_DAY:
      return (
        <AdditionalDetailWrapper>
          <span>Time:</span>
          <span>8 am - 8 pm</span>
        </AdditionalDetailWrapper>
      );
    case IOrderType.PRE_BOOK:
      return getPrebookTime(order.preBookDeliveryTime) ? (
        <AdditionalDetailWrapper>
          <span>Time:</span>
          <span>{getPrebookTime(order.preBookDeliveryTime)}</span>
        </AdditionalDetailWrapper>
      ) : null;
    default:
      return null;
  }
};

const getDateDetail = (order: IOrder) => {
  const date = order.type === IOrderType.PRE_BOOK ? order.preBookDeliveryDate : order.date;
  return date ? (
    <AdditionalDetailWrapper>
      <span>Date:</span>
      <span>{format(date || new Date(), "MMMM do")}</span>
    </AdditionalDetailWrapper>
  ) : null;
};

const AdditionalInformations: FunctionComponent = () => {
  const { order, orderPrice, orderDetails } = useOrderContext();
  const time = useOrderTimer(order);
  const orderDetailsFilled = orderDetails.pickupDetails.postcode && orderDetails.dropOffDetails.postcode;

  const getOrderPrice = () => {
    if (orderPrice) {
      return (orderPrice / 100).toFixed(2);
    } else if (order.type != IOrderType.PRE_BOOK) {
      return getMinPromoPrice(order.type!);
    } else {
      return getMinPromoPrice(IOrderType.SAME_DAY);
    }
  };

  const showTimer = useCallback(() => shouldShowTimer(order, location.pathname, time), [time, location, order]);

  return (
    <Transition timeout={100} in={order.type !== undefined}>
      {status => (
        <AnimatedAdditionalInformations status={status}>
          <AdditionalInformationsContainer>
            <AdditionalInformationWrapper>
              {getDateDetail(order)}
              {getTimeDetail(order)}
              <Transition timeout={20} in={!!orderDetailsFilled}>
                {status => (
                  <AdditionalLocationWrapper status={status}>
                    <AdditionalDetailWrapper>
                      <span>From:</span>
                      <span>{orderDetails.pickupDetails.postcode}</span>
                    </AdditionalDetailWrapper>
                    <AdditionalDetailWrapper>
                      <span>To:</span>
                      <span>{orderDetails.dropOffDetails.postcode}</span>
                    </AdditionalDetailWrapper>
                  </AdditionalLocationWrapper>
                )}
              </Transition>
            </AdditionalInformationWrapper>
            <AdditionalPriceContainer>
              <PriceOuterWrapper>
                <PriceWrapper>
                  {!orderPrice && <span>from</span>}
                  <h3>£{getOrderPrice()}</h3>
                </PriceWrapper>
                <span>{getOrderPriceDescription(orderPrice)}</span>
              </PriceOuterWrapper>
            </AdditionalPriceContainer>
            {
              <TimerContainer visible={showTimer() == true}>
                <TimeDivider />
                <TimeWrapper>
                  <TimeText warning={time ? time < 120 : false}>{formatTimerTime(time)}</TimeText>
                  <span>Time Left</span>
                </TimeWrapper>
              </TimerContainer>
            }
          </AdditionalInformationsContainer>
        </AnimatedAdditionalInformations>
      )}
    </Transition>
  );
};

export default AdditionalInformations;
