import React, { ComponentPropsWithoutRef, Dispatch, FunctionComponent, SetStateAction, useState } from "react";
import { Field, FieldProps } from "formik";
import { DirectionalInput } from "modules/shared/components/PhoneInput/PhoneInput.components";

interface PhoneInputProps extends ComponentPropsWithoutRef<"input"> {
  setError: Dispatch<SetStateAction<string>>;
  setFocused: Dispatch<SetStateAction<boolean>>;
}

const clearedDirectional = (value: string, cursorPosition: number): string => {
  return value
    .split("")
    .filter((char, index) => index !== cursorPosition - 1)
    .join("");
};

const isDirectionalAllowed = (value: string): boolean => {
  return /^([0-9]{0,4})$/.test(value);
};

const normalizeDirectionalInput = (e: React.ChangeEvent<HTMLInputElement>) => {
  // eslint-disable-next-line prefer-const
  let cursorPosition = e.target.selectionStart;
  // eslint-disable-next-line prefer-const
  let cursorEndPosition = e.target.selectionEnd;
  const input = e.target.value;
  if (input == "+") {
    e.target.value = "";
  } else if (e.target.value.startsWith("+")) {
    const number = input.substr("+".length);
    if (isDirectionalAllowed(number)) {
      e.target.value = "+" + number;
    } else {
      e.target.value = clearedDirectional(e.target.value, cursorPosition!);
      e.target.selectionStart! = --cursorPosition!;
      e.target.selectionEnd! = --cursorEndPosition!;
    }
  } else {
    if (isDirectionalAllowed(input)) {
      e.target.value = "+" + input;
    } else if (!input.startsWith("+")) {
      if (input.substr(1).startsWith("+")) {
        e.target.value = input.substr(1);
      } else {
        e.target.value = "+" + input.substr(1);
      }
    } else {
      e.target.value = "+" + clearedDirectional(e.target.value, cursorPosition!);
      e.target.selectionStart! = --cursorPosition!;
      e.target.selectionEnd! = --cursorEndPosition!;
    }
  }
};

const DirectionalInputField: FunctionComponent<PhoneInputProps> = ({
  placeholder,
  type,
  disabled,
  setError,
  setFocused,
  ...fieldProps
}: PhoneInputProps) => {
  return (
    <Field {...fieldProps}>
      {({ field, meta }: FieldProps) => {
        const [touched, setTouched] = useState(false);
        touched && setError(meta.error ? meta.error : "");
        return (
          <>
            <DirectionalInput
              onPaste={e => e.preventDefault()}
              {...field}
              autoComplete="tel-country-code"
              type={type}
              placeholder={placeholder}
              disabled={disabled}
              onChange={e => {
                normalizeDirectionalInput(e);
                field.onChange(e);
              }}
              onFocus={() => {
                setFocused(true);
              }}
              onBlur={e => {
                field.onBlur(e);
                setFocused(false);
                setTouched(true);
              }}
            />
          </>
        );
      }}
    </Field>
  );
};

export default DirectionalInputField;
