import React, { FunctionComponent, useCallback } from "react";
import { Formik, FormikHelpers } from "formik";
import { ContactDetailsFormValues, contactDetailsValidationSchema } from "modules/OrderingForm/ContactDetails/form";
import ContactDetailsFormContent from "modules/OrderingForm/ContactDetails/ContactDetailsFormContent";
import useOrderContext from "modules/App/OrdersLayout/OrderContext/useOrderContext";
import { useHistory } from "react-router-dom";
import routesPaths from "modules/Routing/routesPaths";
import createBaseOrder from "common/firebase/orders/createBaseOrder";
import updateOrderDetails from "common/firebase/orders/updateOrderDetails";
import { IOrder, IOrderType } from "@medlog/shared/models/orders/IOrder";
import { NON_EXISTING_ID } from "modules/App/OrdersLayout/OrderContext/Storage/baseOrder";
import { updateOrderBaseDetails } from "common/firebase/orders/updateOrderBaseDetails";
import { IOrderDetails } from "@medlog/shared/models/orders/IOrderDetails";
import { updatePrebookDetails } from "common/firebase/orders/updatePrebookDetails";
import { trackOrderScreenAction } from "common/analytics/trackOrderingFormEvent";

const createOrUpdateOrder = async (order: IOrder, details: IOrderDetails) => {
  if (order.uid === NON_EXISTING_ID) {
    const newOrder = await createBaseOrder(order);
    await updateOrderDetails(newOrder.uid, details);
    if (order.type === IOrderType.PRE_BOOK) {
      await updatePrebookDetails(order, order.preBookDeliveryDate, order.preBookDeliveryTime);
    }
    return newOrder;
  } else {
    await updateOrderBaseDetails(order, { phone: order.phone, email: order.email });
    return order;
  }
};

const ContactDetailsForm: FunctionComponent = () => {
  const { order, setOrder, orderDetails, setCurrentOrderState } = useOrderContext();
  const history = useHistory();

  const handleNavigation = () => {
    if (order.type == IOrderType.URGENT) {
      history.push(routesPaths.AVAILABILITY);
    } else {
      history.push(routesPaths.SUMMARY);
    }
  };

  const handleSubmit = useCallback(
    async (values: ContactDetailsFormValues, actions: FormikHelpers<ContactDetailsFormValues>) => {
      if (!values.termsAndPrivacyAccepted) return;

      actions.setSubmitting(true);

      const updatedOrder: IOrder = {
        ...order,
        termsAndPrivacyAccepted: values.termsAndPrivacyAccepted,
        email: values.email,
        phone: {
          directional: values.phone.directional,
          phoneNumber: values.phone.phoneNumber,
        },
      };

      try {
        const newOrder = await createOrUpdateOrder(updatedOrder, orderDetails);

        if (order.uid === NON_EXISTING_ID) {
          trackOrderScreenAction("contact-details-entered", newOrder);
        }
        setOrder({ ...orderDetails, ...newOrder });
        setCurrentOrderState(newOrder.currentState!);
        handleNavigation();
      } catch (error) {
        console.log(error);
      } finally {
        actions.setSubmitting(false);
      }
    },
    []
  );

  return (
    <Formik
      initialValues={{
        email: order.email,
        phone: { directional: order.phone.directional, phoneNumber: order.phone.phoneNumber },
        termsAndPrivacyAccepted: order.termsAndPrivacyAccepted,
      }}
      validationSchema={contactDetailsValidationSchema}
      onSubmit={handleSubmit}
    >
      {formProps => <ContactDetailsFormContent {...formProps} />}
    </Formik>
  );
};

export default ContactDetailsForm;
