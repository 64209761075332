import OrdersLayout from "modules/App/OrdersLayout/OrdersLayout";
import ContactDetails from "modules/OrderingForm/ContactDetails/ContactDetails";
import OrderSummary from "modules/OrderingForm/Summary/OrderSummary";
import Storybook from "modules/Storybook/Storybbook";
import Postcodes from "modules/OrderingForm/Postcodes/Postcodes";
import PackageSize from "modules/OrderingForm/PackageSize/PackageSize";
import CheckingAvailability from "modules/OrderingForm/CheckingAvailability/CheckingAvailability";
import DeliveryDetails from "modules/OrderingForm/DeliveryDetails/DeliveryDetails";
import Confirmation from "modules/OrderingForm/Confirmation/Confirmation";
import routesPaths from "modules/Routing/routesPaths";
import Cancelation from "modules/OrderingForm/Cancelation/Cancelation";

import DeliveryDate from "modules/OrderingForm/DeliveryDate/DeliveryDate";
import DeliveryTime from "modules/OrderingForm/DeliveryTime/DeliveryTime";
import CourierAvailable from "modules/OrderingForm/Summary/CourierAvailable";

import Reschedule from "modules/OrderingForm/Failure/Reschedule";
import Rejection from "modules/OrderingForm/Failure/Rejection";
import TimesUp from "modules/OrderingForm/TimesUp/TimesUp";

import Error404 from "@medlog/shared/components/Error404/Error404";

export interface RouteConfig {
  path: string | string[];
  exact: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any;
}

export const routes: RouteConfig[] = [
  { exact: false, path: routesPaths.HOME, component: OrdersLayout },
  { exact: true, path: "/storybook", component: Storybook },
  { exact: true, path: routesPaths.ERROR404, component: Error404 },
];

// Ordering routes
const POSTCODES = { exact: true, path: routesPaths.POSTCODES, component: Postcodes };
const SIZE = { exact: true, path: routesPaths.SIZE, component: PackageSize };
const DETAILS = { exact: true, path: routesPaths.DETAILS, component: ContactDetails };
const AVAILABILITY = { exact: true, path: routesPaths.AVAILABILITY, component: CheckingAvailability };
const DELIVERY = {
  exact: true,
  path: [routesPaths.PICKUP, routesPaths.PICKUP_CONTACT, routesPaths.DROPOFF, routesPaths.DROPOFF_CONTACT],
  component: DeliveryDetails,
};
const DELIVERY_DATE = { exact: true, path: routesPaths.DATE, component: DeliveryDate };
const DELIVERY_TIME = { exact: true, path: routesPaths.TIME, component: DeliveryTime };
const SUMMARY = { exact: true, path: routesPaths.SUMMARY, component: OrderSummary };
const CONFIRMATION = { exact: true, path: routesPaths.CONFIRMATION, component: Confirmation };
const CANCELATION = { exact: true, path: routesPaths.CANCELATION, component: Cancelation };
const RESCHEDULE = { exact: true, path: routesPaths.RESCHEDULE, component: Reschedule };
const REJECTION = { exact: true, path: routesPaths.REJECTION, component: Rejection };
const TIMESUP = { exact: true, path: routesPaths.TIMESUP, component: TimesUp };
const COURIER_AVAILABLE = { exact: true, path: routesPaths.COURIER_AVAILABLE, component: CourierAvailable };

export const urgentFormRoutes: RouteConfig[] = [
  POSTCODES,
  SIZE,
  DETAILS,
  DELIVERY,
  CANCELATION,
  SUMMARY,
  CONFIRMATION,
  RESCHEDULE,
  REJECTION,
  COURIER_AVAILABLE,
  TIMESUP,
  AVAILABILITY,
];

export const samedayFormRoutes: RouteConfig[] = [
  POSTCODES,
  DELIVERY,
  SIZE,
  DETAILS,
  SUMMARY,
  CANCELATION,
  CONFIRMATION,
  REJECTION,
  TIMESUP,
];

export const prebookedFormRoutes: RouteConfig[] = [
  POSTCODES,
  DELIVERY_DATE,
  DELIVERY_TIME,
  DELIVERY,
  SIZE,
  DETAILS,
  SUMMARY,
  CANCELATION,
  CONFIRMATION,
  REJECTION,
  TIMESUP,
];
