import React from 'react';
import { InputLabelMain, InputLabelOptional } from './Input.components';

type LabelInputProps = {
  text: string;
  error?: boolean;
  optional?: boolean;
  focused: boolean;
};

const LabelInput = ({ text, optional, error, focused }: LabelInputProps) => {
  return (
    <>
      <InputLabelMain error={error} focused={focused}>
        {text}
      </InputLabelMain>
      {optional && (
        <InputLabelOptional error={error} focused={focused}>
          {' '}
          (optional)
        </InputLabelOptional>
      )}
    </>
  );
};

export default LabelInput;
