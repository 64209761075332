import React, { useCallback } from "react";
import { Formik } from "formik";
import useOrderContext from "modules/App/OrdersLayout/OrderContext/useOrderContext";
import { useHistory } from "react-router";
import routesPaths from "modules/Routing/routesPaths";
import { PackageSizeFormValues, packageSizeValidationSchema } from "modules/OrderingForm/PackageSize/form";
import PackageSizeFormContent from "modules/OrderingForm/PackageSize/PackageSizeFormContent";
import { NON_EXISTING_ID } from "modules/App/OrdersLayout/OrderContext/Storage/baseOrder";
import { updateOrderBaseDetails } from "common/firebase/orders/updateOrderBaseDetails";

const PackageSizeForm = () => {
  const history = useHistory();
  const { order, setOrder } = useOrderContext();

  const handleSubmit = useCallback(
    async (values: PackageSizeFormValues) => {
      try {
        const newOrder = { ...order, parcelSize: values.size };
        if (order.uid !== NON_EXISTING_ID) {
          await updateOrderBaseDetails(newOrder, { size: values.size });
        }
        setOrder(newOrder);
        history.push(routesPaths.DETAILS);
      } catch (error) {
        console.log(error);
      }
    },
    [order]
  );

  return (
    <Formik
      initialValues={{ size: order.parcelSize }}
      validationSchema={packageSizeValidationSchema}
      onSubmit={handleSubmit}
    >
      {formProps => <PackageSizeFormContent {...formProps} />}
    </Formik>
  );
};

export default PackageSizeForm;
