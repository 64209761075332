import * as Yup from "yup";
import IPhone from "@medlog/shared/models/common/IPhone";

export interface PickupContactFormValues {
  contactName: string;
  email?: string;
  phone: IPhone;
}

export const pickupContactInitialValues: PickupContactFormValues = {
  contactName: "",
  email: "",
  phone: {
    directional: "+44",
    phoneNumber: "",
  },
};

export const pickupContactValidationSchema = Yup.object().shape({
  contactName: Yup.string().required("Contact name is required"),
  email: Yup.string().optional().default("").email("E-mail must be valid"),
  phone: Yup.object().shape({
    directional: Yup.string()
      .required("Directional number is required")
      .max(5)
      .matches(/^[+][0-9]{2,4}$/, "Directional number is wrong"),
    phoneNumber: Yup.string()
      .required("Phone number is required")
      .min(6, "Phone number is wrong")
      .max(11, "Phone number is wrong"),
  }),
});
