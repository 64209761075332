import { IPreBookDeliveryTime } from "@medlog/shared/models/orders/IOrder";
import * as Yup from "yup";

export interface OrderTimeFormValues {
  preBookDeliveryTime: IPreBookDeliveryTime | undefined;
}

export const deliveryTimeValidationSchema = Yup.object().shape({
  preBookDeliveryTime: Yup.object().shape({
    timeSpecified: Yup.boolean().required(),
    deliveryTime: Yup.date().optional(),
  }),
});
