const IMPERIAL_BASIS = 1.609344;

const getDistanceBetween = (pickupAddress: string, dropoffAddress: string): Promise<number> => {
  return new Promise((resolve, reject) => {
    const distanceMatrixService = new google.maps.DistanceMatrixService();
    distanceMatrixService.getDistanceMatrix(
      {
        origins: [pickupAddress],
        destinations: [dropoffAddress],
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.IMPERIAL,
      },
      (response, status) => {
        const element = response.rows[0].elements[0];
        if (
          status === google.maps.DistanceMatrixStatus.OK &&
          element.status !== google.maps.DistanceMatrixElementStatus.NOT_FOUND
        )
          // It's possible to get estimated time between points, v2 suggestion to resolve as google.maps.Distance object?
          resolve(response.rows[0].elements[0].distance.value / IMPERIAL_BASIS);
        else return reject(-1);
      }
    );
  });
};

export default getDistanceBetween;
