import React from "react";
import {
  Container,
  HeaderContainer,
  HeaderTitle,
  IconContainer,
  PointerIcon,
  UrgentLabel,
  Content,
} from "modules/shared/components/Sidebar/DetailsItem/DetailsItem.components";
import { ReactComponent as IconCheck } from "modules/shared/components/Sidebar/DetailsItem/icon_check.svg";
import { FunctionComponent } from "react";
import SidebarSections from "modules/shared/components/Sidebar/SidebarSections";
import { Transition } from "react-transition-group";
import { useState } from "react";
import useOrderContext from "modules/App/OrdersLayout/OrderContext/useOrderContext";
import { IOrderState } from "@medlog/shared/models/orders/IOrderState";
import routesPaths from "modules/Routing/routesPaths";

import { ReactComponent as IconContact } from "modules/shared/components/Sidebar/DetailsItem/icons/contact.svg";
import { ReactComponent as IconDate } from "modules/shared/components/Sidebar/DetailsItem/icons/date.svg";
import { ReactComponent as IconDropoff } from "modules/shared/components/Sidebar/DetailsItem/icons/dropoff.svg";
import { ReactComponent as IconLocation } from "modules/shared/components/Sidebar/DetailsItem/icons/location.svg";
import { ReactComponent as IconPayment } from "modules/shared/components/Sidebar/DetailsItem/icons/payment.svg";
import { ReactComponent as IconPickup } from "modules/shared/components/Sidebar/DetailsItem/icons/pickup.svg";
import { ReactComponent as IconSize } from "modules/shared/components/Sidebar/DetailsItem/icons/size.svg";

type DetailsItemProps = {
  animationDisabled?: boolean;
  enableScaling?: boolean;
  checked: boolean;
  active: boolean;
  type: SidebarSections;
  showBadge?: boolean;
  children?: React.ReactNode | undefined;
  onClick: (arg: SidebarSections) => void;
  forceDisplayContent?: boolean;
};

const getTitle = (type: SidebarSections) => {
  switch (type) {
    case SidebarSections.DATE:
      return "Date";
    case SidebarSections.DATE_AND_TIME:
      return "Date and time";
    case SidebarSections.LOCATION:
      return "Location";
    case SidebarSections.PACKAGE_SIZE:
      return "Package size";
    case SidebarSections.CONTACT_DETAILS:
      return "Contact details";
    case SidebarSections.PICKUP_DETAILS:
      return "Pick-up details";
    case SidebarSections.DROPOFF_DETAILS:
      return "Drop-off details";
    case SidebarSections.PAYMENT:
      return "Payment";
  }
};

export const getItemActiveIcon = (type: SidebarSections) => {
  switch (type) {
    case SidebarSections.DATE:
      return <IconDate />;
    case SidebarSections.DATE_AND_TIME:
      return <IconDate />;
    case SidebarSections.LOCATION:
      return <IconLocation />;
    case SidebarSections.PACKAGE_SIZE:
      return <IconSize />;
    case SidebarSections.CONTACT_DETAILS:
      return <IconContact />;
    case SidebarSections.PICKUP_DETAILS:
      return <IconPickup />;
    case SidebarSections.DROPOFF_DETAILS:
      return <IconDropoff />;
    case SidebarSections.PAYMENT:
      return <IconPayment />;
    default:
      throw `Incorrect sidebar section: ${type}`;
  }
};

export const getIcon = (checked: boolean, active: boolean, type: SidebarSections) => {
  if (active) {
    return getItemActiveIcon(type);
  } else if (checked) {
    return <IconCheck />;
  } else {
    return <PointerIcon />;
  }
};

const DetailsItem: FunctionComponent<DetailsItemProps> = ({
  onClick,
  children,
  active,
  checked,
  type,
  showBadge,
  animationDisabled,
  enableScaling,
  forceDisplayContent = false,
}) => {
  const [hovered, setHovered] = useState(false);
  const { order } = useOrderContext();

  const enabled = [
    undefined,
    IOrderState.CREATED,
    IOrderState.PAYMENT_PENDING,
    IOrderState.SAMEDAY_INSTEAD,
    IOrderState.REJECTED,
  ].includes(order.currentState);

  const disabled = location.pathname === routesPaths.RESCHEDULE || location.pathname === routesPaths.REJECTION;

  return (
    <Container
      disabled={!enabled || disabled}
      checked={checked}
      onClick={() => {
        //Disable onCLick for hovered state if animations are disabled.
        if (!enableScaling && (hovered || !animationDisabled)) {
          onClick(type);
        }
      }}
      animationDisabled={animationDisabled}
      enableScaling={enableScaling}
    >
      <HeaderContainer
        onClick={() => {
          if (enableScaling) {
            onClick(type);
          }
        }}
        active={active}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <IconContainer checked={!active && checked}>{getIcon(checked, active, type)}</IconContainer>
        <HeaderTitle active={active} checked={checked}>
          {getTitle(type)}
        </HeaderTitle>
        {showBadge && !order.rescheduled && <UrgentLabel>Urgent</UrgentLabel>}
      </HeaderContainer>
      <Transition timeout={100} in={checked || forceDisplayContent} appear unmountOnExit mountOnEnter>
        {status => <Content status={status}>{children}</Content>}
      </Transition>
    </Container>
  );
};

export default DetailsItem;
