import { IOrder } from "@medlog/shared/models/orders/IOrder";
import { IOrderDetails } from "@medlog/shared/models/orders/IOrderDetails";
import { firestore } from "config/firebase/firebase";
import { NON_EXISTING_ID } from "modules/App/OrdersLayout/OrderContext/Storage/baseOrder";

export const updateDetailsIfOrderExists = async (order: IOrder, details: IOrderDetails) => {
  if (order.uid !== NON_EXISTING_ID) {
    await firestore.collection("orders").doc(order.uid).collection("details").doc("pickupAndDropoff").update(details);
  }
};
