import styled from "styled-components/macro";
import { media } from "@medlog/shared/styles/media";
import { colors } from "@medlog/shared/styles/colors";
import { HeadingContent } from "modules/shared/components/Heading/Heading.components";
import { GetHeroSize } from "modules/shared/styles/mixins";

export const RadioButton = styled.div<{ selected: boolean }>`
  width: 18px;
  height: 18px;
  border-radius: 9999px;
  box-sizing: border-box;
  border: ${p => (p.selected ? 5 : 1.5)}px solid ${p => (p.selected ? colors.mint._500 : colors.gray._400)};
  margin-top: 6px;
  position: relative;
  transition: all 0.1s ease-in-out;

  ${media.down("LAPTOP")`
    height: 16px;
    width: 16px;
  `}

  ${media.down("MOBILE")`
    height: 20px;
    width: 20px;
  `}
`;

export const RadioContainer = styled.button<{ selected: boolean }>`
  margin-bottom: 12px;
  display: flex;
  padding: 0;
  margin: 0;
  margin-bottom: 12px;
  gap: 8px;
  box-sizing: border-box;
  background-color: transparent;
  border: 0px;
  cursor: pointer;
  outline: none;
  text-align: left;

  :hover ${RadioButton}, :focus ${RadioButton} {
    border-color: ${p => (p.selected ? colors.mint._400 : colors.mint._500)};
    border-width: ${p => (p.selected ? 5 : 1.5)}px;
  }
`;

export const RadioTitle = styled.span`
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  margin-top: 2px;
  color: ${colors.gray._700};

  ${media.down("LAPTOP")`
    font-size: ${16}px;
    margin-top: 0px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    margin-top: 3px;
    font-size: ${14}px;
  `}

  ${media.down("LAPTOP_SMALL")`
    font-size: ${14}px;
  `}

  ${media.down("TABLET")`
    font-size: ${16}px;
  `}

  ${media.down("MOBILE")`
    font-size: ${16}px;
  `}
`;

export const PromoSpan = styled.span`
  padding: 2px 8px;
  background: ${colors.mint._150};
  border-radius: 100px;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: ${colors.mint._700};
`;

export const RadioDescription = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  letter-spacing: 0.01em;
  color: ${colors.gray._700};

  ${media.down("LAPTOP")`
    font-size: ${16}px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    font-size: ${14}px;
  `}

  ${media.down("LAPTOP_SMALL")`
    font-size: ${14}px;
  `}

  ${media.down("TABLET")`
    font-size: ${16}px;
  `}

  ${media.down("MOBILE")`
    font-size: ${16}px;
  `}
`;

export const RadioPromoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const RadioHeaderContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

export const PostcodeInputsContainer = styled.div`
  display: "flex";
  flex-direction: "column";

  ${media.down("MOBILE")`
    margin: 0px 18px;    
  `}
`;

export const PostcodeSectionWrapper = styled.div<{
  smallerMargin?: boolean;
  show: boolean;
  animationDisabled: boolean;
}>`
  margin-top: ${p => (p.smallerMargin ? 0 : 40)}px;

  ${p => media.down("LAPTOP")`
      margin-top: ${p.smallerMargin ? 0 : 32}px;
  `};

  ${p => media.down("LAPTOP_MEDIUM")`
      margin-top: ${p.smallerMargin ? 0 : 20}px;
  `};

  ${p => media.down("LAPTOP_SMALL")`
      margin-top: ${p.smallerMargin ? 0 : 20}px;
  `};

  ${p => media.down("TABLET")`
      margin-top: ${p.smallerMargin ? 0 : 32}px;
  `};

  ${p => media.down("MOBILE")`
    margin-top: ${p.smallerMargin ? 0 : 16}px;
  `};

  ${props =>
    !props.animationDisabled &&
    media.down("MOBILE")`
    transition: transform 500ms ease-in-out;  
  `}

  :first-child {
    ${props =>
      props.show
        ? media.down("MOBILE")`
      transform: translateX(0px);    
    `
        : media.down("MOBILE")`
      transform: translateX(-100vh);
    `}
  }

  :last-child {
    ${props =>
      props.show
        ? media.down("MOBILE")`
        margin-top: -202px;
        opacity: 1;
        transform: translateX(0);
    `
        : media.down("MOBILE")`
        margin-top: -202px;
        opacity: 0;
      transform: translateX(100vh);
    `}
  }
`;

export const PostcodesWrapper = styled.div<{ isDropoff?: boolean }>`
  display: flex;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid ${colors.gray._100};
  box-sizing: border-box;
  border-radius: 30px;
  padding: 63px 90px 163px 90px;
  align-self: center;
  justify-self: center;
  margin-top: auto;
  box-shadow: 0px -4px 24px -18px rgba(58, 68, 74, 0.2), 0px 8px 24px -18px rgba(58, 68, 74, 0.2);

  ${media.down("LAPTOP")`
      padding: 48px 72px 74px 72px;
      position: relative;
      top: 12px;
  `};

  ${media.down("LAPTOP_SMALL")`
    padding: 22px 40px 26px 40px;
  `};

  ${media.down("LAPTOP_MEDIUM")`
      padding: 22px 49px 64px 49px;
  `};

  ${media.down("TABLET")`
      padding: 48px 72px 74px 72px;
  `};

  ${media.down("MOBILE")`
    padding: 0px 0px 0px 0px;
    background: transparent;
    box-shadow: none;
    width: 100%;
    border: 0;
    margin-top: 0px;
  `}

  ${media.down("MOBILE_TINY")`
    margin-top: -20px;
  `}
`;

export const PostcodesHeading = styled(HeadingContent)`
  max-width: 430px;

  ${media.down("LAPTOP")`
    max-width: 344px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    max-width: 284px;
  `}

  ${media.down("LAPTOP_SMALL")`
    max-width: 284px;
  `}

  ${media.down("TABLET")`
    max-width: 344px;
  `}

  ${media.down("MOBILE")`
    max-width: 70vw;
    margin-top: 32px;
  `}
`;

export const PostcodesInnerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  > img {
    position: absolute;
    top: -170px;

    ${media.down("LAPTOP")`
      top: -140px;
    `}

    ${media.down("LAPTOP_MEDIUM")`
      top: -110px;
    `}

    ${media.down("LAPTOP_SMALL")`
      top: -80px;
    `}

    ${media.down("TABLET")`
      top: -110px;
    `}

    ${GetHeroSize(140)}

    ${media.down("MOBILE")`
      display: none;
    `}
  }
`;

export const PostcodeFormFieldsWrapper = styled.div`
  transform: scale(1.14);
  transform-origin: top center;
  z-index: 100;

  ${media.down("LAPTOP")`
    transform: scale(1);
  `}

  ${media.down("LAPTOP_MEDIUM")`
    transform: scale(0.9464);
  `}

  ${media.down("LAPTOP_SMALL")`
    transform: scale(0.9107);
  `}

  ${media.down("TABLET")`
    transform: none;
  `}

  ${media.down("MOBILE")`
    padding-bottom: 40px;
  `}

  ${media.down("MOBILE_TINY")`
    padding-bottom: 20px;
  `}
`;

export const PostcodesInputsWrapper = styled.div`
  display: flex;
  width: 100%;

  > :first-child {
    margin-right: 8px;
  }

  ${media.down("LAPTOP_MEDIUM")`
    margin-bottom: -12px;
  `}

  ${media.down("MOBILE")`
    flex-direction: column;

    > :first-child {
      margin-right: 0;
    }
  `}
`;

export const PostcodesSectionHeading = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  line-height: 160%;
  letter-spacing: -0.01em;
  color: #1a1f23;
  width: 338px;
  font-size: 17.5px;
  margin-bottom: 12px;
  > svg {
    margin-right: 8px;
    width: 20px;
    height: 20px;
  }

  ${media.down("LAPTOP")`
    font-size: 16px;
  `}

  ${media.down("MOBILE")`
    width: auto;
    margin-left: 18px;
  `}
`;

export const CustomErrorMessage = styled.span<{ hide?: boolean }>`
  position: absolute;
  bottom: -49px;
  z-index: -1;
  background: ${colors.red._50};
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  > span {
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    color: ${colors.red._500};
  }
  > svg {
    margin-right: 6px;
  }

  ${media.down("LAPTOP_MEDIUM")`
    bottom: -30px;
    height: 30px;
  `}

  ${media.down("TABLET")`
    height: 40px;
    bottom: -49px;
  `}

  ${p =>
    !p.hide &&
    media.down("MOBILE")`
  bottom: 32px;
    width: 90vw;
    top: auto;
    margin-bottom: 10px;
    margin-top: 0;
  `}
`;
