import { useEffect } from "react";
import AppEventTarget from "common/events/AppEventTarget";

const useEventHandler = (eventType: string, eventHandler: () => void) => {
  useEffect(() => {
    AppEventTarget.addEventListener(eventType, eventHandler);
    return () => {
      AppEventTarget.removeEventListener(eventType, eventHandler);
    };
  }, [eventType, eventHandler]);
};

export default useEventHandler;
