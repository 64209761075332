import React, { FunctionComponent, useState } from "react";
import { IOrderType } from "@medlog/shared/models/orders/IOrder";
import {
  ButtonBorderContainer,
  ButtonContainer,
  InformationsContainer,
  ButtonImage,
  ButtonImageContainer,
  DescriptionSpanContainer,
  PriceSpanContainer,
  DescriptionSpan,
  DescriptionHeader,
  PriceBadge,
} from "modules/OrderingForm/OrderType/components/OrderTypeButton/OrderTypeButton.components";

import IconUrgent from "modules/OrderingForm/OrderType/icons/icon_urgent.svg";
import IconSameday from "modules/OrderingForm/OrderType/icons/icon_same_day.svg";
import IconUrgentDisabled from "modules/OrderingForm/OrderType/icons/icon_urgent_disabled.svg";
import IconSamedayDisabled from "modules/OrderingForm/OrderType/icons/icon_same_day_disabled.svg";
import IconPrebook from "modules/OrderingForm/OrderType/icons/icon_preebook.svg";
import ShadowRed from "modules/OrderingForm/OrderType/icons/shadow_red.svg";
import ShadowBlue from "modules/OrderingForm/OrderType/icons/shadow_blue.svg";
import { getMinPromoPrice } from "@medlog/shared/helpers/getOrderPrice";

const getOrderHeader = (type: IOrderType) => {
  switch (type) {
    case IOrderType.URGENT:
      return <>Urgent Delivery 90 minutes*</>;
    case IOrderType.SAME_DAY:
      return <>Same-Day Delivery</>;
    case IOrderType.PRE_BOOK:
      return <>Pre-book a Courier</>;
  }
};

const getOrderInformations = (type: IOrderType) => {
  switch (type) {
    case IOrderType.URGENT:
      return "Your parcel will be delivered as soon as possible. 90 minutes tops!";
    case IOrderType.SAME_DAY:
      return "Get your parcel delivered today between 8 am - 8 pm!";
    case IOrderType.PRE_BOOK:
      return "Book a courier to deliver your parcel on the day of your choice.";
  }
};

const getOrderTypeIcon = (type: IOrderType, enabled: boolean) => {
  switch (type) {
    case IOrderType.URGENT:
      return enabled ? IconUrgent : IconUrgentDisabled;
    case IOrderType.SAME_DAY:
      return enabled ? IconSameday : IconSamedayDisabled;
    case IOrderType.PRE_BOOK:
      return IconPrebook;
  }
};

type OrderTypeButtonProps = {
  selected: boolean;
  type: IOrderType;
  onClick: () => void;
  enabled?: boolean;
};

const OrderTypeButton: FunctionComponent<OrderTypeButtonProps> = ({ type, selected, onClick, enabled }) => {
  const [focuesd, setFocused] = useState(false);
  return (
    <ButtonBorderContainer highlighted={focuesd} onClick={onClick} selected={selected} enabled={enabled ?? true}>
      <ButtonContainer
        type="submit"
        enabled={enabled ?? true}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      >
        <ButtonImageContainer>
          <ButtonImage src={getOrderTypeIcon(type, enabled ?? true)} type={type} enabled={enabled ?? true} />
          {type === IOrderType.URGENT ? (
            <img className="shadow" src={ShadowRed} />
          ) : (
            <img className="shadow" src={ShadowBlue} />
          )}
        </ButtonImageContainer>
        <InformationsContainer>
          <PriceBadge color={type === IOrderType.URGENT ? "red" : "green"}>
            from{" "}
            <strong>£{getMinPromoPrice(type === IOrderType.URGENT ? IOrderType.URGENT : IOrderType.SAME_DAY)}*</strong>
          </PriceBadge>
          <DescriptionHeader>{getOrderHeader(type)}</DescriptionHeader>
          <DescriptionSpanContainer>
            <DescriptionSpan>{getOrderInformations(type)}</DescriptionSpan>
          </DescriptionSpanContainer>
          <PriceSpanContainer>
            <DescriptionSpan>
              from{" "}
              <strong>
                £{getMinPromoPrice(type === IOrderType.URGENT ? IOrderType.URGENT : IOrderType.SAME_DAY)}*
              </strong>
            </DescriptionSpan>
          </PriceSpanContainer>
        </InformationsContainer>
      </ButtonContainer>
    </ButtonBorderContainer>
  );
};

export default OrderTypeButton;
