import { colors } from "@medlog/shared/styles/colors";
import { media } from "@medlog/shared/styles/media";
import { DefaultBackgroundImage, DefaultBoxShadow, RWDFontSizes } from "modules/shared/styles/mixins";
import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${DefaultBackgroundImage}
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Outline = styled.span`
  position: absolute;
  border-radius: 30px;
  height: 80px;
  width: 100%;
  background: ${colors.red._500};
  top: -4px;

  ${media.down("MOBILE")`
    width: calc(100% - 64px);
  `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  background: ${colors.white};
  border-radius: 30px;
  align-items: center;

  ${DefaultBoxShadow};

  > button {
    position: absolute;
    bottom: -30px;
  }

  ${media.down("LAPTOP_BIG")`
    width: 610px;
    height: 610px;
  `}

  ${media.down("LAPTOP")`
    width: 520px;
    height: 520px;
  `}
  
  ${media.down("LAPTOP_MEDIUM")`
    width: 420px;
    height: 420px;
  `}

  ${media.down("LAPTOP_SMALL")`
    width: 382px;
    height: 382px;
  `}

  ${media.down("LAPTOP_TINY")`
    width: 382px;
    height: 382px;
  `}

  ${media.down("TABLET")`
    width: 488px;
    height: 488px;
  `}

  ${media.down("MOBILE")`
    max-width: calc(100vw - 64px);
    height: 100%;
    padding-top: 80px;
    padding-bottom: 52px;
    margin: 0px 32px;
  `}
`;

export const ShowRatingContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
`;

export const ShowRatingHeader = styled.h1`
  margin: 16px 64px 0px 64px;
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${colors.gray._800};

  ${RWDFontSizes("LAPTOP_38")}

  ${media.down("MOBILE")`
    margin: 16px;
  `}
`;

export const RatingContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;

  ${media.down("LAPTOP_BIG")`
    margin-top: 130px;
  `}

  ${media.down("LAPTOP")`
    margin-top: 110px;
  `}
  
  ${media.down("LAPTOP_MEDIUM")`
    margin-top: 90px;
  `}

  ${media.down("LAPTOP_SMALL")`
    margin-top: 80px;
  `}

  ${media.down("LAPTOP_TINY")`
    margin-top: 80px;
  `}

  ${media.down("TABLET")`
    margin-top: 110px;
  `}

  ${media.down("MOBILE")`
    margin: auto;
  `}

  > :nth-child(3) {
    margin-top: 40px;
    width: calc(100% - 32px);

    ${media.down("LAPTOP_BIG")`
      margin-top: 64px;
    `}

    ${media.down("LAPTOP")`
      margin-top: 40px;
    `}

    ${media.down("LAPTOP_MEDIUM")`
      margin-top: 32px;
    `}

    ${media.down("LAPTOP_SMALL")`
      margin-top: 16px;
    `}

    ${media.down("LAPTOP_TINY")`
      margin-top: 16px;
    `}

    ${media.down("TABLET")`
      margin-top: 40px;
    `}

    ${media.down("MOBILE")`
      margin-top: 32px;
      width: calc(100% - 64px);
      margin-left: 32px;
      margin-right: 32px;
    `}
  }
`;

export const LogoWrapper = styled.div`
  position: absolute;
  top: -4px;
  left: 50%;
  transform: translateX(-50%);
`;

export const LogoImage = styled.img`
  ${media.down("LAPTOP_BIG")`
    height: 90px;
  `}

  ${media.down("LAPTOP")`
    height: 80px;
  `}
  
  ${media.down("LAPTOP_MEDIUM")`
    height: 70px;
  `}

  ${media.down("LAPTOP_SMALL")`
    height: 60px;
  `}

  ${media.down("LAPTOP_TINY")`
    height: 60px;
  `}

  ${media.down("TABLET")`
    height: 80px;
  `}

  ${media.down("MOBILE")`
    height: 70px;
  `}
`;

export const HeaderTitle = styled.h1`
  margin: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.1px;
  color: #4a4a4f;
  margin: 0px 16px;

  ${RWDFontSizes("LAPTOP_26")}
`;

export const RatingContainer = styled.div`
  display: flex;
  margin-top: 32px;

  > :nth-child(2) {
    margin: 0px 38px;

    ${media.down("MOBILE")`
      margin: 0px 19px;
    `}
  }
`;

export const RatingBox = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: transform 0.2s ease;

  :hover {
    transform: scale(1.1);
  }
`;

export const RateImage = styled.img<{ selected: boolean }>`
  transition: all 0.1s ease-in;
  ${props =>
    !props.selected &&
    css`
      filter: grayscale(1);
      opacity: 0.4;
    `}

  ${media.down("LAPTOP_BIG")`
    width: 103px;
    height: 88px;
  `}

  ${media.down("LAPTOP")`
    width: 75px;
    height: 64px;
  `}
  
  ${media.down("LAPTOP_MEDIUM")`
    width: 65px;
    height: 56px;
  `}

  ${media.down("LAPTOP_SMALL")`
    width: 65px;
    height: 56px;
  `}

  ${media.down("LAPTOP_TINY")`
    width: 56px;
    height: 48px;
  `}

  ${media.down("TABLET")`
    width: 75px;
    height: 64px;
  `}

  ${media.down("MOBILE")`
    width: 56px;
    height: 48px;
  `}
`;

export const RateCheckContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 18px;
  align-items: center;
`;

export const RateCheck = styled.div<{ selected: boolean }>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-sizing: border-box;

  ${props =>
    props.selected
      ? css`
          border: 6px solid ${colors.mint._500};
        `
      : css`
          border: 2px solid rgba(0, 16, 61, 0.12);
        `}
`;

export const RateDescription = styled.span`
  margin-left: 8px;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  color: ${colors.gray._800};

  ${RWDFontSizes("LAPTOP_16")}
`;
