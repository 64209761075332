import styled from "styled-components/macro";
import { colors } from "@medlog/shared/styles/colors";
import IconDivider from "modules/shared/components/Sidebar/DetailsItem/Items/icon_divider.svg";
import { media } from "@medlog/shared/styles/media";

export const Container = styled.div`
  font-style: normal;
  font-weight: normal;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 120%;
  color: ${colors.gray._400};

  > b {
    font-weight: 500;
  }

  > ul {
    margin-top: 0px;
    padding-left: 0px;
  }

  > ul li {
    margin-top: 8px;
  }

  ${media.down("LAPTOP")`
    font-size: 14px;
  `}

  ${media.down("MOBILE")`
    font-size: 14px;
  `}
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;

  > span {
    display: flex;
    align-items: flex-start;
  }
`;

export const LightSpan = styled.span`
  white-space: nowrap;
  color: ${colors.gray._300};
  font-weight: 500;
`;

export const Pointer = styled.div`
  width: 2px;
  height: 2px;
  margin: 6px 6px;
  border-radius: 50%;
  background: ${colors.gray._400};
`;

export const Divider = styled.div`
  background-image: url("${IconDivider}");
  width: 8px;
  height: 8px;
  margin: 8px 0px;
`;

export const AddressDetails = styled.div`
  > :first-child {
    margin-bottom: 2px;
  }
`;

export const DateTimeSpan = styled.span`
  transition: transform 300ms ease;
  position: relative;

  ::before {
    content: "";
    position: absolute;
    left: -13px;
    top: 6px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: ${colors.gray._400};
  }

  :hover {
    transform: scale(0.98);
  }
`;

export const DateTimeDetails = styled.div`
  display: flex;
  flex-direction: column;
  overflow: visible;
  width: 100%;
  margin-left: 13px;

  > :nth-child(1) {
    margin-bottom: 8px;
  }
`;

export const ContactDetails = styled.div`
  display: flex;
  flex-direction: column;

  > :first-child {
    margin-bottom: 4px;
  }

  span {
    overflow-wrap: break-word;
  }
`;
