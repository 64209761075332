import {
  getStorageOrderParsed,
  getStorageOrderPrice,
} from "modules/App/OrdersLayout/OrderContext/Storage/sessionStorage.helpers";

export const NON_EXISTING_ID = "non-existing-id";

const storageOrder = getStorageOrderParsed();
const storagePrice = getStorageOrderPrice();

export const baseOrder = {
  uid: NON_EXISTING_ID,
  archived: false,
  type: undefined,
  copyCount: 0,
  copydSuccessed: false,
  email: "",
  phone: {
    directional: "+44",
    phoneNumber: "",
  },
  date: undefined,
  parcelSize: undefined,
  currentState: undefined,
  price: undefined,
  paid: false,
};

export const storedOrder = storageOrder || baseOrder;
export const storedPrice = storagePrice;
