import React, { FunctionComponent, useState } from "react";
import {
  InputContainer,
  InputPlaceholder,
  InputsWrapper,
  InputsDivider,
} from "modules/shared/components/PhoneInput/PhoneInput.components";
import DirectionalInputField from "modules/shared/components/PhoneInput/DirectionalInputField";
import PhoneNumberInputField from "modules/shared/components/PhoneInput/PhoneNumberInputField";
import { ErrorLabel } from "@medlog/shared/components/Input/Input.components";
import { useEffect } from "react";
import { FormikErrors } from "formik";
import IPhone from "@medlog/shared/models/common/IPhone";

const DIRECTIONAL_INPUT_NAME = "phone.directional";
const PHONE_NUMBER_INPUT_NAME = "phone.phoneNumber";

const PhoneInput: FunctionComponent<{ errors?: FormikErrors<IPhone>; submitCount: number }> = ({
  errors,
  submitCount,
}) => {
  const [directionalError, setDirectionalError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [error, setError] = useState("");
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (submitCount > 0) {
      setDirectionalError(errors?.directional ?? "");
      setPhoneNumberError(errors?.phoneNumber ?? "");
    }
  }, [errors, submitCount]);

  useEffect(() => {
    if (phoneNumberError) setError(phoneNumberError);
    if (directionalError) setError(directionalError);

    if (phoneNumberError && !directionalError) setError(phoneNumberError);
    if (!phoneNumberError && directionalError) setError(directionalError);
    if (!phoneNumberError && !directionalError) setError("");
  }, [phoneNumberError, directionalError]);

  return (
    <>
      <InputContainer error={!!error} focused={focused}>
        <InputPlaceholder error={!!error} focused={focused}>
          Phone
        </InputPlaceholder>
        <InputsWrapper>
          <DirectionalInputField
            name={DIRECTIONAL_INPUT_NAME}
            type="tel"
            setError={setDirectionalError}
            setFocused={setFocused}
          />
          <InputsDivider error={!!error} />
          <PhoneNumberInputField
            name={PHONE_NUMBER_INPUT_NAME}
            type="tel"
            setError={setPhoneNumberError}
            setFocused={setFocused}
          />
        </InputsWrapper>
      </InputContainer>
      <ErrorLabel>{error}</ErrorLabel>
    </>
  );
};

export default PhoneInput;
