import React, { FunctionComponent } from "react";
import {
  OrderDetailsHeaderWrapper,
  OrderDetailsWrapper,
  OrderDetailsHeader,
  OrderDetailsDescriptionWrapper,
  OrderNumberWrapper,
  PostcodesWrapper,
  Price,
  DetailsButtonWrapper,
  UrgentBadge,
  OrderTime,
  MobileTitle,
  PriceDetailsWrapper,
} from "modules/MyOrders/ClientDashboard/OrderDetails/OrderDetails.components";
import { IOrder, IOrderType } from "@medlog/shared/models/orders/IOrder";
import { IOrderState } from "@medlog/shared/models/orders/IOrderState";
import { ReactComponent as CalendarIcon } from "modules/MyOrders/ClientDashboard/OrderDetails/icons/calendar.svg";
import { ReactComponent as ThumbsIcon } from "modules/MyOrders/ClientDashboard/OrderDetails/icons/thumbs.svg";
import { ReactComponent as TruckIcon } from "modules/MyOrders/ClientDashboard/OrderDetails/icons/truck.svg";
import { ReactComponent as ArrowIcon } from "modules/MyOrders/ClientDashboard/OrderDetails/icons/arrow.svg";
import { ReactComponent as Chevron } from "modules/MyOrders/ClientDashboard/OrderDetails/icons/chevron.svg";
import { isSameDay } from "date-fns";
import { useHistory } from "react-router";
import routesPaths from "modules/Routing/routesPaths";
import { format } from "date-fns";
import { Transition } from "react-transition-group";
import { useIsMobileDown } from "common/helpers/useMedia";
import { getShortUid } from "common/helpers/getShortUid";
import RescheduledBadge from "@medlog/shared/components/RescheduledBadge/RescheduledBadge";

// 0 - Scheduled
// 1 - On way
// 2 - Deilvered
const headers = [
  {
    icon: <CalendarIcon />,
    description: "Scheduled",
  },
  {
    icon: <ThumbsIcon />,
    description: "Shipment delivered",
  },
  {
    icon: <TruckIcon />,
    description: "Order accepted",
  },
  {
    icon: <TruckIcon />,
    description: "Courier on the way",
  },
  {
    icon: <TruckIcon />,
    description: "Parcel picked-up",
  },
  {
    icon: <TruckIcon />,
    description: "Parcel delivered",
  },
];

const getOrderHeader = (order: IOrder) => {
  let headerIndex = 0;
  switch (order.type) {
    case IOrderType.URGENT:
    case IOrderType.SAME_DAY:
      if (order.currentState === IOrderState.COPIED || order.currentState === IOrderState.PAYMENT_SUCCEEDED)
        headerIndex = 2;
      else if (order.currentState === IOrderState.COURIER_ON_THE_WAY) headerIndex = 3;
      else if (order.currentState === IOrderState.PARCEL_PICKED_UP) headerIndex = 4;
      else if (order.currentState === IOrderState.PARCEL_DELIVERED) headerIndex = 5;
      else headerIndex = 2;
      break;
    case IOrderType.PRE_BOOK:
      if (order.currentState === IOrderState.COPIED || order.currentState === IOrderState.PAYMENT_SUCCEEDED)
        headerIndex = 0;
      else if (order.currentState === IOrderState.COURIER_ON_THE_WAY) headerIndex = 3;
      else if (order.currentState === IOrderState.PARCEL_PICKED_UP) headerIndex = 4;
      else if (order.currentState === IOrderState.PARCEL_DELIVERED) headerIndex = 5;
      else headerIndex = 2;
      break;
    default:
      headerIndex = 0;
  }
  const header = headers[headerIndex];
  return (
    <OrderDetailsHeader>
      {header.icon}
      <span>{header.description}</span>
    </OrderDetailsHeader>
  );
};

const getOrderTime = (order: IOrder) => {
  switch (order.type) {
    case IOrderType.URGENT:
    case IOrderType.SAME_DAY:
      if (isSameDay(order.date!, new Date())) return "Today";
      else return format(order.date!, "dd.MM.yyyy");
    case IOrderType.PRE_BOOK:
      if (isSameDay(order.preBookDeliveryDate!, new Date())) return "Today";
      else return format(order.preBookDeliveryDate!, "dd.MM.yyyy");
    default:
      return format(order.date!, "dd.MM.yyyy");
  }
};

const OrderDetails: FunctionComponent<{ order: IOrder }> = ({ order }) => {
  const history = useHistory();
  const isMobile = useIsMobileDown();
  return (
    <Transition timeout={0} in={true} appear>
      {status => (
        <OrderDetailsWrapper
          onClick={() => history.push(`${routesPaths.CONFIRMATION}?uid=${order.uid}`)}
          status={status}
        >
          <OrderDetailsHeaderWrapper>
            <>{getOrderHeader(order)}</>
            {!isMobile && <OrderTime>{getOrderTime(order)}</OrderTime>}
            {order.type === IOrderType.URGENT && <UrgentBadge>Urgent</UrgentBadge>}
            {order.rescheduled && <RescheduledBadge visible={order.rescheduled} />}
          </OrderDetailsHeaderWrapper>
          <OrderDetailsDescriptionWrapper>
            {isMobile && (
              <MobileTitle>
                Date: <OrderTime>{getOrderTime(order)}</OrderTime>
              </MobileTitle>
            )}
            <OrderNumberWrapper>
              Order Number: <span>{getShortUid(order.uid)}</span>
            </OrderNumberWrapper>
            <PostcodesWrapper>
              {isMobile && <MobileTitle>Location: </MobileTitle>}
              {order.pickupAndDropoff?.pickupDetails.postcode || "-"} <ArrowIcon />
              {order.pickupAndDropoff?.dropOffDetails.postcode || "-"}
            </PostcodesWrapper>
            {!isMobile && <Price>£{order.price ? (order.price / 100).toFixed(2) : "-"}</Price>}
            {!isMobile && (
              <DetailsButtonWrapper>
                Details <Chevron />
              </DetailsButtonWrapper>
            )}
            {isMobile && (
              <PriceDetailsWrapper>
                <Price>
                  <MobileTitle>Price: </MobileTitle>£{order.price ? (order.price / 100).toFixed(2) : "-"}
                </Price>
                <DetailsButtonWrapper>
                  Details <Chevron />
                </DetailsButtonWrapper>
              </PriceDetailsWrapper>
            )}
          </OrderDetailsDescriptionWrapper>
        </OrderDetailsWrapper>
      )}
    </Transition>
  );
};

export default OrderDetails;
