import React, { FunctionComponent } from "react";
import { FormikProps, Form } from "formik";
import { OrderTypeFormValues } from "modules/OrderingForm/OrderType/form";
import { RadiosWrapper } from "modules/OrderingForm/OrderType/OrderType.components";
import { IOrderType } from "@medlog/shared/models/orders/IOrder";
import OrderTypeButton from "modules/OrderingForm/OrderType/components/OrderTypeButton";
import useSettingsContext from "modules/OrderingRoutingLayout/SettingsContext/useSettingsContext";

const OrderTypeFormContent: FunctionComponent<FormikProps<OrderTypeFormValues>> = ({ values, setFieldValue }) => {
  const { deliveriesEnabled, urgentsEnabled, sameDaysEnabled } = useSettingsContext();

  return (
    <Form>
      <RadiosWrapper role="group">
        <OrderTypeButton
          enabled={deliveriesEnabled && urgentsEnabled}
          type={IOrderType.URGENT}
          selected={values.type === IOrderType.URGENT}
          onClick={() => {
            if (deliveriesEnabled && urgentsEnabled) {
              setFieldValue("type", IOrderType.URGENT);
            } else {
              setFieldValue("type", undefined);
            }
          }}
        />
        <OrderTypeButton
          enabled={deliveriesEnabled && sameDaysEnabled}
          type={IOrderType.SAME_DAY}
          selected={values.type === IOrderType.SAME_DAY}
          onClick={() => {
            if (deliveriesEnabled && sameDaysEnabled) {
              setFieldValue("type", IOrderType.SAME_DAY);
            } else {
              setFieldValue("type", undefined);
            }
          }}
        />
        <OrderTypeButton
          type={IOrderType.PRE_BOOK}
          selected={values.type === IOrderType.PRE_BOOK}
          onClick={() => {
            setFieldValue("type", IOrderType.PRE_BOOK);
          }}
        />
      </RadiosWrapper>
    </Form>
  );
};

export default OrderTypeFormContent;
