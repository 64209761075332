export const CLIENT_TOKEN_KEY = "token";

export const setClientToken = (clientId: string) => {
  localStorage.setItem(CLIENT_TOKEN_KEY, clientId);
};

export const getClientToken = () => {
  return localStorage.getItem(CLIENT_TOKEN_KEY);
};

export const removeClientToken = () => {
  localStorage.removeItem(CLIENT_TOKEN_KEY);
};
