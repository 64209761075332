import { TransitionStatus } from "react-transition-group";
import styled, { css } from "styled-components/macro";
import { colors } from "@medlog/shared/styles/colors";
import { media } from "@medlog/shared/styles/media";

export const CAnimation = styled.div<{ status: TransitionStatus }>`
  transition: transform 300ms ease, opacity 200ms ease;
  opacity: ${props => (props.status === "entered" ? "1" : "0")};
  transform: translateX(${props => (props.status === "entered" ? "" : "-100px")});
`;

export const DetailsContainer = styled.div`
  display: flex;
  align-self: center;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-top: 40px;
  box-sizing: border-box;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  .alignBottom {
    margin-top: auto;
    ${media.down("MOBILE")`
      margin-top: 0;
    `}
  }
`;

export const DeliveryDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: center;
  button {
    margin-top: auto;
    margin-bottom: 40px;
  }
`;

export const FormHeroContainer = styled.div<{ position: "left_bottom" | "right_bottom" | "right_center" }>`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid ${colors.gray._100};
  box-sizing: border-box;
  border-radius: 30px;
  object-fit: contain;
  align-self: center;
  justify-self: center;
  margin-top: auto;
  box-shadow: 0px -4px 24px -18px rgba(58, 68, 74, 0.2), 0px 8px 24px -18px rgba(58, 68, 74, 0.2);
  z-index: 1;

  > img {
    position: absolute;
    width: 175px;

    ${props =>
      props.position === "right_center" &&
      css`
        bottom: calc(50% - 64px);
        right: -100px;
      `}
    ${props =>
      props.position === "right_bottom" &&
      css`
        bottom: -25px;
        right: -100px;
      `}
    ${props =>
      props.position === "left_bottom" &&
      css`
        bottom: -50px;
        left: -90px;
      `}
  }

  ${media.down("MOBILE")`
    box-shadow: none;
    border: 0;
    margin-top: 0;
    border-radius: 0;
    background: transparent; 

    > img { display: none; }
  `}
`;

export const DeliveryDetailsWrapper = styled.div<{ biggerPaddingBottom?: boolean }>`
  background: #ffffff;
  border-radius: 30px;
  ${p => p.biggerPaddingBottom && "padding-bottom: 90px"};
  z-index: 2;

  padding: 50px 90px 112px 90px;

  ${media.down("LAPTOP")`
      padding: 40px 72px 28px 72px;
  `};

  ${media.down("LAPTOP_SMALL")`
      padding: 20px 40px 0px 40px;

      & textarea {
        height: 20px !important;
        min-height: 20px !important;
      }
  `};

  ${media.down("LAPTOP_MEDIUM")`
      padding: 22px 49px 0px 49px;
  `};

  ${media.down("TABLET")`
      padding: 48px 60px 74px 60px;
  `};

  ${media.down("MOBILE")`
      padding: 0;
      border-radius: 0;
      background: transparent;
  `}
`;

export const DeliveryDetailsInnerWrapper = styled.div`
  width: 424px;

  ${media.down("LAPTOP")`
    width: 340px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    width: 340px;
    height: 380px;
  `}

  ${media.down("LAPTOP_SMALL")`
    width: 340px;
    height: 320px;
  `}

  ${media.down("TABLET")`
    width: 340px;
  `}


  ${media.down("MOBILE")`
    position: relative;
    margin-top: 64px;
    margin-bottom: 56px;
    width: 100vw;
    > form {
      clip-path: polygon(0 20px, 100% 0, 100% 100%, 0 100%);
      background: white;
    }
    
    > h2 {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      z-index: 999;
      top: -56px;
      width: 70vw;
    }
  `}

  ${media.down("MOBILE_TINY")`
    margin-top: 52px;
  `}

  display: flex;
  flex-direction: column;
`;

export const DeliveryDetailsInputsWrapper = styled.div`
  transform: scale(1.25);
  transform-origin: top center;
  margin-top: 40px;
  position: relative;

  ${media.down("LAPTOP")`
    transform: scale(1);
  `}

  ${media.down("LAPTOP_MEDIUM")`
    transform: scale(0.875);
    margin-top: 16px;
  `}

  ${media.down("LAPTOP_SMALL")`
    margin-top: 16px;
  `}

  ${media.down("TABLET")`
    transform: scale(1);
    margin-top: 40px;
  `}

  ${media.down("MOBILE")`
    margin-top: 72px;
  `}

  ${media.down("MOBILE_SMALL")`
    margin-top: 56px;
  `}

  ${media.down("MOBILE_TINY")`
    margin-top: 40px;
  `}
`;

type PICKUPORDROPOFF = "pickup" | "dropoff";

export const IndicatorImage = styled.img<{ direction: PICKUPORDROPOFF }>`
  position: absolute;
  top: -80px;
  left: 50%;
  transform: translate(-50%, 0);

  ${p =>
    p.direction === "dropoff" &&
    css`
      transform: translate(-50%, 0) scaleX(-1);
    `}

  height: 50px;

  ${media.down("LAPTOP")`
    height: 40px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    top: -60px;
    height: 32px;
  `}

  ${media.down("TABLET")`
    top: -80px;
    height: 40px;
  `}

  ${media.down("MOBILE")`
    display: none;
  `}
`;
