import React, { FunctionComponent } from "react";
import { FormikProps, Form } from "formik";
import InputField from "@medlog/shared/components/Input/InputField";
import Button from "@medlog/shared/components/Button";
import { PickupLocationFormValues } from "modules/OrderingForm/DeliveryDetails/PickupDetails/PickupLocation/form";
import {
  DetailsWrapper,
  DeliveryDetailsInnerWrapper,
  DeliveryDetailsInputsWrapper,
  DeliveryDetailsWrapper,
  IndicatorImage,
  FormHeroContainer,
} from "modules/OrderingForm/DeliveryDetails/DeliveryDetails.components";
import Heading from "modules/shared/components/Heading/Heading";
import PickupIndicator from "modules/OrderingForm/DeliveryDetails/icons/indicator.png";
import Hint from "modules/shared/components/Hint/Hint";
import HeroLeft from "modules/OrderingForm/DeliveryDetails/icons/hero_left.png";
import { useIsMobileDown } from "common/helpers/useMedia";
import MobileButton from "modules/shared/components/Mobile/MobileButton";

const PickupLocationFormContent: FunctionComponent<FormikProps<PickupLocationFormValues>> = ({
  submitForm,
  isSubmitting,
}) => {
  const isMobile = useIsMobileDown();
  return (
    <DetailsWrapper>
      <FormHeroContainer position="left_bottom">
        <img src={HeroLeft} />
        <DeliveryDetailsWrapper>
          <IndicatorImage direction="pickup" src={PickupIndicator} />
          <DeliveryDetailsInnerWrapper>
            <Heading>
              Complete the <b>pick-up</b> <br />
              location details
            </Heading>
            <Form
              onKeyDown={async e => {
                const isTextArea = e.target instanceof HTMLTextAreaElement;
                if (e.key === "Enter" && e.shiftKey === false && !isTextArea) {
                  e.preventDefault();
                  await submitForm();
                }
              }}
            >
              <DeliveryDetailsInputsWrapper>
                <InputField
                  name="placeName"
                  label="Location name"
                  type="text"
                  inputSize={isMobile ? "mobile" : "large"}
                />
                <Hint position="left" hint="e.g. flat, departament" delay={500}>
                  <InputField
                    multiline
                    name="placeDetails"
                    label="Location details"
                    type="text"
                    inputSize={isMobile ? "mobile" : "large"}
                  />
                </Hint>

                <Hint position="right" hint="e.g. Second floor - use the stairs, the lift is broken" delay={1000}>
                  <InputField
                    multiline
                    name="additionalInformation"
                    label="Do we need to know anything extra?"
                    type="text"
                    inputSize={isMobile ? "mobile" : "large"}
                  />
                </Hint>
              </DeliveryDetailsInputsWrapper>
            </Form>
          </DeliveryDetailsInnerWrapper>
        </DeliveryDetailsWrapper>
      </FormHeroContainer>
      {!isMobile && (
        <Button type="submit" onClick={submitForm} className="bottomButton" loading={isSubmitting}>
          Next step
        </Button>
      )}
      {isMobile && <MobileButton isSubmit={true} onClick={submitForm} text="Next step" loading={isSubmitting} />}
    </DetailsWrapper>
  );
};

export default PickupLocationFormContent;
