import * as Yup from "yup";

export interface PickupLocationFormValues {
  placeName: string;
  placeDetails: string;
  additionalInformation: string;
}

export const pickupLocationInitialValues: PickupLocationFormValues = {
  placeName: "",
  placeDetails: "",
  additionalInformation: "",
};

export const pickupLocationValidationSchema = Yup.object().shape({
  placeName: Yup.string().required("Location name is required"),
  placeDetails: Yup.string().required("Location details are required"),
  additionalInformation: Yup.string(),
});
