import { colors } from "@medlog/shared/styles/colors";
import { css } from "styled-components/macro";
import { media } from "@medlog/shared/styles/media";
import Background from "@medlog/shared/icons/background.svg";

const FACTORS = {
  LAPTOP_BIG: { width: 1.25, height: 1.25 },
  LAPTOP: { width: 1, height: 1 },
  LAPTOP_MEDIUM: { width: 0.9, height: 0.9 },
  LAPTOP_SMALL: { width: 0.8, height: 0.8 },
};

export const GetHeroSizeWithRatio = (width: number, ratio: number) => {
  return css`
    width: ${width}px;
    height: ${width * ratio}px;
  `;
};

export const setRWDWidth = (
  laptopBig: number,
  laptop: number,
  laptopMedium: number,
  laptopTiny: number,
  tablet: number
) => css`
  ${media.down("LAPTOP_BIG")`
    width: ${laptopBig}px;
  `}
  ${media.down("LAPTOP")`
    width: ${laptop}px;
  `}
  ${media.down("LAPTOP_MEDIUM")`
    width: ${laptopMedium}px;
  `}
  ${media.down("LAPTOP_TINY")`
    width: ${laptopTiny}px;
  `}
  ${media.down("TABLET")`
    width: ${tablet}px;
  `}
`;

export const GetHeroSize = (width: number) => css`
  width: ${width}px;

  ${media.down("LAPTOP")`
      width: ${width * 0.9}px;
    `}

  ${media.down("LAPTOP_MEDIUM")`
      width: ${width * 0.75}px;
    `}

    ${media.down("LAPTOP_SMALL")`
      width: ${width * 0.56}px;
    `}

    ${media.down("TABLET")`
      width: ${width * 0.66}px;
    `}


    ${media.down("MOBILE")`
      width: ${width * 0.56}px;
    `}
`;

export const RWDWidthsAndHeights = (laptopWidth: number, laptopHeight: number) => css`
  width: ${laptopWidth * FACTORS.LAPTOP_BIG.width}px;
  height: ${laptopHeight * FACTORS.LAPTOP_BIG.height}px;

  ${media.down("LAPTOP")`
    width: ${laptopWidth * FACTORS.LAPTOP.width}px;
    height: ${laptopHeight * FACTORS.LAPTOP.height}px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    width: ${laptopWidth * FACTORS.LAPTOP_MEDIUM.width}px;
    height: ${laptopHeight * FACTORS.LAPTOP_MEDIUM.height}px;
  `}

  ${media.down("LAPTOP_SMALL")`
    width: ${laptopWidth * FACTORS.LAPTOP_SMALL.width}px;
    height: ${laptopHeight * FACTORS.LAPTOP_SMALL.height}px;
  `}
`;

type FONT_SIZE_PROGRESSIONS =
  | "LAPTOP_38"
  | "LAPTOP_32"
  | "LAPTOP_28"
  | "LAPTOP_26"
  | "LAPTOP_18"
  | "LAPTOP_16"
  | "LAPTOP_14";

type FONT_SIZES_DICTIONARY = { [key in FONT_SIZE_PROGRESSIONS]: number[] };

const FONT_SIZES: FONT_SIZES_DICTIONARY = {
  LAPTOP_38: [48, 38, 30, 28, 38],
  LAPTOP_32: [40, 32, 30, 24, 32],
  LAPTOP_28: [36, 28, 24, 22, 28],
  LAPTOP_26: [32, 26, 21, 20, 26],
  LAPTOP_18: [22, 18, 16, 14, 18],
  LAPTOP_16: [20, 16, 14, 12, 16],
  LAPTOP_14: [18, 14, 12, 12, 14],
};

export const RWDFontSizes = (fontSizeProgression: FONT_SIZE_PROGRESSIONS) => {
  const sizes = FONT_SIZES[fontSizeProgression];
  if (!sizes) throw `No font size progression defined for ${fontSizeProgression}`;

  return css`
    font-size: ${sizes[0]}px;

    ${media.down("LAPTOP")`
      font-size: ${sizes[1]}px;
    `}

    ${media.down("LAPTOP_MEDIUM")`
      font-size: ${sizes[2]}px;
    `}

    ${media.down("LAPTOP_SMALL")`
      font-size: ${sizes[3]}px;
    `}

    ${media.down("TABLET")`
      font-size: ${sizes[4]}px;
    `}
  `;
};

type BASIC_TEXT_TYPE = "Header" | "Content";

const BasicText = (type: BASIC_TEXT_TYPE) => css`
  margin: 0;
  padding: 0;
  font-weight: ${type == "Header" ? 600 : 300};
  line-height: ${type == "Header" ? 120 : 140}%;
  letter-spacing: ${type == "Header" ? -0.02 : -0.01}em;
  color: ${colors.gray._800};
`;

export const MessageHeaderH2 = css`
  ${BasicText("Header")}
  ${RWDFontSizes("LAPTOP_38")}
`;

export const MessageDescriptionSpan = css`
  ${BasicText("Content")}
  ${RWDFontSizes("LAPTOP_18")}

  margin-top: 12px;
`;

export const ModalHeaderH2 = css`
  ${BasicText("Header")}
  ${RWDFontSizes("LAPTOP_28")}
`;

export const ModalDescriptionSpan = css`
  ${BasicText("Content")}
  ${RWDFontSizes("LAPTOP_16")}

  margin-top: 12px;
`;

export const MobileHeader = css`
  ${media.down("MOBILE")`
    font-size: 24px;
  `}

  ${media.down("MOBILE_SMALL")`
    font-size: 22px;
  `}

  ${media.down("MOBILE_TINY")`
    font-size: 20px;
  `}
`;

export const MobileDescription = css`
  ${media.down("MOBILE")`
    font-size: 18px;
  `}

  ${media.down("MOBILE_SMALL")`
    font-size: 16px;
  `}

  ${media.down("MOBILE_TINY")`
    font-size: 16px;
  `}
`;

export const MobileSmallText = css`
  ${media.down("MOBILE")`
    font-size: 16px;
  `}

  ${media.down("MOBILE_SMALL")`
    font-size: 14px;
  `}

  ${media.down("MOBILE_TINY")`
    font-size: 12px;
    `}
`;

export const DefaultBoxShadow = css`
  box-shadow: 0px -4px 24px -18px rgba(58, 68, 74, 0.2), 0px 8px 24px -18px rgba(58, 68, 74, 0.2);
`;

export const DefaultBackgroundImage = css`
  background: #f8f8f9;
  background-image: url("${Background}");
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: 100%;

  ${media.down("MOBILE")`
    background-image: none;
  `}
`;

export const FirefoxScrollbar = () => css`
  scrollbar-color: ${colors.gray._700} transparent;
  scrollbar-width: thin;
`;

export const FirefoxLightScrollbar = () => css`
  scrollbar-color: ${colors.gray._300} transparent;
  scrollbar-width: thin;
`;
