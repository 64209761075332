import styled, { css } from "styled-components/macro";
import { colors } from "@medlog/shared/styles/colors";
import { media } from "@medlog/shared/styles/media";
import { FirefoxLightScrollbar } from "modules/shared/styles/mixins";

export const PostcodeInputContainer = styled.div`
  position: relative;

  ${media.down("MOBILE")`
    > * {
      pointer-events: none;
    }

  .full-screen & {
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100vw;
      padding-top: 16px;

      > * {
        pointer-events: auto;
      }

      > div:first-child { 
        width: 100vw; 
        margin: 0 auto; 
        border-bottom: 1px solid #EDF0F3; 
        height: 70px;
        z-index:999; 
    
        
        > * { 
          margin: 0 auto;
          background: white;
          z-index: -1;
          height: 70px;
        }
      }
    }
  `}
`;

export const PostcodeSuggestionsContainer = styled.div<{
  small?: boolean;
  suggestionsVisible: boolean;
}>`
  position: absolute;
  top: 68px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  width: ${p => (p.small ? 112 : 248)}px;
  overflow: hidden;
  z-index: 999;
  max-height: 235px;
  overflow-y: auto;

  ${props =>
    props.suggestionsVisible &&
    css`
      box-shadow: 0px 4px 20px -2px rgba(105, 119, 129, 0.12);
      border: 1px solid #eaeef1;
    `}

  ${FirefoxLightScrollbar}
  
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background: #d3dde4;
    border-radius: 30px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${colors.red._500};
  }
  ::-webkit-scrollbar-track {
    background: #f2f5f8;
    border-radius: 0px;
    box-shadow: inset 0px 0px 0px 0px #f0f0f0;
  }

  ${media.down("MOBILE")`
    position: static;
    
    .full-screen & {
        z-index: 1;
        width: 95vw;
        position: static;
        max-height: none;
        border: 0;
        box-shadow: none;
        margin-left: 5vw;
      }
  `}
`;

export const PostcodeSuggestion = styled.div<{ active?: boolean }>`
  display: flex;
  height: 48px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 130%;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #697781;
  background: white;
  > svg {
    margin-right: 6px;
  }
  :hover {
    background: #f2f5f8;
    color: #1a1f23;
    cursor: pointer;
  }

  ${p =>
    p.active &&
    css`
      background: #f2f5f8;
      color: #1a1f23;
    `}

  ${media.down("MOBILE")`
    .full-screen & {
      justify-content: start;
      margin-left: 4px;

      :hover {
        background: transparent
      }
    }
  `}
`;

export const PostcodeSuggestionsWrapper = styled.div`
  margin: 16px 8px 16px 0px;

  ${media.down("MOBILE")`
    .full-screen & {
      margin: 0;
    }
  `}
`;
