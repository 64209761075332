import styled, { css } from "styled-components";
import Background from "@medlog/shared/components/Error404/background.png";
import { colors } from "@medlog/shared/styles/colors";
import { media } from "@medlog/shared/styles/media";
import { getAppType } from "@medlog/shared/helpers/getAppType";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-image: url("${Background}");
  display: flex;
  flex-direction: column;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: white;

  ${getAppType() !== "ORDERING" &&
  css`
    position: absolute;
    top: 0;
    height: 100vh;
    z-index: 10000;
  `}
`;

export const Logo = styled.img`
  position: absolute;
  left: 48px;
  top: 0px;
  width: 64px;
  height: 80px;
  display: static;
  cursor: pointer;
  z-index: 2;
  transform-origin: top;
  transition: transform 0.3s ease;

  :hover {
    transform: scale(1.05);
  }

  ${media.down("TABLET")`
    left: 32px;
  `} @media only screen and (max-width: 500px) {
    display: none;
  }
`;

export const Header = styled.div`
  margin-top: 40px;
  margin-right: 48px;
  margin-left: 112px;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  z-index: 2;

  @media only screen and (max-width: 500px) {
    justify-content: center;
    margin-right: 0px;
    margin-left: 0px;
  }

  a {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.01em;
    color: ${colors.gray._600};
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }

    :nth-child(even) {
      margin: 0px 32px;
    }

    ${media.down("LAPTOP_BIG")`
      font-size: 16px;
    `}

    ${media.down("LAPTOP")`
      font-size: 14px;
    `}

    ${media.down("TABLET")`
      font-size: 14px;
    `}

    ${media.down("MOBILE_SMALL")`
      font-size: 14px;
    `}
  }
`;

export const Content = styled.div`
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;

  > img {
    margin-top: -64px;
    max-width: calc(100% - 32px);
  }
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -23px;

  > h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 120%;
    text-align: center;
    letter-spacing: -0.02em;
    color: ${colors.gray._800};
    margin: 0px;

    ${media.down("LAPTOP_BIG")`
      font-size: 38px;
    `}

    ${media.down("LAPTOP")`
      font-size: 30px;
    `}

    ${media.down("TABLET")`
      font-size: 38px;
    `}

    ${media.down("MOBILE_SMALL")`
      font-size: 30px;
    `}
  }

  > span {
    margin-top: 8px;
    margin-bottom: 41px;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 140%;
    text-align: center;
    color: ${colors.gray._600};

    ${media.down("LAPTOP_BIG")`
      font-size: 20px;
    `}

    ${media.down("LAPTOP")`
      font-size: 16px;
    `}

    ${media.down("TABLET")`
      font-size: 20px;
    `}

    ${media.down("MOBILE_SMALL")`
      font-size: 16px;
    `}
  }
`;
