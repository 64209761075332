import { IOrder } from "@medlog/shared/models/orders/IOrder";
import { IOrderDetails } from "@medlog/shared/models/orders/IOrderDetails";
import isNotEmpty from "common/helpers/isNotEmpty";
import routesPaths from "modules/Routing/routesPaths";
import SidebarSections from "modules/shared/components/Sidebar/SidebarSections";

const isContactChecked = (order: IOrder) => isNotEmpty(order.email);
const isDateChecked = (order: IOrder) => !!order.date;
const isDateAndTimeChecked = (order: IOrder) => !!order.preBookDeliveryTime;
const isDropOffChecked = (details?: IOrderDetails) => isNotEmpty(details?.dropOffDetails.phone?.phoneNumber || "");
const isLocationChecked = (details?: IOrderDetails) => isNotEmpty(details?.dropOffDetails.postcode || "");
const isPackageSizeChecked = (order: IOrder) => order.parcelSize !== undefined;
const isPaymentChecked = (order: IOrder) => order.paid;
const isPickupChecked = (details?: IOrderDetails) => isNotEmpty(details?.pickupDetails.phone?.phoneNumber || "");

export const isSidebarItemChecked = (section: SidebarSections, order: IOrder, details?: IOrderDetails) => {
  switch (section) {
    case SidebarSections.DATE:
      return isDateChecked(order);
    case SidebarSections.DATE_AND_TIME:
      return isDateAndTimeChecked(order);
    case SidebarSections.LOCATION:
      return isLocationChecked(details);
    case SidebarSections.PACKAGE_SIZE:
      return isPackageSizeChecked(order);
    case SidebarSections.CONTACT_DETAILS:
      return isContactChecked(order);
    case SidebarSections.PICKUP_DETAILS:
      return isPickupChecked(details);
    case SidebarSections.DROPOFF_DETAILS:
      return isDropOffChecked(details);
    case SidebarSections.PAYMENT:
      return isPaymentChecked(order);
  }
};

export const isSidebarItemActive = (section: SidebarSections, pathname: string) => {
  switch (section) {
    case SidebarSections.DATE:
      return pathname === routesPaths.HOME;
    case SidebarSections.DATE_AND_TIME:
      return [routesPaths.DATE, routesPaths.TIME].includes(pathname);
    case SidebarSections.LOCATION:
      return pathname === routesPaths.POSTCODES;
    case SidebarSections.PACKAGE_SIZE:
      return pathname === routesPaths.SIZE;
    case SidebarSections.CONTACT_DETAILS:
      return pathname === routesPaths.DETAILS;
    case SidebarSections.PICKUP_DETAILS:
      return [routesPaths.PICKUP, routesPaths.PICKUP_CONTACT].includes(pathname);
    case SidebarSections.DROPOFF_DETAILS:
      return [routesPaths.DROPOFF, routesPaths.DROPOFF_CONTACT].includes(pathname);
    case SidebarSections.PAYMENT:
      return routesPaths.SUMMARY == pathname;
  }
};

export const getSidebarTitle = (pathname: string) => {
  switch (pathname) {
    case routesPaths.HOME:
      return "Date";
    case routesPaths.DATE:
    case routesPaths.TIME:
      return "Date and time";
    case routesPaths.POSTCODES:
      return "Location";
    case routesPaths.SIZE:
      return "Package size";
    case routesPaths.DETAILS:
      return "Contact details";
    case routesPaths.PICKUP:
    case routesPaths.PICKUP_CONTACT:
      return "Pick-up details";
    case routesPaths.DROPOFF:
    case routesPaths.DROPOFF_CONTACT:
      return "Drop-off details";
    case routesPaths.SUMMARY:
      return "Payment";
  }

  return "";
};
