import styled, { keyframes } from "styled-components";
import { hexToRgb } from "@material-ui/core";

const spin = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

export const Loader = styled.div<{ color: string }>`
  position: absolute;
  border-top: 2px solid rgba(${props => hexToRgb(props.color)}, 0.2);
  border-right: 2px solid rgba(255, 255, 255, 0.2);
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  border-left: 2px solid ${props => props.color};
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: ${spin} 1s infinite linear;
  animation: ${spin} 1s infinite linear;
  border-radius: 50%;
  width: 15px;
  margin-top: 2px;
  height: 15px;
`;
