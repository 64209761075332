import React, { FunctionComponent } from "react";
import Authentication from "modules/MyOrders/Authentication/Authentication";
import {
  MyOrdersLayoutContainer,
  MyOrdersLayoutWrapper,
} from "modules/MyOrders/MyOrdersLayout/MyOrdersLayout.components";
import PageLoader from "modules/App/PageLoader";
import ClientDashboard from "modules/MyOrders/ClientDashboard/ClientDashboard";
import useMyOrdersContext from "modules/MyOrders/MyOrdersContext/useMyOrdersContext";

const MyOrdersLayout: FunctionComponent = () => {
  const { fetching, authenticated } = useMyOrdersContext();

  if (fetching) return <PageLoader />;
  return (
    <MyOrdersLayoutContainer>
      <MyOrdersLayoutWrapper>{!authenticated ? <Authentication /> : <ClientDashboard />}</MyOrdersLayoutWrapper>
    </MyOrdersLayoutContainer>
  );
};

export default MyOrdersLayout;
