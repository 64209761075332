import React, { FunctionComponent } from "react";
import { MobileInputContainer } from "./MobileInput.components";
import AddressInput from "modules/shared/components/Form/Postcodes/AddressInput/AddressInput";
import PostcodeInput from "modules/shared/components/Form/Postcodes/PostcodeInput/PostcodeInput";
import { ISuggestion } from "common/types/PostcodesAPI/IAutocomplete";

type MobileInputProps = {
  value: ISuggestion | string;
  setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void;
  error: string | undefined;
  onDone?: () => void;
};

type MobileInputPostcodeProps = {
  mode: "postcode";
  postcodeSelected: boolean;
  fieldType: "pickup" | "dropoff";
} & MobileInputProps;

type MobileInputAddressProps = {
  mode: "address";
  postcode: string;
  fieldName: string;
} & MobileInputProps;

const MobileInput: FunctionComponent<MobileInputPostcodeProps | MobileInputAddressProps> = props => {
  const { value, setFieldValue, error, onDone } = props;

  if (props.mode === "postcode") {
    const { postcodeSelected, fieldType } = props;
    return (
      <MobileInputContainer className="full-screen">
        <PostcodeInput
          fieldType={fieldType}
          value={value as string}
          setFieldValue={setFieldValue}
          postcodeSelected={postcodeSelected}
          error={error}
          isMobile={true}
          isFullScreen={true}
          onDone={onDone}
        />
      </MobileInputContainer>
    );
  } else {
    const { postcode, fieldName } = props;
    return (
      <MobileInputContainer className="full-screen">
        <AddressInput
          postcode={postcode}
          disabled={false}
          fieldName={fieldName}
          value={value as ISuggestion}
          setFieldValue={setFieldValue}
          error={error}
          isMobile={true}
          isFullScreen={true}
          onDone={onDone}
        />
      </MobileInputContainer>
    );
  }
};

export default MobileInput;
