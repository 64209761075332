import { colors } from "@medlog/shared/styles/colors";
import { media } from "@medlog/shared/styles/media";
import styled, { css } from "styled-components/macro";

type ModeProps = {
  mode: "full" | "text" | "price";
  active?: boolean;
};

export const MobileButtonContainer = styled.div<ModeProps>`
  background-color: ${props => (props.mode !== "price" && props.active ? colors.red._500 : "transparent")};
  border-radius: 15px;
  overflow: hidden;
  width: 94vw;
  height: 56px;
  margin-bottom: 24px;
  display: flex;
  align-items: stretch;
  position: absolute;
  bottom: 0;
  z-index: 9999;
  ${media.down("MOBILE_TINY")`
    margin-bottom: 16px;
  `}
`;
export const PriceButton = styled.button<ModeProps>`
  border: 0;
  flex-basis: 112px;
  height: 100%;
  background-color: ${colors.red._400};
  border-radius: 15px 0 0 15px;
  color: ${colors.white};
  outline: none;
  > strong {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 104%;
    display: block;
    > small {
      font-weight: 600;
      font-size: 12px;
    }
  }
  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 160%;
    letter-spacing: 0.01em;
  }
  ${props =>
    props.mode === "text" &&
    css`
      display: none;
    `}
  ${props =>
    props.mode === "price" &&
    css`
      border-radius: 15px 15px 15px 15px;
    `}
`;
export const TextButton = styled.button<ModeProps>`
  border: 0;
  height: 56px;
  flex-grow: 1;
  background-color: ${props => (props.active ? colors.red._500 : colors.gray._300)};
  cursor: pointer;
  border-radius: 0 15px 15px 0;
  color: ${colors.white};
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.1px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props =>
    props.mode === "price" &&
    css`
      visibility: hidden;
    `}
  ${props =>
    props.mode === "text" &&
    css`
      border-radius: 15px 15px 15px 15px;
    `}
`;
