import React, { FunctionComponent } from "react";
import {
  HeaderTitle,
  RatingBox,
  RatingContainer,
  RateCheckContainer,
  RateCheck,
  RateDescription,
  RateImage,
  RatingContent,
} from "modules/Rating/Rating.components";
import RateGreat from "modules/Rating/icons/rate_great.png";
import RateOkay from "modules/Rating/icons/rate_okay.png";
import RateBad from "modules/Rating/icons/rate_bad.png";
import Input from "@medlog/shared/components/Input/Input";
import IRate from "@medlog/shared/models/rating/IRate";

type SendRateProps = {
  rateChanged: (value: IRate) => void;
  descriptionChanged: (value: string) => void;
  rate: IRate;
  description: string;
};

const SendRate: FunctionComponent<SendRateProps> = ({ description, descriptionChanged, rate, rateChanged }) => {
  return (
    <RatingContent>
      <HeaderTitle>Are you satisfied with our service?</HeaderTitle>
      <RatingContainer>
        <RatingBox onClick={() => rateChanged(IRate.GREAT)}>
          <RateImage src={RateGreat} selected={rate === IRate.GREAT} />
          <RateCheckContainer>
            <RateCheck selected={rate === IRate.GREAT} />
            <RateDescription>Great</RateDescription>
          </RateCheckContainer>
        </RatingBox>
        <RatingBox onClick={() => rateChanged(IRate.OKAY)}>
          <RateImage src={RateOkay} selected={rate === IRate.OKAY} />
          <RateCheckContainer>
            <RateCheck selected={rate === IRate.OKAY} />
            <RateDescription>Okay</RateDescription>
          </RateCheckContainer>
        </RatingBox>
        <RatingBox onClick={() => rateChanged(IRate.BAD)}>
          <RateImage src={RateBad} selected={rate === IRate.BAD} />
          <RateCheckContainer>
            <RateCheck selected={rate === IRate.BAD} />
            <RateDescription>Bad</RateDescription>
          </RateCheckContainer>
        </RatingBox>
      </RatingContainer>
      <Input
        label="Your opinion"
        type="text"
        inputSize="unset"
        multiline
        optional
        value={description}
        onChange={event => {
          const value = event.target.value;
          descriptionChanged(value);
        }}
      />
    </RatingContent>
  );
};

export default SendRate;
