import React, { FunctionComponent } from "react";
import { Container } from "modules/shared/components/Sidebar/DetailsItem/Items/Items.components";
import { PickupDetail } from "modules/shared/components/Sidebar/DetailsItem/DetailsItem.components";
import DetailsItem from "modules/shared/components/Sidebar/DetailsItem";
import SidebarSections from "modules/shared/components/Sidebar/SidebarSections";
import useOrderContext from "modules/App/OrdersLayout/OrderContext/useOrderContext";
import routesPaths from "modules/Routing/routesPaths";
import { useHistory } from "react-router-dom";
import { Link } from "react-scroll";
import { SCROLL_DURATION } from "modules/shared/components/Sidebar/Sidebar";
import isNotEmpty from "common/helpers/isNotEmpty";
import { isSidebarPathAllowed } from "common/navigation/navigationHandler";
import { IOrderDetails } from "@medlog/shared/models/orders/IOrderDetails";
import { isSidebarItemActive, isSidebarItemChecked } from "modules/shared/components/Sidebar/SidebarHelpers";

const shouldShowAddress = (details?: IOrderDetails) =>
  isNotEmpty(details?.dropOffDetails.address || "") && isNotEmpty(details?.dropOffDetails.placeDetails || "");

const shouldShowContact = (details?: IOrderDetails) => isNotEmpty(details?.dropOffDetails.phone?.phoneNumber || "");

const DropoffDetailsItem: FunctionComponent = () => {
  const { order, orderDetails } = useOrderContext();
  const history = useHistory();
  const checked = isSidebarItemChecked(SidebarSections.DROPOFF_DETAILS, order, orderDetails);
  const isAllowed = isSidebarPathAllowed(routesPaths.DROPOFF, order, orderDetails);

  const handleClick = () => {
    isAllowed && history.push(routesPaths.DROPOFF);
  };

  const handleLocationClick = () => {
    shouldShowAddress(orderDetails) && history.push(routesPaths.DROPOFF);
  };

  const handleContactClick = () => {
    shouldShowContact(orderDetails) && history.push(routesPaths.DROPOFF_CONTACT);
  };

  return (
    <Link
      name={routesPaths.DROPOFF}
      to={routesPaths.DROPOFF}
      smooth={true}
      duration={SCROLL_DURATION}
      containerId="containerId"
    >
      <DetailsItem
        active={isSidebarItemActive(SidebarSections.DROPOFF_DETAILS, location.pathname)}
        checked={checked}
        type={SidebarSections.DROPOFF_DETAILS}
        onClick={handleClick}
        animationDisabled={!isAllowed}
        enableScaling={isAllowed}
        forceDisplayContent
      >
        <Container>
          {shouldShowAddress(orderDetails) && (
            <PickupDetail onClick={handleLocationClick} disabled={!isAllowed}>
              {orderDetails.dropOffDetails.placeName}
              <br />
              {orderDetails.dropOffDetails.placeDetails}
              {orderDetails.dropOffDetails.additionalDetails !== "" && (
                <span>{orderDetails.dropOffDetails.additionalDetails}</span>
              )}
            </PickupDetail>
          )}

          {shouldShowContact(orderDetails) && (
            <PickupDetail onClick={handleContactClick} disabled={!isAllowed}>
              <span>
                <b>{orderDetails.dropOffDetails.name}</b>
                {` ${orderDetails.dropOffDetails.phone?.directional} ${orderDetails.dropOffDetails.phone?.phoneNumber}`}
              </span>
              <span>{orderDetails.dropOffDetails.email}</span>
            </PickupDetail>
          )}
        </Container>
      </DetailsItem>
    </Link>
  );
};

export default DropoffDetailsItem;
