import styled, { css } from "styled-components";
import { media } from "@medlog/shared/styles/media";
import { colors } from "../../styles/colors";
import { BreakpointNames } from "../../styles/const";

type ButtonSizes = "medium" | "large" | "extraLarge" | "block";

type ClippedButtonProps = {
  id?: string;
  size: ButtonSizes;
  active?: boolean;
};

type BreakpointNameOrDefault = BreakpointNames | "default";

type SizeValues = {
  default: { width: string; height: string };
} & {
  [key in BreakpointNames]?: { width: string; height: string };
};

const buttonSizes: { [key in ButtonSizes]: SizeValues } = {
  medium: {
    default: {
      width: "144px",
      height: "48px",
    },
  },
  large: {
    default: {
      width: "160px",
      height: "54px",
    },
    LAPTOP_BIG: {
      width: "200px",
      height: "70px",
    },
    LAPTOP: {
      width: "160px",
      height: "56px",
    },
    LAPTOP_MEDIUM: {
      width: "146px",
      height: "48px",
    },
    LAPTOP_SMALL: {
      width: "138px",
      height: "44px",
    },
    TABLET: {
      width: "160px",
      height: "56px",
    },
  },
  extraLarge: {
    default: {
      width: "192px",
      height: "54px",
    },
    LAPTOP_BIG: {
      width: "240px",
      height: "70px",
    },
    LAPTOP: {
      width: "192px",
      height: "54px",
    },
    LAPTOP_MEDIUM: {
      width: "174px",
      height: "48px",
    },
    LAPTOP_SMALL: {
      width: "160px",
      height: "44px",
    },
    TABLET: {
      width: "192px",
      height: "54px",
    },
  },
  block: {
    default: {
      width: "260px",
      height: "60px",
    },
    LAPTOP_BIG: {
      width: "312px",
      height: "70px",
    },
    LAPTOP: {
      width: "260px",
      height: "60px",
    },
    LAPTOP_MEDIUM: {
      width: "210px",
      height: "48px",
    },
    LAPTOP_SMALL: {
      width: "200px",
      height: "44px",
    },
    TABLET: {
      width: "260px",
      height: "60px",
    },
    MOBILE: {
      width: "200px",
      height: "60px",
    },
  },
};

const getButtonWidth = (size: ButtonSizes, breakpoint: BreakpointNameOrDefault = "default") => {
  return !buttonSizes[size][breakpoint] ? buttonSizes[size].default.width : buttonSizes[size][breakpoint]!.width;
};

const getButtonHeight = (size: ButtonSizes, breakpoint: BreakpointNameOrDefault = "default") => {
  return !buttonSizes[size][breakpoint] ? buttonSizes[size].default.height : buttonSizes[size][breakpoint]!.height;
};

export const ClipPathButton = styled.div<ClippedButtonProps>`
  width: ${props => getButtonWidth(props.size)};
  height: ${props => getButtonHeight(props.size)};
  clip-path: url(#${props => props.id});
  background: ${props => (props.active ? colors.red._500 : colors.gray._200)};
  display: flex;
  overflow: hidden;
  align-items: center;
  overflow: hidden;
  justify-content: center;
  white-space: nowrap;

  :active {
    background: ${props => (props.active ? colors.red._600 : colors.gray._200)};
  }

  :hover {
    background: ${props => (props.active ? colors.red._600 : colors.gray._200)};
  }

  ${props => media.down("LAPTOP_BIG")`
    width: ${getButtonWidth(props.size, "LAPTOP_BIG")};
    height: ${getButtonHeight(props.size, "LAPTOP_BIG")};
  `}

  ${props => media.down("LAPTOP")`
    width: ${getButtonWidth(props.size, "LAPTOP")};
    height: ${getButtonHeight(props.size, "LAPTOP")};
  `}

  ${props => media.down("LAPTOP_MEDIUM")`
    width: ${getButtonWidth(props.size, "LAPTOP_MEDIUM")};
    height: ${getButtonHeight(props.size, "LAPTOP_MEDIUM")};
  `}

  ${props => media.down("LAPTOP_SMALL")`
    width: ${getButtonWidth(props.size, "LAPTOP_SMALL")};
    height: ${getButtonHeight(props.size, "LAPTOP_SMALL")};
  `}

  ${props => media.down("TABLET")`
    width: ${getButtonWidth(props.size, "TABLET")};
    height: ${getButtonHeight(props.size, "TABLET")};
  `}

  ${props => media.down("MOBILE")`
    width: ${getButtonWidth(props.size, "MOBILE")};
    height: ${getButtonHeight(props.size, "MOBILE")};
  `}
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-top: -1.5px;
`;

type ButtonContainerProps = {
  active?: boolean;
  animated?: boolean;
  disabled?: boolean;
};

export const ButtonContainer = styled.button<ButtonContainerProps>`
  position: relative;
  background: none;
  border: none;
  transition: transform 0.05s ease;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  overflow: hidden;
  ${props =>
    !props.disabled &&
    css`
      :active {
        transform: scale(0.95);
      }
    `}

  :focus {
    outline: none;
    ${ClipPathButton} {
      background: ${props => (props.disabled ? colors.gray._200 : colors.red._600)};
    }
  }
`;

export const ButtonContent = styled.div<ButtonContainerProps>`
  text-align: center;
  display: inline-block;
  position: relative;
  padding: 20px 0px;
  width: 100%;
  overflow: hidden;
  transition: all 0.2s linear 0s;
  font-weight: 600;
  font-size: 20px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: ${props => (props.disabled ? colors.gray._600 : colors.white)};

  &:before {
    content: ">>";
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    top: 0;
    opacity: 0;
    height: 100%;
    width: 70px;
    transition: all 0.2s linear 0s;
  }

  ${props =>
    props.animated &&
    !props.disabled &&
    css`
      &:hover {
        text-indent: -20px;
        &:before {
          opacity: 1;
          text-indent: 0px;
        }
      }
    `}

  > span {
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -56%);
  }

  ${media.down("LAPTOP")`
     font-size: 16px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
     font-size: 14px;
  `}

  ${media.down("LAPTOP_SMALL")`
     font-size: 13px;
  `}

   ${media.down("TABLET")`
     font-size: 16px;
  `}
`;

export const ButtonSvg = styled.svg`
  position: absolute;
  width: 0;
  height: 0;
`;
