import React, { FunctionComponent } from "react";
import {
  Container,
  FailureContainer,
  FailureDescription,
  HeroContainer,
  HeroMobileContainer,
  WhiteSheetFix,
} from "modules/OrderingForm/Failure/Failure.components";
import WhiteBox from "modules/shared/components/WhiteBox/WhiteBox";
import MobileSheet from "modules/shared/components/Mobile/MobileSheet";

type FailureConfiguration = {
  header: string;
  mainButtonText: string;
  description: JSX.Element;
  mainButttonClick: () => void;
  underlinedButtonClick: () => void;
  loading?: boolean;
  hero: JSX.Element;
};

type FailureProps = {
  configuration: FailureConfiguration;
};

const Failure: FunctionComponent<FailureProps> = ({ configuration }) => {
  const { header, mainButtonText, description, mainButttonClick, underlinedButtonClick, loading } = configuration;

  return (
    <>
      {/* On mobile there is white space below content. To fix this we need to add additional view. */}
      <WhiteSheetFix />
      <Container>
        <MobileSheet>
          <WhiteBox
            mainButton={{ text: mainButtonText, action: mainButttonClick, loading }}
            underlinedButton={{ text: "Cancel my Order", action: underlinedButtonClick }}
          >
            <FailureContainer>
              <h2>{header}</h2>
              <FailureDescription>{description}</FailureDescription>
              <HeroContainer>{configuration.hero}</HeroContainer>
            </FailureContainer>
          </WhiteBox>
        </MobileSheet>
        <HeroMobileContainer>{configuration.hero}</HeroMobileContainer>
      </Container>
    </>
  );
};

export default Failure;
