import React, { FunctionComponent } from "react";
import { Container } from "modules/shared/components/Sidebar/DetailsItem/Items/Items.components";
import DetailsItem from "modules/shared/components/Sidebar/DetailsItem";
import SidebarSections from "modules/shared/components/Sidebar/SidebarSections";
import routesPaths from "modules/Routing/routesPaths";
import useOrderContext from "modules/App/OrdersLayout/OrderContext/useOrderContext";
import { useHistory } from "react-router-dom";
import { Link } from "react-scroll";
import { SCROLL_DURATION } from "modules/shared/components/Sidebar/Sidebar";
import { IOrderType } from "@medlog/shared/models/orders/IOrder";
import { IOrderState } from "@medlog/shared/models/orders/IOrderState";
import { isSidebarPathAllowed } from "common/navigation/navigationHandler";
import { isSidebarItemActive, isSidebarItemChecked } from "modules/shared/components/Sidebar/SidebarHelpers";

const PaymentItem: FunctionComponent = () => {
  const { order, orderDetails } = useOrderContext();
  const history = useHistory();
  const checked = isSidebarItemChecked(SidebarSections.PAYMENT, order);
  const isAllowed = isSidebarPathAllowed(routesPaths.SUMMARY, order, orderDetails);
  const active = isSidebarItemActive(SidebarSections.PAYMENT, location.pathname);

  const handleClick = () => {
    if (isAllowed) {
      if (order.type === IOrderType.URGENT) {
        switch (order.currentState) {
          case IOrderState.CREATED:
            history.push(routesPaths.AVAILABILITY);
            break;
          case IOrderState.PAYMENT_PENDING:
            history.push(routesPaths.SUMMARY);
            break;
          case IOrderState.SAMEDAY_INSTEAD:
            history.push(routesPaths.RESCHEDULE);
            break;
          case IOrderState.REJECTED:
            history.push(routesPaths.REJECTION);
            break;
        }
      } else {
        history.push(routesPaths.SUMMARY);
      }
    }
  };

  return (
    <Link
      name={routesPaths.SUMMARY}
      to={routesPaths.SUMMARY}
      smooth={true}
      duration={SCROLL_DURATION}
      containerId="containerId"
    >
      <DetailsItem
        active={active}
        checked={checked}
        type={SidebarSections.PAYMENT}
        onClick={handleClick}
        animationDisabled={!isAllowed}
      >
        <Container></Container>
      </DetailsItem>
    </Link>
  );
};

export default PaymentItem;
