import React, { FunctionComponent, useState } from "react";
import { IParcelSize } from "@medlog/shared/models/orders/IOrder";
import {
  ButtonBorderContainer,
  ButtonContainer,
  InformationsContainer,
  ButtonImage,
  ButtonImageContainer,
} from "modules/OrderingForm/PackageSize/PackageSizeButton/PackageSizeButton.components";

import IconSmall from "modules/OrderingForm/PackageSize/icons/small.png";
import IconMedium from "modules/OrderingForm/PackageSize/icons/medium.png";
import IconLarge from "modules/OrderingForm/PackageSize/icons/large.png";
import { getSizeDimensions, getSizeInformations, getSizeTitle } from "common/helpers/sizeHelpers";

const getSizeIcon = (size: IParcelSize) => {
  switch (size) {
    case IParcelSize.SMALL:
      return IconSmall;
    case IParcelSize.MEDIUM:
      return IconMedium;
    case IParcelSize.LARGE:
      return IconLarge;
  }
};

type OrderTypeButtonProps = {
  selected: boolean;
  type: IParcelSize;
  delay: number;
  onClick: () => void;
};

const PackageSizeButton: FunctionComponent<OrderTypeButtonProps> = ({ delay, type, selected, onClick }) => {
  const [focused, setFocused] = useState(false);
  return (
    <ButtonBorderContainer highlighted={focused} onClick={onClick} selected={selected}>
      <ButtonContainer type="button" onFocus={() => setFocused(true)} onBlur={() => setFocused(false)}>
        <ButtonImageContainer>
          <ButtonImage src={getSizeIcon(type)} delay={delay} />
        </ButtonImageContainer>
        <InformationsContainer>
          <h2>{getSizeTitle(type)}</h2>
          <h3>{getSizeInformations(type)}</h3>
          <span>{getSizeDimensions(type)}</span>
        </InformationsContainer>
      </ButtonContainer>
    </ButtonBorderContainer>
  );
};

export default PackageSizeButton;
