import Firebase from "firebase/app";
import { firestore } from "config/firebase/firebase";
import IRate from "@medlog/shared/models/rating/IRate";
import { IOrder } from "@medlog/shared/models/orders/IOrder";

export const sendRate = async (order: IOrder, rate: IRate, description: string) => {
  await firestore
    .collection("rates")
    .doc(order.uid)
    .set({
      uid: order.uid,
      orderDate: order.date,
      orderType: order.type,
      rescheduled: order.rescheduled ?? false,
      rate: rate,
      description: description,
      timestamp: Firebase.firestore.FieldValue.serverTimestamp(),
      archived: false,
    });
};
