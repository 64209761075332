import { IOrder, IOrderType } from "@medlog/shared/models/orders/IOrder";
import routesPaths from "modules/Routing/routesPaths";
import { History } from "history";
import { IDeliveryDetails, IOrderDetails } from "@medlog/shared/models/orders/IOrderDetails";
import { baseOrder } from "modules/App/OrdersLayout/OrderContext/Storage";
import { areDeliveriesDisabled } from "common/firebase/deliveriesConfig/areDeliveriesDisabled";

export const handleCheckAvailabilityAgain = async (
  order: IOrder,
  history: History<unknown>,
  setupOrder: (order: IOrder, details?: IOrderDetails) => void,
  clearOrder: () => void
) => {
  const deliveriesDisabled = await areDeliveriesDisabled("urgent");
  if (deliveriesDisabled && order.type !== IOrderType.PRE_BOOK) {
    history.push(routesPaths.REJECTION, true);
  } else if (order.type === IOrderType.URGENT) {
    const orderDetails = order.pickupAndDropoff!;
    const pickupDetails: IDeliveryDetails = {
      ...orderDetails.pickupDetails,
      postcodeSelected: true,
    };

    const dropOffDetails: IDeliveryDetails = {
      ...orderDetails.dropOffDetails,
      postcodeSelected: true,
    };
    const newUrgent: IOrder = {
      ...baseOrder,
      date: new Date(),
      price: order.price,
      type: IOrderType.URGENT,
      phone: order.phone,
      email: order.email,
      parcelSize: order.parcelSize,
      pickupAndDropoff: {
        pickupDetails,
        dropOffDetails,
      },
    };

    setupOrder(newUrgent, newUrgent.pickupAndDropoff!);
    history.push(routesPaths.DETAILS);
  } else {
    clearOrder();
    history.push(routesPaths.HOME, true);
  }
};
