import styled, { css, keyframes } from "styled-components/macro";
import { colors } from "@medlog/shared/styles/colors";
import { media } from "@medlog/shared/styles/media";
import { GetHeroSizeWithRatio } from "modules/shared/styles/mixins";

const ShakeAnimation = keyframes`
  0% { transform: translate(2px, 2px) rotate(0deg); }
  1% { transform: translate(-2px, -3px) rotate(-2deg); }
  2% { transform: translate(-4px, 0px) rotate(2deg); }
  3% { transform: translate(4px, 3px) rotate(0deg); }
  4% { transform: translate(2px, -2px) rotate(2deg); }
  5% { transform: translate(-2px, 3px) rotate(-2deg); }
  6% { transform: translate(-4px, 2px) rotate(0deg); }
  7% { transform: translate(4px, 2px) rotate(-2deg); }
  8% { transform: translate(-2px, -2px) rotate(2deg); }
  9% { transform: translate(2px, 3px) rotate(0deg); }
  10% { transform: translate(2px, -3px) rotate(-2deg); }
  11% { transform: translate(0px, 0px) rotate(0deg); }
`;

export const Container = styled.div`
  position: relative;
  height: 100%
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const IconClockImage = styled.img`
  position: absolute;
  display: flex;
  left: 0px;
  right: 0px;
  top: -158px;
  width: 296px;
  height: 296px;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;

  ${media.down("LAPTOP")`
    top: -140px; width: 238px; height: 238px;`}

  ${media.down("LAPTOP_MEDIUM")`
    top: -102px; 
  `}

  ${media.down("LAPTOP_SMALL")`
    width: 200px;
    height: 200px;
    top: -92px;
  `}

  ${media.down("TABLET")`
    display: flex;
  `}
`;

export const IconHeroImage = styled(IconClockImage)`
  ${GetHeroSizeWithRatio(286, 0.85)}
  top: -185px;
  margin: 0px auto;
  left: 0;
  right: 0;
  z-index: 100;

  ${media.down("LAPTOP")`
    top: -120px; 
    ${GetHeroSizeWithRatio(186, 0.85)}
  `}

  ${media.down("LAPTOP_MEDIUM")`
    top: -117px; 
    ${GetHeroSizeWithRatio(180, 0.85)}
  `}

  ${media.down("TABLET")`
    top: -112px; 
  ${GetHeroSizeWithRatio(180, 0.85)}
  `}
`;

export const CancelOrderButton = styled.button`
  top: 61px;

  position: relative;
  font-weight: 600;
  font-size: 20px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.01em;
  text-decoration-line: underline;
  color: ${colors.red._500};
  border: none;
  background-color: transparent;
  width: 100%;
  cursor: pointer;

  ${media.down("LAPTOP")`
    font-size: 16px;
    top: 41px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    font-size: 14px;
    top: 21px;
  `}

  ${media.down("LAPTOP_SMALL")`
    font-size: 12px;
  `}

  ${media.down("TABLET")`
    top: 61px;
    font-size: 16px;
  `}
`;

export const BoxOuterContainer = styled.div`
  margin-top: 41px;
  display: flex;
  position: relative;

  ${media.down("LAPTOP")`
    margin-top: 41px;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    margin-top: 24px;
  `}

  ${media.down("LAPTOP_SMALL")`
    margin-top: 16px;
  `}

  ${media.down("TABLET")`
    margin-top: 41px;
  `}
`;

export const BoxContainer = styled.div`
  border: 1px solid ${colors.greyscale.white};
  background: white;
  border-radius: 40px;
  width: 683px;

  padding: 88px 0 56px 0;

  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #f2f5f8;
  box-sizing: border-box;
  box-shadow: 0px -4px 24px -18px rgba(58, 68, 74, 0.2), 0px 8px 24px -18px rgba(58, 68, 74, 0.2);
  border-radius: 30px;
  z-index: 10 !important;

  ${media.down("LAPTOP")`
    width: 546px;
    padding: 68px 0 47px 0;
  `}

  ${media.down("LAPTOP_MEDIUM")`
    width: 480px;
    padding: 52px 0 32px 0;
  `}

  ${media.down("LAPTOP_SMALL")`
    width: 453px;
    padding: 60px 0 32px 0;
  `}

  ${media.down("TABLET")`
    width: 544px;
    padding: 68px 0 56px 0;
  `}
`;

export const TimerContainer = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  top: calc(-56px / 2);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  width: fit-content;
`;

export const AvailabilityWrapper = styled.div<{ shakeButton: boolean }>`
  justify-content: center;
  text-align: center;
  align-items: center;

  ${p =>
    p.shakeButton &&
    css`
      > button {
        margin-top: 32px;
        animation: ${ShakeAnimation} 10s infinite;
        animation-delay: 10s;
      }
    `}

  > h1 {
    margin: 0px;
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;
    text-align: center;
    letter-spacing: -0.02em;
    color: ${colors.gray._800};
  }

  > h3 {
    margin: 0 auto;
    margin-top: 12px;
    font-weight: 300;
    font-size: 22px;
    line-height: 140%;
    text-align: center;
    color: ${colors.gray._600};
    max-width: 463px;
  }

  ${media.down("LAPTOP")`
    >h1 { font-size: 32px; }
    >h3 { font-size: 18px; max-width: 370px;}
  `}

  ${media.down("LAPTOP_MEDIUM")`
    >h1 { font-size: 30px; }
    >h3 { font-size: 16px; max-width: 334px; }
  `}

  ${media.down("LAPTOP_SMALL")`
    >h1 { font-size: 24px; }
    >h3 { font-size: 14px; max-width: 334px; }
  `}

  ${media.down("TABLET")`
    >h1 { font-size: 32px; }
    >h3 { font-size: 18px; max-width: 370px; }
  `}
`;

export const InformationsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1;
  align-items: center;

  > :nth-child(1) {
    margin-right: 16px;
  }

  & + button {
    margin-top: 24px;

    ${media.down("LAPTOP")`
      margin-top: 12px;
    `}

    ${media.down("LAPTOP_MEDIUM")`
      margin-top: 8px;
    `}

    ${media.down("LAPTOP_SMALL")`
      margin-top: 8px;
    `}


    ${media.down("TABLET")`
      margin-top: 12px;
    `}
  }
`;

export const InformationBox = styled.div`
  height: 104px;
  border: 1px solid ${colors.gray._300};
  box-sizing: border-box;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 40px 0 32px 0;

  > h1 {
    margin: 0px 47px;
    font-weight: 600;
    font-size: 32px;
    line-height: 120%;
    letter-spacing: -0.005em;
    color: ${colors.gray._800};
  }

  > h2 {
    margin: 0px 47px;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 120%;
    text-align: center;
    letter-spacing: -0.02em;
    color: ${colors.gray._800};
  }

  > h4 {
    margin: 0px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    text-align: center;
    color: ${colors.gray._600};
  }

  ${media.down("LAPTOP")`
    height: 83px;
    margin: 32px 0 32px 0;
    > h2 { font-size: 26px}
    > h1 { font-size: 26px }
    > h4 { font-size: 12px }
  `}

  ${media.down("LAPTOP_MEDIUM")`
    height: 70px;
    margin: 32px 0 16px 0;
    > h2 { font-size: 22px}
    > h1 { font-size: 22px }
  `}

  ${media.down("LAPTOP_SMALL")`
    height: 68px;
    margin: 24px 0 16px 0;
    > h2 { font-size: 20px}
    > h1 { font-size: 20px }
  `}

  ${media.down("TABLET")`
    height: 83px;
    margin: 40px 0 32px 0;
    > h2 { font-size: 26px}
    > h1 { font-size: 26px }
    > h4 { font-size: 12px }
  `}
`;
