import { IOrder } from "@medlog/shared/models/orders/IOrder";
import Firebase from "firebase/app";

export const OrderConverter = {
  toFirestore(order: IOrder): Firebase.firestore.DocumentData {
    return { ...order };
  },

  fromFirestore(snapshot: Firebase.firestore.QueryDocumentSnapshot): IOrder {
    const data = snapshot.data();
    const order = data as IOrder;
    const paymentStartDate = data.paymentStartDate;
    if (paymentStartDate instanceof Firebase.firestore.Timestamp) {
      order.paymentStartDate = (paymentStartDate as Firebase.firestore.Timestamp).toDate();
    }
    if (data.pickupDate instanceof Firebase.firestore.Timestamp) {
      order.pickupDate = (data.pickupDate as Firebase.firestore.Timestamp).toDate();
    }
    if (data.dropOffDate instanceof Firebase.firestore.Timestamp) {
      order.dropOffDate = (data.dropOffDate as Firebase.firestore.Timestamp).toDate();
    }
    if (data.handledAt instanceof Firebase.firestore.Timestamp) {
      order.handledAt = (data.handledAt as Firebase.firestore.Timestamp).toDate();
    }
    if (data.preBookDeliveryDate instanceof Firebase.firestore.Timestamp) {
      order.preBookDeliveryDate = (data.preBookDeliveryDate as Firebase.firestore.Timestamp).toDate();
    }
    if (data.preBookDeliveryTime?.deliveryTime instanceof Firebase.firestore.Timestamp) {
      order.preBookDeliveryTime!.deliveryTime = (
        data.preBookDeliveryTime?.deliveryTime as Firebase.firestore.Timestamp
      ).toDate();
    }
    if (data.paymentTime instanceof Firebase.firestore.Timestamp) {
      order.paymentTime = (data.paymentTime as Firebase.firestore.Timestamp).toDate();
    }
    if (data.copyDate instanceof Firebase.firestore.Timestamp) {
      order.copyDate = (data.copyDate as Firebase.firestore.Timestamp).toDate();
    }

    if (data.preBookDeliveryTime?.deliveryTime instanceof Firebase.firestore.Timestamp) {
      order.preBookDeliveryTime!.deliveryTime = (
        data.preBookDeliveryTime?.deliveryTime as Firebase.firestore.Timestamp
      ).toDate();
    }

    return {
      ...order,
      date: (data.date as Firebase.firestore.Timestamp).toDate(),
    };
  },
};
