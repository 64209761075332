import React, { FunctionComponent, useEffect, useState } from "react";
import { FormikProps, Form } from "formik";
import { ContactDetailsFormValues } from "modules/OrderingForm/ContactDetails/form";
import InputField from "@medlog/shared/components/Input/InputField";
import PhoneInput from "modules/shared/components/PhoneInput";
import {
  ContactDetailsInputsInnerWrapper,
  ContactDetailsInputsWrapper,
  PriceConfirmation,
} from "modules/OrderingForm/ContactDetails/ContactDetails.components";
import Button from "@medlog/shared/components/Button";
import { IOrderType } from "@medlog/shared/models/orders/IOrder";
import useOrderContext from "modules/App/OrdersLayout/OrderContext/useOrderContext";
import PrivacyCheck from "modules/shared/components/PrivacyCheck/PrivacyCheck";
import { useIsMobileDown } from "common/helpers/useMedia";
import MobileButton from "modules/shared/components/Mobile/MobileButton";

const ContactDetailsFormContent: FunctionComponent<FormikProps<ContactDetailsFormValues>> = ({
  isSubmitting,
  validateForm,
  setFieldValue,
  errors,
}) => {
  const { order, orderPrice } = useOrderContext();
  const [submitCount, setSubmitCount] = useState(0);
  const [checked, setChecked] = useState(false);
  const isMobile = useIsMobileDown();

  useEffect(() => {
    setChecked(order.termsAndPrivacyAccepted === true);
  }, [order.termsAndPrivacyAccepted]);

  return (
    <Form>
      <ContactDetailsInputsWrapper>
        <ContactDetailsInputsInnerWrapper>
          <InputField label="Email address" name="email" type="email" inputSize={isMobile ? "mobile" : "large"} />
          <PhoneInput errors={errors.phone} submitCount={submitCount} />
        </ContactDetailsInputsInnerWrapper>
        {isMobile && (
          <PriceConfirmation>
            <h3>£{orderPrice && (orderPrice / 100).toFixed(2)}</h3>
            <span>Order Price</span>
          </PriceConfirmation>
        )}
        <PrivacyCheck
          buttonText={order.type === IOrderType.URGENT ? "check availability" : "next"}
          checked={checked}
          onClick={() => {
            setChecked(!checked);
            setFieldValue("termsAndPrivacyAccepted", !checked);
          }}
        />
        {!isMobile && (
          <Button
            active={checked}
            loading={isSubmitting}
            size={order.type === IOrderType.URGENT ? "extraLarge" : "large"}
            type="submit"
            onClick={() => {
              setSubmitCount(submitCount + 1);
              validateForm();
            }}
          >
            {order.type == IOrderType.URGENT ? "Check availability" : "Next"}
          </Button>
        )}
      </ContactDetailsInputsWrapper>
      {isMobile && (
        <MobileButton
          isSubmit={true}
          mode="text"
          active={checked}
          text={order.type == IOrderType.URGENT ? "Check availability" : "Next"}
          onClick={() => {
            setSubmitCount(submitCount + 1);
            validateForm();
          }}
        />
      )}
    </Form>
  );
};

export default ContactDetailsFormContent;
