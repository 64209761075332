import React, { FunctionComponent } from "react";
import {
  Container,
  DateTimeDetails,
  DateTimeSpan,
  Pointer,
  TextContainer,
} from "modules/shared/components/Sidebar/DetailsItem/Items/Items.components";
import DetailsItem from "modules/shared/components/Sidebar/DetailsItem";
import SidebarSections from "modules/shared/components/Sidebar/SidebarSections";
import useOrderContext from "modules/App/OrdersLayout/OrderContext/useOrderContext";
import { IOrder, IOrderType } from "@medlog/shared/models/orders/IOrder";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import routesPaths from "modules/Routing/routesPaths";
import { Link } from "react-scroll";
import { SCROLL_DURATION } from "modules/shared/components/Sidebar/Sidebar";
import { isSidebarPathAllowed } from "common/navigation/navigationHandler";
import { formatAsLondonTime } from "@medlog/shared/helpers/formatAsLondonTime";
import { isSidebarItemActive, isSidebarItemChecked } from "modules/shared/components/Sidebar/SidebarHelpers";

const formatDate = (order: IOrder) => {
  if (order.type === IOrderType.PRE_BOOK && order.preBookDeliveryDate) {
    return format(order.preBookDeliveryDate, "dd.MM.yyyy");
  } else if (order.date) {
    return format(order.date, "dd.MM.yyyy");
  }
  return "";
};

const formatTime = (order: IOrder) => {
  if (order.type === IOrderType.PRE_BOOK) {
    return order.preBookDeliveryTime?.timeSpecified
      ? formatAsLondonTime(order.preBookDeliveryTime.deliveryTime!)
      : "8AM - 8PM";
  } else {
    return "Today";
  }
};

const shouldDisplayTime = (order: IOrder) => {
  return order.preBookDeliveryTime?.timeSpecified || order.type != IOrderType.PRE_BOOK
    ? true
    : order.preBookDeliveryTime !== undefined;
};

const DateItem: FunctionComponent = () => {
  const { order, orderDetails } = useOrderContext();
  const section = order.type === IOrderType.PRE_BOOK ? SidebarSections.DATE_AND_TIME : SidebarSections.DATE;
  const checked = isSidebarItemChecked(section, order);
  const history = useHistory();
  const isAllowed = isSidebarPathAllowed(routesPaths.HOME, order, orderDetails);
  const active = isSidebarItemActive(section, location.pathname);
  const isPrebook = order.type === IOrderType.PRE_BOOK;

  const handleClick = () => {
    if (isAllowed) {
      if (order.type === IOrderType.PRE_BOOK) {
        history.push(routesPaths.DATE);
      } else {
        history.push(routesPaths.HOME);
      }
    }
  };

  const handleTimeClick = () => {
    history.push(routesPaths.TIME);
  };

  return (
    <Link
      name={routesPaths.HOME}
      to={routesPaths.HOME}
      smooth={true}
      duration={SCROLL_DURATION}
      containerId="containerId"
    >
      <DetailsItem
        forceDisplayContent={order.preBookDeliveryDate !== undefined}
        animationDisabled={!isAllowed}
        active={active}
        checked={checked}
        showBadge={order.type === IOrderType.URGENT}
        type={section}
        onClick={handleClick}
        enableScaling={isAllowed}
      >
        <Container>
          {isPrebook ? (
            <DateTimeDetails>
              <DateTimeSpan onClick={handleClick}>{formatDate(order)}</DateTimeSpan>
              {shouldDisplayTime(order) && <DateTimeSpan onClick={handleTimeClick}>{formatTime(order)}</DateTimeSpan>}
            </DateTimeDetails>
          ) : (
            <TextContainer>
              <span>{formatDate(order)}</span>
              <Pointer />
              {shouldDisplayTime(order) && <span>{formatTime(order)}</span>}
            </TextContainer>
          )}
        </Container>
      </DetailsItem>
    </Link>
  );
};

export default DateItem;
