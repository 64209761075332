import React, { FunctionComponent, useState } from "react";
import {
  ButtonsWrapper,
  ModalOverlay,
  ConfirmModalContainer,
  ConfirmModalDescription,
  CancelButton,
} from "modules/shared/components/ConfirmModal/ConfirmModal.components";
import { Prompt, useHistory } from "react-router-dom";
import { useEffect } from "react";
import useOrderContext from "modules/App/OrdersLayout/OrderContext/useOrderContext";
import { IOrderState } from "@medlog/shared/models/orders/IOrderState";
import routesPaths from "modules/Routing/routesPaths";
import Button from "@medlog/shared/components/Button";
import { IOrderType } from "@medlog/shared/models/orders/IOrder";
import AppEventTarget from "common/events/AppEventTarget";
import HideMobileSidebarEvent from "common/events/HideMobileSidebarEvent";
import { useIsMobileDown } from "common/helpers/useMedia";
import { DisplayModalState } from "common/navigation/states/DisplayModalState";

const GENERAL_ROUTES = [routesPaths.MY_ORDERS, routesPaths.RATING];

const ORDERING_ROUTES = [
  routesPaths.PICKUP,
  routesPaths.PICKUP_CONTACT,
  routesPaths.DROPOFF,
  routesPaths.DROPOFF_CONTACT,
];

interface IAllowedRoutes {
  state: IOrderState | undefined;
  routes: string[];
}

const UrgentRoutes: IAllowedRoutes[] = [
  {
    state: undefined,
    routes: [routesPaths.HOME, routesPaths.POSTCODES, routesPaths.SIZE, routesPaths.DETAILS, routesPaths.REJECTION],
  },
  { state: IOrderState.CREATED, routes: [routesPaths.AVAILABILITY, routesPaths.REJECTION, routesPaths.TIMESUP] },
  {
    state: IOrderState.PAYMENT_PENDING,
    routes: [
      ...ORDERING_ROUTES,
      routesPaths.SUMMARY,
      routesPaths.CANCELATION,
      routesPaths.TIMESUP,
      routesPaths.COURIER_AVAILABLE,
    ],
  },
  {
    state: IOrderState.SAMEDAY_INSTEAD,
    routes: [...ORDERING_ROUTES, routesPaths.RESCHEDULE, routesPaths.SUMMARY, routesPaths.TIMESUP],
  },
  { state: IOrderState.REJECTED, routes: [...ORDERING_ROUTES, routesPaths.REJECTION] },
  { state: IOrderState.LOST, routes: [routesPaths.TIMESUP, routesPaths.REJECTION] },
];
const SameDayRoutes: IAllowedRoutes[] = [
  {
    state: undefined,
    routes: [
      routesPaths.HOME,
      routesPaths.POSTCODES,
      ...ORDERING_ROUTES,
      routesPaths.SIZE,
      routesPaths.DETAILS,
      routesPaths.AVAILABILITY,
      routesPaths.REJECTION,
    ],
  },
  {
    state: IOrderState.CREATED,
    routes: [
      routesPaths.HOME,
      routesPaths.POSTCODES,
      ...ORDERING_ROUTES,
      routesPaths.SIZE,
      routesPaths.DETAILS,
      routesPaths.AVAILABILITY,
      routesPaths.SUMMARY,
      routesPaths.CANCELATION,
      routesPaths.TIMESUP,
    ],
  },
  { state: IOrderState.PAYMENT_SUCCEEDED, routes: [routesPaths.CONFIRMATION] },
  { state: IOrderState.LOST, routes: [routesPaths.TIMESUP] },
];

const PreBookRoutes: IAllowedRoutes[] = [
  {
    state: undefined,
    routes: [
      routesPaths.HOME,
      routesPaths.DATE,
      routesPaths.TIME,
      routesPaths.POSTCODES,
      ...ORDERING_ROUTES,
      routesPaths.SIZE,
      routesPaths.DETAILS,
      routesPaths.AVAILABILITY,
      routesPaths.REJECTION,
    ],
  },
  {
    state: IOrderState.CREATED,
    routes: [
      routesPaths.HOME,
      routesPaths.POSTCODES,
      ...ORDERING_ROUTES,
      routesPaths.DATE,
      routesPaths.TIME,
      routesPaths.SIZE,
      routesPaths.DETAILS,
      routesPaths.AVAILABILITY,
      routesPaths.SUMMARY,
      routesPaths.CANCELATION,
      routesPaths.TIMESUP,
    ],
  },
  { state: IOrderState.PAYMENT_SUCCEEDED, routes: [routesPaths.CONFIRMATION] },
  { state: IOrderState.LOST, routes: [routesPaths.TIMESUP] },
];

enum MessageType {
  NONE,
  COURIER_FOUND,
  LOOKING_FOR_A_COURIER,
}

const getMessage = (type: MessageType) => {
  switch (type) {
    case MessageType.COURIER_FOUND:
      return "If you need to make changes to any of the previous stages, your order will be cancelled. Please be aware that we cannot guarantee that another courier will be available.";
    case MessageType.LOOKING_FOR_A_COURIER:
      return "If you need to make changes to any of the previous stages, the current courier search will be canceled.";
    case MessageType.NONE:
      return "";
  }
};

const getHeader = (type: MessageType) => {
  switch (type) {
    case MessageType.COURIER_FOUND:
      return "A courier is available!";
    case MessageType.LOOKING_FOR_A_COURIER:
      return "We are looking for a courier!";
    case MessageType.NONE:
      return "";
  }
};

const ConfirmModal: FunctionComponent = () => {
  const history = useHistory();
  const [showDialog, setShowDialog] = useState(false);
  const [cancelOrder, setCancelOrder] = useState(false);
  const { order, currentOrderState, clearOrder } = useOrderContext();
  const [messageType, setMessageType] = useState(MessageType.NONE);

  useEffect(() => {
    if (cancelOrder) {
      clearOrder();
      setCancelOrder(false);
      history.push(routesPaths.HOME);
    }
  }, [cancelOrder]);

  const handleNavigation = (pathname: string, force: boolean): boolean => {
    if (cancelOrder || force || GENERAL_ROUTES.includes(pathname)) {
      return true;
    }

    let paths: IAllowedRoutes | undefined;

    switch (order.type) {
      case IOrderType.URGENT:
        paths = UrgentRoutes.find(r => r.state === currentOrderState);
        break;
      case IOrderType.SAME_DAY:
        paths = SameDayRoutes.find(r => r.state === currentOrderState);
        break;
      case IOrderType.PRE_BOOK:
        paths = PreBookRoutes.find(r => r.state === currentOrderState);
        break;
    }
    if (paths === undefined || paths?.routes.includes(pathname)) {
      setShowDialog(false);
      setMessageType(MessageType.NONE);
      return true;
    } else {
      if (order.type === IOrderType.URGENT && !order.rescheduled) {
        if (order.currentState === IOrderState.PAYMENT_PENDING) {
          setMessageType(MessageType.COURIER_FOUND);
        } else {
          setMessageType(MessageType.LOOKING_FOR_A_COURIER);
        }
        setShowDialog(true);
      } else {
        setMessageType(MessageType.NONE);
        return true;
      }
    }

    AppEventTarget.dispatchEvent(new HideMobileSidebarEvent());
    return false;
  };

  return (
    <>
      <Prompt
        when={true}
        message={location => {
          if (location.state as DisplayModalState) {
            const state = location.state as DisplayModalState;
            if (state.displayModal) {
              setShowDialog(state.displayModal);
              setMessageType(MessageType.LOOKING_FOR_A_COURIER);
            }
            return true;
          } else {
            return handleNavigation(location.pathname, location.state == true);
          }
        }}
      />
      <ModalOverlay show={showDialog}>
        <ConfirmModalContainer show={showDialog}>
          <h2>{getHeader(messageType)}</h2>
          <ConfirmModalDescription>{getMessage(messageType)}</ConfirmModalDescription>
          <ButtonsWrapper>
            <CancelButton
              onClick={() => {
                setCancelOrder(true);
                setShowDialog(false);
              }}
            >
              Cancel the order
            </CancelButton>
            <Button
              size={useIsMobileDown() ? "large" : "extraLarge"}
              onClick={() => {
                setShowDialog(false);
              }}
            >
              Continue the order
            </Button>
          </ButtonsWrapper>
        </ConfirmModalContainer>
      </ModalOverlay>
    </>
  );
};

export default ConfirmModal;
