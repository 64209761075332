const Config = {
  analytics: {
    tracking_code: process.env.REACT_APP_GA_TRACKING_CODE,
  },
  get_address_io: {
    public_key: "OkQc9lQbBkm8vm5r7LsGrg9599",
  },
};

export default Config;
