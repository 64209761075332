import { IPreBookDeliveryTime } from "@medlog/shared/models/orders/IOrder";
import { colors } from "@medlog/shared/styles/colors";
import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { format, getDate } from "date-fns";
import { media } from "@medlog/shared/styles/media";
import { formatAsLondonTime } from "@medlog/shared/helpers/formatAsLondonTime";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  margin-right: 16px;

  > h2 {
    margin: 0px;
    padding: 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    text-align: center;
    letter-spacing: -0.02em;
    color: ${colors.gray._800};

    ${media.down("LAPTOP")`
      font-size: 20px;
    `};

    ${media.down("LAPTOP_MEDIUM")`
      font-size: 18px;
    `};

    ${media.down("LAPTOP_SMALL")`
      font-size: 16px;
    `};

    ${media.down("TABLET")`
      font-size: 20px;
    `};
  }
`;

const Divider = styled.div`
  width: 2px;
  height: 8px;
  border-radius: 5px;
  background: ${colors.red._500};
  margin-left: 7px;
  margin-right: 7px;
`;

type PrebookTimeProps = {
  date: Date;
  time: IPreBookDeliveryTime;
};

const formatDate = (date: Date) => {
  const month = format(date, "MMM");
  const dayNumber = getDate(date);
  const daySuffix = dayNumber % 10 === 1 ? "st" : dayNumber % 10 === 2 ? "nd" : dayNumber % 10 === 3 ? "rd" : "th";
  return `${month} ${dayNumber}${daySuffix}`;
};

const formatTime = (time: IPreBookDeliveryTime) => {
  if (time.timeSpecified) {
    return formatAsLondonTime(time.deliveryTime!);
  } else {
    return "8 am - 8 pm";
  }
};

const PrebookTime: FunctionComponent<PrebookTimeProps> = ({ date, time }) => {
  return (
    <Container>
      <h2>{formatDate(date)}</h2>
      <Divider />
      <h2>{formatTime(time)}</h2>
    </Container>
  );
};

export default PrebookTime;
