import ButtonLoader from "@medlog/shared/components/ButtonLoader";
import { getMinPromoPrice } from "@medlog/shared/helpers/getOrderPrice";
import { IOrderType } from "@medlog/shared/models/orders/IOrder";
import useOrderContext from "modules/App/OrdersLayout/OrderContext/useOrderContext";
import React, { FunctionComponent } from "react";
import { MobileButtonContainer, PriceButton, TextButton } from "./MobileButton.components";

type MobileButtonProps = {
  isSubmit?: boolean;
  onClick?: () => void;
  text?: string;
  loading?: boolean;
  mode?: "text" | "full" | "price";
  active?: boolean;
  customPrice?: number;
};

const MobileButton: FunctionComponent<MobileButtonProps> = ({
  isSubmit,
  onClick,
  mode,
  text,
  loading,
  active = true,
  customPrice,
}) => {
  const { order } = useOrderContext();
  mode = mode || "full";

  const withoutPrice = (
    <>
      <strong>
        <small>from</small> £
        {order.type === IOrderType.URGENT ? getMinPromoPrice(IOrderType.URGENT) : getMinPromoPrice(IOrderType.SAME_DAY)}
      </strong>
      <span>including VAT</span>
    </>
  );

  const withPrice = (price: number) => (
    <>
      <strong>£{(price / 100).toFixed(2)}</strong>
      <span>including VAT</span>
    </>
  );

  const onButtonClick = () => {
    if (onClick && active && !loading) {
      onClick();
    }
  };

  return (
    <MobileButtonContainer active={active} mode={mode}>
      <PriceButton active={active} mode={mode} type={isSubmit ? "submit" : "button"} onClick={() => onButtonClick()}>
        {customPrice ? withPrice(customPrice) : order.price ? withPrice(order.price) : withoutPrice}
      </PriceButton>
      <TextButton active={active} mode={mode} type={isSubmit ? "submit" : "button"} onClick={() => onButtonClick()}>
        {loading ? <ButtonLoader variant="dark" /> : text}
      </TextButton>
    </MobileButtonContainer>
  );
};

export default MobileButton;
