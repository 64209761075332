import styled from "styled-components";
import { media } from "@medlog/shared/styles/media";

export const SectionContainerWrapper = styled.div`
  display: flex;
  flex: 1;
  margin: auto;
  height: 100%;
  min-width: 600px;

  align-items: center;
  justify-content: center;
  padding-top: 20px;
  min-height: 550px;
  box-sizing: border-box;

  ${media.down("TABLET")`
    min-width: 500px;
  `}

  ${media.down("MOBILE")`
    min-width: unset;
    /* overflow-x: hidden;
    overflow-y: visible; */
    padding-top: 0;
  `}

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    ${media.down("MOBILE")`
        justify-content: start;
      `}

    .alignBottom {
      //margin-top: auto;
      margin-bottom: 60px;

      ${media.down("LAPTOP")`
        margin-bottom: 40px;
      `};

      ${media.down("LAPTOP_SMALL")`
        margin-bottom: 32px;
      `};

      ${media.down("MOBILE")`
        justify-content: start;
      `}
    }
  }

  .alignBottom {
    margin-top: auto;
    margin-bottom: 40px;

    ${media.down("LAPTOP_SMALL")`
        margin-bottom: 32px;
      `};
  }
`;
