import React, { FunctionComponent } from "react";
import PostcodesForm from "modules/OrderingForm/Postcodes/PostcodesForm";
import SectionContainer from "modules/shared/components/SectionContainer/SectionContainer";
import { WhiteCover } from "modules/App/OrdersLayout/OrdersLayout.components";

const PostCodes: FunctionComponent = () => {
  return (
    <>
      <SectionContainer>
        <PostcodesForm />
        <WhiteCover />
      </SectionContainer>
    </>
  );
};

export default PostCodes;
