import React, { createContext, Dispatch, SetStateAction } from "react";
import { IOrder } from "@medlog/shared/models/orders/IOrder";

export interface MyOrdersContextType {
  fetching: boolean;
  authenticated: boolean;
  orders: IOrder[];
  setOrders: Dispatch<SetStateAction<IOrder[]>>;
  tokenExpired: boolean;
  logout: () => void;
}

const MyOrdersContext: React.Context<MyOrdersContextType> = createContext<MyOrdersContextType>(
  {} as MyOrdersContextType
);

export default MyOrdersContext;
