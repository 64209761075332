import React, { FunctionComponent, useCallback } from "react";
import { Formik, FormikHelpers } from "formik";
import { useHistory } from "react-router-dom";
import {
  PickupLocationFormValues,
  pickupLocationValidationSchema,
} from "modules/OrderingForm/DeliveryDetails/PickupDetails/PickupLocation/form";
import PickupLocationFormContent from "modules/OrderingForm/DeliveryDetails/PickupDetails/PickupLocation/PickupLocationFormContent";
import routesPaths from "modules/Routing/routesPaths";
import useOrderContext from "modules/App/OrdersLayout/OrderContext/useOrderContext";
import { updateDetailsIfOrderExists } from "common/firebase/orders/updateDetailsIfOrderExists";

const PickupLocationForm: FunctionComponent = () => {
  const { order, orderDetails, setOrderDetails } = useOrderContext();
  const history = useHistory();
  const handleSubmit = useCallback(
    async (values: PickupLocationFormValues, actions: FormikHelpers<PickupLocationFormValues>) => {
      actions.setSubmitting(true);
      const updatedOrderDetails = {
        pickupDetails: {
          ...orderDetails.pickupDetails,
          placeName: values.placeName,
          placeDetails: values.placeDetails,
          additionalDetails: values.additionalInformation,
        },
        dropOffDetails: orderDetails.dropOffDetails,
      };
      try {
        await updateDetailsIfOrderExists(order, updatedOrderDetails);
        setOrderDetails(updatedOrderDetails);
        history.push(routesPaths.PICKUP_CONTACT);
      } catch (e) {
        console.error(e);
      } finally {
        actions.setSubmitting(false);
      }
    },
    []
  );

  return (
    <Formik
      initialValues={{
        placeName: orderDetails.pickupDetails.placeName,
        placeDetails: orderDetails.pickupDetails.placeDetails,
        additionalInformation: orderDetails.pickupDetails.additionalDetails,
      }}
      validationSchema={pickupLocationValidationSchema}
      onSubmit={handleSubmit}
    >
      {formProps => <PickupLocationFormContent {...formProps} />}
    </Formik>
  );
};

export default PickupLocationForm;
